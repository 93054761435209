<div class="row">


  <div class="col-lg-3 mt-lg-0 mt-4 order-2 order-lg-0">



  </div>

  <div class="col order-1 order-lg-0">


        <!-- <ul>
            <li (click)="goto('/profile')" ><a class="py-0" ><h5 class="neue-reg text-right"><i class="fas fa-user item"></i> </h5></a></li>
        </ul> -->

        <!-- <div class="col-3">
            <h5 class="neue-reg text-right"><i class="fas fa-user item"></i> {{customer.name}}</h5>
        </div>
        <div class="col-3">
            <h5 class="neue-reg text-right"><i class="fas fa-shopping-cart item"></i> {{customer.name}}</h5>
        </div> -->


  </div>



</div>


