import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map, tap } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { ProgressService } from "src/app/services/progress.service";
import { SnackService } from 'src/app/services/snack.service';
declare let $;

@Component({
  selector: 'admin-layout-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss']
})
export class LabComponent implements OnInit {
  lab: any;
  isLoaderHidden = true;
  pagination: any = {};
  dates = {
    from:new Date().toISOString().slice(0,10).split('-').reverse().join('-'),
    to:new Date().toISOString().slice(0,10).split('-').reverse().join('-')
  }
  currentPer: any;
  flags: { month: Boolean; day: Boolean, requests:boolean } = {
    month: false,
    day: false,
    requests:false
  };
  per="";

  selected_tests = [];

  pers: any = [];

  toggleFlags = (per,page=1) => {
    this.pagination=[];
    this.per="requests";
      this.pers = []
      setTimeout(()=> {
        let min_date = this.dates.from.split('-').reverse().join('-')
        let max_date = this.dates.to.split('-').reverse().join('-')
        this.api.Admin.labs.get_requests(
          this.lab.id,{min_date , max_date,page}
        ).subscribe((res) => {
          this.pers = res["data"];
          this.pagination = this.data.extract_pagination(res)

        });
      }, 0)

  };

  getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }



  constructor(
    private activatedRoute: ActivatedRoute,
    private progress: ProgressService,
    private api: ApiService,
    public router: Router,
    public snack : SnackService,
    private data: DataService
  ) {}




  ngOnInit() {

    this.activatedRoute.params.subscribe((param) => {
      this.progress.on();
      console.log("reached");
      this.api.Admin.labs.get_one(param.id).subscribe((res) => {
        this.progress.off();
        this.lab = res;
        this.toggleFlags('requests')
      });
    });
  }


}
