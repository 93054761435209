import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackService } from 'src/app/services/snack.service';
import { ProgressService } from 'src/app/services/progress.service';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { DataService } from 'src/app/services/data.service';
import { NgForm } from '@angular/forms';
import { Arabic } from 'flatpickr/dist/l10n/ar';
import { AgoPipe } from 'src/app/pipes/ago.pipe';

declare var $: any;

@Component({
  selector: 'technician-layout-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class NewRequestComponent implements OnInit {
  request: any = null;

  tests: Array<any> = [];
  isProfileSelected = false;
  isLabNotesSaveHidden = true;
  is_expert: any = false;
  isTestSelected = false;
  request_id: any;
  action: any = '';
  samples$: Observable<Array<{ code: string; name: string; id: number }>> = of(
    []
  );

  //-----------------------------------------------------------------------------------------
  labs$: Observable<any> = of([]);
  pricing: any = [];
  searchType: any = 'p';
  total: any = 0;
  selectedProfiles: any = [];
  selectedTests: any = [];
  patient: Object;
  notes: Array<any> = [];

  download_upload_modal_data = {
    title: '',
  };

  buttons: Array<{ label: string; data: any }> = [
    {
      label: 'Upload',
      data: { title: 'Upload' },
    },
  ];
  bacteria_antibaiotics: any;
  cfu: String;
  bacteria_selected: Array<any> = [];
  anti_selected: Array<any> = [];
  historyData: any = [];
  pagination: any = {};
  page: number = 1;
  total_rows: number;
  hasActivityLog: Boolean = false;
  fromParams = null;
  toParams = null;
  patient_searchParams = '';
  typeParams = 'name';
  lab_to_lab: boolean = true
  getLabs() {
    this.labs$ = this.api.Admin.labs.get({}).pipe(map(res => res['labs']));
  }

  ////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private route: ActivatedRoute,
    public api: ApiService,
    public snack: SnackService,
    public router: Router,
    public progress: ProgressService,
    public data: DataService,
    private ago: AgoPipe
  ) {}

  ////////////////////////////////////////////////////////////////////////////////////

  printHisto() {
    this.api.Common.printables.histo(this.request_id).subscribe((res) => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

  getAntibiotics(id) {
    this.api.Admin.bacteria.antibiotics(id).subscribe((res) => {
      this.bacteria_antibaiotics = res;
    });
  }

  addBacteria({ bacterias, id }, added_bacteria) {
    let bacteria = bacterias
    let bacteria_antibaiotics2;
    added_bacteria['pivot'] = {
      cfu: null,
    };
    bacteria_antibaiotics2 = this.getAntibiotics(added_bacteria.id);
    (bacteria || []).forEach((bac) => {
      this.bacteria_selected.push(bac.id);
    });
    if (!this.bacteria_selected.includes(added_bacteria.id)) {
      this.api
        .Common.requests.samples.bacteria.add(this.request_id, {
          sample_id: id,
          bacteria_id: added_bacteria.id,
        })
        .subscribe((res) => {
          this.snack.show('Bacteria was added successfully');
          if (!bacteria) bacteria = [];
          if (this.bacteria_antibaiotics) {
            added_bacteria.antibiotics = this.bacteria_antibaiotics;
          } else {
            added_bacteria.antibiotics = [];
          }
          console.log('added_bacteria.antibiotic', added_bacteria.antibiotic);
          // added_bacteria.antibiotic = []
          bacteria.unshift(added_bacteria);
        });
    } else {
      this.snack.showerror('This Bacteria Already Added');
    }
  }

  addCfu({ id }, bacteria_id, cf) {
    this.api.Common.requests.samples.bacteria
      .CFU(this.request_id, {
        sample_id: id,
        bacteria_id: bacteria_id.id,
        cfu: cf,
      })
      .subscribe((res) => {
        if (!res['error']) {
          this.snack.show('CFU was added successfully');
        } else {
          this.snack.showerror('CFU is more than 100 char');
        }
      });
  }

  addAntibiotic({ antibiotics }, added_antibiotic) {
    (antibiotics || []).forEach((anti) => {
      this.anti_selected.push(anti.id);
    });
    if (!this.anti_selected.includes(added_antibiotic.id)) {
      antibiotics.unshift(added_antibiotic);
      this.snack.show('Select a sensitivity please');
    } else {
      this.snack.showerror('This Antibiotic Already Added');
    }
  }

  addSensitivity(sample_id, bacteria_id, { id, sensitivity }) {
    let antibiotic_id = id;
    setTimeout(() => {
      this.api
        .Common.requests.samples.bacteria.antibiotics.add(this.request_id, {
          antibiotic_id,
          bacteria_id,
          sensitivity,
          sample_id,
        })
        .subscribe((res) => {
          this.snack.show('antibiotic was added successfully');
        });
    }, 0);
  }



  removeSampleBacteria({ bacterias, id }, removed_bacteria, index) {
    this.api
      .Common.requests.samples.bacteria.remove(this.request_id, {
        sample_id: id,
        bacteria_id: removed_bacteria.id,
      })
      .subscribe((res) => {
        this.snack.show('Bacteria was removed successfully');
        bacterias.splice(index, 1);
      });
  }

  removeSampleAnti(sample, bacteria, anti, index) {
    let sensitivity = anti['sensitivity'];
    if (!sensitivity) return bacteria['antibiotics'].splice(index, 1);

    let sample_id = sample.id;
    let bacteria_id = bacteria.id;
    let antibiotic_id = anti.id;

    this.api.Common.requests.samples.bacteria.antibiotics.remove
      (this.request_id, {
        antibiotic_id,
        bacteria_id,
        sensitivity,
        sample_id,
      })
      .subscribe((res) => {
        bacteria['antibiotics'].splice(index, 1);

        this.snack.show('antibiotic was removed successfully');
      });
  }

  upload(form: NgForm) {
    let data = JSON.parse(JSON.stringify(form.value));
    data['online_tests'] = data['online_tests'].map((one) => one.id);
    this.api.Common.ltl.upload(data)
      .subscribe((done) => {
        if (done['error'])
          return this.snack.showerror('Sorry, something went wrong');

        this.snack.show('The request was uploaded successfully');
        $('#download_upload_modal').modal('hide');
      });
  }

  backWithData(){
    var queries = `?from=${this.fromParams}&to=${this.toParams}&patient_search=${this.patient_searchParams}&type=${this.typeParams}`
    this.router.navigateByUrl(`technician${queries}`)
    
  }

  ngOnInit() {
    this.api.Common.Get.lab_info().subscribe(res => {
      this.lab_to_lab = res['data']['settings']['lab']
    })
    this.getLabs();
    //-----------------------------------------------------------------------------------------
    $('#picker').flatpickr({
      dateFormat: 'Y-m-d',
      locale: this.data.language == 'arabic' ? Arabic : null,
      position: 'auto center',
    });

    //-----------------------------------------------------------------------------------------

    this.progress.off();

    this.is_expert = this.data.me['sub_roles']?.includes('histopathologist');

    //-----------------------------------------------------------------------------------------

    this.route.params.subscribe((params) => {
      //-----------------------------------------------------------------------------------------

      this.request_id = params['request_id'];

      // this.api.Common.requests.get_one(this.request_id).subscribe((res) => {
      // this.api.Tests.getRequestTests(this.request_id, -1).pipe(map((tests:Array<any>) => tests.filter(one => one.online_id)))
      // if (res["tests"]) {

      // }
      // });

      this.samples$ = this.api.Common.requests.samples.get(params['request_id']);
      //-----------------------------------------------------------------------------------------

      switch (params['action']) {
        //-----------------------------------------------------------------------------------------

        case 'edit': {
          this.action = 'Edit';
          this.getRequest();

        if(this.data.me.sub_roles.indexOf('activityLog') != -1){
          this.hasActivityLog = true
        }

          break;
        }

        //-----------------------------------------------------------------------------------------

        default: {
          this.router.navigateByUrl('/');
        }

        //-----------------------------------------------------------------------------------------
      }
    });

    this.route.queryParams.subscribe((params) => {
      console.log("params_check",params['from'])
      this.fromParams = params['from'];
      this.toParams = params['to'];
      this.patient_searchParams = params['patient_search'];
      this.typeParams = params['type'];
    })


    //-----------------------------------------------------------------------------------------
  }
  // @HostListener('keydown', ['$event']) onKeyDown(e) {
  //   if ((e.which == 9 || e.keyCode == 9)) {
  //       e.preventDefault();
  //       // if (e.srcElement.nextElementSibling) {
  //         //   e.srcElement.nextElementSibling.focus();
  //         //   $('input').keyup(function(){
  //         //     if(this.value.length==$(this).attr("maxlength")){
  //         //         $(this).next().focus();
  //         //     }
  //         // });
  //       // }
  //       // else{
  //       //     console.log('close keyboard');
  //       // }
  //       return;
  //       }

  //   }

  ////////////////////////////////////////////////////////////////////////////////////

  toggle(value) {
    this.searchType = value;
    console.log(this.searchType);
  }

  ////////////////////////////////////////////////////////////////////////////////////

  saveLabNotes(lab_input) {
    this.api.Common.requests
      .update_laboratory_information(this.request_id, {
        laboratory_information: lab_input.value,
        request_id: this.request_id,
        correspondent_id: this.request['correspondent']['id'],
      })
      .subscribe((res) => {
        if (!res['error']) {
          this.snack.show(res['message']);
          this.isLabNotesSaveHidden = true;
        }
      });
  }

  ////////////////////////////////////////////////////////////////////////////////////

  onSubmit(form) {
    // switch(this.action){
    //   case 'New' : {
    //     this.createRequest(form)
    //     break;
    //   }
    //   case 'Edit' : {
    //     this.editRequest(form)
    //     break;
    //   }
  }

  ////////////////////////////////////////////////////////////////////////////////////

  getRequest() {
    //-----------------------------------------------------------------------------------------

    this.progress.on();

    //-----------------------------------------------------------------------------------------

    this.api.Common.requests.get_one(this.request_id).subscribe((res) => {
      //-----------------------------------------------------------------------------------------

      this.progress.off();

      this.tests = res['tests'];
      this.notes = res['tests']
        .map((test) => test.notes)
        .flat()
        .map((res) => {
          if (res.results) res.val = res.results.content;
          return res;
        });

      //-----------------------------------------------------------------------------------------

      [res['patient'].age, res['patient'].age_type, res['patient'].birthdate] =
        this.ago.transform(res['patient']['birthdate'], { slashes: true });
      this.patient = res['patient'];
      this.data.patient = res['patient'];
      this.request = res;
      this.data.request = res;

      if (this.request['correspondent']) {
        this.request.correspondent_id = res['correspondent']['id'];
      } else this.request.correspondent = {};

      this.request.pricing_id = res['pricing']['id'];
      this.request.urgent_flag = res['urgent_flag'];
      $('#picker')[0].value = res['hospitalization_date'];
      this.total = res['total'];

      // })
    });

    //-----------------------------------------------------------------------------------------
  }

  showWSaveLabNotes() {
    this.isLabNotesSaveHidden = false;
  }

  addNotesContent(note) {
    let body = {
      test_note_id: note.id,
      patient_request_id: this.request_id,
      content: note.val,
    };
    this.api.Technician.results.set_notes_results(body).subscribe((res) => {
      if (!res['error']) {
        this.snack.show(res['message']);
        note.showSaveText = false;
      }
    });
  }
  async history() {
    // await this.getData(this.page);
    await this.getDataEdited(this.page);
    $('#history').modal('toggle');
  }
  async getData(page = this.page) {
    this.page = page;

    // let queries = '&hospital_id=' + this.patient['hospital_id'];
    let queries = '&patient_name=' + this.patient['id'];

    this.api.Common.Get.filters.results(queries).subscribe((res) => {
      this.historyData = res['data'];
      this.total_rows = res['total'];
      this.pagination = this.data.extract_pagination(res);
    });
  }
  async getDataEdited(page = this.page) {
    this.page = page;

    // let queries = '&hospital_id=' + this.patient['hospital_id'];
    let queries = '&patient_id=' + this.patient['id'];

    this.api.Common.Get.filters.history(queries).subscribe((res) => {
      this.historyData = res['data'];
      this.pagination = this.data.extract_pagination(res);
    });
  }

  ngOnDestroy(): void {
    this.data.patient = null;
    this.data.request = null;
  }
}
