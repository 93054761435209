import { Injectable } from '@angular/core';
import {  Subject } from 'rxjs';
declare let $;


interface Props {
  title:string,
  action?: 'Add' | 'Update',
  message:string,
  datum?:object,
  inputs:object,
  method:Function,
  onSuccess:Function,
  deleteMessage?:string,
  deleteMethod?:Function,
  getDatum?:Function
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {



  constructor() { }
  is_opened:boolean = false;
  title = ''
  message = '';
  deleteMessage = '';
  deleteMethod = null
  onSuccess = null;
  action: 'Add' | 'Update' = 'Add';
  datum = {}
  method = null;
  fixed_keys = {}
  inputs = {}
  appendings:any = {}
  getDatum:Function = null;
  assign_emitted:Subject<boolean> = new Subject()


  fireModal(id){
    $(`#${id}`).modal('show');
    this.is_opened = true;
    $(`#${id}`).on('hidden.bs.modal',  () => {
      this.is_opened = false;
      $(`#${id}Closer`).click()
      $(`#${id}`).off('hidden.bs.modal')
    })
  }

  assign(props: Props){
    this.fixed_keys = {}
    this.datum = {}
    this.onSuccess = null;
    this.method = null;
    this.getDatum = null;
    this.action = 'Add';
    this.message = '';
    this.deleteMessage = '';
    this.deleteMethod = null
    for(let prop in props){
      this[prop] = props[prop]
    }
    this.assign_emitted.next(true)
    this.fireModal('dynamic-modal');
  }

  changesMade:Subject<any> = new Subject()

}
