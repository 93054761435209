import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Arabic } from "flatpickr/dist/l10n/ar";
import { DataService } from 'src/app/services/data.service';
declare let $;
declare let moment;

@Component({
  selector: 'admin-layout-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  constructor(public router:Router,private api:ApiService, private data:DataService) { }

  activity$:Observable<any>;
  from = null
  to = null

  activity_log(){
    this.activity$ = this.api.Common.Get.activity({code: document.getElementById('code')['value']})
  }

  print(){
    this.api.Common.printables.print_activity({min_date:this.data.format_date(this.from), max_date:this.data.format_date(this.to)}).subscribe(res => {
      const fileURL = URL.createObjectURL(res);

      window.open(fileURL, '_blank');
    })
  }
  //
  ngOnInit() {

    this.from = moment().subtract(7, 'days').format('DD-MM-YYYY')
    this.to = moment().format('DD-MM-YYYY')
  }

  ngAfterViewInit(){

    if(this.data.activity_log_request_id_stored){
      document.getElementById('code')['value'] = this.data.activity_log_request_id_stored;
        this.activity_log();
        this.data.activity_log_request_id_stored = "";
    }

    setTimeout(()=>{

      $("#fromPicker").flatpickr({
      dateFormat: "d-m-Y",
      defaultDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
      locale: this.data.language == "arabic" ? Arabic : null,
      position: "auto center",
    })



    $("#toPicker").flatpickr({
      dateFormat: "d-m-Y",
      defaultDate: moment().format('DD-MM-YYYY'),
      locale: this.data.language == "arabic" ? Arabic : null,
      position: "auto center",
    })

  }, 0)
  }

}
