import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Data, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { SnackService } from 'src/app/services/snack.service';
import { ProgressService } from 'src/app/services/progress.service';
import {debounce} from'lodash';
import { Arabic } from 'flatpickr/dist/l10n/ar';
declare var $:any;


@Component({
  selector: 'receptionist-layout-patient-select',
  templateUrl: './patient-select.component.html',
  styleUrls: ['./patient-select.component.scss']
})
export class PatientSelectComponent implements OnInit {

  tests : any = [];
  key = '';
  patients: Observable<any>;
  type: string = 'name';
  patient_search: any = '';

  @Output() setSelectedPatient = new EventEmitter();


  constructor(public data:DataService,public api : ApiService,
    public router:Router,public snack : SnackService,public progress : ProgressService) {
      this.onChange = debounce(this.onChange , 200)
     }





  getPatients(){

      $('#patient_dropdown').show();

    let patient_search = this.type == 'birthdate'? this.data.format_date(this.patient_search) : this.patient_search

    this.patients = this.api.Common.patients.get({patient_search_by: this.type, patient_search,page: 1,paginated:false}).pipe(map(res => res['data']),tap(res => console.log(res)
    ));

  }


  onChange(key){
    $('#patient_dropdown').show();
    this.getPatients();
  }


  ngOnInit() {

  }




  hide(){
    console.log('problem')
      $('#patient_dropdown').hide();


  }


  pick(patient){
    $('#patient_autocomplete').val("");
    $('#patient_dropdown').hide();
    this.setSelectedPatient.emit(patient)
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {
      $('#birthdatePicker').flatpickr({
        dateFormat: 'd-m-Y',
        allowInput: true,
        locale: this.data.language == 'arabic' ? Arabic : null,
        position: 'auto center',
      });
    }, 0)
  }

}
