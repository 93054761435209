<div class="container-fluid py-2 px-4">
    <ng-container *ngIf="ticket">
        <form #form="ngForm" id="cor_form" (ngSubmit)="onSubmit(form)">
            <div class="row neue-reg mt-3">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1"> {{ "Title" | locale }}</label>
                        <div class="form-group col-12 col-lg-12">
                            <input
                            name="title"
                            [(ngModel)]="ticket.title"
                            class="form-control"
                            type="text"
                            [placeholder]="'Title' | locale"
                            autocomplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1"> {{ "Description" | locale }}</label>
                        <div class="form-group col-12 col-lg-12">
                              <textarea
                              rows="5"
                              name="description"
                              [(ngModel)]="ticket.description"
                              type="text"
                              class="form-control"
                              [placeholder]="'Description' | locale"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row neue-reg my-4">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="sureState" class="alert alert-danger mx-auto" role="alert">
                            {{ "Are you sure?" | locale }}
                            <span (click)="remove()" class="mx-2 btn-danger btn-sm clickable">{{ "Delete" | locale }}</span
                ><span
                  (click)="sureState = false"
                  class="ml-2 btn-sm btn-light clickable"
                  >{{ "Cancel" | locale }}</span
                >
              </div>
              <ng-container *ngIf="!sureState">
                <!-- <button
                  *ngIf="action.type == 'Edit'"
                  type="button"
                  (click)="sureState = true"
                  class="btn btn-outline-danger tajwal-regium px-4 mx-2"
                >
                  {{ "Delete" | locale }}
                </button> -->
                <button
                  form="cor_form"
                  type="submit"
                  class="btn btn-outline-primary tajwal-regium px-5"
                >
                  {{ (action.type == "Edit" ? "Save" : "Add") | locale }}
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>