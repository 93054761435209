import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[Loading]'
})
export class LoadingDirective implements OnInit, OnChanges {
  @Input() loadingState: boolean;
  @Input() loadingColor: boolean;

  constructor(private elem: ElementRef) {
  }

  ngOnInit(): void {
    if (this.loadingState) {
      let sm = Array.from(this.elem.nativeElement.classList).find(one => one == 'btn-sm');
      this.elem.nativeElement.innerHTML = `<div class="spinner-border custom-spinner-btn ${this.loadingColor? 'text-' + this.loadingColor : 'text-primary'} ${sm? 'spinner-border-sm': ''}"></div>`;
    }
  }

  ngOnChanges(changes): void {
    this.loadingState = changes.loadingState?.currentValue;
    if (this.loadingState) {
      let sm = Array.from(this.elem.nativeElement.classList).find(one => one == 'btn-sm');
      this.elem.nativeElement.innerHTML = `<div class="spinner-border custom-spinner-btn ${this.loadingColor? 'text-' + this.loadingColor : 'text-primary'} ${sm? 'spinner-border-sm': ''}"></div>`;
      this.elem.nativeElement.style.background= 'transparent';
      this.elem.nativeElement.style.border= 'none';
    }
  }
}
