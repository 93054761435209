<div class="container-fluid py-2 px-4">
  <ng-container *ngIf="param">
    <form
      id="parameter_form"
      #parameter_form="ngForm"
      (ngSubmit)="onSubmit(parameter_form)"
    >
      <input type="hidden" name="id" [(ngModel)]="param.id" />

      <div class="row neue-reg mt-3">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputEmail1">{{ "Code" | locale }}</label>
            <input
              required
              maxlength="25"
              name="code"
              [(ngModel)]="param.code"
              #code_input="ngModel"
              type="text"
              class="form-control"
              [placeholder]="'Code' | locale"
            />

            <small
              style="display: block"
              class="start"
              *ngIf="
                (code_input.errors ? code_input.errors['required'] : false) &&
                code_input.touched
              "
            >
              {{ "This field is required" | locale }}
            </small>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputEmail1"> {{ "Name" | locale }}</label>
            <input
              required
              maxlength="60"
              [(ngModel)]="param.name"
              name="name"
              type="text"
              class="form-control"
              [placeholder]="'Name' | locale"
              #name_input="ngModel"
            />

            <small
              style="display: block"
              class="start"
              *ngIf="
                (name_input.errors ? name_input.errors['required'] : false) &&
                name_input.touched
              "
            >
              {{ "This field is required" | locale }}
            </small>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <!-- <div class="col-lg-6">

            <div class="form-group">
                <label for="exampleInputEmail1">Unit</label>
                <input required  maxlength="25" name="unit" [(ngModel)]="param.unit" #unit_input="ngModel" type="text" class="form-control"   placeholder="Enter Unit">


                    <small *ngIf="
                    (unit_input.errors?unit_input.errors['required']:false)
                    && unit_input.touched">
                        the code is required
                    </small>


            </div>

        </div> -->

        <div class="col-lg-6">
          <div class="form-group">
            <label for="type_id">{{ "Type" | locale }}</label>
            <select
              [ngModel]="param.type_id || types[0].id"
              name="type_id"
              id="type_id"
              class="custom-select"
            >
              <option selected *ngFor="let type of types" [value]="type.id">
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="options">{{ "Options" | locale }}</label>
            <!-- <div class="">
              <button
                hidden
                #add
                id="pushOpt"
                type="button"
                (click)="pushOpt(optionInput, add)"
                class="btn save-btn btn-success py-0 roboto-400 btn-result"
              >
                Add
              </button>
              <button
              #edit
              id="pupOpt"
              type="button"
              (click)="updateOpt(optionInput)"
              class="btn save-btn btn-warning py-0 roboto-400 btn-result updateOption"
              style="display: none;"
            >
              Edit
            </button>
            </div> -->
            <div class="input-group">
              <input
                [ngModelOptions]="{ standalone: true }"
                (input)="(null)"
                type="text"
                [(ngModel)]="opti"
                class="form-control optionFeild"
                [placeholder]="'Add an option' | locale"
                id="optionFeild"
                [ngClass]="{ 'rad-left': data.isRTL, 'rad-right': !data.isRTL }"
              />

              <div class="input-group-append">
                <button
                  [disabled]="!opti"
                  (click)="
                    optionIndex || optionIndex == 0 ? updateOpt() : pushOpt()
                  "
                  class="btn btn-outline-primary form-control"
                  [ngClass]="{
                    'rad-left': !data.isRTL,
                    'rad-right': data.isRTL
                  }"
                  [innerText]="
                    (optionIndex || optionIndex == 0 ? 'Edit' : 'Add') | locale
                  "
                ></button>
              </div>
            </div>

            <!-- <button
              #edit
              id="pupOpt"
              type="button"
              (click)="updateOpt(updateOption, i)"
              class="btn save-btn btn-warning py-0 roboto-400 btn-result updateOption"
              style="display: none;"
            >
              Edit
            </button> -->
            <!-- <input
              #updateOption
              (input)="add.hidden = false"
              type="text"
              class="form-control updateOption"
              placeholder="Enter Option"
              style="display: none;"
              id="updateOption2"
            /> -->
          </div>
        </div>
      </div>

      <p class="start" *ngIf="options.length">{{ "Added Options" | locale }}</p>
      <div style="overflow-x: scroll; white-space: nowrap" class="my-2 start">
        <span
          class="m-2 p-2 py-3 btn-light btn"
          *ngFor="let opt of options; let i = index"
          >{{ opt }}
          <span class="mx-1 btn-sm btn-primary pt-2">
            <i (click)="editOpt(opt, i)" class="fas fa-edit h5 m-0"></i>
          </span>
          <span class="mx-1 btn-sm btn-danger pt-2">
            <i (click)="removeOpt(opt)" class="far fa-trash-alt h5 m-0"></i>
          </span>
        </span>
      </div>

      <div class="row neue-reg mt-1">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="exampleInputEmail1">{{
              "Default result" | locale
            }}</label>
            <input
              name="default_value"
              [(ngModel)]="param.default_value"
              type="text"
              class="form-control"
              [placeholder]="'Default result' | locale"
            />
          </div>
        </div>
      </div>

      <div class="row neue-reg mt-1">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="exampleInputEmail1">{{
              "Specification" | locale
            }}</label>
            <textarea
              rows="5"
              name="extra_notes"
              [(ngModel)]="param.extra_notes"
              type="text"
              class="form-control"
              [placeholder]="'Extra notes' | locale"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-lg-6 p-0">
        <div class="d-flex h-100 align-items-center">
          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="show_old_result"
              [(ngModel)]="param.show_old_result"
              id="show_old_result"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="show_old_result"
              >{{ "Show old result" | locale }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-lg-12 p-0">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group form-check start">
              <input
                type="checkbox"
                class="form-check-input"
                name="show_previous_deviation"
                [(ngModel)]="param.show_previous_deviation"
                id="show_previous_deviation"
              />
              <label
                class="form-check-label"
                [ngClass]="{ 'mr-4': data.isRTL }"
                for="show_previous_deviation"
                >{{ "Show Deviation" | locale }}</label
              >
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="exampleInputEmail1">{{ "Deviation Percentage" | locale }}</label>
              <input
                name="deviation_value"
                [(ngModel)]="param.deviation_value"
                #deviation_value_input="ngModel"
                type="number"
                class="form-control"
                [placeholder]="'Deviation Value' | locale"
              />
            </div>
          </div>
        </div>
      </div>

      <p class="col-12 start" *ngIf="formulas.length">
        {{ "Formulas" | locale }}
      </p>
      <div class="my-2 start">
        <p class="m-2 p-2 btn-light btn py-3" *ngFor="let f of formulas">
          {{ f }}
          <span class="btn-sm btn-danger pt-2">
            <i (click)="removeFormula(f)" class="far fa-trash-alt h5 m-0"></i>
          </span>
        </p>
      </div>

      <p
        class="btn btn-outline-primary mt-2 start py-2"
        data-toggle="collapse"
        data-target="#formula"
        aria-expanded="false"
        aria-controls="collapseExample"
        style="display: block; width: fit-content; border-radius: 10px"
      >
        {{ "Add a formula" | locale }}
      </p>
      <div style="direction: ltr" class="collapse" id="formula">
        <div class="card card-body mt-2">
          <input
            class="my-2 card card-body"
            disabled
            name="cformula"
            [(ngModel)]="cformula"
          />
          <div class="container-fluid">
            <div class="row justify-content-around">
              <div class="col-lg-8 p-0 start mt-2">
                <selector
                [no_label]="true"
                  title="parameters"
                  (setSelectedItem)="formulise($event)"
                ></selector>
              </div>
              <div class="col-lg-4">
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="input-group m-0">
                      <input
                        name="formulaN"
                        [(ngModel)]="formulaN"
                        placeholder="to add number"
                        class="form-control"
                        type="text"
                        style="
                          border-top-right-radius: 0 !important;
                          border-bottom-right-radius: 0 !important;
                        "
                      />
                      <div class="input-group-append">
                        <span
                          (click)="formulise(formulaN, true)"
                          class="input-group-text btn btn-info"
                          >Add</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                (click)="formulise(btn)"
                *ngFor="let btn of formulaBtns"
                type="button"
                class="col-auto m-1"
                [ngClass]="{ 'btn-primary': btn == 'Done' }"
              >
                {{ btn }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="my-2 row">
    <span class="py-2">Options:</span>
    <div style="flex-wrap: nowrap;overflow-x: scroll;" class="col py-2">
      <span *ngFor="let opt of options" class="mx-2">{{opt}}</span>
    </div>
  </div> -->
    </form>

    <div class="row">
      <div class="col-12">
        <h6 class="mt-3 roboto-400 start">{{ "Reference Ranges" | locale }}</h6>
      </div>
    </div>

    <ng-container *ngFor="let RR of RRs; index as i">
      <div class="row mt-3">
        <div class="col-12">
          <div class="d-flex align-items-center card w-100">
            <div class="d-flex w-100 justify-content-end pt-3 px-3">
              <button
                (click)="removeRR(RR.id)"
                type="button"
                class="btn btn-outline-danger p-0 px-1"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>

            <div class="container-fluid">
              <div class="row neue-reg">
                <ng-container *ngIf="RR.type_id == 2">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">{{
                        "Range" | locale
                      }}</label>
                      <input
                        required
                        maxlength="25"
                        [(ngModel)]="RR.range"
                        #range_input="ngModel"
                        type="text"
                        class="form-control"
                        [placeholder]="'Range' | locale"
                      />

                      <small
                        style="display: block"
                        class="start"
                        *ngIf="
                          (range_input.errors
                            ? range_input.errors['required']
                            : false) && range_input.touched
                        "
                      >
                        {{ "This field is required" | locale }}
                      </small>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="RR.type_id == 1">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="exampleInputEmail1">{{
                        "Min value" | locale
                      }}</label>
                      <input
                        required
                        maxlength="25"
                        [(ngModel)]="RR.min"
                        #min_input="ngModel"
                        type="text"
                        class="form-control"
                        [placeholder]="'Min value' | locale"
                      />

                      <small
                        style="display: block"
                        class="start"
                        *ngIf="
                          (min_input.errors
                            ? min_input.errors['required']
                            : false) && min_input.touched
                        "
                      >
                        {{ "This field is required" | locale }}
                      </small>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="exampleInputEmail1">{{
                        "Max value" | locale
                      }}</label>
                      <input
                        required
                        maxlength="25"
                        [(ngModel)]="RR.max"
                        #max_input="ngModel"
                        type="text"
                        class="form-control"
                        [placeholder]="'Max value' | locale"
                      />

                      <small
                        style="display: block"
                        class="start"
                        *ngIf="
                          (max_input.errors
                            ? max_input.errors['required']
                            : false) && max_input.touched
                        "
                      >
                        {{ "This field is required" | locale }}
                      </small>
                    </div>
                  </div>
                </ng-container>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ "Gender" | locale }}</label>
                    <select [(ngModel)]="RR.gender_id" class="custom-select">
                      <option [ngValue]="null">
                        {{ "None" | locale }}
                      </option>
                      <option
                        *ngFor="let gender of data.genders"
                        [value]="gender.id"
                      >
                        {{ gender.name | locale }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{
                      "Min age" | locale
                    }}</label>
                    <input
                      maxlength="25"
                      [(ngModel)]="RR.age_min"
                      #min_input="ngModel"
                      type="text"
                      class="form-control"
                      [placeholder]="'min age' | locale"
                    />
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{
                      "Max age" | locale
                    }}</label>
                    <input
                      maxlength="25"
                      [(ngModel)]="RR.age_max"
                      #max_input="ngModel"
                      type="text"
                      class="form-control"
                      [placeholder]="'max age' | locale"
                    />
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="type_id">{{ "Type" | locale }}</label>
                    <select [(ngModel)]="RR.type_id" class="custom-select">
                      <option *ngFor="let type of types" [value]="type.id">
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{
                      "Unit" | locale
                    }}</label>
                    <input
                      maxlength="25"
                      [(ngModel)]="RR.unit"
                      #unit_input="ngModel"
                      type="text"
                      class="form-control"
                      [placeholder]="'Unit' | locale"
                    />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{
                      "Specification" | locale
                    }}</label>
                    <textarea
                      rows="5"
                      [(ngModel)]="RR.note"
                      #note_input="ngModel"
                      type="text"
                      class="form-control"
                      [placeholder]="'Note' | locale"
                    ></textarea>
                  </div>
                </div>

                <div class="col-lg-12 mb-4">


                  <selector
                    [datum]="RR.device"
                    title="device"
                  >

                  </selector>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row mt-3">
      <div class="col-12">
        <div class="d-flex align-items-center w-100">
          <button
            (click)="addRrInput()"
            style="font-size: 20px"
            type="button"
            class="btn btn-outline-primary roboto-300 px-5"
          >
            +
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="row neue-reg my-4">
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <div *ngIf="sureState" class="alert alert-danger mx-auto" role="alert">
          {{ "Are you sure?" | locale }}
          <span (click)="remove()" class="mx-2 btn-danger btn-sm clickable">{{
            "Delete" | locale
          }}</span
          ><span
            (click)="sureState = false"
            class="ml-2 btn-sm btn-light clickable"
            >{{ "Cancel" | locale }}</span
          >
        </div>

        <ng-container *ngIf="!sureState">
          <button
            *ngIf="action.type == 'Edit'"
            type="button"
            (click)="sureState = true"
            class="btn btn-outline-danger tajwal-regium px-4 mx-2"
          >
            {{ "Delete" | locale }}
          </button>
          <button
            form="parameter_form"
            type="submit"
            class="btn btn-outline-primary tajwal-regium px-5"
          >
            {{ (action.type == "Edit" ? "Save" : "Add") | locale }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
