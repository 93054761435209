import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
declare var $:any

@Component({
  selector: 'admin-layout-clinics',
  templateUrl: './clinics.component.html',
  styleUrls: ['./clinics.component.scss']
})
export class ClinicsComponent implements OnInit {


  clinics: any [] = [];
  selectedClinic:any = {}
  action:any = {type : 'Add'}
  isLoaderHidden = true;
  sureState:Boolean = false



  constructor(public router:Router,public api : ApiService,public progress : ProgressService, public ms:ModalService) { }



  activate(clinic){
    if(clinic.isActive){
      this.api.Admin.clinics.deactivate(clinic.id).subscribe(res => {
        if(!res['error']) clinic.isActive = false
      })

    }else{
      this.api.Admin.clinics.activate(clinic.id).subscribe(res => {
        if(!res['error']){
          this.clinics.forEach(one => one.isActive = false)
          clinic.isActive = true
        }
      })
    }

  }

  ngOnInit() {

    this.progress.on();
    this.sureState = false
    this.getClinics();

  }

  ngAfterViewInit(){

    var input$ : Observable<any> = fromEvent($('#test_autocomplete'), 'input');
    input$.pipe(map(res => res['target'].value),debounceTime(500)).subscribe(res => {


      console.log('here');

      this.isLoaderHidden = false
      // this.getTests(this.key);

    })

  }


  getClinics(){

    // $('.dropdown-menu').dropdown('show');

    this.api.Admin.clinics.get().subscribe(res => {

      this.progress.off();
      this.isLoaderHidden = true

      this.clinics = res

    })
  }

  openModal(action){

    this.action = action
    $("#clinic-modal").modal('show');

  }

  // testCreated(){
  //   this.getTests(this.key);
  //   $("#test-modal").modal('hide');
  // }

}
