import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (!localStorage.getItem('token')) {
      this.router.navigateByUrl('/login');
    }

    return !!localStorage.getItem('token');
  }
}

@Injectable()
export class NotAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (localStorage.getItem('token')) {
      this.router.navigateByUrl('/');
    }
    return !localStorage.getItem('token');
  }
}

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private api: ApiService,
    private data: DataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {

    this.data.check_tab_opened_time()

    return this.api.Common.me.get().pipe(
      map((res) => {
        let roles = res['data'].roles;
        if (!roles.length) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('login');
          return false;
        } else {
          if (!roles.filter((role) => role.name == 'admin')[0]) {
            this.router.navigateByUrl(roles[0].name);
            return false;
          } else {
            this.data.me = res['data'];
            this.api.Admin.users.getPermissions().subscribe(res=>{
              this.data.permissions$.next(res)
            })
            this.data.role_route_changed.next('admin')
            return true;
          }
        }
      }),
      catchError((err) => {
        if (err['status'] == 401) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('login');
        }
        return of(false);
      })
    );
  }
}

@Injectable()
export class TechnicianAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private api: ApiService,
    private data: DataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.data.check_tab_opened_time()
    return this.api.Common.me.get().pipe(
      map((res) => {
        let roles = res['data'].roles;
        if (!roles.length) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('login');
          return false;
        } else {
          if (!roles.filter((role) => role.name == 'technician')[0]) {
            this.router.navigateByUrl(roles[0].name);
            return false;
          } else {
            this.data.me = res['data'];
            this.data.role_route_changed.next('technician')
            return true;
          }
        }
      }),
      catchError((err) => {
        if (err['status'] == 401) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('login');
        }
        return of(false);
      })
    );
  }
}

@Injectable()
export class ReceptionistAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private api: ApiService,
    private data: DataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.data.check_tab_opened_time()
    return this.api.Common.me.get().pipe(
      map((res) => {
        let roles = res['data'].roles;
        if (!roles.length) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('login');
          return false;
        } else {
          if (!roles.filter((role) => role.name == 'receptionist')[0]) {
            this.router.navigateByUrl(roles[0].name);
            return false;
          } else {
            this.data.me = res['data'];
            this.data.role_route_changed.next('receptionist')
            return true;
          }
        }
      }),
      catchError((err) => {
        if (err['status'] == 401) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('login');
        }
        return of(false);
      })
    );
  }
}
