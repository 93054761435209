

<ng-container >

  <div class="row ">
    <div class="col">
      <h2 class="roboto-300 start">{{'Home' | locale}}</h2>
    </div>
  </div>


  <!-- <technician-layout-stat></technician-layout-stat> -->


  <div class="card mt-4 mb-5">
    <div class="container-fluid py-3 px-4">



      <div class="row mt-2">


        <div class="form-group col-12 col-lg-6">
          <input
            id="fromPicker"
            [(ngModel)]="from"
            class="flatpickr flatpickr-input active form-control"
            type="date"
            [placeholder]="'Date from' | locale"
            (change)="getRequests(1)"
            autocomplete="off"
          />
        </div>
        <div class="form-group col-12 col-lg-6">
          <input
            id="toPicker"
            [(ngModel)]="to"
            class="flatpickr flatpickr-input active form-control"
            type="date"
            [placeholder]="'Date to' | locale"
            (change)="getRequests(1)"
            autocomplete="off"
          />
        </div>

          <div class="col-lg-4">

            <div class="form-group m-0 roboto-400">
              <input type="text"
               class="form-control" id="request_num_autocomplete"
              [placeholder]="('Search by Request #' | locale)">

              <!-- <i [hidden]="!isRequestNumLoaderHidden" class="fas search-icon fa-search"></i>

              <div [hidden]="isRequestNumLoaderHidden"  #loader  class="spinner-border text-primary load" role="status">
                <span class="sr-only ">Loading...</span>
              </div> -->

            </div>
          </div>


          <div class="col-lg-4">


              <div class="form-group m-0 roboto-400">
                <!-- <input type="text"
                  class="form-control px-5" id="patient_name_autocomplete"
                placeholder="Patient name or phone number">

                <i [hidden]="!isLoaderHidden" class="fas search-icon fa-search"></i>

                <div [hidden]="isLoaderHidden"  #loader  class="spinner-border text-primary load" role="status">
                  <span class="sr-only ">Loading...</span>
                </div> -->


        <div class="input-group ">

          <!-- <div class="input-group-prepend">
            <span>
            </span>
          </div> -->
          <div class="input-group-prepend">

               <button class="btn btn-outline-primary form-control" [ngClass]="{'rad-left': data.isRTL , 'rad-right': !data.isRTL}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [innerHTML]="(type == 'hospital_id'? 'Hospital ID' : type | titlecase) | locale" ></button>
          <div class="dropdown-menu">
            <a *ngFor="let key of ['name', 'phone' , 'hospital_id', 'insurance_id', 'barcode', 'NID' , 'birthdate']" class="dropdown-item clickable" (click)="type = key;patient_search = '';getRequests(1)" style="text-align: start;">{{
              (key == "hospital_id"
                ? "Hospital ID" :
                key == 'NID'? key
                : 
                key == 'insurance_id' ? "Insurance ID"
                : (key | titlecase)
              ) | locale
            }}</a>
        </div>

              <!-- <div [hidden]="isLoaderHidden" #loader class="spinner-border text-primary " role="status">
                <span class="sr-only ">Loading...</span>
              </div> -->

        </div>
        <input *ngIf="type != 'birthdate'" type="text" class="form-control" [ngClass]="{'rad-left': !data.isRTL , 'rad-right': data.isRTL}" [(ngModel)]="patient_search"
        (keydown)="getRequests(1)"
        [placeholder]="'Patient search' | locale">

        <input [ngStyle]="type == 'birthdate'? {}: {display: 'none'}" [ngClass]="{'rad-left': !data.isRTL , 'rad-right': data.isRTL}" id="birthdatePicker" [(ngModel)]="patient_search" class="flatpickr flatpickr-input active form-control " type="date"
        [placeholder]="'Birthdate' | locale" (change)="getRequests(1)" autocomplete="off" />

        <!-- <div class="input-group-append">

      </div> -->
        </div>


              </div>


              <!-- <div class="form-group m-0 roboto-400"><input type="text"
                name="key" [(ngModel)]="key" class="form-control px-5" id="exampleFormControlInput1"
                placeholder="Search with patient name">

                <i class="fas search-icon fa-search"></i>

              </div> -->

          </div>





        <!-- <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end ">
            <button  data-toggle="modal" data-target="#modal"
             type="button" id="add-button" class="btn btn-primary w-100 roboto-400">Add +</button>
          </div>
        </div> -->


        <div class="col-lg-4 mt-lg-0">

          <selector [no_label]="true" [no_margin]="true" title="tests" [datum]="selectedTest" (setSelectedItem)="search()" ></selector>

        </div>
      </div>

      <div class="row mt-4">



        <div class="col-lg-4">
          <ng-container *ngIf="data.categories[0]">
            <div class="form-group m-0">
              <select
                (change)="search()"
                [(ngModel)]="category_id"
                name="category_id"
                id="category"
                class="custom-select"
              >
                <option value="">{{'All Categories' | locale}}</option>
                <option *ngFor="let cat of data.categories" [value]="cat.id">
                  {{ cat.name }}
                </option>
              </select>
            </div>
          </ng-container>

          <!-- <div class="form-group ">
            <label for="civility">Gender</label>
            <select [ngModel]="patient.gender_id || genders[0].id"   name="gender_id" id="civility" class="custom-select">
                <option selected *ngFor="let gender of genders"  [value]="gender.id">{{gender.name}}</option>
            </select>
          </div> -->
        </div>

        <!-- <div class="col-lg-3 mt-2 mt-lg-0">

          <div class="form-group ">
            <select [(ngModel)]="payment_status" (change)="search()"  name="correspondent_id" id="civility" class="custom-select">
              <option  [value]="''">All</option>
              <option *ngFor="let payment_status of payment_statuses "  [value]="payment_status">{{payment_status}}</option>
            </select>
          </div>

        </div> -->


        <div class="col-lg-4 mt-2 mt-lg-0">

          <div class="form-group ">
            <select [(ngModel)]="status" (change)="search()"  name="correspondent_id" id="civility" class="custom-select">
              <option  [value]="''">{{'All' | locale}}</option>
              <option *ngFor="let status of statuses "  [value]="status">{{status | locale}}</option>
            </select>
          </div>

        </div>

        <div class="col-lg-4 mt-2 mt-lg-0">

          <div class="form-group ">
            <select   [(ngModel)]="isValidated" (change)="search()"  name="correspondent_id" id="civility" class="custom-select">
              <option *ngFor="let one of [{label:'All', value:null},{label:'Validated', value:1},{label:'Unvalidated', value:0}] "  [ngValue]="one.value">{{one.label | locale}}</option>
            </select>
          </div>

        </div>




      <!-- <div class="col-12 col-lg-2 mt-3 mt-lg-0">
        <div class="d-flex w-100 justify-content-end ">
          <button  data-toggle="modal" data-target="#modal"
           type="button" id="add-button" class="btn btn-primary w-100 roboto-400">Add +</button>
        </div>
      </div> -->


    </div>

      <div class="row ">

        <div class="col-12">
          <div class="table-responsive  ">
            <table style="width: 100%;" align="center">
            <thead>
              <tr class="neue-light roboto-400">
                <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                <th style="width: 150px;" scope="col">{{'Request #' | locale}}</th>
                <th style="width: 200px;" scope="col"> {{'Patient name' | locale}}</th>
                <th style="width: 200px;" scope="col"> {{'Correspondent' | locale}}</th>
                <th style="width: 200px;" scope="col"> {{'Main Correspondent' | locale}}</th>
                <th style="width: 150px;" scope="col"> {{'Date' | locale}}</th>
                <!-- <th scope="col"> Clinical Info</th> -->
                <th style="width: 200px;" scope="col"> {{'Result' | locale}}</th>
                <th style="width: 30px;" scope="col"> {{'Details' | locale}}</th>
                <!-- <th width="10%" scope="col"> Total</th> -->
                <th style="width: 30px;" scope="col"> {{'Status' | locale}}</th>
                <th style="width: 30px;" scope="col"> {{'Urgency' | locale}}</th>
                <th style="width: 30px;" scope="col"> {{'Repeat' | locale}}</th>

                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>



            </thead>
            <tbody class="neue-light ">
              <ng-container *ngFor="let request of requests | async | paginate: { id:'pagination' ,
              itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                 <tr valign="middle" (click)="editRequest(request)"  class="clickable_row roboto-400" [ngClass]="{'urgent':request.urgent_flag,'is_repeat':request.is_repeat,'is_whatsapp': request.is_whatsapp}">
                  <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                  <td style="width: 150px;">{{request.reception_number}}</td>
                  <td style="width: 200px;">{{request.patient?.name }}</td>
                  <td style="width: 200px;">

                    {{
                      request.correspondent
                        ? request.correspondent.name
                        : ("OPD" | locale)
                    }}
                  </td>
                  <td style="width: 150px">
                    <ng-container *ngIf="request.correspondent">
                      {{
                        request.correspondent.subcategory
                          ? request.correspondent.subcategory.name
                          : ("None" | locale)
                      }}
                    </ng-container>

                    <ng-container *ngIf="!request.correspondent">{{ ("None" | locale) }}</ng-container>
                  </td>


                  <td style="width: 150px;">{{request.created_at | date}}</td>
                  <!-- <td>{{request.clinical_information }}</td> -->
                  <td style="width: 200px;">

                    <div class="tag" [ngClass]="
                    {
                      'red-tag' : (request.status == 'in progress'),
                      'green-tag' : !(request.status == 'in progress')
                    }
                  ">
                    <p class="roboto-300">
                      {{(request.status == 'technically validated'?'Validated' : request.status) | titlecase | locale }}
                    </p>
                    </div>


                    <!-- <button
                    *ngIf=" (request.status != 'active' && request.status != null)"
                     type="submit" class="btn btn-outline-success roboto-400 py-1 btn-result">Show Result
                    </button>


                    <button
                    *ngIf=" (request.status == 'active')"
                     type="submit" disabled class="btn btn-outline-danger roboto-400 py-1 btn-result"> Not ready
                    </button> -->

                  </td>
                  <td style="width: 30px; ">

                    <button (click)="$event.stopPropagation();openDetails(request.id,request.patient_id)"
                      type="button" class="btn btn-outline-info p-0 px-3"><i class="far fa-clipboard"></i></button>


                  </td>
                  <!-- <td width="10%">{{request.total + data.info['currency']}}</td> -->

                  <td style="width: 30px; ">

                    <div class="tag"
                      [ngClass]="{'alert-warning' : (request.payment_check == 'partially-paid'),'alert-success':(request.payment_check == 'paid')}">


                      <span class="roboto-400 text-capitalize ">
                        {{(request.correspondent?.isLTA? 'LTA' : request.payment_status) | locale }}
                        <!-- {{(request.correspondent?.isLTA? 'LTA' : request.payment_status) | titlecase }} -->
                      </span>

                    </div>

                  </td>
                  <td style="width: 30px;">

                      <div class="tag" [ngClass]="
                        {'red-tag' : request.urgent_flag, 'green-tag' : !request.urgent_flag}
                      ">
                        <p class="roboto-300">
                          {{request.urgent_flag | urgent | locale}}
                        </p>
                      </div>


                  </td>
                  <td style="width: 30px;">

                      <div class="tag" [ngClass]="
                        {'is_repeat' : request.is_repeat, 'green-tag' : !request.is_repeat}
                      ">
                        <p class="roboto-300">
                          {{request.is_repeat? ('Repeat' | locale:2):('New' | locale)}}
                        </p>
                      </div>


                  </td>
                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>



            </tbody>


            </table>


          </div>
        </div>


          <div class="col-12 mt-3">
              <div class="w-100 d-flex justify-content-center neue-med p-0">
                  <pagination-controls
                  responsive="true"
                  [previousLabel]="'Previous' | locale"
                  [nextLabel]="'Next' | locale"
                   (pageChange)="getRequests($event)"
                   id="pagination"></pagination-controls>
              </div>
          </div>



      </div>


    </div>
  </div>

</ng-container>

<!-- <router-outlet></router-outlet> -->

<!-- Modal -->
<!-- <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">

      <div class="modal-body">


        <div class="container-fluid px-4">
          <div class="row mt-4">
            <div class="col-12">
                <h5  class="roboto-500">Select a patinet</h5>
            </div>
          </div>
        </div>



        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
                <h5  class="roboto-500">or create new</h5>
            </div>
          </div>
        </div>


        <technician-layout-new-patient (patinetCreated)="onPatientCreated($event)" [action]="{'type' : 'New' }" ></technician-layout-new-patient>
      </div>
    </div>
  </div>
</div> -->


<div class="modal fade" id="details_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <div class="container-fluid px-4">

          <ng-container *ngIf="summery_patientId && summery_request_id">
            <technician-layout-detials [patientId]="summery_patientId" [request_id]="summery_request_id">
            </technician-layout-detials>
          </ng-container>
        </div>





      </div>
    </div>
  </div>
</div>
