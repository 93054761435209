import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range'
})
export class RangePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let range = args[0].replace( /\(|\)/g , '').split(' - ').map(one => Number(one))
    return (value < range[0] || value > range[1])
  }

}
