import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
declare var $:any

@Component({
  selector: 'admin-layout-germ-families',
  templateUrl: './germ-families.component.html',
  styleUrls: ['./germ-families.component.scss']
})
export class GermFamiliesComponent implements OnInit {

  clinics: any [] = [];
  families: any [] = [];
  selectedFamily:any = {}
  action:any = {type : 'Add'}
  isLoaderHidden = true;
  sureState:Boolean = false

  constructor(public router:Router,public api : ApiService,public progress : ProgressService, public ms:ModalService) { }

  ngOnInit() {

    this.progress.on();
    this.getFamilies();

  }

  ngAfterViewInit(){

    var input$ : Observable<any> = fromEvent($('#test_autocomplete'), 'input');
    input$.pipe(map(res => res['target'].value),debounceTime(500)).subscribe(res => {


      console.log('here');

      this.isLoaderHidden = false
      // this.getTests(this.key);

    })

  }


  getFamilies(){

    // $('.dropdown-menu').dropdown('show');

    this.api.Admin.germ_families.get().subscribe(res => {

      this.progress.off();
      this.isLoaderHidden = true

      this.families = res

    });
  }


}

