import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import {
  AdminAuthGuard,
  AuthGuard,
  NotAuthGuard,
  ReceptionistAuthGuard,
  TechnicianAuthGuard,
} from './guards/AuthGuard';
import { RequestsListComponent as ReceptionistRequestsListComponent } from './home/content/receptionist-layout/requests-list/requests-list.component';
import { NewRequestComponent as ReceptionistNewRequestComponent } from './home/content/receptionist-layout/new-request/new-request.component';
import { ReportsComponent } from './home/content/reports/reports.component';
import { AdminLayoutComponent } from './home/content/admin-layout/admin-layout.component';
import { TechnicianLayoutComponent } from './home/content/technician-layout/technician-layout.component';
import { ReceptionistLayoutComponent } from './home/content/receptionist-layout/receptionist-layout.component';

import { RequestsListComponent as TechnicianRequestsListComponent } from './home/content/technician-layout/requests-list/requests-list.component';
import { NewRequestComponent as TechnicianNewRequestComponent } from './home/content/technician-layout/new-request/new-request.component';
import { EditResultComponent as TechnicianEditResultComponent } from './home/content/technician-layout/edit-result/edit-result.component';

import { CashierListComponent as AdminCashierListComponent } from './home/content/admin-layout/cashier-list/cashier-list.component';
import { PatientsListComponent } from './home/content/patients-list/patients-list.component';
import { CorrespondentsComponent } from './home/content/correspondents/correspondents.component';
import { ParametersListComponent as AdminParametersListComponent } from './home/content/admin-layout/parameters-list/parameters-list.component';
import { EmployeesListComponent as AdminEmployeesListComponent } from './home/content/admin-layout/employees-list/employees-list.component';
import { CatsListComponent as AdminCatsListComponent } from './home/content/admin-layout/cats-list/cats-list.component';
import { PaymentMethodsListComponent as AdminPaymentMethodsListComponent } from './home/content/admin-layout/payment-methods-list/payment-methods-list.component';
import { CivilitiesListComponent as AdminCivilitiesListComponent } from './home/content/admin-layout/civilities-list/civilities-list.component';
import { PricingListComponent as AdminPricingListComponent } from './home/content/admin-layout/pricing-list/pricing-list.component';
import { DevicesListComponent as AdminDevicesListComponent } from './home/content/admin-layout/devices-list/devices-list.component';
import { CorrespondentComponent as AdminCorrespondentComponent } from './home/content/admin-layout/correspondent/correspondent.component';
import { ClinicsComponent as AdminClinicsComponent } from './home/content/admin-layout/clinics/clinics.component';
import { ClinicComponent as AdminClinicComponent } from './home/content/admin-layout/clinic/clinic.component';
import { GermFamiliesComponent as AdminGermFamiliesComponent } from './home/content/admin-layout/germ-families/germ-families.component';
import { BacteriaComponent as AdminBacteriaComponent } from './home/content/admin-layout/bacteria/bacteria.component';
import { AntibioticsComponent as AdminAntibioticsComponent } from './home/content/admin-layout/antibiotics/antibiotics.component';
import { ReceptionistComponent as AdminReceptionistComponent } from './home/content/admin-layout/receptionist/receptionist.component';
import { ReceptionistsComponent as AdminReceptionistsComponent } from './home/content/admin-layout/receptionists/receptionists.component';
import { FamilyListComponent as AdminFamilyListComponent } from './home/content/admin-layout/family-list/family-list.component';
import { SubCategoriesListComponent as AdminSubCategoriesListComponent } from './home/content/admin-layout/sub-categories-list/sub-categories-list.component';
import { UnitListComponent as AdminUnitsListComponent } from './home/content/admin-layout/unit-list/unit-list.component';
import { MicroComponent as AdminMicroComponent } from './home/content/admin-layout/micro/micro.component';
import { ActivityComponent } from './home/content/activity/activity.component';
import { DoctorsComponent } from './home/content/doctors/doctors.component';
import { SettingsComponent as AdminSettingsComponent } from './home/content/admin-layout/settings/settings.component';
import { LabsListComponent as AdminLabsListComponent } from './home/content/admin-layout/labs-list/labs-list.component';
import { LabComponent as AdminLabComponent } from './home/content/admin-layout/lab/lab.component';
import { TestsComponent } from './home/content/tests/tests.component';
import { SearchComponent } from './home/content/search/search.component';
import { SamplesComponent } from './home/content/samples/samples.component';
import { ReceptionstsTransactionsComponent as AdminReceptionstsTransactionsComponent } from './home/content/admin-layout/receptionsts-transactions/receptionsts-transactions.component';
import { SupportListComponent } from './home/content/admin-layout/support-list/support-list.component';
import { SupportComponent } from './home/content/admin-layout/support/support.component';

const AdminChildren: Routes = [

  {
    path: 'patients',
    component: PatientsListComponent,
    data:{admin:true}
  },

  {
    path: 'families', component: AdminFamilyListComponent
  },
  {
    path: 'subcategories', component: AdminSubCategoriesListComponent
  },
  {
    path: 'units', component: AdminUnitsListComponent
  },
  {
    path: 'doctors',
    component: DoctorsComponent,
  },
  {
    path: 'receptionists',
    component: AdminReceptionistsComponent,
  },
  {
    path: 'receptionists/:id',
    component: AdminReceptionistComponent,
  },
  {
    path: 'rec/transactions',
    component: AdminReceptionstsTransactionsComponent,
  },
  {
    path: 'clinics',
    component: AdminClinicsComponent,
  },
  {
    path: 'micro',
    component: AdminMicroComponent,
    children: [
      { path: '', redirectTo: 'germ-families', pathMatch: 'full' },

      {
        path: 'germ-families',
        component: AdminGermFamiliesComponent,
      },
      {
        path: 'antibiotics',
        component: AdminAntibioticsComponent,
      },
      {
        path: 'bacteria',
        component: AdminBacteriaComponent,
      },
    ],
  },
  {
    path: 'clinics/:id',
    component: AdminClinicComponent,
  },
  {
    path: 'correspondents',
    component: CorrespondentsComponent,
  },

  {
    path: 'labs',
    component: AdminLabsListComponent,
  },

  {
    path: 'labs/:id',
    component: AdminLabComponent,
  },

  {
    path: 'correspondents/:id',
    component: AdminCorrespondentComponent,
  },

  {
    path: 'samples',
    component: SamplesComponent,
  },

  {
    path: 'parameters',
    component: AdminParametersListComponent,
  },

  {
    path: 'activity',
    component: ActivityComponent,
  },

  {
    path: 'settings',
    component: AdminSettingsComponent,
  },
  
  {
    path: 'support',
    component: SupportListComponent,
  },

  {
    path: 'support/:id',
    component: SupportComponent,
  },

  {
    path: 'users',
    component: AdminEmployeesListComponent,
  },

  {
    path: 'reports',
    component: ReportsComponent,
  },



  {
    path: 'categories',
    component: AdminCatsListComponent,
  },

  {
    path: 'cashiers',
    component: AdminCashierListComponent,
  },

  {
    path: 'payment-methods',
    component: AdminPaymentMethodsListComponent,
  },

  {
    path: 'civilities',
    component: AdminCivilitiesListComponent,
  },

  {
    path: 'pricing',
    component: AdminPricingListComponent,
  },

  {
    path: 'devices',
    component: AdminDevicesListComponent,
  },

  {
    path: 'tests',
    component: TestsComponent,
  },

  { path: '', redirectTo: 'tests', pathMatch: 'full' },
];

const TechnicianChildren: Routes = [
  // /, children : requestListChildren
  {
    path: '',
    component: TechnicianRequestsListComponent,
  },

  {
    path: 'patients/:id/requests/:request_id/edit-result',
    component: TechnicianEditResultComponent,
  },
  {
    path: 'requests/:request_id/:action',
    component: TechnicianNewRequestComponent,
  },

  {
    path: 'activity',
    component: ActivityComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'samples',
    component: SamplesComponent,
  },
  {
    path: 'tests',
    component: TestsComponent,
  },
];

const ReceptionistChildren: Routes = [
  {
    path: '',
    component: ReceptionistRequestsListComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },

  {
    path: 'receptionist', component: AdminReceptionistComponent

  },

  {
    path: 'requests/:request_id/:action',
    component: ReceptionistNewRequestComponent,
  },

  {
    path: 'requests/:action',
    component: ReceptionistNewRequestComponent,
  },

  {
    path: 'activity',
    component: ActivityComponent,
  },
  {
    path: 'reports',
    component: ReportsComponent,
  },
  {
    path: 'tests',
    component: TestsComponent,
  },

  {
    path: 'patients',
    component: PatientsListComponent,
  },
  {
    path: 'correspondents',
    component: CorrespondentsComponent,
  },
  {
    path: 'doctors',
    component: DoctorsComponent,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

const homeChildren: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AdminAuthGuard],
    children: AdminChildren,
  },
  {
    path: 'technician',
    component: TechnicianLayoutComponent,
    canActivate: [TechnicianAuthGuard],
    children: TechnicianChildren,
  },
  {
    path: 'receptionist',
    component: ReceptionistLayoutComponent,
    canActivate: [ReceptionistAuthGuard],
    children: ReceptionistChildren,
  },
  { path: '', redirectTo: '/admin/tests', pathMatch: 'full' },
  { path: '**', redirectTo: '/admin/tests', pathMatch: 'full' },
];

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [NotAuthGuard] },
  {
    path: '',
    component: HomeComponent,
    children: homeChildren,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
