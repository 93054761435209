import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $:any

@Component({
  selector: 'app-correspondents',
  templateUrl: './correspondents.component.html',
  styleUrls: ['./correspondents.component.scss']
})
export class CorrespondentsComponent implements OnInit {

  correspondents:any;
  subcategories:any;
  subcategory_id = 0;
  searchByName ="" ;
  action :any = {}


  constructor(public data:DataService, public router:Router,public api : ApiService,public progress : ProgressService, public ms:ModalService) { }

  ngOnInit() {

    this.data.remove.subscribe(({id,type}) => {
      if(type == "correspondent"){
        this.correspondents = this.correspondents.filter(one => one.id != id)
        $("#cor-modal").modal('hide');
      }
    })


    this.getCorrespondents(this.searchByName);
    this.getSubcategories();
  }


   getCorrespondents(name=""){
    // $('.dropdown-menu').dropdown('show');
    this.progress.on();
    // this.api.Common.Get.correspondents({name,"with[]":['payment']}).subscribe(res => {
    this.api.Common.Get.fitered_withSearch_correspondents({name,"with[]":['payment'], subcategory_id:this.subcategory_id}).subscribe(res => {
      this.correspondents = res
      this.progress.off();
      console.log(res)
    });
  }

  getSubcategories(){
    this.api.Common.Get.subcategories().subscribe(res => {
      this.subcategories = res
      console.log(res)
    });
  }



  selectDefault(correspondent){
    this.correspondents.forEach(one => {
      if(one.id != correspondent.id) one['is_default'] = false
      else one['is_default'] = !one['is_default']
    })

    this.api.Admin.correspondents.toggle_default(correspondent.id).subscribe(one => {})

  }

  openModal(action){

    this.action = action
    $("#cor-modal").modal('show');

  }



  correspondentCreated(){
    this.getCorrespondents(this.searchByName);
    $("#cor-modal").modal('hide');
  }

  async correspondentsSearch(){
    console.log(this.searchByName);
    let query=this.searchByName;
    // let query="name="+this.searchByName;
   await this.getCorrespondents(query);

  }


}
