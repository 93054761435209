import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss']
})
export class UnitListComponent implements OnInit {
  action: any = '';
  units: any[] = [];

  constructor(
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public ms: ModalService
  ) {}

  ngOnInit() {
    this.getUnits();
  }

  getUnits() {
    this.progress.on();

    this.api.Common.Get.units().subscribe((res) => {
      this.progress.off();
      this.units = res;
    });
  }
}
