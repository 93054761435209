import {
  Component,
  OnInit,
  AfterViewChecked,
  AfterViewInit,
} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { debounceTime, map, switchAll, tap } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { SnackService } from "src/app/services/snack.service";
import { ProgressService } from "src/app/services/progress.service";
import { Observable, fromEvent } from "rxjs";
import { Arabic } from "flatpickr/dist/l10n/ar";
declare var $: any;
declare var moment:any;
@Component({
  selector: "technician-layout-requests-list",
  templateUrl: "./requests-list.component.html",
  styleUrls: ["./requests-list.component.scss"],
})
export class RequestsListComponent implements OnInit, AfterViewInit {
  username: any = localStorage.getItem("userName");
  key: any = "";
  requests: any;
  page = 1;
  from = '';
  to = '';
  pagination: any = {};
  type: string = "name";
  patient_search: any = "";

  selectedTest: any = {}
  reception_number: any;
  isRequestNumLoaderHidden = true;
  isLoaderHidden = true;

  status: any = "";
  isValidated: number = null;
  payment_status: any = "";
  category_id: any = "";
  statuses = [
    "active",
    "in progress",
    "completed",
    "partially validated",
    "technically validated",
  ];
  payment_statuses = ["paid", "unpaid"];


  summery_request_id: number;
  summery_patientId: number;



  constructor(
    public api: ApiService,
    public router: Router,
    public data: DataService,
    public snack: SnackService,
    public progress: ProgressService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // this.data.copy('there\'sno way Im thinking of that')
    let queries = this.data.queries;
    for (let key in queries) {
      switch (key) {
        case "status":
          this.status = queries[key];
          break;

        case "payment_status":
          this.payment_status = queries[key];
          break;

        case "category_id":
          this.category_id = queries[key];
          break;

        case "patient_name":
          this.key = queries[key];
          break;

        case "reception_number":
          this.reception_number = queries[key];
          break;

        case "min_date":
          this.from = queries[key].split("-").reverse().join("-");
          break;

        case "max_date":
          this.to = queries[key].split("-").reverse().join("-");
          break;
        case "test_status":
          this.isValidated = queries[key];
          break;
        case "page":
          this.page = queries[key];
          break;

        case "test_id":
          this.api.Common.Get.test(queries[key]).subscribe((res) => {
            this.selectedTest = res;
            this.getRequests(1);
          });
          break;

        default:
          break;
      }
    }

    this.progress.on();
   // this.getRequests(this.page);
   this.api.Common.Get.lab_info().subscribe(res =>{
    if(res['data']['settings']['ReqListDefaultDate'] ){
      this.route.queryParams.subscribe((params) => {
        if (params['from']){
          this.from = params['from'];
        }else{
          this.from = moment().format("DD-MM-YYYY");
        }
        if (params['to']){
          this.to = params['to'];
        }else{
          this.to = moment().format("DD-MM-YYYY");
        }
      })
    }else{
      this.route.queryParams.subscribe((params) => {
        if (params['from']){
          this.from = params['from'];
        }else{
          this.from = '';
        }
        if (params['to']){
          this.to = params['to'];
        }else{
          this.to = '';
        }
      })
    }
    this.getRequests(this.page);
  })


  this.route.queryParams.subscribe((params) => {
    if (params['patient_search']) this.patient_search = params['patient_search'];
    if (params['type']) this.type = params['type'];
  })
  }

  ngAfterViewInit() {
    // this.isValidated=params.test_status
    $("#request_num_autocomplete").val(this.reception_number);
    $("#patient_name_autocomplete").val(this.key);
    $("toPicker").val(this.to);
    $("fromPicker").val(this.from);
    var input$: Observable<any> = fromEvent(
      $("#request_num_autocomplete"),
      "input"
    );
    input$.pipe(map((res) => res["target"].value)).subscribe((res) => {
      this.reception_number = res;
      this.isRequestNumLoaderHidden = false;
      this.getRequests(1);
    });

    var input2$: Observable<any> = fromEvent(
      $("#patient_name_autocomplete"),
      "input"
    );
    input2$.pipe(map((res) => res["target"].value)).subscribe((res) => {
      this.key = res;
      this.isLoaderHidden = false;
      this.getRequests(1);
    });

    setTimeout(() => {
      $("#fromPicker").flatpickr({
        dateFormat: "d-m-Y",
        allowInput: true,
        defaultDate: this.from,
        locale: this.data.language == "arabic" ? Arabic : null,
        position: "auto center",
      });

      $("#toPicker").flatpickr({
        dateFormat: "d-m-Y",
        allowInput: true,
        defaultDate: this.to,
        locale: this.data.language == "arabic" ? Arabic : null,
        position: "auto center",
      });

      $("#birthdatePicker").flatpickr({
        dateFormat: "d-m-Y",
        allowInput: true,
        locale: this.data.language == "arabic" ? Arabic : null,
        position: "auto center",
      });
    }, 0);
  }

  search() {
    this.getRequests(1);
  }




  get_queries(page) {
    return new Promise((resolve) =>
      setTimeout(() => {
        let patient_search = this.patient_search.match(/[0-9]+-[0-9]+-[0-9]+/)
          ? this.patient_search.split("-").reverse().join("-")
          : this.patient_search;
        resolve({
          page: page,
          patient_search,
          patient_search_by: this.type,
          reception_number: this.reception_number,
          test_id: this.selectedTest ? this.selectedTest.id : null,
          status: this.status,
          test_status: this.isValidated,
          payment_status: this.payment_status,
          category_id: this.category_id,
          min_date: this.from.split("-").reverse().join("-"),
          max_date: this.to.split("-").reverse().join("-"),
        });
      }, 0)
    );
  }

  async getRequests(page) {

    this.page = page;
    let queries = await this.get_queries(page);
    this.data.queries = queries;

    this.requests = this.api.Common.requests.get(queries).pipe(
      tap((res) => {
        console.log('first stop')

        this.pagination = this.data.extract_pagination(res);
        this.progress.off();
        this.isRequestNumLoaderHidden = true;
        this.isLoaderHidden = true;
      }),
      map((res) => res["data"])
    );
  }

  editRequest(request) {
    var queries = `?from=${this.from}&to=${this.to}&patient_search=${this.patient_search}&type=${this.type}`
    this.router.navigateByUrl(
      `technician/requests/${request["id"]}/edit${queries}`
    );

    
  
  }



  openDetails(id, patient_id) {
    this.summery_request_id = id;
    this.summery_patientId = null;
    this.summery_patientId = patient_id;
    $("#details_modal").modal("toggle");
  }
}
