import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-layout-micro',
  templateUrl: './micro.component.html',
  styleUrls: ['./micro.component.scss']
})
export class MicroComponent implements OnInit {

  routes: Array<String> = ["germ-families" , "antibiotics" , "bacteria"]
  route: any = "germ-families"

  goto(){
    this.router.navigateByUrl(`admin/micro/${this.route}`)
  }

  constructor(private router:Router) { }

  ngOnInit() {
    this.route = location.href.split('/')[location.href.split('/').length -1]
  }

}
