<nav class="navbar navbar-expand-lg   ">
  <div class="container-fluid">

      <button type="button" id="sidebarCollapse" class="toggle"
      (click)="data.sidebarStatus = !data.sidebarStatus"
      >
          <i class="fas fa-bars"></i>
      </button>


      <!-- <button type="button" id="sidebarCollapse" class="toggle ">
          <i class="fas fa-shopping-cart"></i>
      </button> -->


      <!-- <button class="toggle d-lg-none ml-auto collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fas fa-arrow-down"></i>
      </button> -->



      <!-- <div class="navbar-collapse collapse" id="navbarSupportedContent">

          <ul class="nav navbar-nav ml-auto tajwal-medium">

              <li class="nav-item  ">
                  <a class="nav-link" href="#">الطلبات</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link btn btn-outline-primary" href="#">تسجيل الدخول</a>
              </li>

          </ul>
      </div> -->
  </div>
</nav>
