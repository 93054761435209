import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable, fromEvent, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
declare var $:any

@Component({
  selector: 'selector',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss']
})
export class ItemSelectComponent implements OnInit {

  // items : any = [];;
  @Input() group = false
  @Input() no_label = false
  @Input() no_margin = false
  @Input() top = null
  @Input() array = null
  @Input() datum = null
  method: Function = null
  key = '';
  filter;
  query_name:string = 'name'
  hasData:boolean = false
  @Input() is_paginated:boolean = true
  filtered_items: Array<any> = [];
  items: Observable<any>;
  not_paginated_items:Array<any> = [];
  arr = Array
  @Input() title =""
  @Input() label =""
  row:number=0;
  @Input() unique:string = ''
  selectedDatum:any = null
  Params: any [] = [];
  headers:any[] = []
  values:any[] = []
  inputs = {}
  route:any = ""
  action :any = {}
  page : any = 1
  pagination: any ;
  isLoaderHidden = true;

  constructor(public data:DataService,public api : ApiService,
    public router:Router) { }

    @Output() setSelectedItem = new EventEmitter();


  getItems(value){
    let method = this.method || this.api.Common.Get[this.route]
    this.array? this.items = of(this.array) :
    this.items = method({[this.query_name] : value, page:1}).pipe(map(res => this.filter? this.filter(this.hasData? res['data']: res) : this.hasData? res['data']: res));
    if(!this.is_paginated){
      this.items.subscribe(items => {
        this.not_paginated_items = items
      })
    }
    // $('.item-dropdown-menu' + this.route).dropdown('show');


  }


  change({value}){
    this.row=0;
    $('.item-dropdown-menu-' + this.route + this.unique).show();
    if(this.is_paginated) this.getItems(value)
    else this.filtered_items = this.not_paginated_items.filter(obj => obj.code.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
  }

  ngOnInit() {
    if(this.title == 'tests'){
      // this.label = 'Device'
      this.query_name = 'code'
      this.label = "Test"
      this.headers = ['Name']
      this.values = ['name',]
      this.route = 'tests'
      this.hasData = true
    }else if(this.title == 'doctors'){
      // this.label = 'Device'
      this.headers = ['Name']
      this.values = ['name',]
      this.route = 'doctorsSearch'
      this.label = 'Doctor'
      this.query_name = 'name'
      this.hasData = true
    }else if(this.title == 'samples'){
      // this.label = 'Device'
      this.headers = ['Name']
      this.values = ['name',]
      this.label = 'sample'
      this.route = 'samples'
      this.is_paginated = false
    }else if(this.title == 'device'){
      // this.label = 'Device'
      this.headers = ['Code','Name']
      this.values = ['code','name',]
      this.query_name = 'code'
      this.method = this.api.Admin.devices.get
      this.label = 'Device | Method'
    }else if(this.title == 'parameters'){
      // this.label = 'Device'
      this.headers = ['Name' , 'Code']
      this.values = ['name', 'code']
      this.query_name = 'code'
      this.label = 'Parameters'
      this.route = 'parameters'
      this.hasData = true
    }else if(this.title == 'bacteria'){
      // this.label = 'Device'
      this.headers = ['Name']
      this.values = ['name',]
      this.route = 'bacteria'
      this.query_name = 'code'
    }else if(this.title == 'antibiotic'){
      this.label = 'Antibiotics'
      this.headers = ['Name']
      this.values = ['name',]
      this.route = 'antibiotics'
      this.query_name = 'code'
    }else if(this.title == 'employee'){
      this.headers = ['ID','Name']
      this.values = ['id','name',]
      this.query_name = 'name'
      this.method = this.api.Common.Get.treasuries
      this.label = 'Cashiers'
    }else if(this.title == 'units'){
      this.headers = ['ID','Name']
      this.values = ['id','name',]
      this.query_name = 'code'
      this.method = this.api.Common.Get.units
      this.label = 'Units'
    } else if(this.title == 'categoires'){
      this.headers = ['ID','Name']
      this.values = ['id','name',]
      this.query_name = 'code'
      this.method = this.api.Common.Get.categories
      this.label = 'Categoires'
    }
    if(!this.is_paginated) this.getItems("")


  }


  hide(){
    $('.item-dropdown-menu-' + this.route + this.unique).hide();
  }

  setItem(item){
    $('.item-dropdown-menu-' + this.route + this.unique).hide();
    $('#item_autocomplete-' + this.route + this.unique).val("");
    if(this.datum){
      let obj = JSON.parse(JSON.stringify(item))
      Object.keys(this.datum).forEach(key => delete this.datum[key])
      Object.keys(obj).forEach(key => this.datum[key] = obj[key])
    }
    this.setSelectedItem.emit(item)




  }

  shiftFocusDown(){
  this.selectTestFromTable('down');
  }
  shiftFocusUp(){
  this.selectTestFromTable('up');
  }
  selectTestFromTable(pressKey){
    let rows= document.querySelectorAll('.allRow')
    let nextTest= rows[this.row];
    nextTest.setAttribute('class','allRow ')
    nextTest.setAttribute('id','selectTest')
    pressKey=='down'?this.row+=1:this.row-=1
    if((this.row>=rows.length)||(this.row<0)){
      var index=0;
      this.row=0;
    }else{
      var index=this.row;
    }



    let test= rows[index];
    // console.log('row----',this.row,'next>>>>',next,'last>>>>',last,'index!!!!',index,'input>>',test);

    test.setAttribute('class','allRow selectTest')
    test.setAttribute('id','selectTest')
    test.scrollIntoView();
  }
  enter(){
    document.getElementById("selectTest").click();
  }

}
