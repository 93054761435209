import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'admin-layout-payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.scss'],
})
export class PaymentMethodsListComponent implements OnInit {
  paymentMethods: any[] = [];
  action: any = '';

  constructor(
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public ms: ModalService
  ) {}

  ngOnInit() {
    this.getPaymentMethods();
  }

  getPaymentMethods() {
    this.progress.on();

    this.api.Common.Get.payment_methods().subscribe((res) => {
      this.progress.off();
      this.paymentMethods = res;
    });
  }
}
