<div class="row">
    <div class="col">
      <h2 class="roboto-300 start">
        {{'Transactions' | locale}}
      </h2>
    </div>

    <div style="display: flex; align-items: center" class="mx-3">
      <div class="form-group form-check start mb-0">
        <input
          type="checkbox"
          class="form-check-input"
          name="excel"
          [(ngModel)]="excel2"
          [checked]="excel2"
          id="excel2"
        />
        <label
          class="form-check-label"
          [ngClass]="{ 'mr-4': data.isRTL }"
          for="excel"
          >{{ "Excel" | locale }}</label
        >
      </div>
    </div>
    <button
          data-toggle="modal"
          data-target="#clinic-modal"
          type="button"
          id="add-button"
          style="height:fit-content"
          class="btn btn-primary roboto-400 px-5 my-auto"
          (click)="print_transaction()"
        >
          {{ "Print" | locale }}
    </button>
</div>
<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <div class="row mt-2">
      <div class="col-4 mt-3 mt-lg-0">
        <h4 style="text-align:end">{{'Total' | locale}}: {{ total || 0}}</h4>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label for="fromPicker">{{'Date From' | locale}}</label>
              <input
                id="fromPicker"
                [(ngModel)]="from"
                class="flatpickr flatpickr-input active form-control"
                type="date"
                (change)="sendRequest()"
                placeholder="Select Date.."
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="toPicker">{{'Date To' | locale}}</label>
              <input
                id="toPicker"
                [(ngModel)]="to"
                class="flatpickr flatpickr-input active form-control"
                type="date"
                (change)="sendRequest()"
                placeholder="Select Date.."
              />
            </div>
          </div>
          <div class="col-md-3">
            <label>{{'Time From' | locale}}</label>
            <input
              type="time"
              class="form-control"
              id="from_time"
              name="from_time"
              (change)="sendRequest()"
              [(ngModel)]="from_time"
              class="flatpickr flatpickr-input active form-control"
              placeholder="Select Time.."
            />
          </div>
          <div class="col-md-3">
            <label>{{'Time To' | locale}}</label>
            <input
              type="time"
              class="form-control"
              name="to_time"
              id="to_time"
              (change)="sendRequest()"
              [(ngModel)]="to_time"
              class="flatpickr flatpickr-input active form-control"
              placeholder="Select Time.."
            />
          </div>
          <div class="form-group col-6 col-lg-6 col-md-6">
            <Label>{{ "Payment Method" | locale }}</Label>
            <select
              name="payment_method_filter"
              [(ngModel)]="payment_method_filter"
              class="form-control"
              id="payment_method_filter"
              (change)="sendRequest()"
            >
              <option value="">{{ "All" | locale }}</option>
              <ng-container *ngFor="let payment_method of payment_methods">
                <option value="{{ payment_method.id }}">{{ payment_method.name }}</option>
              </ng-container>
            </select>
          </div>
          <div class="form-group col-6 col-lg-6 col-md-6">
            <Label>{{ "Payer" | locale }}</Label>
            <select
              name="payer"
              [(ngModel)]="payer"
              class="form-control"
              id="payer"
              (change)="sendRequest()"
            >
              <option value="">{{ "All" | locale }}</option>
                <option value="patient">{{ "Patient" | locale }}</option>
                <option value="correspondent">{{ "Correspondent" | locale }}</option>
            </select>
          </div>
          <div class="form-group col-6 col-lg-6 col-md-6">
            <Label>{{ "User" | locale }}</Label>
            <select
              name="user_id"
              [(ngModel)]="user_id"
              class="form-control"
              id="user_id"
              (change)="sendRequest()"
            >
              <option value="">{{ "All" | locale }}</option>
                <ng-container *ngFor="let user of users">
                  <option value="{{user.id}}">{{ user.name }}</option>
                </ng-container>
            </select>
          </div>
          <div class="form-group col-6 col-lg-6 col-md-6">
            <Label>{{ "Cashiers" | locale }}</Label>
            <select
              name="cashier_id"
              [(ngModel)]="cashier_id_filter"
              class="form-control"
              id="cashier_id_filter"
              (change)="sendRequest()"
            >
              <option value="">{{ "All" | locale }}</option>
              <ng-container *ngFor="let cashier of cashiers">
                <option value="{{ cashier.id }}">{{ cashier.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table mt-3">
          <thead>
            <tr class="neue-light roboto-400">
              <th  scope="col" >{{'ID' | locale:2}}</th>
              <th scope="col">{{'Request #' | locale}}</th>
              <th scope="col">{{'Time'  | locale}}</th>
              <th scope="col">{{'Payer'  | locale}}</th>
              <th scope="col">{{'Value'  | locale}}</th>
              <th scope="col">{{'Payment method'  | locale}}</th>
            </tr>
          </thead>
          <br />

          <tbody class="neue-light mt-2">
            <ng-container
              *ngFor="
                let req of transactions
                  | paginate
                    : {
                        id: 'pagination_transactions',
                        itemsPerPage: pagination_transactions.per_page,
                        currentPage: pagination_transactions.current_page,
                        totalItems: pagination_transactions.total
                      }; let i = index
              "
            >
              <tr valign="middle" class="clickable_row roboto-400">
                <td>{{ i + 1 }}</td>
                <td>{{ req.patient_request.reception_number }}</td>
                <td>{{ req.created_at | date: "full" | locale:'date' }}</td>
                <td>{{ req.payer }}</td>
                <td>{{ req.amount }}{{ data.info["currency"] }}</td>
                <td>{{ req.payment_method.name }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="col-12 mt-3">
        <div class="w-100 d-flex justify-content-center neue-med p-0">
          <pagination-controls
            responsive="true"
            [previousLabel]="'Previous' | locale"
            [nextLabel]="'Next' | locale"
            (pageChange)="getTransactions($event)"
            id="pagination_transactions"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
