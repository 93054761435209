import { ResultStatusPipe } from './pipes/result.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContentComponent } from './home/content/content.component';
import { SidebarComponent } from './home/sidebar/sidebar.component';
import { NavComponent } from './home/nav/nav.component';
import { TitleComponent } from './home/content/title/title.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ApiService } from './services/api.service';
import { ApiService as TechnicianLayoutApiService } from './services/technician-api.service';
import { ApiService as AdminLayoutApiService } from './services/admin-api.service';
import { ApiService as ReceptionistLayoutApiService } from './services/receptionist-api.service';
import { ApiService as CommonApiService } from './services/common-api.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TimeLeftPipe } from './pipes/time_left.pipe';
import { DatePipe } from './pipes/date.pipe';
import { TimePipe } from './pipes/time.pipe';
import { MainComponent } from './home/content/main/main-component';
import { RequestsListComponent as ReceptionistRequestsListComponent } from './home/content/receptionist-layout/requests-list/requests-list.component';
import { PatientSelectComponent as ReceptionistPatientSelectComponent } from './home/content/receptionist-layout/patient-select/patient-select.component';
import { NewRequestComponent as ReceptionistNewRequestComponent } from './home/content/receptionist-layout/new-request/new-request.component';
import { NewPatientComponent as ReceptionistNewPatientComponent } from './home/content/receptionist-layout/new-patient/new-patient.component';
import { UrgentPipe } from './pipes/urgent.pipe';
import { RepeatPipe } from './pipes/repeat.pipe';
import {
  AdminAuthGuard,
  AuthGuard,
  NotAuthGuard,
  ReceptionistAuthGuard,
  TechnicianAuthGuard,
} from './guards/AuthGuard';
import { PaymentComponent as ReceptionistPaymentComponent } from './home/content/receptionist-layout/payment/payment.component';
import { StatComponent } from './home/content/stat/stat.component';
import { TestSelectComponent as ReceptionistTestSelectComponent } from './home/content/receptionist-layout/test-select/test-select.component';
import { RequestSummaryComponent as ReceptionistRequestSummaryComponent } from './home/content/receptionist-layout/request-summary/request-summary.component';
import { LoadingComponent } from './home/content/loading/loading.component';
import { ResultComponent as ReceptionistResultComponent } from './home/content/receptionist-layout/result/result.component';
import { CorrespondentComponent as ReceptionistCorrespondentComponent } from './home/content/receptionist-layout/correspondent/correspondent.component';
import { TheDatePipe } from './pipes/the-date.pipe';
import { AgoPipe } from './pipes/ago.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalePipe } from './pipes/locale.pipe';
import { FindPipe } from './pipes/find.pipe';
import { DrawSampleComponent as ReceptionistDrawSampleComponent } from './home/content/receptionist-layout/draw-sample/draw-sample.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AdminLayoutComponent } from './home/content/admin-layout/admin-layout.component';
import { TechnicianLayoutComponent } from './home/content/technician-layout/technician-layout.component';
import { ReceptionistLayoutComponent } from './home/content/receptionist-layout/receptionist-layout.component';

import { RequestsListComponent as TechnicianRequestsListComponent } from './home/content/technician-layout/requests-list/requests-list.component';
import { NewRequestComponent as TechnicianNewRequestComponent } from './home/content/technician-layout/new-request/new-request.component';
import { EditResultComponent as TechnicianEditResultComponent } from './home/content/technician-layout/edit-result/edit-result.component';

import { PatientsListComponent } from './home/content/patients-list/patients-list.component';
import { ParametersListComponent as AdminParametersListComponent } from './home/content/admin-layout/parameters-list/parameters-list.component';
import { EmployeesListComponent as AdminEmployeesListComponent } from './home/content/admin-layout/employees-list/employees-list.component';
import { CatsListComponent as AdminCatsListComponent } from './home/content/admin-layout/cats-list/cats-list.component';
import { FamilyListComponent as AdminFamilyListComponent } from './home/content/admin-layout/family-list/family-list.component';
import { PaymentMethodsListComponent as AdminPaymentMethodsListComponent } from './home/content/admin-layout/payment-methods-list/payment-methods-list.component';
import { CivilitiesListComponent as AdminCivilitiesListComponent } from './home/content/admin-layout/civilities-list/civilities-list.component';
import { PricingListComponent as AdminPricingListComponent } from './home/content/admin-layout/pricing-list/pricing-list.component';
import { NewParameterComponent as AdminNewParameterComponent } from './home/content/admin-layout/new-parameter/new-parameter.component';
import { NewTestComponent as AdminNewTestComponent } from './home/content/admin-layout/new-test/new-test.component';
import { DevicesListComponent as AdminDevicesListComponent } from './home/content/admin-layout/devices-list/devices-list.component';
import { CashierListComponent as AdminCashierListComponent } from './home/content/admin-layout/cashier-list/cashier-list.component';
import { CorrespondentComponent as AdminCorrespondentComponent } from './home/content/admin-layout/correspondent/correspondent.component';
import { ClinicsComponent as AdminClinicsComponent } from './home/content/admin-layout/clinics/clinics.component';
import { ClinicComponent as AdminClinicComponent } from './home/content/admin-layout/clinic/clinic.component';
import { GermFamiliesComponent as AdminGermFamiliesComponent } from './home/content/admin-layout/germ-families/germ-families.component';
import { BacteriaComponent as AdminBacteriaComponent } from './home/content/admin-layout/bacteria/bacteria.component';
import { ReceptionistsComponent as AdminReceptionistsComponent } from './home/content/admin-layout/receptionists/receptionists.component';
import { ReceptionistComponent as AdminReceptionistComponent } from './home/content/admin-layout/receptionist/receptionist.component';
import { AntibioticsComponent as AdminAntibioticsComponent } from './home/content/admin-layout/antibiotics/antibiotics.component';
import { MicroComponent as AdminMicroComponent } from './home/content/admin-layout/micro/micro.component';
import { ActivityComponent } from './home/content/activity/activity.component';
import { ExpirationComponent as AdminExpirationComponent } from './home/content/admin-layout/expiration/expiration.component';
import { SettingsComponent as AdminSettingsComponent } from './home/content/admin-layout/settings/settings.component';
import { LabsListComponent as AdminLabsListComponent } from './home/content/admin-layout/labs-list/labs-list.component';
import { LabComponent as AdminLabComponent } from './home/content/admin-layout/lab/lab.component';
import { UpdateAllPriceComponent as AdminUpdateAllPriceComponent } from './home/content/admin-layout/update-all-price/update-all-price.component';
import { ItemSelectComponent } from './home/content/item-select/item-select.component';
import { ModalComponent } from './home/content/modal/modal.component';
import { LoadingDirective } from './directives/loading.directive';
import { CheckSelectedPatientsPipe } from './pipes/check-selected-patients.pipe';
import { DoctorsComponent } from './home/content/doctors/doctors.component';
import { RolesDirective } from './directives/roles.directive';
import { TestsComponent } from './home/content/tests/tests.component';
import { CorrespondentsComponent } from './home/content/correspondents/correspondents.component';
import { ReportsComponent } from './home/content/reports/reports.component';
import { SearchComponent } from './home/content/search/search.component';
import { SamplesComponent } from './home/content/samples/samples.component';
import { RangePipe } from './pipes/range.pipe';
import { DetialsComponent as TechnicianDetialsComponent} from './home/content/technician-layout/detials/detials.component';
import { ReceptionstsTransactionsComponent as AdminReceptionstsTransactionsComponent } from './home/content/admin-layout/receptionsts-transactions/receptionsts-transactions.component';
import { SubCategoriesListComponent } from './home/content/admin-layout/sub-categories-list/sub-categories-list.component';
import { UnitListComponent } from './home/content/admin-layout/unit-list/unit-list.component';
import { SupportListComponent } from './home/content/admin-layout/support-list/support-list.component';
import { NewSupportComponent } from './home/content/admin-layout/new-support/new-support.component';
import { SupportComponent } from './home/content/admin-layout/support/support.component';
import { MessagingComponent } from './home/content/receptionist-layout/messaging/messaging.component';

@NgModule({
  declarations: [
    AppComponent,
    DatePipe,
    TimePipe,
    ResultStatusPipe,
    HomeComponent,
    ContentComponent,
    SidebarComponent,
    NavComponent,
    TitleComponent,
    LoginComponent,

    TimeLeftPipe,
    UrgentPipe,
    RepeatPipe,
    MainComponent,
    ModalComponent,
    ActivityComponent,

    ReceptionistTestSelectComponent,
    ReceptionistRequestSummaryComponent,

    ReceptionistResultComponent,
    ReceptionistCorrespondentComponent,
    ReceptionistDrawSampleComponent,
    ReceptionistRequestsListComponent,
    ReceptionistPatientSelectComponent,
    ReceptionistNewRequestComponent,
    ReceptionistNewPatientComponent,

    ReceptionistPaymentComponent,

    LoadingComponent,

    TheDatePipe,

    AgoPipe,

    LocalePipe,
    CheckSelectedPatientsPipe,
    RangePipe,
    FindPipe,
    AdminLayoutComponent,
    TechnicianLayoutComponent,
    ReceptionistLayoutComponent,

    TechnicianRequestsListComponent,
    TechnicianNewRequestComponent,
    TechnicianEditResultComponent,
    TechnicianDetialsComponent,
    PatientsListComponent,
    StatComponent,
    AdminParametersListComponent,
    AdminEmployeesListComponent,
    AdminCatsListComponent,
    AdminFamilyListComponent,
    AdminPaymentMethodsListComponent,
    AdminCivilitiesListComponent,
    AdminPricingListComponent,
    AdminNewParameterComponent,
    AdminNewTestComponent,
    AdminDevicesListComponent,
    AdminCashierListComponent,
    AdminCorrespondentComponent,
    AdminClinicsComponent,
    AdminClinicComponent,
    AdminGermFamiliesComponent,
    AdminBacteriaComponent,
    AdminReceptionistsComponent,
    AdminReceptionistComponent,
    AdminAntibioticsComponent,
    AdminMicroComponent,
    AdminExpirationComponent,
    AdminSettingsComponent,
    AdminLabsListComponent,
    AdminLabComponent,
    AdminUpdateAllPriceComponent,
    ItemSelectComponent,
    LoadingDirective,
    DoctorsComponent,
    RolesDirective,
    TestsComponent,
    CorrespondentsComponent,
    ReportsComponent,
    SearchComponent,
    SamplesComponent,
    AdminReceptionstsTransactionsComponent,
    SubCategoriesListComponent,
    UnitListComponent,
    SupportListComponent,
    NewSupportComponent,
    SupportComponent,
    MessagingComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    RecaptchaModule,
    AppRoutingModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxPaginationModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    ApiService,
    TechnicianLayoutApiService,
    ReceptionistLayoutApiService,
    AdminLayoutApiService,
    CommonApiService,

    AuthGuard,
    NotAuthGuard,
    TechnicianAuthGuard,
    AdminAuthGuard,
    ReceptionistAuthGuard,
    TheDatePipe,
    AgoPipe,
    LocalePipe,
    FindPipe,
    CheckSelectedPatientsPipe,
    RangePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
