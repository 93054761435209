<ng-container *ngIf="lab">
  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{ lab.name }}</h2>
    </div>
    <!-- <div class="col-2 ">
      <button
      (click)="currentPer = undefined"
      data-toggle="modal"
      data-target="#paymentModal"
        type="button"
        id="add-button"
        class="btn btn-primary w-100 roboto-400"
      >
        Pay
      </button>
    </div> -->
  </div>
</ng-container>

<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <P class="col-12 h5 grey start my-2 mx-auto" style="width: fit-content">{{ "Lab info" | locale }}</P>

    <div *ngIf="lab" class="col-12 table-responsive">
      <table  class="table">
        <thead class="bg-white">
          <tr>
            <!-- <th>{{ "Code" | locale }}</th> -->
            <th>{{ "Phone" | locale }}</th>
            <th>{{ "Number of Downloaded" | locale }}</th>
            <th>{{ "Downloaded total" | locale }}</th>
            <th>{{ "Number of uploaded" | locale }}</th>
            <th>{{ "Uploaded total" | locale }}</th>
            <!-- <th>{{ "Status" | locale }}</th> -->
          </tr>
        </thead>

        <tbody>
          <tr>
            <!-- <td>{{ lab.code }}</td> -->

            <td>{{ lab.phone_number || "None" }}</td>

            <td>{{ lab.num_of_requests?.downloaded || 0 }}</td>
            <td>{{ lab.total_downloaded || 0 }}</td>

            <td>{{ lab.num_of_requests?.uploaded || 0 }}</td>
            <td>{{ lab.total_uploaded || 0 }}</td>

            <!-- <td>{{ lab.status | locale }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <!-- //  request list component start / -->

    <ng-container>
      <div class="card mt-4 mb-5">
        <div class="container-fluid py-3 px-4">
          <!-- <div class="row mt-2">
              <div class="col-12 col-lg-10">
                <div class="form-group m-0 roboto-400">
                  <input
                    type="text"
                    class="form-control px-5"
                    id="request_autocomplete"
                    placeholder="Search with patient name"
                  />

                  <i
                    [hidden]="!isLoaderHidden"
                    class="fas search-icon fa-search"
                  ></i>

                  <div
                    [hidden]="isLoaderHidden"
                    #loader
                    class="spinner-border text-primary load"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-2 mt-3 mt-lg-0">
                <div class="d-flex w-100 justify-content-end">
                  <button
                    data-toggle="modal"
                    data-target="#modal"
                    type="button"
                    id="add-button"
                    class="btn btn-primary w-100 roboto-400"
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div> -->

          <!-- <div class="row mt-2 justify-content-center">
              <div *ngFor="let per of ['requests','day', 'month']" class="col-2 mx-4">
                <div class="d-flex w-100 justify-content-end">
                  <p
                    (click)="toggleFlags(per)"
                    class="btn btn-outline-primary btn-sm w-100 roboto-400 m-0"
                    [ngClass]="{
                      'btn-primary text-white': flags[per]
                    }"
                  >
                    {{ per }}
                  </p>
                </div>
              </div>

            </div> -->
          <!-- <div class="row mt-2 justify-content-center">

              <div [ngStyle]="!flags.requests?{'display' : 'none'}: {}" *ngFor="let date of ['from', 'to']" class="col-5 mt-3">
                <div class="form-group">
                  <label >{{date}}</label>
                  <input
                    [name]="date"
                    [(ngModel)]="dates[date]"
                    [id]="date + 'Picker'"
                    (change)="toggleFlags('requests')"
                    class="flatpickr flatpickr-input active form-control"
                    type="text"
                    placeholder="Select Date.."
                  />
                </div>
              </div>
              <div *ngIf="flags.requests" class="form-group col-2 mt-3">
                <label> &nbsp; </label>
                <button (click)="print_requests()" class="btn form-control btn-outline-primary"  >Print</button>
              </div>

            </div> -->

          <div class="row">
            <h3 class="col-12 text-center">{{ "Requests" | locale }}</h3>
            <div class="col-12">
              <div class="table-responsive">
                <table class="table mt-3">
                  <thead>
                    <tr class="neue-light roboto-400">
                      <th  scope="col" > {{'ID' | locale:2}}</th>
                      <!-- <th width="15%" scope="col">Request #</th> -->

                      <th scope="col">{{ "Request #" | locale }}</th>
                      <th scope="col">{{ "Type" | locale }}</th>

                      <!-- <th  scope="col">Patient</th>
                        <th  scope="col" style="text-align: center;">Hospital ID</th>
                        <th  scope="col" style="text-align: center;">lab ID</th>
                        <th scope="col">Date</th>
                        <th  scope="col">Patient total</th>
                        <th  scope="col"> Total</th>
                        <th scope="col"> Paid </th>
                        <th  scope="col"> Remaining </th>
                        <th  scope="col">Status</th> -->
                      <!-- <th style="width: 50px" scope="col">Payments</th> -->
                      <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                      <!-- <th scope="col">خيارات</th> -->
                      <!-- <th scope="col"></th> -->
                    </tr>

                    <!-- | paginate: { id:'pagination' ,
              itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }" -->
                  </thead>
                  <tbody class="neue-light">
                    <ng-container
                      *ngFor="
                        let request of pers
                          | paginate
                            : {
                                id: 'pagination',
                                itemsPerPage: pagination.per_page,
                                currentPage: pagination.current_page,
                                totalItems: pagination.total
                              }; let i = index
                      "
                    >
                      <tr data-target="#lab_tests_modal" data-toggle="modal" (click)="selected_tests = request.tests" valign="middle" class="clickable_row roboto-400">
                        <td>{{ i + 1 }}</td>
                        <td>{{ request.reception_number }}</td>
                        <td>{{ request.type | locale }}</td>
                        <!-- <td >{{request.patient_hospital_id || '❌'}}</td>
                          <td >{{request.patient_insurance_id || '❌'}}</td>
                          <td>{{ request.created_at | date }}</td>
                          <td>{{request.payment?.patient_total}}</td>
                          <td>{{request.payment?.lab_total}}</td>
                          <td>{{request.payment?.lab_paid}}</td>
                          <td>{{request.payment?.lab_remaining}}</td>
                          <td >
                            {{
                              request.payment?.lab_isPaid
                                ? "Paid"
                                : "Unpaid"
                            }}
                            <span class="roboto-400 cur"> </span>
                          </td> -->
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12 mt-3" *ngIf="!(flags.month || flags.day)">
              <div class="w-100 d-flex justify-content-center neue-med p-0">
                <pagination-controls
                  responsive="true"
                  [previousLabel]="'Previous' | locale"
                  [nextLabel]="'Next' | locale"
                  (pageChange)="toggleFlags('requests', $event)"
                  id="pagination"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- <router-outlet></router-outlet> -->

    <!-- Modal -->
    <div
        class="modal fade"
        id="lab_tests_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-body">
              <div class="container-fluid px-4">
                <div class="row mt-4">
                  <div class="col-12">
                    <h5 class="roboto-500 text-center">{{'Tests | Profiles' | locale}}</h5>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table mt-3">
                          <thead>
                            <tr class="neue-light roboto-400">
                              <th  scope="col" > {{'ID' | locale:2}}</th>
                              <th scope="col">{{ "Code" | locale }}</th>
                              <th scope="col">{{ "Name" | locale }}</th>
                              <th scope="col">{{ "Price" | locale }}</th>

                          </thead>
                          <tbody class="neue-light">
                            <ng-container
                              *ngFor="
                                let test of selected_tests; let i = index"
                            >
                              <tr style="height:auto" valign="middle" class="clickable_row roboto-400">
                                <td>{{ i + 1 }}</td>
                                <td>{{ test.code }}</td>
                                <td>{{ test.name  }}</td>
                                <td>{{ test.price }}</td>

                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>


                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

    <!-- request list component end -->
  </div>
</div>

<!-- <router-outlet></router-outlet> -->
