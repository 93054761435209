import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { DataService } from '../services/data.service';

@Directive({
  selector: '[roles]',
})
export class RolesDirective implements OnInit {
  sub: Subscription = new Subscription();

  @Input() roles: {
    admin?: boolean;
    receptionist?: boolean;
    technician?: boolean;
    all?:boolean;
    type?: 'click';
  } = {};
  ngOnInit(): void {
    if (this.roles.type == 'click' && !this.roles[this.data.role] && !this.roles.all) {
      const el = this.elementRef.nativeElement;
      setTimeout(
        () =>
          Array.from(el.parentNode.children).forEach((tr: HTMLElement) => {
            tr.style.cursor = 'inherit';
          }),
        0
      );

      this.sub = fromEvent(el.parentNode, 'click', { capture: true }).subscribe(
        (e: any) => {
          e.stopPropagation();
        }
      );
      // })
    }

    if (this.roles.type == 'click' || this.roles[this.data.role] || this.roles.all) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private data: DataService,
    private elementRef: ElementRef
  ) {}
}
