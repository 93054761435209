
<div class="row">
    <div class="col">
      <h4 class="roboto-300 m-0 mt-1 start">{{'Request Details' | locale}} #{{request?.reception_number}}</h4>
    </div>


</div>
  <div *ngIf="request && request.patient" class="">


    <div class="container-fluid">

       <hr>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive  ">
            <table class="table  blueTable">
            <thead>



            </thead>
            <br>

            <tbody class="neue-light ">

                <!-- <tr>
                  <td class="head roboto-700 pb-4 pt-4">Patient Info</td>
                </tr> -->

                <!-- <tr valign="middle"  class="clickable_row roboto-400">
                  <td width="25%"><b>Patient id</b> </td>
                  <td>{{request['patient_id']}}</td>
                </tr> -->


                <tr >
                    <td class="head roboto-700 pb-4 pt-4" width="100%">
                    <h2>
                      {{'Request Info' | locale}}
                    </h2>
                    </td>
                </tr>

                <tr valign="middle"  class="clickable_row roboto-400">
                    <td width="50%"><b>{{'Patient Name' | locale}}</b> </td>
                    <td width="50%">{{request['patient']['name']}}</td>
                </tr>

                <tr valign="middle"  class="clickable_row roboto-400">
                    <td width="10%"><b>{{'Age' | locale}}</b> </td>
                    <td width="10%">{{request['patient']['humanized_age']}}</td>
                    <td width="25%"><b>{{'Birthdate' | locale}}</b> </td>
                    <td width="25%">{{request['patient']['birthdate']}}</td>
                    <td width="10%"><b>{{'Gender' | locale}}</b> </td>
                    <td width="20%">{{(request.patient?.gender?.name || '-') | locale}}</td>
                </tr>
                <tr valign="middle"  class="clickable_row roboto-400">
                    <td><b>{{'Phone Number' | locale}}</b> </td>
                    <td>{{request['patient']['phone_number'] || '-'}}</td>
                    <td><b>{{'Correspondent ID' | locale}}</b> </td>
                    <td>{{request['patient']['insurance_id'] || '-'}}</td>
                    <td><b>{{'Hospital ID' | locale}}</b> </td>
                    <td>{{request['patient']['hospital_id'] || '-'}}</td>
                </tr>

                <tr valign="middle"  class="clickable_row roboto-400">
                    <td width="25%"><b>{{'Correspondent' | locale}}</b> </td>
                    <td width="25%">{{ request['correspondent']?.name || '-'}}</td>
                    <td width="25%"><b>{{'Hospitalization Date' | locale}}</b> </td>
                    <td width="25%">{{request['hospitalization_date']? (request['hospitalization_date']  | date) : '-'}}</td>
                </tr>

                <tr valign="middle"  class="clickable_row roboto-400">
                  <td width="25%"><b>{{'Clinical Information' | locale}}</b> </td>
                  <td width="25%">{{request['clinical_information'] || '-'}}</td>
                  <td width="25%"><b>{{'Pricing' | locale}}</b> </td>
                  <td width="25%">{{request['pricing']['name']}}</td>
                </tr>

                <tr valign="middle"  class="clickable_row roboto-400">
                  <td width="50%"><b>{{'Laboratory Information' | locale}}</b> </td>
                  <td width="50%">{{request['laboratory_information'] || '-'}}</td>
                </tr>

                <tr valign="middle"  class="clickable_row roboto-400">
                  <td width="25%"><b>{{'Urgency' | locale}}</b> </td>
                  <td width="25%">{{request['urgent_flag'] | urgent | locale}}</td>
                  <td width="25%"><b>{{'Repeat' | locale}}</b> </td>
                  <td width="25%">{{request['is_repeat'] ? ('Repeat' | locale:2) : ('New' | locale)}}</td>
                </tr>
<!--
                <tr >
                  <td class="head roboto-700 pb-4 pt-4">Payment Info</td>
                </tr>
                <ng-container *ngIf="request.pricing">
                  <tr valign="middle" class="clickable_row roboto-400">
                    <td width="25%"><b>Pricing</b> </td>
                    <td>{{request.pricing.name }} </td>
                  </tr>
                </ng-container> -->


                <!-- <tr >
                  <td class="head roboto-700 pb-4 pt-4">Profiles </td>
                </tr>
                <ng-container *ngIf="request.profiles">
                  <tr *ngFor="let profile of request['profiles']['data']" valign="middle" class="clickable_row roboto-400">
                    <td width="25%"><b>Profile</b> </td>
                    <td>{{profile['code'] + "  |  " +  profile['name'] + "  |  " + profile['amount'] + "LYD" }} </td>
                  </tr>
                </ng-container> -->

                <tr >
                  <td class="head roboto-700 pb-4 pt-4" width="100%">
                  <h2>
                    {{'Tests' | locale}}
                  </h2>
                   </td>
                </tr>
                <ng-container *ngIf="request.tests">
                  <tr>
                    <td width="50%"><b>{{'Code' | locale}}</b> </td>
                    <td width="50%"><b>{{'Name' | locale}}</b> </td>
                  </tr>
                  <tr *ngFor="let test of request['tests']" valign="middle" class="clickable_row roboto-400">
                    <td width="50%">{{test['code']}} </td>
                    <td width="50%">{{test['name']}} </td>
                  </tr>
                </ng-container>






            </tbody>
            </table>
          </div>
        </div>
      </div>




    </div>



  </div>


