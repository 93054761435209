import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ProgressService } from 'src/app/services/progress.service';
import { SnackService } from 'src/app/services/snack.service';

declare var $: any;
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'admin-layout-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss'],
})
export class DevicesListComponent implements OnInit {
  user = {};
  devices: any[] = [];
  action: any = {};

  date: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;

  constructor(
    public snack: SnackService,
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public ms:ModalService
  ) {}

  ngOnInit() {
    this.getDevices();

    // this.user = this.data.user;
    this.user['id'] =  localStorage.getItem('id')
    console.log('user', this.user)

    this.dropdownList = this.devices;

    this.dropdownSettings = {
      enableCheckAll: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
  }

  expiration() {
    let devices = []
    this.selectedItems.forEach(function(element) {
      devices.push(element.id)
    })
    let params = {'devices': devices, 'date': this.date}
    this.api.Admin.expiration(params).subscribe(
      (res) => {
        $('#device-exp-modal').modal('hide');
        this.snack.show('Devices Expiration date updated ');
      }
    );

    console.log([this.selectedItems, this.date]);
    // console.log(devices);
  }

  getDevices() {
    this.progress.on();

    this.api.Admin.devices.get().subscribe((res) => {
      this.progress.off();
      this.devices = res;
    });
  }
}
