<div class="row">
  <div class="col">
    <h2 class="roboto-300 start">{{ "Units" | locale }}</h2>
  </div>
</div>
<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <div class="row mt-2">
      <div class="col-12 col-lg-2 mt-3 mt-lg-0">
        <div class="d-flex w-100 justify-content-end">
          <button
            type="button"
            id="add-button"
            class="btn btn-outline-primary w-100 roboto-400"
            (click)="
              ms.assign({
                title: 'Add a Unit',
                route: null,
                datum: {},
                inputs: { name: true, code: true },
                method: api.Admin.units.add,
                message: 'Unit was added successfully',
                onSuccess: getUnits.bind(this)
              })
            "
          >
            {{ "Add" | locale }} +
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th scope="col">{{ "ID" | locale: 2 }}</th>
                <th width="40%" scope="col">{{ "code" | locale }}</th>
                <th width="40%" scope="col">{{ "Name" | locale }}</th>
              </tr>
            </thead>
            <br />

            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let unit of units; let i = index">
                <tr
                  (click)="ms.assign({
                    title: 'Edit a Unit',
                    action:'Update',
                    route: null,
                    datum: unit,
                    inputs: { name: true, code: true},
                    method: api.Admin.units.update,
                    message: 'Unit was updated successfully',
                    onSuccess: getUnits.bind(this),
                    deleteMethod:api.Admin.units.remove,
                    deleteMessage:'Unit was deleted successfully'
                  });
                "
                  valign="middle"
                  class="clickable_row roboto-400"
                >
                  <td scope="row">{{ i + 1 }}</td>
                  <td width="40%">{{ unit.code }}</td>
                  <td width="40%">{{ unit.name }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
