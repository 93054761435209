  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{'Receptionists' | locale}}</h2>
    </div>

    <div style="display: flex; align-items: center" class="mx-3">
      <div class="form-group form-check start mb-0">
        <input
          type="checkbox"
          class="form-check-input"
          name="excel"
          [(ngModel)]="excel"
          [checked]="excel"
          id="excel"
        />
        <label
          class="form-check-label"
          [ngClass]="{ 'mr-4': data.isRTL }"
          for="excel"
          >{{ "Excel" | locale }}</label
        >
      </div>
    </div>

      <button style="height: fit-content;" (click)="printRevenues()" class="btn px-5 my btn-primary my-auto"  >{{'Print' | locale}}</button>
    <!-- <div class="col-2" >
      <input name="excel" [(ngModel)]="excel" [checked]="excel" type="checkbox" class="form-check-input" id="excel">
      <label>Excel</label>
    </div> -->
  </div>

  <div class="card mb-5 mt-5">
    <div class="container-fluid py-3 px-4">
      <div class="row justify-content-center">
        <div class="col-9">
          <div class="container">
            <div class="row">
              <div *ngFor="let key of globals_keys" class="col p-2">
                <div class="card">

                  <div class="card-body p-2 px-3">
                    <h5 class="mb-0 pb-0 text-dark start">
                      {{key | titlecase | locale}}
                    </h5>
                    <p class="mb-0 pb-0 start">{{gloabls[key]}}</p>
                  </div>

                </div>
              </div>
              <button style="height: fit-content;" (click)="router.navigateByUrl('admin/rec/transactions')" class="btn px-5 my btn-primary my-auto"  >{{'Transactions' | locale}}</button>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="fromPicker">{{'Revenue Day' | locale}}</label>
            <input
              id="revPicker"
              [(ngModel)]="revDate"
              class="flatpickr flatpickr-input active form-control"
              (change)="getReceptionists()"
              type="date"
              placeholder="Select Date.."
            />
          </div>
        </div>

        <div class="col-5">
          <div class="form-group">
            <label for="fromPicker">{{'Name' | locale}}</label>
            <input
              id="revPicker"
              class="flatpickr flatpickr-input active form-control"
              (keyup)="filter($event.target.value, 'name')"
              type="text"
              [placeholder]="'Search by name' | locale"
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label >{{'Serial number' | locale}}</label>
            <input

              class=" active form-control"
              (keyup)="filter($event.target.value,'id')"
              type="text"
              [placeholder]="'Search by serial number' | locale"
            />
          </div>
        </div>




      </div>

      <!-- <div class="row">
        <div class="col-md-6">
          <label>{{'Time From' | locale}}</label>

          <input
              id="time_from"
              [(ngModel)]="from_time"
              class="flatpickr flatpickr-input active form-control"
              (change)="getReceptionists()"
              type="date"
              [placeholder]="'Time from' | locale"
            />


        </div>
        <div class="col-md-6">
          <label>{{'Time To' | locale}}</label>

          <input
              id="time_to"
              [(ngModel)]="to_time"
              class="flatpickr flatpickr-input active form-control"
              (change)="getReceptionists()"
              type="date"
              [placeholder]="'Time To' | locale"
            />
        </div>
      </div> -->

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                  <th width="8%" scope="col">{{'id' | locale:2}}</th>
                  <th width="12.5%" scope="col">{{'Name' | locale}}</th>
                  <th width="12.5%" scope="col">{{'Birthdate' | locale}}</th>
                  <th width="12.5%" scope="col">{{'Total' | locale}}</th>
                  <th width="12.5%" scope="col">{{'Cash in' | locale}}</th>
                  <th width="12.5%" scope="col">{{'Payment Methods' | locale}}</th>
                  <!-- <th scope="col">Others</th> -->
                  <!-- <th scope="col">خيارات</th> -->
                  <!-- <th scope="col"></th> -->
                </tr>
              </thead>
              <br />

              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let rec of filtered_recs;let i = index">
                  <tr
                    (click)="router.navigateByUrl('admin/receptionists/' + rec.id)"
                    valign="middle"
                    class="clickable_row roboto-400"
                  >
                    <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                    <td width="8%">{{ i + 1 }}</td>
                    <td width="12.5%">{{ rec.name }}</td>
                    <td width="12.5%">{{ rec.birthdate | date }}</td>
                    <td width="12.5%">{{ rec.revenue?.patient_total || 0 }}{{data.info['currency']}}</td>
                    <td width="12.5%">{{ rec.revenue?.patient_paid || 0 }}{{data.info['currency']}}</td>
                    <td width="12.5%">
                      <button
                      [disabled]='!rec.revenue.payment_methods.length'
                      (click)="$event.stopPropagation();show_payment_modal(rec.revenue)" class="btn btn-light">
                      <i class="fas fa-money-bill-wave"></i>
                      </button>
                    </td>
                    <!-- <td>
                      <button (click)="$event.stopPropagation();show_others_modal(rec.revenue)" class="btn btn-light">
                        {{rec.revenue?.from_others_total || 0}}
                      </button>
                    </td> -->

                    <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                    <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- <router-outlet></router-outlet> -->





<div class="modal fade" id="others_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Others</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container >
          <div class="card mt-4 mb-5">
            <div class="container-fluid py-3 px-4">

              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table mt-3">
                      <thead>
                        <tr
                          class="neue-light roboto-400"
                        >
                          <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                          <!-- <th width="15%" scope="col">Request #</th> -->
                          <th style="min-width: 100px" scope="col">
                            #
                          </th>
                          <th style="min-width: 100px" scope="col">Name</th>
                          <th style="min-width: 100px" scope="col">Value</th>

                          <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                          <!-- <th scope="col">خيارات</th> -->
                          <!-- <th scope="col"></th> -->
                        </tr>

                        <!-- | paginate: { id:'pagination' ,
                itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }" -->
                      </thead>
                      <tbody class="neue-light">

                          <ng-container *ngFor="let other of others">
                            <tr class="neue-light roboto-400">
                              <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                              <!-- <th width="15%" scope="col">Request #</th> -->
                              <th style="min-width: 100px" scope="col">
                                {{ other.id }}
                              </th>
                              <th style="min-width: 100px" scope="col">
                                {{ other.first_name + " "  + other.last_name}}
                              </th>

                              <th style="min-width: 100px" scope="col">
                                {{ other.total_value || 0 }}
                              </th>

                              <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                              <!-- <th scope="col">خيارات</th> -->
                              <!-- <th scope="col"></th> -->
                            </tr>
                          </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-12 mt-3">
                  <div class="w-100 d-flex justify-content-center neue-med p-0">
                    <!-- <pagination-controls
                      responsive="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      (pageChange)="getRequests($event, key)"
                      id="pagination"
                    ></pagination-controls> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="payment_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Payment Methods</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="payments"  >
          <div class="card mt-4 mb-5">
            <div class="container-fluid py-3 px-4">

              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table mt-3">
                      <thead>
                        <tr
                          class="neue-light roboto-400"
                        >
                          <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                          <!-- <th width="15%" scope="col">Request #</th> -->
                          <th style="min-width: 100px" scope="col">
                            #
                          </th>
                          <th style="min-width: 100px" scope="col">Name</th>
                          <th style="min-width: 100px" scope="col">Value</th>

                          <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                          <!-- <th scope="col">خيارات</th> -->
                          <!-- <th scope="col"></th> -->
                        </tr>

                        <!-- | paginate: { id:'pagination' ,
                itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }" -->
                      </thead>
                      <tbody class="neue-light">

                          <ng-container *ngFor="let payment of payments">
                            <tr class="neue-light roboto-400">
                              <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                              <!-- <th width="15%" scope="col">Request #</th> -->
                              <th style="min-width: 100px" scope="col">
                                {{ payment.id }}
                              </th>
                              <th style="min-width: 100px" scope="col">
                                {{ payment.name}}
                              </th>

                              <th style="min-width: 100px" scope="col">
                                {{ payment.value }}
                              </th>

                              <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                              <!-- <th scope="col">خيارات</th> -->
                              <!-- <th scope="col"></th> -->
                            </tr>
                          </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-12 mt-3">
                  <div class="w-100 d-flex justify-content-center neue-med p-0">
                    <!-- <pagination-controls
                      responsive="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      (pageChange)="getRequests($event, key)"
                      id="pagination"
                    ></pagination-controls> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
</div>
