import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api.service';
import { DataService } from '../services/data.service';
import { ModalService } from '../services/modal.service';
import { ProgressService } from '../services/progress.service';
declare var $:any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public data : DataService,private router:Router, private api:ApiService, private progress:ProgressService, public ms:ModalService) { }





  dates = {
    from:new Date().toISOString().slice(0,10).split('-').reverse().join('-'),
    to:new Date().toISOString().slice(0,10).split('-').reverse().join('-')
  }



  flags: { month: Boolean; day: Boolean } = {
    month: false,
    day: false,
  };

  pers: any = [];

  toggleFlags = (per) => {
    if (per == "month") this.flags.day = false;
    if (per == "day") this.flags.month = false;
    this.flags[per] = !this.flags[per];
    if (this.flags[per]) {
      if(this.data.stats_info.name == 'parameters'){
        this.api.Admin.getParameterStats(this.data.stats_info.name ,this.data.stats_info.id,{per}).subscribe((res) => {
          this.pers = res;
        });
      }else{
        this.api.Admin.getStats(this.data.stats_info.name ,this.data.stats_info.id,{per}).subscribe((res) => {
          this.pers = res;
        });
      }
    }
  };

  // hover:boolean = false;

  ngOnInit() {

    this.data.tab_open_intreval = setInterval(()=>{
      localStorage.setItem('tab_open' , (new Date()).getTime() + "")
    },1000 * 30)

    let a = 0;

    this.data.statsModal.subscribe( pdf => {
      if(pdf){

        setTimeout(()=>{
          ['from','to'].forEach(one => {

            $(`#${one}_picker`)[0].flatpickr({
              dateFormat: "d-m-Y",
              defaultDate:this.dates[one]
            });
          })
        }, 0)

      }else this.toggleFlags('day')
    })

    environment.production? window.console.log = ()=>{} : null

    this.api.Common.Get.genders().subscribe((res) => {
      this.data.genders = res;
      this.data.dataAssigned.next(true)
    });
    
    this.api.Common.Get.doctors().subscribe((res) => {
      this.data.doctors = res;
      this.data.dataAssigned.next(true)
    });

    this.api.Common.Get.pricings().subscribe((res) => {
      this.data.pricings = res;
      this.data.dataAssigned.next(true)
    });

    this.api.Common.Get.treasuries().subscribe((res) => {
      this.data.cashiers = res;
      this.data.dataAssigned.next(true)
    });

    this.api.Common.Get.categories().subscribe((res) => {
      this.data.categories = res;
      this.data.dataAssigned.next(true)
    });

    this.api.Common.Get.subcategories().subscribe((res) => {
      this.data.subcategories = res;
      this.data.dataAssigned.next(true)
    });

    this.api.Common.Get.payment_methods().subscribe((res) => {
      this.data.payment_methods = res;
      this.data.dataAssigned.next(true)
    });

    this.api.Common.Get.civilities().subscribe((res) => {
      this.data.civilities = res;
      this.data.dataAssigned.next(true)
    });



    localStorage.setItem('last_action' , (new Date()).getTime() + "")

    this.router.events.subscribe(events => {
      localStorage.setItem('last_action' , (new Date()).getTime() + "")
    })

    this.api.Common.Get.lab_info().subscribe(res => {
      this.data.info = res['data']
      this.data.infoAssigned.next(true)
      let minutes;
      if(res['data'] && res['data']['settings'] && res['data']['settings']['logout-timer']){
        minutes = +res['data']['settings']['logout-timer']
        localStorage.setItem('minutes', minutes)
        if(minutes){
          this.data.logout_intreval = setInterval(()=>{
            this.data.check_logout_timer_difference()
          },1000 * 15)
        }
      }
    })


  }

  print(form:NgForm){
    let data = JSON.parse(JSON.stringify(form.value))
    let min = data['from'].split('-').reverse().join('/')
    let max = data['to'].split('-').reverse().join('/')
    if(this.data.stats_info.name == 'parameters'){
      this.api.Common.printables.getParameterStatsPDF(this.data.stats_info.name ,this.data.stats_info.id,{max_date:max,min_date:min, per:data['per']}).subscribe((res) => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
    }else{
      this.api.Common.printables.getStats(this.data.stats_info.name ,this.data.stats_info.id,{max_date:max,min_date:min, per:data['per']}).subscribe((res) => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
    }
  }

  ngAfterViewInit(){
    $('#statsModal').on('hidden.bs.modal',  () => {
      this.pers = [];
      ['month', 'day'].forEach(one => this.flags[one] = false)
    })
  }

  onDestroy(){
    clearInterval(this.data.logout_intreval)
  }
  }
