import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { Arabic } from 'flatpickr/dist/l10n/ar';
declare let $;


@Component({
  selector: 'admin-layout-expiration',
  templateUrl: './expiration.component.html',
  styleUrls: ['./expiration.component.scss']
})
export class ExpirationComponent implements OnInit {

  date :string;
  fixed_date:string;
  day:any;
  color : any

  constructor(public api : ApiService, public data:DataService) { }

  ngOnInit() {

    this.data.infoAssigned.subscribe(isAssigned =>{
      if(isAssigned){
        this.fixed_date = this.data.info["expire_at"]
        this.date = this.data.format_date(this.fixed_date)
      }
    })
  }

  ngAfterViewInit() {
    $('#expiration_picker').flatpickr({
      dateFormat: 'd-m-Y',
      allowInput: true,
      locale: this.data.language == 'arabic' ? Arabic : null,
      position: 'auto center',
    });
  }

  setDate(){
    this.api.Admin.update_expiration({date:this.data.format_date(this.date)}).subscribe(res =>{
     this.fixed_date=res['data'];
    });
  }
}
