<div [hidden]="(type == 'dialog'?  !progress.showDialogProgress : true)" class="dialog-loading">

  <div class="container-fluid h-100">

    <div class="row h-100 justify-content-center align-items-center">

      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>

    </div>

  </div>

</div>


<div [hidden]="(type == 'main'?  !progress.showProgress : true)" class="main-loading">

  <div class="container-fluid h-100">

    <div class="row h-100 ">

      <div class="spinner-border  text-primary main-spinner"
      [ngClass]="{'right-spinner': !data.isRTL, 'left-spinner': data.isRTL}"
      style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>

    </div>

  </div>

</div>

