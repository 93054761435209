import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'admin-layout-labs-list',
  templateUrl: './labs-list.component.html',
  styleUrls: ['./labs-list.component.scss'],
})
export class LabsListComponent implements OnInit {
  labs: any;
  searchByName = '';
  action: any = {};

  globals = [
    {
      label: 'Overall Total Downloaded',
      value: 0,
      key: 'overall_total_downloaded',
    },
    {
      label: 'Overall Total Uploaded',
      value: 0,
      key: 'overall_total_uploaded',
    },
  ];

  dates = {
    from: new Date().toISOString().slice(0, 10).split('-').reverse().join('-'),
    to: new Date().toISOString().slice(0, 10).split('-').reverse().join('-'),
  };

  constructor(
    private data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public ms: ModalService
  ) {}

  ngOnInit() {
    this.data.remove.subscribe(({ id, type }) => {
      if (type == 'correspondent') {
        this.labs = this.labs.filter((one) => one.id != id);
        $('#cor-modal').modal('hide');
      }
    });

    this.getLabs();
  }

  getLabs() {
    setTimeout(() => {
      let min = this.dates.from.split('-').reverse().join('-');
      let max = this.dates.to.split('-').reverse().join('-');

      // $('.dropdown-menu').dropdown('show');
      this.progress.on();

      this.api.Admin.labs
        .get({ min_date: min, max_date: max })
        .subscribe((res) => {
          this.labs = res['labs'];
          this.globals.forEach((one) => {
            if (one['key'] == 'overall_total_downloaded')
              one['value'] = res['overall_total_downloaded'];
            if (one['key'] == 'overall_total_uploaded')
              one['value'] = res['overall_total_uploaded'];
          });
          this.progress.off();
        });
    }, 0);
  }

  // selectDefault(correspondent) {
  //   this.labs.forEach((one) => {
  //     if (one.id != correspondent.id) one['is_default'] = false;
  //     else one['is_default'] = !one['is_default'];
  //   });

  //   this.api.Correspondents.toggle_default(correspondent.id).subscribe(
  //     (one) => {}
  //   );
  // }

  openModal(action) {
    this.action = action;
    $('#cor-modal').modal('show');
  }

  correspondentCreated() {
    this.getLabs();
    $('#cor-modal').modal('hide');
  }

  // async labsSearch(){
  //   console.log(this.searchByName);
  //   let query="name="+this.searchByName;
  //  await this.getLabs(query);

  // }

  ngAfterViewInit() {
    ['from', 'to'].forEach((one) => {
      $(`#${one}Picker`)[0].flatpickr({
        dateFormat: 'Y-m-d',
      });
    });
  }
}
