


  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{'Cashiers | Bank accounts' | locale}}</h2>
    </div>
  </div>

  <div class="card mb-5 mt-3">
    <div class="container-fluid py-3 px-4">



      <div class="row mt-2">



        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end ">
            <button
             type="button" id="add-button" class="btn btn-outline-primary w-100 roboto-400" (click)="
             ms.assign({
               title: 'Add a Cashier | Bank account',
               route: null,
               datum: {},
               inputs: { name: true, initial_balance:true, is_external:true, is_bank:true},
               method: api.Admin.treasuries.add,
               message: 'Cashier | Bank account was added successfully',
               onSuccess: getCashiers.bind(this)
             });
           ">{{'Add' | locale}} +</button>
          </div>
        </div>


      </div>

      <div class="row">

        <div class="col-12">
          <div class="table-responsive  ">
            <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                <!-- <th width="15%" scope="col"> code</th> -->
                <th scope="col">{{'ID' | locale:2}}</th>
                <th scope="col"> {{'Name' | locale}}</th>
                <th scope="col"> {{'Initial balance' | locale}}</th>
                <th scope="col"> {{'Balance' | locale}}</th>
                <th scope="col"> {{'Type' | locale}}</th>
                <th scope="col"> {{'External' | locale}}</th>

                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>



            </thead>
            <br>

            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let cashier of cashiers; let i =index ">

                 <tr  (click)="
                 ms.assign({
                   title: 'Edit a Cashier | Bank account',
                   action:'Update',
                   route: null,
                   datum: cashier,
                   inputs: { name: true, initial_balance:true, is_external:true, is_bank:true},
                   method: api.Admin.treasuries.update,
                   message: 'Cashier | Bank account was updated successfully',
                   onSuccess: getCashiers.bind(this),
                   deleteMethod:api.Admin.treasuries.remove,
                   deleteMessage:'Cashier | Bank account was deleted successfully'
                 });
               " valign="middle"  class="clickable_row roboto-400">
                  <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                  <!-- <td>{{cashier.code}}</td> -->
                  <td>{{i+1 }}</td>
                  <td>{{cashier.name }}</td>
                  <td>{{cashier.initial_balance }}</td>
                  <td>{{cashier.balance }}</td>
                  <td>{{(cashier.is_bank? 'Bank account': 'Cashier') | locale }}</td>
                  <td>{{cashier.is_external? "✔":"✖" }}</td>

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>



            </tbody>
            </table>
          </div>
        </div>

      </div>


    </div>
  </div>


<!-- <router-outlet></router-outlet> -->
