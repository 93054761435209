import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';

interface OptionsHeaders {
  route: string;
  body?: any;
  abs?: boolean;
  pdf?: boolean;
  prefixed?: boolean;
  params?: any
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  base = environment.baseURL + '/';
  prefixed = this.base + location.pathname.split('/').filter(Boolean)[0] + '/';
  options = {
    route: '',
    body:{},
    abs: false,
    pdf: false,
    prefixed: true,
  }


  printZebraURL: string ='http://127.0.0.1:3000';

  get_headers(action = true) {
    if (action) localStorage.setItem('last_action', new Date().getTime() + '');
    return new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token') || ''
    );
  }

  constructor(private http: HttpClient, public data: DataService) {
    this.data.role_route_changed.subscribe((role) => {
      this.prefixed = this.base + role + '/';
    });
  }
  // base:string = environment.base_url + '/'
  get(options: OptionsHeaders) {

    let headers = this.get_headers();
    options = {...this.options, ...options}
    return this.http.get(
      (options.abs ? '' : options.prefixed ? this.prefixed : this.base) +
        options.route,
      {
        headers: headers || {},
        responseType: options.pdf ? 'blob' : null,
        params: options.params || {}
      }
    );
  }
  post(options: OptionsHeaders) {
    let headers = this.get_headers();
    options = {...this.options, ...options}
    console.log(options, headers)

    return this.http.post(
      (options.abs ? '' : options.prefixed ? this.prefixed : this.base) +
        options.route,
      options.body,
      {
        headers: headers ? headers : {},
        params: options.params || {}
      }
    );
  }
  put(options: OptionsHeaders) {
    let headers = this.get_headers();
    options = {...this.options, ...options}

    return this.http.put(
      (options.abs ? '' : options.prefixed ? this.prefixed : this.base) +
        options.route,
      options.body,
      {
        headers: headers ? headers : {},
        params: options.params || {}
      }
    );
  }
  delete(options: OptionsHeaders) {
    let headers = this.get_headers();
    options = {...this.options, ...options}

    return this.http.delete(
      (options.abs ? '' : options.prefixed ? this.prefixed : this.base) +
        options.route,
      {
        headers: headers ? headers : {},
        params: options.params || {}
      }
    );
  }
}
