import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { SnackService } from 'src/app/services/snack.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    public router: Router,
    public data: DataService,
    private api: ApiService,
    private snack: SnackService
  ) {}

  // $rev:Observable<any>;

  role: string = '';

  routes: Array<{
    link: string;
    label: string;
    icon: string;
    key: string;
    authorized: string;
  }>;
  username: any = localStorage.getItem('userName');
  show_pass: boolean = false;
  show_pass2: boolean = false;
  show_pass3: boolean = false;

  langs: Array<{ name: string; isRTL: boolean }> = ((_) => {
    let langs = [{ name: 'english', isRTL: false }];
    langs[this.data.language == 'arabic' ? 'unshift' : 'push']({
      name: 'arabic',
      isRTL: true,
    });
    return langs;
  })();
  current_role: string = 'receptionist';
  urls = {
    admin: 'admin',
    receptionist: 'res',
    technician: 'tec',
    accountant: 'fms',
  };

  l = (() =>
    this.langs.find((one) => one.name == this.data.language) ||
    this.langs[0])();

  ngOnInit() {
    this.data.role_route_changed.subscribe((role) => {
      this.role = role;
    });
    this.role = location.pathname.split('/').filter(Boolean)[0];

    this.data.sidebarStatus = true;
    this.routes = [
      {
        link: '',
        label: 'Home',
        icon: 'fas fa-home',
        key: 'receptionist',
        authorized: 'home',
      },
      {
        link: 'search',
        label: 'Search',
        icon: 'fas fa-search',
        key: 'receptionist',
        authorized: 'search',
      },
      {
        link: 'patients',
        label: 'Patients',
        icon: 'fas fa-users',
        key: 'receptionist',
        authorized: 'patients',
      },
      {
        link: 'doctors',
        label: 'Doctors',
        icon: 'fas fa-stethoscope',
        key: 'receptionist',
        authorized: 'doctors',
      },
      {
        link: 'activity',
        label: 'Activity log',
        icon: 'fas fa-clipboard-list',
        key: 'receptionist',
        authorized: 'activityLog',
      },
      {
        link: 'tests',
        label: 'Tests | Profiles',
        icon: 'fas fa-vial',
        key: 'receptionist',
        authorized: 'tests',
      },
      {
        link: 'correspondents',
        label: 'correspondents',
        icon: 'fas fa-city',
        key: 'receptionist',
        authorized: 'correspondents',
      },
      {
        link: "/receptionist",
        label: "Receptionist",
        key: 'receptionist',
        icon: "fas fa-user",
        authorized: "receptionist",
      },
      {
        link: '/reports',
        label: 'Reports',
        icon: 'fas fa-scroll',
        key: 'receptionist',
        authorized: 'reports',
      },

      //tech routes

      {
        link: '',
        label: 'Home',
        icon: 'fas fa-home',
        key: 'technician',
        authorized: 'home',
      },
      {
        link: 'search',
        label: 'Search',
        icon: 'fas fa-search',
        key: 'technician',
        authorized: 'search',
      },

      {
        link: 'activity',
        label: 'Activity log',
        icon: 'fas fa-clipboard-list',
        key: 'technician',
        authorized: 'activityLog',
      },
      {
        link: 'tests',
        label: 'Tests | Profiles',
        icon: 'fas fa-vial',
        key: 'technician',
        authorized: 'tests',
      },
      {
        link: 'samples',
        label: 'samples',
        icon: 'fas fa-syringe',
        key: 'technician',
        authorized: 'samples',
      },

      {
        label: 'Tests | Profiles',
        key: 'admin',
        link: 'tests',
        icon: 'fas fa-vial',
        authorized: 'tests',
      },
      {
        label: 'Parameters',
        key: 'admin',
        link: 'parameters',
        icon: 'fas fa-align-left',
        authorized: 'parameters',
      },
      {
        label: 'Samples',
        key: 'admin',
        link: 'samples',
        icon: 'fas fa-syringe',
        authorized: 'samples',
      },
      {
        label: 'Categories',
        key: 'admin',
        link: 'categories',
        icon: 'fas fa-stethoscope',
        authorized: 'categories',
      },
      {
        label: 'Families',
        key: 'admin',
        link: 'families',
        icon: 'fas fa-clipboard',
        authorized: 'families',
      },

      {
        label: 'Devices | Methods',
        key: 'admin',
        link: 'devices',
        icon: 'fas fa-microchip',
        authorized: 'devices',
      },
      {
        label: 'Units',
        key: 'admin',
        link: 'units',
        icon: 'fa fa-users',
        authorized: 'units',
      },
      {
        label: 'Users',
        key: 'admin',
        link: 'users',
        icon: 'fas fa-user-friends',
        authorized: 'users',
      },
      {
        label: 'Patients',
        key: 'admin',
        link: 'patients',
        icon: 'fas fa-user',
        authorized: 'patients',
      },
      {
        label: 'Doctors',
        key: 'admin',
        link: 'doctors',
        icon: 'fas fa-stethoscope',
        authorized: 'doctors',
      },
      {
        label: 'Receptionists',
        key: 'admin',
        link: 'receptionists',
        icon: 'fas fa-user',
        authorized: 'receptionists',
      },
      {
        label: 'Main Correspondent',
        key: 'admin',
        link: 'subcategories',
        icon: 'fas fa-city',
        authorized: 'subcategories',
      },
      {
        label: 'Correspondents',
        key: 'admin',
        link: 'correspondents',
        icon: 'fas fa-city',
        authorized: 'correspondents',
      },
      {
        label: 'labs',
        key: 'admin',
        link: 'labs',
        icon: 'fas fa-flask',
        authorized: 'labs',
      },
      {
        label: 'Clinics',
        key: 'admin',
        link: 'clinics',
        icon: 'fas fa-home',
        authorized: 'clinics',
      },
      {
        label: 'Civilities',
        key: 'admin',
        link: 'civilities',
        icon: 'fas fa-user-alt',
        authorized: 'civilities',
      },
      {
        label: 'Pricing',
        key: 'admin',
        link: 'pricing',
        icon: 'fas fa-dollar-sign',
        authorized: 'pricing',
      },
      {
        label: 'Cashiers | Banks',
        key: 'admin',
        link: 'cashiers',
        icon: 'fas fa-cash-register',
        authorized: 'cashiers',
      },
      {
        label: 'Payment Methods',
        key: 'admin',
        link: 'payment-methods',
        icon: 'fas fa-money-bill-wave',
        authorized: 'payment',
      },
      {
        label: 'Micro',
        key: 'admin',
        link: 'micro',
        icon: 'fas fa-microscope',
        authorized: 'micro',
      },
      {
        label: 'Reports',
        key: 'admin',
        link: 'reports',
        icon: 'fas fa-scroll',
        authorized: 'reports',
      },
      {
        label: 'Activity log',
        key: 'admin',
        link: 'activity',
        icon: 'fas fa-clipboard-list',
        authorized: 'activity',
      },
      {
        label: 'Settings',
        key: 'admin',
        link: 'settings',
        icon: 'fas fa-cog',
        authorized: 'settings',
      },
      {
        label: 'Support',
        key: 'admin',
        link: 'support',
        icon: 'fas fa-life-ring',
        authorized: 'support',
      },
    ];
  }

  paymentMethodIndex = '';

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userId');
    location.href = location.origin + '/home';
  }

  change_language({ language }) {
    localStorage.setItem('language', language['name']);
    language.isRTL
      ? localStorage.setItem('isRTL', 'true')
      : localStorage.removeItem('isRTL');
    this.data.language = language['name'];
    this.data.isRTL = language['isRTL'];
    this.data.reload.next();
    $('#language').modal('hide');
  }

  goto(url) {
    if ($(window).width() < 768) $('#sidebar, #content').toggleClass('active');

    // this.router.navigateByUrl(url);
  }

  openModal(str) {
    // if(str == 'rev'){
    //   this.$rev = this.api.common.getRev().pipe(map(one => one['data']), tap(one =>{
    //     console.log(one)
    //   }
    //   ))
    // }

    $(`#${str}-modal`).modal('show');
  }

  change_cre(form: NgForm) {
    this.api.Common.me.update(form.value).subscribe((data) => {
      if (!data['error']) {
        $('#cre-modal').modal('hide');
        this.snack.show(data['message']);
      } else {
        if (data['errors']) {
          this.snack.showerror(data['errors']['password'][0]);
        } else {
          this.snack.showerror(data['message']);
        }
      }
    });
  }

  isLoggedIn() {
    if (localStorage.getItem('token') != null) {
      return true;
    } else {
      return false;
    }
  }
}
