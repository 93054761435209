import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { DataService } from '../services/data.service';

@Pipe({
  name: 'locale'
})
export class LocalePipe implements PipeTransform {

  constructor(private data:DataService, private language:LanguageService){}

  transform(value: string, key:any = 1, ...args: unknown[]): unknown {

    if(key == 'date'){
      if(this.data.isRTL){
        let splitted = value.split(" ")
        let time = splitted[0] + " " + splitted[2] + "  " + (this.language['languages_1'][this.data.language][splitted[3]]);

        return time
      }else return value
    }else return this.language['languages_' + key][this.data.language][value?.toLocaleLowerCase()] || value;

  }

}
