  <div class="card mb-5 mt-3">
    <div class="container-fluid py-3 px-4">
      <div class="row mt-2">
        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end">
            <button
              data-toggle="modal"
              data-target="#bacterium-modal"
              type="button"
              id="add-button"
              class="btn btn-outline-primary w-100 roboto-400"
              (click)="action.type = 'Add'; sureState = false; selectedBacterium = {}; antibiotics = [];"
            >
              {{'Add' | locale}} +
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <th  scope="col" > {{'ID' | locale:2}}</th>
                  <!-- <th width="15%" scope="col"> code</th> -->
                  <th width="25%">{{'Code' | locale}}</th>
                  <th width="25%" style="min-width: 150px" scope="col">{{'Name' | locale}}</th>
                  <th width="25%" style="min-width: 150px" scope="col">{{'Germ Family' | locale}}</th>
                  <!-- <th scope="col">Contact</th> -->
                  <th width="25%" scope="col">{{'Edit' | locale}}</th>
                  <!-- <th scope="col">Activation</th> -->
                </tr>
              </thead>
              <br />

              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let bacterium of bacteria; let i = index">
                  <tr valign="middle" class="clickable_row roboto-400">
                    <td scope="row">{{i + 1}}</td>
                    <td width="25%">{{ bacterium.code }}</td>
                    <td width="25%" style="min-width: 150px">{{ bacterium.name }}</td>
                    <td width="25%" style="min-width: 150px">
                      {{ bacterium.family?.name }}
                    </td>

                    <td width="25%" style="min-width: 100px" scope="col">
                      <button
                        (click)="
                          selectedBacterium = bacterium; action.type = 'Edit';sureState = false;getAntibiotics(bacterium.id)
                        "
                        data-toggle="modal"
                        data-target="#bacterium-modal"
                        type="button"
                        class="btn btn-outline-info p-0 px-3"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                    </td>
                    <!-- <td>
                        <input (change)="activate(clinic)" class="col" type="checkbox" [ngModel]="clinic.isActive">
                    </td> -->
                    <!-- <td>{{ clinic.isActive?'Active': 'Not Active' }}</td> -->
                    <!-- <td>{{test.amount }} {{data.info['currency']}}</td> -->

                    <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                    <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- <router-outlet></router-outlet> -->

<!-- Modal -->
<div
  class="modal fade"
  id="bacterium-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <!-- <app-loading [type]="'dialog'"></app-loading> -->
      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
              <h5 class="roboto-500 start">{{ ((action.type == 'Edit'?'Edit':'Add') + ' a Bacteria') | locale }}</h5>
            </div>
            <div class="col-12">
              <form
                id="bacteriumForm"
                #bacteriumForm="ngForm"
                (ngSubmit)="onSubmit(bacteriumForm)"
              >
                <div class="row neue-reg mt-3">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label> {{'Code' | locale}}</label>
                      <input
                        required
                        maxlength="25"
                        [ngModel]="selectedBacterium.code"
                        name="code"
                        type="text"
                        class="form-control"
                        [placeholder]="'Code' | locale"
                        #code_input="ngModel"
                      />

                      <!-- <small
                        *ngIf="
                          (code_input.errors
                            ? code_input.errors['required']
                            : false) && code_input.touched
                        "
                      >
                        the code is required
                      </small> -->
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>{{'Name' | locale}}</label>
                      <input
                        required
                        maxlength="25"
                        [ngModel]="selectedBacterium.name"
                        name="name"
                        type="text"
                        class="form-control"
                        [placeholder]="'Name' | locale"
                        #name_input="ngModel"
                      />

                      <!-- <small
                        *ngIf="
                          (name_input.errors
                            ? name_input.errors['required']
                            : false) && name_input.touched
                        "
                      >
                        the name is required
                      </small> -->
                    </div>
                  </div>
                </div>
                <div class="row neue-reg mt-3">
                  <div *ngIf="families" class="col-lg-12">
                    <div class="form-group">
                      <label for="germ_family">{{'Type' | locale}}</label>
                      <select
                        [ngModel]="
                          selectedBacterium.germ_family_id || families[0]?.id
                        "
                        name="germ_family_id"
                        id="germ_family"
                        class="custom-select"
                      >
                        <option
                          selected
                          *ngFor="let family of families"
                          [value]="family.id"
                        >
                          {{ family.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row neue-reg my-4">
                  <div class="col-lg-12">
                    <selector
                    title="antibiotic"
                    (setSelectedItem)="setSelectedAntibiotics($event)"
                    >

                    </selector>

                  </div>
                </div>
                <div *ngIf="antibiotics.length" class="col-12">
                  <div class="table-responsive">
                    <table class="table mt-3">
                      <thead>
                        <tr class="neue-light roboto-400">
                          <th width="15%" scope="col">{{'code' | locale}}</th>
                          <th scope="col">{{'Name' | locale}}</th>
                          <th scope="col">{{'Order' | locale}}</th>
                          <th scope="col">{{'Remove' | locale}}</th>
                        </tr>
                      </thead>
                      <br />

                      <tbody class="neue-light mt-2">
                        <ng-container *ngFor="let antibaiotic of antibiotics;let i =index">
                          <tr valign="middle" class="clickable_row roboto-400">
                            <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                            <td>{{ antibaiotic.code }}</td>
                            <td>{{ antibaiotic.name }}</td>
                            <td>
                              <input type="text" class="form-control" [name]="'order_' + i" style="width: 40px!important;" [(ngModel)]="antibaiotic['order']">
                            </td>
                            <td>
                              <button
                                (click)="removeAnti(antibaiotic)"
                                type="button"
                                class="btn btn-outline-danger"
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </td>

                            <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                            <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                          </h5></a>
                          </td> -->
                            <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row neue-reg my-4">
                  <div class="col-12">
                    <div class="d-flex justify-content-end">
                      <div
            *ngIf="sureState"
            class="alert alert-danger mx-auto"
            role="alert"
          >
            {{ "Are you sure?" | locale }}
            <span
              (click)="remove()"
              class="mx-2 btn-danger btn-sm clickable"
              >{{ "Delete" | locale }}</span
            ><span
              (click)="sureState = false"
              class="ml-2 btn-sm btn-light clickable"
              >{{ "Cancel" | locale }}</span
            >
          </div>
                      <ng-container *ngIf="!sureState">
                        <button
                          *ngIf="action.type == 'Edit'"
                          type="button"
                          (click)="sureState = true"
                          class="btn btn-outline-danger tajwal-regium px-4 mx-2"
                        >
                          {{'Delete' | locale}}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-outline-primary tajwal-regium px-5"
                        >
                          {{ (action.type == "Edit" ? "Save" : "Add") | locale}}
                        </button>
                      </ng-container>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
