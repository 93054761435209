

  <div class="card mb-5 mt-3">
    <div class="container-fluid py-3 px-4">

      <div class="row mt-2">



        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end ">
            <button

        type="button"
        id="add-button"
        class="btn btn-outline-primary w-100 roboto-400"
        (click)="
        ms.assign({
          title: 'Add an Antibiotic',
          route: null,
          datum: {},
          inputs: { name: true, code: true, commercial_name:true, is_antibiotic: true },
          method: api.Admin.antibiotics.add,
          message: 'Antibiotic was added successfully',
          onSuccess: getAntibiotics.bind(this)
        });
      "      >
        {{'Add' | locale}} +
      </button>
      </div>
        </div>


      </div>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <th  scope="col" >  {{'ID' | locale:2}}</th>
                  <!-- <th width="15%" scope="col"> code</th> -->
                  <th width="25%">{{'Code' | locale}}</th>
                  <th width="25%" style="min-width: 150px" scope="col">{{'Name' | locale}}</th>
                  <th width="25%" style="min-width: 150px" scope="col">{{'Commercial Name' | locale}}</th>
                  <!-- <th scope="col">Contact</th> -->
                  <th width="25%" scope="col">{{'Edit' | locale}}</th>
                  <!-- <th scope="col">Activation</th> -->
                </tr>
              </thead>
              <br />

              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let antibiotic of antibiotics;let i = index">
                  <tr valign="middle" class="clickable_row roboto-400">
                    <td scope="row">{{i + 1}}</td>
                    <td width="25%">{{ antibiotic.code }}</td>
                    <td width="25%" style="min-width: 150px">{{ antibiotic.name }}</td>
                    <td width="25%" style="min-width: 150px">
                      {{ antibiotic.commercial_name }}
                    </td>
                    <td width="25%" style="min-width: 100px" scope="col">
                      <button
                      (click)="
                      ms.assign({
                        title: 'Edit an Antibiotic',
                        action:'Update',
                        route: null,
                        datum: antibiotic,
                        inputs: { name: true, code: true, commercial_name:true, is_antibiotic: true },
                        method: api.Admin.antibiotics.update,
                        message: 'Antibiotic was updated successfully',
                        onSuccess: getAntibiotics.bind(this),
                        deleteMethod:api.Admin.antibiotics.remove,
                        deleteMessage:'Antibiotic was deleted successfully'
                      });
                    "
                        type="button"
                        class="btn btn-outline-info p-0 px-3"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                    </td>
                    <!-- <td>
                        <input (change)="activate(clinic)" class="col" type="checkbox" [ngModel]="clinic.isActive">
                    </td> -->
                    <!-- <td>{{ clinic.isActive?'Active': 'Not Active' }}</td> -->
                    <!-- <td>{{test.amount }} {{data.info['currency']}}</td> -->

                    <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                    <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- <router-outlet></router-outlet> -->

