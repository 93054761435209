<div class="row">
  <div class="col">
    <h2 class="roboto-300 start">{{ "Patients" | locale }}</h2>
  </div>
  <ng-container *ngIf="data.role == 'admin'">
    <div style="display: flex; align-items: center" class="mx-3">
      <div class="form-group form-check start mb-0">
        <input
          type="checkbox"
          class="form-check-input"
          name="excel"
          [(ngModel)]="excel"
          [checked]="excel"
          id="excel"
        />
        <label
          class="form-check-label"
          [ngClass]="{ 'mr-4': data.isRTL }"
          for="excel"
          >{{ "Excel" | locale }}</label
        >
      </div>
    </div>
    <button
      *ngIf="insurance"
      type="button"
      class="btn btn-outline-success px-4"
      data-target="#upload-patient-modal"
      data-toggle="modal"
    >
      {{'Upload' | locale}}
    </button>
    <button
      data-toggle="modal"
      data-target="#clinic-modal"
      type="button"
      id="add-button"
      style="height: fit-content"
      class="btn btn-primary roboto-400 px-5 my-auto"
      (click)="print_report()"
    >
      {{ "Print" | locale }}
    </button>
  </ng-container>
</div>

<div *ngIf="data.role == 'admin'" class="row">
  <div *ngFor="let date of ['from', 'to']" class="col mt-3">
    <div class="form-group">
      <label>{{ "date " + date | locale }}</label>
      <input
        [name]="date"
        [(ngModel)]="dates[date]"
        [id]="date + 'Picker'"
        class="flatpickr flatpickr-input active form-control"
        type="text"
        placeholder="Select Date.."
      />
    </div>
  </div>
</div>

<div class="card mt-5 mb-5">
  <div class="container-fluid py-3 px-4">
    <div class="row mt-2">
      <div class="col-12 col-lg-10">
        <div class="form-group m-0 roboto-400">
          <input
            type="text"
            name="key"
            id="pat_autocomplete"
            class="form-control"
            [placeholder]="'Search by patient name' | locale"
          />

          <!-- <i [hidden]="!isLoaderHidden" class="fas search-icon fa-search"></i>

              <div [hidden]="isLoaderHidden"  #loader  class="spinner-border text-primary load" role="status">
                <span class="sr-only ">Loading...</span>
              </div> -->
        </div>
      </div>

      <div class="col-12 col-lg-2 mt-3 mt-lg-0">
        <div class="d-flex w-100 justify-content-end">
          <button
            data-toggle="modal"
            data-target="#modal"
            (click)="action = { type: 'New' }"
            type="button"
            id="add-button"
            class="btn btn-outline-primary w-100 roboto-400"
          >
            {{ "Add" | locale }} +
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <!-- <th> Paid</th> -->

                <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                <th width="15%" scope="col">{{ "ID" | locale: 2 }}</th>
                <th width="15%" scope="col">{{ "Name" | locale }}</th>
                <th>{{ "Birthdate" | locale }}</th>
                <th style="white-space: nowrap">
                  {{ "Correspondent ID" | locale }}
                </th>
                <th>{{ "Hospital ID" | locale }}</th>
                <th>{{ "Phone" | locale }}</th>
                <th *ngIf="data.role == 'admin'" scope="col">
                  {{ "Paid" | locale }}
                </th>
                <th *ngIf="data.role == 'admin'" scope="col">
                  {{ "Remaining" | locale }}
                </th>
                <th *ngIf="data.role == 'receptionist'">
                  {{ "Balance" | locale }}
                </th>
                <th>{{ "Patient card" | locale }}</th>
                <th *ngIf="data.role == 'admin'" scope="col">
                  <input
                    *ngIf="!refresher"
                    (click)="$event.preventDefault(); patient_selection()"
                    (change)="$event.preventDefault()"
                    [checked]="
                      checked_patients | checkSelectedPatients: patients
                    "
                    type="checkbox"
                  />
                </th>
                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>
            </thead>

            <tbody class="neue-light mt-2">
              <ng-container
                *ngFor="
                  let patient of patients
                    | paginate
                      : {
                          id: 'pagination',
                          itemsPerPage: pagination.per_page,
                          currentPage: pagination.current_page,
                          totalItems: pagination.total
                        };
                  let i = index
                "
              >
                <tr
                  (click)="openModal({ type: 'Edit', id: patient.id })"
                  valign="middle"
                  class="clickable_row roboto-400"
                >
                  <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                  <td width="15%">{{ i + 1 }}</td>
                  <td width="15%">{{ patient.name }}</td>

                  <td>{{ patient.birthdate }}</td>
                  <td>{{ patient.insurance_id || "✖" }}</td>
                  <td>{{ patient.hospital_id || "✖" }}</td>
                  <td>{{ patient.phone_number || "✖" }}</td>
                  <td *ngIf="data.role == 'admin'">
                    {{ patient.payment.paid }}{{ data.info["currency"] }}
                  </td>
                  <td *ngIf="data.role == 'admin'">
                    {{ patient.payment.total_summery - patient.payment.paid
                    }}{{ data.info["currency"] }}
                  </td>
                  <td *ngIf="data.role == 'receptionist'">
                    {{ patient.payment.unpaid + data.info["currency"] }}
                  </td>
                  <td>
                    <button
                      (click)="$event.stopPropagation(); print(patient.id)"
                      type="button"
                      class="btn btn-outline-info p-0 px-3"
                    >
                      {{ "Print" | locale }}
                      <!-- <i class="far fa-print"></i> -->
                    </button>
                  </td>
                  <td *ngIf="data.role == 'admin'">
                    <input
                      (click)="$event.stopPropagation()"
                      (change)="
                        $event.preventDefault();
                        checked_patients[patient.id] =
                          !checked_patients[patient.id];
                        refresh()
                      "
                      [checked]="!!checked_patients[patient.id]"
                      type="checkbox"
                      [id]="patient.id"
                    />
                  </td>

                  <!-- <td>{{patient.payment.paid}} LYD</td> -->

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-12 mt-3">
        <div class="w-100 d-flex justify-content-center neue-med p-0">
          <pagination-controls
            responsive="true"
            [previousLabel]="'Previous' | locale"
            [nextLabel]="'Next' | locale"
            (pageChange)="getPatients(key, true, $event)"
            id="pagination"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <router-outlet></router-outlet> -->

<!-- Modal -->
<div
  class="modal fade"
  id="modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <app-loading [type]="'dialog'"></app-loading>

      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
              <h5 class="roboto-500 start">
                {{
                  (action.type == "Edit" ? "Edit" : "Add") + " a patient"
                    | locale
                }}
              </h5>
            </div>
          </div>
        </div>

        <receptionist-layout-new-patient
          (patientCreated)="onPatientCreated($event)"
          [action]="action"
        ></receptionist-layout-new-patient>

        <ng-container *ngIf="action.type == 'Edit'">
          <hr />
          <app-patinet-payments-stats
            [action]="action"
          ></app-patinet-payments-stats>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="upload-patient-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{'Upload' | locale}}</h5>
      </div>

      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <form
              id="uploadPatientForm"
              #uploadPatientForm="ngForm"
              (ngSubmit)="uploadPatient(uploadPatientForm)"
              enctype="multipart/form-data"
            >
              <div class="d-flex h-100 align-items-center start">

                <div class="form-group form-check">
                  <input
                    type="file"
                    class="form-check-input"
                    name="patientFile"
                    id="sendFile"
                    (change)="fileChoosen($event)"
                  />
                  <!-- <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" > {{ test.name }}</label> -->
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="modal-footer">

        <div class="row">
          <button form="uploadPatientForm" type="submit" class="btn btn-primary">
            {{'Upload' | locale}}
          </button>
        </div>

      </div>
    </div>
  </div>
</div>