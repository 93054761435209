  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{'Clinics' | locale}}</h2>
    </div>
    <div class="col-2">
      <button
              type="button"
              id="add-button"
              class="btn btn-primary w-100 roboto-400"
              (click)="
              ms.assign({
                title: 'Add a Clinic',
                route: null,
                datum: {},
                inputs: { name: true, address: true, contact:true },
                method: api.Admin.clinics.add,
                message: 'Clinic was added successfully',
                onSuccess: getClinics.bind(this)
              });
            "            >
              {{'Add' | locale}} +
            </button>
    </div>
  </div>

  <div class="card mb-5 mt-5">
    <div class="container-fluid py-3 px-4">

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <th  scope="col" > {{"ID" | locale:2}}</th>
                  <!-- <th width="15%" scope="col"> code</th> -->
                  <th style="min-width: 150px;" scope="col">{{'Name' | locale}}</th>
                  <th scope="col">{{'Address' | locale}}</th>
                  <th scope="col">{{'Contact' | locale}}</th>
                  <th scope="col">{{'Edit' | locale}}</th>
                  <th scope="col">{{'Activation' | locale}}</th>


                </tr>
              </thead>
              <br />

              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let clinic of clinics; let i = index">
                  <tr
                  (click)="router.navigateByUrl('admin/clinics/' + clinic.id)"
                    valign="middle"
                    class="clickable_row roboto-400"
                  >
                    <td scope="row">{{i + 1}}</td>
                    <td style="min-width: 150px;">{{ clinic.name }}</td>
                    <td>{{ clinic.address }}</td>
                    <td>{{ clinic.contact }}</td>
                    <td  style="min-width: 100px" scope="col">
                      <button
                      (click)="$event.stopPropagation();
                      ms.assign({
                        title: 'Edit a Clinic',
                        action:'Update',
                        route: null,
                        datum: clinic,
                        inputs: { name: true, address: true, contact:true },
                        method: api.Admin.clinics.update,
                        message: 'Clinic was updated successfully',
                        onSuccess: getClinics.bind(this),
                        deleteMethod:api.Admin.clinics.remove,
                        deleteMessage:'Clinic was deleted successfully'
                      });
                    "
                        type="button"
                        class="btn btn-outline-info p-0 px-3"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                    </td>
                    <td>
                        <input (change)="activate(clinic)" (click)="$event.stopPropagation();" class="col" type="checkbox" [ngModel]="clinic.isActive">
                    </td>
                    <!-- <td>{{ clinic.isActive?'Active': 'Not Active' }}</td> -->
                    <!-- <td>{{test.amount }} {{data.info['currency']}}</td> -->

                    <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                    <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- <router-outlet></router-outlet> -->
