import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackService } from 'src/app/services/snack.service';
import { Router } from '@angular/router';
import { ProgressService } from 'src/app/services/progress.service';
import { AgoPipe } from 'src/app/pipes/ago.pipe';
import { map, tap } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { Arabic } from 'flatpickr/dist/l10n/ar';
declare var $: any;

@Component({
  selector: 'receptionist-layout-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.scss'],
})
export class NewPatientComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isCreate: boolean = true;
  civilities$: any;
  genders: any = [];
  civilities: any = [];
  civility_id: any = null;
  ageTypes = ['years', 'months', 'days'];
  deleting: boolean = false;
  sureState: boolean = false;
  autoSelectGender = true;
  promise;
  @Output() patientCreated = new EventEmitter();

  changeAgeType = (type, form: NgForm) => {
    this.patient.age_type = type;
    form.value.age_type = type;
  };

  @Input() patient: any;

  @Input() action: any;

  constructor(
    public api: ApiService,
    public snack: SnackService,
    private router: Router,
    public progress: ProgressService,
    private ago: AgoPipe,
    public data: DataService
  ) {
    this.api.Common.Get.lab_info().subscribe(res =>{
      this.autoSelectGender = res['data']['settings']['selectGender']
   })
  }

  ngOnInit() {
    // this.api.Common.Get.lab_info().subscribe(res =>{
    //    this.autoSelectGender = res['data']['settings']['selectGender']
    // })
    this.getCivilities()
    this.getData();
    // this.data.dataAssigned.subscribe(assigned => {
    //   if(assigned){
    //     if(this.data.genders.length && this.civilities.length){
    //       this.patient.gender_id = this.patient.gender_id || this.data.genders[0].id;
    //       this.patient.civility_id = this.patient.civility_id || this.civilities[0].id;
    //       this.genderChanged({ value: this.data.genders[0].id });
    //     }
    //   }
    // })
    }

    getData() {
      this.getGenders()
    }


  getGenders() {
    return this.api.Common.Get.genders().subscribe(res => {
      console.log('1', res)
        this.genders = res;
        if(this.autoSelectGender == false){
          this.patient.gender_id = this.patient.gender_id || res[0].id;
        }
        console.log("=====================>", this.patient.gender_id);
        console.log("=====================>", this.autoSelectGender);
        // this.genderChanged({ value: res[0].id });
    });
    // .pipe(
    //   map((res) => res["data"]),
    //   tap((res) => {
    //     console.log('1', res)
    //     this.genders = res;
    //     this.patient.gender_id = this.patient.gender_id || res[0].id;
    //     console.log("=====================>", this.patient.gender_id);
    //     this.genderChanged({ value: res[0].id });
    //   })
    // );
  }

  getCivilities() {
    return this.api.Common.Get.civilities().subscribe(res => {
      this.civilities = res;
        this.patient.civility_id
          ? null
          : (this.patient.civility_id = res[0].id);
    });
    // .pipe(
    //   map((res) => res["data"]),
    //   tap((res) => {
    //     this.civilities = res;
    //     this.patient.civility_id
    //       ? null
    //       : (this.patient.civility_id = res[0].id);
    //   })
    // );
  }

  genderChanged({ value }) {
    let civility = this.civilities.find((one) => one.gender_id == value)
    this.patient.civility_id = civility.id
    // this.patient.civility_id = this.civilities.find(
    //   (one) => one.gender_id == value
    // )['civility_id'] || this.patient.civility_id;
    
    // this.patient.civility_id = this.patient? this.patient.civility_id : null
  }

  civilityChanged({ value }) {
    this.patient.gender_id = this.civilities.find((one) => one.id == value)[
      'gender_id'
    ] || this.patient.gender_id;
  }

  ngAfterViewInit() {
    $('#patient_picker').flatpickr({
      dateFormat: 'd-m-Y',
      allowInput: true,
      locale: this.data.language == 'arabic' ? Arabic : null,
      position: 'auto center',
    });
  }

  onSubmit(form) {
    form.value.age_type = this.patient.age_type;

    if (form.valid) {
      // switch between creating and editing
      switch (this.action['type']) {
        case 'New': {
          let vals = {...form.value};
          // var birthdate = $('#patient_picker')[0].value.split('-');
          if (!vals['birthdate'] && !vals['age'])
          return this.snack.showerror('Please enter either age or birthdate');
          vals['birthdate'] = this.data.format_date(vals['birthdate'])
          if(!vals['birthdate']) delete vals['birthdate']
          this.api.Common.patients.add(vals).subscribe((res) => {
            if (!res['error']) {
              this.snack.show('The patient was successfully added');

              this.patientCreated.emit(res['patient_id']);
            } else {
              this.snack.showerror('Something went wrong');
            }
          });

          break;
        }

        case 'Edit': {
          let vals = {...form.value};

          // var birthdate = $('#patient_picker')[0].value.split('-');
          if (!vals['birthdate'] && !vals['age'])
          return this.snack.showerror('Please enter either age or birthdate');
          vals['birthdate'] = this.data.format_date(vals['birthdate'])
          if(!vals['birthdate']) delete vals['birthdate']
          vals['id'] = this.patient.id
          this.api.Common.patients.update(vals).subscribe(
            (res) => {
              if (res['error'] != true) {
                this.snack.show('The patient was successfully updated');
                this.patientCreated.emit(res);
              }
            }
          );

          break;
        }
      }
    } else {
      this.snack.showerror('Please enter all data correctly and try again');
    }
  }

  ngOnChanges(changes) {
    console.log(this.patient)
    this.progress.onDP();
    if (!this.patient || JSON.stringify(this.patient) == '{}') {
      this.patient = {
        first_name: '',
        middle_name: '',
        last_name: '',
        birth_year: '',
        birth_month: '',
        insurance_id: '',
        hospital_id: '',
        birth_day: '',
        gender_id: this.data.genders[0]?.id,
        civility_id: this.civilities[0]?.id,
        age: '',
        age_type: 'years',
      };
    }
    if (
      !changes.action ||
      !changes.action.currentValue ||
      !changes.action.currentValue.type
    ) {
      changes.action = {};
      changes.action['currentValue'] = {};
    }

    if (changes.action.currentValue.type != null) {
      let type = changes.action.currentValue.type;
      this.setPatientIfAvaliable(type);
    }
  }

  setPatientIfAvaliable(type) {
    switch (type) {
      case 'New': {

        this.patient = {
          age_type: 'years',
          gender_id: this.patient.gender_id,
          civility_id: this.patient.civility_id,
        };
        console.log(this.patient);

        this.civility_id = null;
        this.progress.offDP();
        let picker = $('#picker')[0];
        if (picker) picker.value = null;

        break;
      }
      case 'Edit': {
        this.api.Common.patients.get_one(this.action['id']).subscribe((res) => {
          this.patient = res;
          this.patient.age_type = null;
          [this.patient.age, this.patient.age_type, this.patient.birthdate] =
            this.ago.transform(res['birthdate']);
          this.civility_id = this.patient.civility_id;
          let picker = $('#picker')[0];
          if (picker)
            picker.value =
              this.patient.birth_day +
              '-' +
              this.patient.birth_month +
              '-' +
              this.patient.birth_year;

          this.progress.offDP();
        });
        break;
      }
    }
  }
  
  remove() {
    this.deleting = true;
    this.api.Common.patients.remove(this.patient.id).subscribe((res) => {
      this.deleting = false;
      if (res['error'])
        return this.snack.showerror('Sorry something went wrong');
      return this.snack.show('Deleted Successfully');
    });
  }

  // createRequest(patient_id){

  //   this.api.Requests.createIntialRequest(patient_id).subscribe(res => {

  //     if (res['error'] == false ){

  //       this.router.navigateByUrl(`/patients/${patient_id}/requests/${res['request_id']}/add`);
  //       console.log('here');

  //       $("#modal").toggle("hide");
  //       $('body').removeClass('modal-open');
  //       $('.modal-backdrop').remove();
  //       $('.dropdown-menu').dropdown('hide');

  //     }
  //     else {

  //       this.snack.showerror('something went wrong');

  //     }

  //   })

  // }

  // }
}
