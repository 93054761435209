import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";
import { ProgressService } from "src/app/services/progress.service";
import { version } from '../../../version';
declare var $: any;

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit {



  current_role: string = "receptionist";
  roles: Array<any> = [];
  appVersion: string = version;

  urls = {
    admin: "admin",
    receptionist: "res",
    technician: "tec",
    accountant: "fms",
  };

  new_tab:boolean = false;


  constructor(
    public api: ApiService,
    public data: DataService,
    public router: Router,
    public progress: ProgressService
  ) {}




  ngOnInit() {

    this.data.infoAssigned.subscribe(isAssigned => {
      if(isAssigned){
      this.new_tab = this.data.info['settings']['rolePageInNewTab']
      }
    })
  }
}
