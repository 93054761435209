<ng-container>
  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{ "Families" | locale }}</h2>
    </div>
  </div>

  <div class="card mb-5 mt-5">
    <div class="container-fluid py-3 px-4">
      <div class="row mt-2">
        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end">
            <button
              type="button"
              id="add-button"
              class="btn btn-outline-primary w-100 roboto-400"
              (click)="
                ms.assign({
                  title: 'Add a Family',
                  route: null,
                  datum: {},
                  inputs: { name: true, code: true },
                  method: api.Admin.families.add,
                  message: 'Family was added successfully',
                  onSuccess: getFamilies.bind(this)
                })
              "
            >
              {{ "Add" | locale }} +
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <th scope="col">{{ "ID" | locale: 2 }}</th>
                  <th width="40%" scope="col">{{ "code" | locale }}</th>
                  <th width="40%" scope="col">{{ "Name" | locale }}</th>
                </tr>
              </thead>
              <br />
              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let family of families; let i = index">
                  <tr
                    (click)="
                      ms.assign({
                        title: 'Edit a Family',
                        action: 'Update',
                        route: null,
                        datum: family,
                        inputs: { name: true, code: true },
                        method: api.Admin.families.update,
                        message: 'Family was updated successfully',
                        onSuccess: getFamilies.bind(this),
                        deleteMethod: api.Admin.families.remove,
                        deleteMessage: 'Family was deleted successfully'
                      })
                    "
                    valign="middle"
                    class="clickable_row roboto-400"
                  >
                    <td scope="row">{{ i + 1 }}</td>
                    <td width="40%">{{ family.code }}</td>
                    <td width="40%">{{ family.name }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
