<div class="container-fluid py-2 px-4">
    <button
        (click)="$event.preventDefault()"
        class="form-control"
        style="width: fit-content;height: fit-content; cursor: inherit"
    >
        <h3 class="m-0">#{{ sms_counter }}</h3>
    </button>
    <ng-container>
      <form #form="ngForm" (ngSubmit)="onSubmit(form)">
        <div class="row neue-reg mt-3">
          <div class="col-lg-12">
            <div class="form-group">
                <label>{{ "Phone Number" | locale }}</label>
                <input
                  required
                  pattern="[0-9]{0,14}"
                  title="Phone Number"
                  maxlength="25"
                  name="phone_number"
                  [(ngModel)]="phone_number"
                  #phone_input="ngModel"
                  type="text"
                  class="form-control"
                  [placeholder]="0900000000"
                />
  
              <small
                style="display: block"
                class="start"
                *ngIf="
                  (phone_input.errors ? phone_input.errors['required'] : false) &&
                  (phone_input.touched || form.submitted)
                "
              >
                {{ "This field is required" | locale }}
              </small>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="exampleInputEmail1">{{
                "Message" | locale
              }}</label>
              <textarea
                required
                (keyup)="getCharacterCount()"
                (input)="updateAdditionalValue()"
                rows="5"
                [(ngModel)]="message"
                name="message"
                #note_input="ngModel"
                type="text"
                class="form-control"
                [placeholder]="'Message' | locale"
              ></textarea>
  
              <small
                style="display: block"
                class="start"
                *ngIf="
                  (note_input.errors ? note_input.errors['required'] : false) &&
                  (note_input.touched || form.submitted)
                "
              >
                {{ "This field is required" | locale }}
              </small>
            </div>
        </div>
        <div class="col-md-2">
            <p>{{messageLength}}/{{messgaeLimit}}</p>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2">
            <p>{{"Pages" | locale}}: {{pages}}</p>
        </div>
        </div>
        
        <div class="col-12">
            <div class="d-flex justify-content-end" *ngIf="sms_counter > 0">
                <button
                    type="submit"
                    class="btn btn-outline-primary tajwal-regium px-5"
                >
                    {{ "Add" | locale }}
                </button>
            </div>
        </div>
      </form>
    </ng-container>
</div>