import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { SnackService } from "src/app/services/snack.service";
import { Router } from "@angular/router";
import { ProgressService } from "src/app/services/progress.service";
import { NgForm } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
declare var $: any;

@Component({
  selector: "admin-layout-new-test",
  templateUrl: "./new-test.component.html",
  styleUrls: ["./new-test.component.scss"],
})
export class NewTestComponent implements OnInit, OnChanges {
  @ViewChild("test_form", { static: false })
  form: NgForm;

  sureState: Boolean = false;
  genders$: any;
  civilities$: any;
  genders: any = [];
  civilities: any = [];
  categories: any = [];
  families: any = [];
  pricing: any = [];
  selectedParams: any = [];
  selectedDevices: any = [];
  selectedSample: any = {}
  pricings_valid: any = [];
  notes: Array<String> = [];
  noteIndex:number = null;
  note:string = '';

  promise;
  @Output() setCreated = new EventEmitter();
  @Input() tests: any = [];
  sampleSelectorOutputType: any = "inside";
  SampleInputVal: any = "";

  test: any = {
    code: "",
    name: "",
    amount: "",
    birth_year: "",
    birth_month: "",
    birth_day: "",
    age: "",
    gender_id: "",
    category_id: -1,
    printable_name: true,
    tax: null,
    status:'active',
    inactive_note: "",
    active_note: "",
    cost: 0
  };
  isTax = false

  @Input() action: any;

  constructor(
    public api: ApiService,
    public snack: SnackService,
    public data: DataService,
    private router: Router,
    public progress: ProgressService
  ) {}

  ngOnInit() {


    this.data.infoAssigned.subscribe(isAssigned =>{
      if(isAssigned){
        this.isTax = this.data.info["settings"]['isTax']
      }
    })

    this.getPricing();
    this.getCats();
    this.getFamilies();
  }

  pushNote(){
    if(!this.notes.includes(this.note)){
      this.notes.push(this.note);
      this.note = '';
    }else{
      this.snack.showerror('note exists')
    }
  }


  editNote(note, i){
    this.noteIndex = i
    this.note = note
    // document.getElementById('pushOpt').style.display = 'none'
    // document.getElementById('pupOpt').style.display = 'block'
    }

  updateNote(){
    this.notes[this.noteIndex] = this.note
    this.note = ''
    this.noteIndex = null
  }
  removeNote = note =>{
    let index = this.notes.indexOf(note)
    this.notes = this.notes.filter(one => one != note)
    this.noteIndex = index < this.noteIndex? this.noteIndex - 1 : index == this.noteIndex? null : this.noteIndex
  }

  // originalInputCheck(pricing, clinic, lab) {
  originalInputCheck(pricing, clinic) {
    pricing.oldClinic = 0;
    pricing.clinic_price = 0;
    clinic.value = 0;
    // lab.value = 0;
  }

  clinicInputCheck(e, input, pricing, lab_input) {

    pricing.oldClinic = pricing.oldClinic ? pricing.oldClinic : 0;
    pricing.price = pricing.price ? Number(pricing.price) : 0;
    if (
      !Number(e.data) &&
      e.inputType == "insertText" &&
      !(Number(e.data) == 0)
    ) {
      pricing.clinic_price = pricing.oldClinic;
      input.value = pricing.clinic_price;
      return;
    }
    pricing.clinic_price = pricing.clinic_price
      ? Number(pricing.clinic_price)
      : 0;

    if (pricing.clinic_price > pricing.price) {
      pricing.clinic_price = pricing.oldClinic;
    } else pricing.oldClinic = pricing.clinic_price;
    input.value = pricing.clinic_price;
    lab_input.value = pricing.price - pricing.clinic_price;
  }

  setSelectedParam(e) {
    if(this.selectedParams.find(one => one.id == e.id)) return
    let order = this.selectedParams.length + 1
    while(this.selectedParams.filter(param => param['order'] == order).length){
      order++
    }
    console.log('this.selectedParams', this.selectedParams)
    console.log('e', e)
    if(!e['canPrint']){
      e['canPrint'] = 1
    }
    if(!e['bold']){
      e['bold'] = 1
    }
    e['order'] = order
    this.selectedParams.push(e);
  }

  changePrintStatue(i, e){
    this.selectedParams[i]['canPrint'] = e.target.checked
  }
  boldStatue(i, e){
    this.selectedParams[i]['bold'] = e.target.checked
  }

  setSelectedDevice(e) {
    if(this.selectedDevices.find(one => one.id == e.id)) return this.snack.showerror('This device | method is already added')
    this.selectedDevices.push(e);
  }

  getPricing() {
    this.progress.on();
    this.api.Admin.pricing.pricingsWithNoEnherit().subscribe((res) => {
      this.progress.off();
      this.pricing = res['data'];
      console.log(this.pricing)
      console.log('this.pricing', this.pricing)
      this.pricing.forEach((e) => {
        e.pricing_id = e.id;
      });

      this.progress.offDP();
    });
  }




  removeParam(parameter) {
    this.selectedParams = this.selectedParams.filter(
      (param) => param.id != parameter.id
    );
  }

  removeDevice(deviceToBeRemoved) {
    this.selectedDevices = this.selectedDevices.filter(
      (device) => device.id != deviceToBeRemoved.id
    );
  }

  getCats() {
    this.api.Common.Get.categories().subscribe((res) => {
      this.categories = res;
    });
  }

  getFamilies() {
    this.api.Admin.families.get().subscribe((res) => {
      this.families = res;
    });
  }

  onSubmit(form) {
    this.form = form;
    this.form.value["notes"] = this.notes;
    this.form.value.is_casa = !!this.form.value.is_casa;

    const isAllPricedFilled = this.pricing.some((e) => e.price == null);
    if (isAllPricedFilled) {
      this.snack.showerror("Please enter all prices");
    } else if (form.valid) {
      // switch between creating and editing
      switch (this.action["type"]) {
        case "New": {
          console.log(this.selectedSample);

          if (this.selectedSample) {
            form.value.sample_id = this.selectedSample.id;
          } else {
            this.snack.showerror("Please select a sample");
            return;
          }

          if (form.value.category_id == -1) {
            this.snack.showerror("Please select a category");
            return;
          }


          if (this.selectedParams.length != 0) {
            if(this.selectedParams.filter(one => !one.order || typeof Number(one.order) != 'number').length||
            this.selectedParams.length != Array.from(new Set(this.selectedParams.map(one => one.order))).length
            )  return this.snack.showerror("Please add a unique order for each parameter");

            form.value.parameters = this.selectedParams.map((e) => ({id: e.id, order:e.order, canPrint:e.canPrint, bold:e.bold}));
          } else {
            return this.snack.showerror("Please add parameters to the test");
          }

          if (this.selectedDevices.length != 0) {
            form.value.devices = this.selectedDevices.map((e) => ({id: e.id, is_default: e.is_default}));
          } else {
            return this.snack.showerror("Please add a device | method to the test");

          }

          if (this.pricing.length != 0) {
            form.value.pricing = this.pricing;
            form.value.pricing.forEach((e) => {
              e.clinic_price = e.clinic_price || 0
            });
          } else {
            this.snack.showerror("please add prices");
            return;
          }

          // filter attributes added for validation purposes
          this.filterUnusedAttributes(form.value);

          this.api.Admin.tests.add(form.value).subscribe((res) => {
            if (!res["error"]) {
              this.snack.show("Test successfully added");
              this.setCreated.emit(res);
            } else {
              if (Object.values(res["errors"].length != 0)) {
                this.snack.showerror(Object.values(res["errors"])[0]);
              } else {
                this.snack.showerror("Something went wrong");
              }
            }
          });

          break;
        }

        case "Edit": {
          if (this.selectedSample) {
            form.value.sample_id = this.selectedSample.id;
          } else {
            this.snack.showerror("please select a sample");
            return;
          }

          if (form.value.category_id == -1) {
            this.snack.showerror("please select a category");
            return;
          }

          if (this.selectedParams.length != 0) {
            if(this.selectedParams.filter(one => !one.order || typeof Number(one.order) != 'number').length||
            this.selectedParams.length != Array.from(new Set(this.selectedParams.map(one => one.order))).length
            )  return this.snack.showerror("please add unique order for each parameter");
            form.value.parameters = this.selectedParams.map((e) => ({id: e.id, order:e.order, canPrint:e.canPrint, bold:e.bold}));
          } else if (!form.value.is_casa) {
            this.snack.showerror("please add params to the test");
            return;
          }

          if (this.selectedDevices.length != 0) {
            form.value.devices = this.selectedDevices.map((e) => ({id: e.id, is_default: e.is_default}));
          } else {
            this.snack.showerror("please add devices to the test");
            return;
          }

          if (this.pricing.length != 0) {
            form.value.pricing = this.pricing;
            form.value.pricing.forEach((e) => {
              e.pricing_id = e.id;
            });
          } else {
            this.snack.showerror("please add prices");
            return;
          }

          this.filterUnusedAttributes(form.value);

          form.value['id'] = this.test.id
          this.api.Admin.tests.update( form.value).subscribe((res) => {
            if (res["error"] != true) {
              this.snack.show("Test successfully edited ");
              this.setCreated.emit(res);
            } else {
              if (Object.values(res["errors"].length != 0)) {
                this.snack.showerror(Object.values(res["errors"])[0]);
              } else {
                this.snack.showerror("something went wrong");
              }
            }
          });
          break;
        }
      }
    } else {
      console.log(form.value);
      this.snack.showerror("Please enter all data correctly and try again");
    }
  }

  filterUnusedAttributes(obj) {
    Object.keys(obj).forEach((e) => {
      console.log(e.indexOf("a_"));
      e.indexOf("a_") != -1 ? delete obj[e] : null;
    });
  }

  ngOnChanges(changes) {
    this.progress.onDP();

    console.log(changes);
    this.sureState = false;
    if (changes.action && changes.action.currentValue.type != null) {
      let type = changes.action.currentValue.type;
      this.setTestIfAvaliable(type);
    }
  }


  selectDefaultDevice(device){
    console.log(this.selectedDevices , device)
    this.selectedDevices.forEach(one => {
      if(one.id != device.id) one['is_default'] = false
      else one['is_default'] = !one['is_default']
    })
  }

  setTestIfAvaliable(type) {
    // this.form.resetForm();

    switch (type) {
      case "New": {
        this.test = {};
        this.test.status = 'active'
        this.test.cost = 0
        this.test.inactive_note = ""
        this.test.active_note = ""
        this.test.printable_name = true
        this.test.family_id = null;
        this.test.category_id = "-1";
        this.SampleInputVal = "";
        this.selectedSample = {};
        this.selectedParams = [];
        this.selectedDevices = [];
        this.getPricing();
        this.notes = [];
        break;
      }
      case "Edit": {
        this.api.Admin.tests.get_one(this.action["id"]).subscribe((res) => {
          this.test = res;
          if (res["notes"]) {
            this.notes = res["notes"]["data"].map((one) => one.title);
          } else this.notes = [];
          if (res["category"]) {
            this.test.category_id = res["category"]["id"];
          }
          if (res["family_id"] == null) {
            this.test.family_id = null;
          }


          if (res["sample"]) {
            let sample = JSON.parse(JSON.stringify(res["sample"]))
            Object.keys(sample).forEach(key => this.selectedSample[key] = sample[key])
            this.selectedSample = res["sample"];
            this.SampleInputVal = res["sample"]["name"];

          }

          this.selectedParams = res["parameters"];
          this.selectedDevices = res["devices"];

          if (res["pricing"].length) {
            this.pricing = res["pricing"];
            console.log('pricing', this.pricing)
            this.pricing.forEach((e) => {
               e.price = e.amount; // don't forget
            });
          } else {
            this.getPricing();
          }

          this.progress.offDP();
        });
        break;
      }
    }
  }

  // setSelectedSample(sample) {
  //   // if(this.outputType == "inside")
  //   this.SampleInputVal = sample.name;
  //   this.selectedSample = sample;
  // }

  remove() {
    this.api.Admin.tests.remove(this.test.id).subscribe((res) => {
      if (!res["error"])
        this.data.remove.next({ id: this.test.id, type: "test" });
    });
  }

  // createRequest(test_id){

  //   this.api.Requests.createIntialRequest(test_id).subscribe(res => {

  //     if (res['error'] == false ){

  //       this.router.navigateByUrl(`/tests/${test_id}/requests/${res['request_id']}/add`);
  //       console.log('here');

  //       $("#modal").toggle("hide");
  //       $('body').removeClass('modal-open');
  //       $('.modal-backdrop').remove();
  //       $('.dropdown-menu').dropdown('hide');

  //     }
  //     else {

  //       this.snack.showerror('something went wrong');

  //     }

  //   })

  // }

  // }
}
