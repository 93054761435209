import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { ProgressService } from "src/app/services/progress.service";
import { map, tap } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { Arabic } from "flatpickr/dist/l10n/ar";
import { debounce } from "lodash";
declare var $: any;
declare let moment: any;

@Component({
  selector: "admin-layout-receptionists",
  templateUrl: "./receptionists.component.html",
  styleUrls: ["./receptionists.component.scss"],
})
export class ReceptionistsComponent implements OnInit {
  others: Array<any> = [];
  payments: any = null;
  recs: any[] = [];
  filtered_recs: any[] = [];
  action: any = {};
  revDate: any;
  from_time: any;
  to_time: any;
  excel = false;
  gloabls: { total: number; paid: number; remaining: number } = {
    total: 0,
    paid: 0,
    remaining: 0,
  };

  globals_keys: Array<string> = ["total", "paid"];

  constructor(
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService
  ) {
    this.getReceptionists = debounce(this.getReceptionists, 1500);

  }

  ngOnInit() {
    this.revDate = moment().format("DD-MM-YYYY");
    this.from_time = "00:00";
    this.to_time = "23:59";
    this.getReceptionists();
  }

  filter = (word, place) => {
    place == "name"
      ? (this.filtered_recs = this.recs.filter((rec) =>
          rec["name"].toLocaleLowerCase().includes(word)
        ))
      : place == "id"
      ? (this.filtered_recs = this.recs.filter((rec) =>
          String(rec["id"]).includes(word)
        ))
      : null;
  };

  printRevenues() {
    let excel = this.excel == true ? 1 : 0;
    this.api.Common.printables.receptionists_revenues(
      {date:this.revDate,
      from_time:this.from_time,
      to_time:this.to_time,
      excel}
    ).subscribe((res) => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, "_blank");
    });
  }

  show_others_modal(revenue) {
    this.others = revenue.from_others;
    $("#others_modal").modal("toggle");
  }
  show_payment_modal(payments) {
    // console.log(payments);
    this.payments = payments.payment_methods;
    $("#payment_modal").modal("toggle");
  }

  getReceptionists() {
    console.log(this.from_time, this.to_time);
    // this.progress.on();
    setTimeout(
      () =>
        this.api.Admin.receptionists.get({date:this.revDate.split("-").reverse().join("-"), to_time:this.to_time, from_time:this.from_time}
        ).subscribe((res) => {
          this.progress.off();
          this.globals_keys.forEach((key) => (this.gloabls[key] = 0));
          res.forEach((one) => {
            one["revenue"] = one["revenue"];
            ["total", "paid", "remaining"].forEach(
              (key) =>
                (this.gloabls[key] += one["revenue"]
                  ? one["revenue"]["patient_" + key]
                  : 0)
            );
          });

          this.recs = res;
          this.filtered_recs = this.recs;
          console.log(res);
        }),
      0
    );
  }

  ngAfterViewInit() {
    $("#revPicker").flatpickr({
      dateFormat: "d-m-Y",
      defaultDate: moment().format("DD-MM-YYYY"),
      locale: this.data.language == "arabic" ? Arabic : null,
      position: "auto center",
      maxDate: "today",
    });

    ['#time_from' , '#time_to'].forEach(id => {
      $(id).flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        locale: this.data.language == "arabic" ? Arabic : null,
        position: "auto center",
      });
    })

  }
}
