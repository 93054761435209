<app-nav></app-nav>


<!-- <div  (mouseover)="hover=true" (mouseout)="hover=false"
 id="mySidenav"
 class="sidenav"
 >
  <a style="z-index: 2;" id="about">Admin</a>
</div> -->


<div   class="wrapper">


  <app-sidebar></app-sidebar>
  <app-content [ngStyle]="data.isRTL? {left:0}: {right:0}"  id="content" ></app-content>






</div>


<div class="modal fade" id="dynamic-modal" tabindex="-1" role="dialog" aria-labelledby="settingsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <app-modal *ngIf="ms.is_opened"></app-modal>
</div>
</div>



<div class="modal fade" id="statsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div *ngIf="!data.stats_info?.pdf" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'Statistics' | locale}}</h5>

      </div>
      <div class="modal-body">

        <ng-container >
          <h6 class="start">{{data.stats_info?.label}}</h6>
          <h6 class="start">{{data.stats_info?.id}}</h6>
          <div class="card mt-4 mb-5">
            <div class="container-fluid py-3 px-4">

              <div class="row mt-2 justify-content-center">
                <div *ngFor="let per of ['day', 'month']" class="col-2 mx-4">
                  <div class="d-flex w-100 justify-content-end">
                    <p
                      (click)="toggleFlags(per)"
                      class="btn btn-outline-primary btn-sm w-100 roboto-400 m-0"
                      [ngClass]="{
                        'btn-primary text-white': flags[per]
                      }"
                    >
                      {{ (per == 'day'? 'daily' : 'monthly') | locale }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table mt-3">
                      <thead>
                        <tr
                          *ngIf="flags.month || flags.day"
                          class="neue-light roboto-400"
                        >
                          <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                          <!-- <th width="15%" scope="col">Request #</th> -->
                          <th style="min-width: 100px" scope="col">
                            {{ (flags.month ? "Month" : "Day") | locale }}
                          </th>
                          <th style="min-width: 100px" scope="col">{{'Requests' | locale}}</th>
                          <th style="min-width: 100px" scope="col">{{'Total' | locale}}</th>
                          <th style="min-width: 100px" scope="col">{{'Discount' | locale}}</th>
                          <th style="min-width: 100px" scope="col">{{'Charged' | locale}}</th>

                          <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                          <!-- <th scope="col">خيارات</th> -->
                          <!-- <th scope="col"></th> -->
                        </tr>

                        <!-- | paginate: { id:'pagination' ,
                itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }" -->
                      </thead>
                      <tbody class="neue-light">

                        <ng-container *ngIf="flags.month || flags.day">
                          <ng-container *ngFor="let per of pers">
                            <tr class="neue-light roboto-400">
                              <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                              <!-- <th width="15%" scope="col">Request #</th> -->
                              <th style="min-width: 100px" scope="col">
                                {{ per.date }}
                              </th>
                              <th style="min-width: 100px" scope="col">
                                {{ per.num_of_requests }}
                              </th>
                              <th style="min-width: 100px" scope="col">
                                {{ per.total_price }}
                              </th>
                              <th style="min-width: 100px" scope="col">
                                {{ per.total_discount || 0 }}
                              </th>
                              <th style="min-width: 100px" scope="col">
                                {{ per.total_price_summery }}
                              </th>
                              <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                              <!-- <th scope="col">خيارات</th> -->
                              <!-- <th scope="col"></th> -->
                            </tr>
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-12 mt-3">
                  <div class="w-100 d-flex justify-content-center neue-med p-0">
                    <!-- <pagination-controls
                      responsive="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      (pageChange)="getRequests($event, key)"
                      id="pagination"
                    ></pagination-controls> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>


      </div>
    </div>




    <div *ngIf="data.stats_info?.pdf" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'Report' | locale}}</h5>

      </div>
      <div class="modal-body">

        <form #report_form="ngForm" id="report_form" (ngSubmit)="print(report_form)">
          <div class="container-fluid">
            <div class="row">
              <h6 class="col start">{{data.stats_info?.label}}</h6>
              <select ngModel="day" name="per" class="custom-select col-3" >
                <option *ngFor="let one of ['day', 'month']" [value]="one">{{(one == 'day'? 'daily': 'monthly') | locale}}</option>
              </select>
            </div>
          </div>
          <div class="card mt-4 mb-5">
            <div class="container-fluid py-3 px-4">



              <div class="row">
                <div *ngFor="let date of ['from', 'to']" class="col mt-3">
                  <div class="form-group">
                    <label >{{('Date ' + date )| locale}}</label>
                    <input
                    [(ngModel)]="dates[date]"
                      [name]="date"
                      [id]="date + '_picker'"
                      class="flatpickr flatpickr-input active form-control"
                      type="text"
                      [placeholder]="'Select a date' | locale"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Close' | locale}}</button>
        <button form="report_form" type="submit" class="btn btn-primary">{{'Print' | locale}}</button>
      </div>
    </div>




  </div>
</div>
