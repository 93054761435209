


  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{'Correspondents' | locale}}</h2>
    </div>

  </div>

  <div class="card mb-5 mt-5">
    <div class="container-fluid py-3 px-4">



      <div *roles="{admin:true}" class="row mt-2">


        <div class="col-12 col-lg-8 ">

          <div class="form-group m-0 roboto-400">

            <input type="text"
            [(ngModel)]='searchByName'
            (change)="correspondentsSearch()"
             class="form-control px-5" id="code"
            [placeholder]="'Search by correspondent name' | locale">

            <i  class="fas search-icon fa-search"></i>

          </div>

      </div>

      <div class="col-12 col-lg-2 ">

        <div class="form-group m-0 roboto-400">
          <select
            [(ngModel)]="subcategory_id"
            name="subcategory_id"
            id="civi"
            class="custom-select"
            (change)="correspondentsSearch()"
            >
            <option [value]="'0'">{{'All' | locale}}</option>
            <option
              *ngFor="let subcategory of subcategories"
              [value]="subcategory.id"
            >
              {{ subcategory.name }}
            </option>
          </select>
        </div>

    </div>

        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end ">
            <button
             type="button" id="add-button" class="btn btn-outline-primary w-100 roboto-400" (click)="
             ms.assign({
               title: 'Add a Correspondent',
               route: null,
               datum: {},
               inputs: {   code: true,
               name: true,
               phone_number: true,
               email: true,
               pricing_id: true,
               subcategory_id: true,
               percentage: true,
               isLTA:true },
               method: api.Admin.correspondents.add,
               message: 'Correpondent was added successfully',
               onSuccess: getCorrespondents.bind(this)
             });">{{'Add' | locale}} +</button>
          </div>
        </div>


      </div>

      <div class="row">

        <div class="col-12">
          <div class="table-responsive  ">
            <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th style="width: 50px;"> {{'ID' | locale:2}}</th>
                <th style="width: 50px;" width="15%" scope="col"> {{'code' | locale}}</th>
                <th style="width: 150px;" scope="col"> {{'Name' | locale}}</th>
                <th style="width: 150px;" scope="col"> {{'Phone' | locale}}</th>
                <th style="width: 150px;" scope="col"> {{'Default Pricing' | locale}}</th>
                <th style="width: 150px;" scope="col"> {{'Main Correspondent' | locale}}</th>
                <ng-container *roles="{admin:true}">
                  <th style="width: 150px;" scope="col"> {{'Lab id' | locale}}</th>
                  <!-- <th style="width: 150px;" scope="col"> Standard Price</th> -->
                  <th style="width: 150px;" scope="col"> {{'Original' | locale}}</th>
                  <!-- <th style="width: 150px;" scope="col"> Discount</th> -->
                  <th style="width: 150px;" scope="col"> {{'Charged' | locale}}</th>
                  <th style="width: 150px;" scope="col"> {{'Paid' | locale}}</th>
                </ng-container>
                <th style="width: 150px;" scope="col"> {{'Remaining' | locale}}</th>
                <ng-container *roles="{admin:true}">
                  <th style="width:  50px;" scope="col"> {{'Edit' | locale}}</th>
                  <th style="width:  50px;" scope="col"> {{'Default' | locale}}</th>

                </ng-container>

                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>



            </thead>
            <br>

            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let correspondent of correspondents; let i = index">

                <tr
                *roles="{admin:true, type:'click'}"
                (click)="router.navigateByUrl('admin/correspondents/' + correspondent.id)"
                valign="middle"  class="clickable_row roboto-400">
                <td style="width: 50px;">{{i + 1}}</td>
                <td style="width: 50px;">{{correspondent.code || '✖'}}</td>
                <td style="width: 150px;">{{correspondent.name || '✖' }}</td>
                <td style="width: 150px;">{{correspondent.phone_number || '✖'}}</td>
                <td style="width: 150px;">{{correspondent.pricing || '✖'}}</td>
                <td style="width: 150px;">{{correspondent.subcategory || '✖'}}</td>
                <ng-container *roles="{admin:true}">
                  <td style="width: 150px;">{{correspondent.lab_id || '✖'}}</td>
                  <!-- <td style="width: 150px;">{{correspondent.payment.total}}</td> -->
                  <td style="width: 150px;">{{correspondent.payment?.total || 0}}</td>
                  <!-- <td style="width: 150px;">{{correspondent.payment.total_discount || 0}}</td> -->
                  <td style="width: 150px;">{{(correspondent.payment?.total_after_discount | number) || 0}}</td>
                  <td style="width: 150px;">{{correspondent.payment?.paid | number}}{{data.info['currency']}}</td>
                </ng-container>

                <td style="width: 150px;">{{correspondent.payment?.remaining | number}}{{data.info['currency']}}</td>
                <ng-container *roles="{admin:true}">
                  <td style="width: 50px;">
                    <button (click)="$event.stopPropagation();
                    ms.assign({
                      title: 'Edit a Correspondent',
                      action:'Update',
                      route: null,
                      datum: correspondent,
                      inputs: {   code: true,
                        name: true,
                        phone_number: true,
                        email: true,
                        pricing_id: true,
                        subcategory_id: true,
                        percentage: true,
                        isLTA:true },
                      method: api.Admin.correspondents.update,
                      message: 'Correspondent was updated successfully',
                      onSuccess: getCorrespondents.bind(this),
                      deleteMethod:api.Admin.correspondents.remove,
                      deleteMessage:'Correspondent was deleted successfully'
                    });" type="button" class="btn btn-outline-info p-0 px-3"><i class="fas fa-edit"></i></button>
                  </td>
                  <td style="width: 50px;" (click)="$event.stopPropagation();">
                    <input (change)="$event.preventDefault();selectDefault(correspondent)"  [checked]="correspondent.is_default"  type="checkbox" style="width: 35%;height: 100%;">
                  </td>
                </ng-container>

                <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                  <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                </h5></a>
                </td> -->
                <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
              </tr>
              </ng-container>



            </tbody>
            </table>
          </div>
        </div>

      </div>


    </div>
  </div>


<!-- <router-outlet></router-outlet> -->


