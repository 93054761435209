
         <form id="update_prices_form" #update_prices_form="ngForm" (ngSubmit)="onSubmit(update_prices_form)">
          <div class="modal-body">
            <label for="price_type_id">{{ "Prices" | locale }}</label>
            <select name="price_type_id"id="price_type_id"class="custom-select"(change)="onChange($event.target.value)"[(ngModel)]="price_type_id">
              <option  *ngFor="let price of pricings" [value]="price.id">
              <!-- <option selected > -->
                {{price.name}}
              </option>
            </select>
            <br>
            <label class="mt-2" for="cats">{{ "Categories" | locale }}</label>
            <select
              [(ngModel)]="category_id"
              name="category_id"
              id="cats"
              class="custom-select"
              (change)="changeArea($event, price_type_id, searchValueText)"
            >
            <option [value]="''">{{ "Select a category" | locale }}</option>
            <option [value]="-1">{{ "All" | locale }}</option>
              <option *ngFor="let category of data.categories" [value]="category.id">
                {{ category.name }}
              </option>
            </select>
            <br>
            <label class="mt-2" for="searchValueText">{{ "Search" | locale }}</label>
            <input class="form-control" id="searchValueText" name="searchValueText" [(ngModel)]="searchValueText" (keyup)="searchTest($event, price_type_id, category_id)" [placeholder]="'Search by test | profile code or name' | locale">
            <div class="row">

              <div class="col-12">
                <div class="table-responsive" style="height: 600px;">
                  <table class="table mt-3">
                  <thead
                  style="position: sticky;
                  top: 0;
                  z-index: 300;"
                  >
                    <tr class="neue-light roboto-400">
                      <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                      <th width="15%"  scope="col" class="col-2"> {{'code' | locale}}</th>
                      <th  scope="col" class="col-2"> {{'Name' | locale}}</th>
                      <th scope="col" class="col-2"> {{'Type' | locale}}</th>
                      <!-- <th scope="col" class="col-2">pricing </th> -->
                      <th scope="col" class="col-2">
                        {{'Price' | locale}}
                      </th>
                      <th scope="col" class="col-2">
                        {{'Discount' | locale}}
                      </th>
                      <th scope="col" class="col-2">
                        {{'Cost' | locale}}
                      </th>

                    </tr>



                  </thead>
                  <br>

                  <tbody class="neue-light mt-2">
                    <ng-container *ngFor="let test of testsArray | paginate
                    : {
                        id: 'update_pagination',
                        itemsPerPage: pagination.per_page,
                        currentPage: pagination.current_page,
                        totalItems: pagination.total
                      }; let i = index">

                       <tr style="height: auto;"  valign="middle"  class="clickable_row roboto-400 text-center">
                        <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                        <td class="col-2" >{{test.code}}</td>
                        <td class="col-2">{{test.name }}</td>
                        <td class="col-2">{{(test.isProfile?'Profile': test.isPackage? 'Package' : 'Test') | locale:2 }}</td>

                        <td class="col-2"><input name="{{test.id}}" value="{{test.amount}}" [(ngModel)]="test.amount" class="form-control testsIds"></td>
                        <td class="col-2">
                          <div class="input-group">
                            <input [ngClass]="{'rad-right' : !data.isRTL, 'rad-left' : data.isRTL}" max="100" min="0" minlength="1" maxlength="3" name="dis{{test.id}}" id="dis{{test.id}}" [(ngModel)]="test.discount" class="form-control" (keyup)="applay_discount($event, i, price_type_id)">
                              <div class="input-group-append">
                                <span  [ngClass]="{'rad-right' : data.isRTL, 'rad-left' : !data.isRTL}" class="input-group-text">%</span>
                              </div>
                          </div>
                        </td>
                        <td class="col-2"><input name="cost{{test.id}}" value="{{test.cost}}" [(ngModel)]="test.cost" class="form-control"></td>
                        <!-- <td class="col-2"></td> -->

                        <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                          <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                        </h5></a>
                        </td> -->
                        <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                      </tr>
                    </ng-container>

                    <!-- <div *ngIf="pagination" class="col-12 mt-3">
                      <div class="w-100 d-flex justify-content-center neue-med p-0">
                        <pagination-controls
                          pagination-templates
                          responsive="true"
                          [previousLabel]="'Previous' | locale"
                          [nextLabel]="'Next' | locale"
                          (pageChange)="getTests($event, '')"
                          id="update_pagination"
                        ></pagination-controls>
                      </div>
                    </div> -->

                  </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
              <button class="btn ripple btn-primary"
              form="update_prices_form"
              type="submit">{{'Update' | locale}}</button>
              <button class="btn ripple btn-secondary" data-dismiss="modal" type="button">{{'Cancel' | locale}}</button>
          </div>

        </form>
