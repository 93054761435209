import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { SnackService } from 'src/app/services/snack.service';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

declare var $: any;

@Component({
  selector: 'receptionist-layout-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  request_id: any;
  @Input() request;
  tests: any[] = [];
  profiles: any[] = [];
  result: Object;
  devices:Boolean=false;
  areas:Boolean=false;
  families:Boolean=false;
  directPrint:Boolean=false;
  healthCare:Boolean=false;
  insurance:Boolean=false;
  lab_info_header:Boolean=false;
  outside_note:Boolean=false;
  send_sms:Boolean=false;
  user_roles: any = []
  sms_counter = 0


  constructor(public api:ApiService, public route:ActivatedRoute,public snack : SnackService, public data:DataService) { }

  ngOnInit() {

    this.api.Common.Get.lab_info().subscribe(res =>{
      this.insurance = res['data']['settings']['insurance']
      if(res['data']['settings']['pdf'] && res['data']['settings']['pdf']['results']){
        let results = res['data']['settings']['pdf']['results']
        console.log('results ', results)
        this.devices = results['withDevices'] || false
        this.areas = results['withAreas'] || false
        this.families = results['withFamily'] || false
        this.lab_info_header = results['lab-info-header'] || false
      }
      if((res['data']['settings']['sms']['abunwas']['status'] || res['data']['settings']['sms']['isend']['status']) && (res['data']['sms_counter'] > 0)){
        this.sms_counter = res['data']['sms_counter']
        this.send_sms = true
      }
    })

    // this.data.infoAssigned.subscribe(isAssigned =>{
    //   if(isAssigned){
    //       if(this.data.info && this.data.info['settings'] && this.data.info['settings']['pdf'] && this.data.info['settings']['pdf']['results']){
    //         let results = this.data.info['settings']['pdf']['results']
    //         this.devices = results['withDevices'] || false
    //         this.areas = results['withAreas'] || false
    //         this.lab_info_header = results['lab-info-header'] || false
    //       }
    //   }
    // })


    this.route.params.subscribe(params => {


      this.request_id = params['request_id'];
      this.outside_note = this.request['is_outside_note']



    });


  }

  // printCasa(loc = null){
  //   this.api.Printables.PrintCasa(loc).subscribe(res => {

  //     const fileURL = URL.createObjectURL(res);
  //     window.open(fileURL, '_blank');

  //   }) //hamo
  // }
   printCasa(requestID, testID) {
    // console.log(loc);
    window.open(this.api.Common.base_print_report+"requests/"+requestID+"/tests/"+testID+"/casa", "_blank");

    // this.api.Common.casa.get(requestID,testID).subscribe((res) => {
      // console.log('res', res) //check with Hamo
      // const fileURL =  this.api.base_print_report + '/' + this.request['reception_number'] + '/' + res['location'];
      // window.open(fileURL, "_blank");
    // });
  }

  sendSms(){
    this.api.Common.requests.send_sms(this.request_id).subscribe(res => {
      // if(res['error'] == false){
        this.snack.show('The message sent seccussfully')
          this.api.Common.Get.lab_info().subscribe(res =>{
            this.sms_counter = res['data']['sms_counter']
          })
        $("#send-sms").modal('hide');
      // }
    })
  }


  isArray(d){
    return Array.isArray(d)
  }

  print(form:NgForm){

    let entities = Object.entries(form.value)

    let query = entities.length? '?' : ''
    entities.filter(one => one[1]).forEach(one => query += `tests_ids[]=${one[0]}&`)

    if(this.directPrint){
      this.api.Common.printables.print_result(this.request_id, this.devices,this.areas,this.families,this.lab_info_header,this.outside_note, this.healthCare, query).subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      })
    }else{
      this.api.Common.printables.PrintResultForZebra(this.request_id, this.devices,this.areas,this.families,this.lab_info_header,this.outside_note, this.healthCare, query).subscribe(
        (response) => {
          //Next callback
          console.log('response received')
        },
        (error) => {
            //Error callback
          console.error('error caught in component',error)
          this.api.Common.printables.print_result(this.request_id, this.devices,this.areas,this.families,this.lab_info_header,this.outside_note, this.healthCare, query).subscribe(res => {
            const fileURL = URL.createObjectURL(res);
            if(this.insurance && this.request.patient.insurance_id){
              const a = document.createElement('a');
              a.href = fileURL;
              const today = new Date();
              // a.download = this.request.patient.insurance_id+'-'+today.toISOString().split('T')[0];
              a.download = this.request.patient.insurance_id;
              a.click();
            }else{
              window.open(fileURL, '_blank');
            }
          })
        }
      )
    }
  }

  setDelivered(){
    this.api.Common.requests.toggle_delivry(this.request_id).subscribe(res => {
      if(!res['error']){
        this.snack.show('The request has been set to delivered')
      }
    })
  }



}
