<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{ "Activity log" | locale }}</h2>
    </div>
    <button
    *roles="{admin:true}"
      class="btn btn-outline-primary col-2"
      data-target="#print_modal"
      data-toggle="modal"
    >
      {{ "Print" | locale }}
    </button>
  </div>
</div>

<div class="card mb-5 mt-3">
  <div class="container-fluid py-3 px-4">
    <div class="row mt-2">
      <div class="col-12 col-lg-10">
        <div class="form-group m-0 roboto-400">
          <input
            type="text"
            class="form-control px-5"
            id="code"
            [placeholder]="'Search by request code' | locale"
          />

          <i class="fas search-icon fa-search"></i>
        </div>
      </div>

      <div class="col-12 col-lg-2 mt-3 mt-lg-0">
        <div class="d-flex w-100 justify-content-end">
          <button
            data-toggle="modal"
            data-target="#param-modal"
            type="button"
            id="add-button"
            class="btn btn-outline-primary w-100 roboto-400"
            (click)="activity_log()"
          >
            {{ "Search" | locale }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th style="width: 50px">{{ "ID" | locale: 2 }}</th>
                <th width="50%" scope="col">{{ "Action" | locale }}</th>
                <th width="50%" style="width: 200px">{{ "Date" | locale }}</th>
              </tr>
            </thead>
            <br />
            <tbody class="neue-light mt-2">
              <ng-container
                *ngFor="let activity of activity$ | async; let i = index"
              >
                <tr valign="middle" class="clickable_row roboto-400">
                  <td style="width: 50px">{{ i + 1 }}</td>
                  <td width="50%" scope="col">{{ activity.action }}</td>
                  <td width="50%" style="width: 200px">
                    {{ activity.created_at | date: "full" }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="print_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{ "Print" | locale }}</h5>
      </div>

      <div class="modal-body">
        <div>
          <div class="form-group">
            <label for="fromPicker">{{ "Date From" | locale }}</label>
            <input
              id="fromPicker"
              [(ngModel)]="from"
              class="flatpickr flatpickr-input active form-control"
              type="date"
              placeholder="Select Date.."
            />
          </div>
        </div>
        <div>
          <div class="form-group">
            <label for="toPicker">{{ "Date To" | locale }}</label>
            <input
              id="toPicker"
              [(ngModel)]="to"
              class="flatpickr flatpickr-input active form-control"
              type="date"
              placeholder="Select Date.."
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button (click)="print()" class="btn btn-primary col-2">Print</button>
      </div>
    </div>
  </div>
</div>
