<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title font-weight-bold" id="settingsModalLabel">
      {{ ms.title | locale }}
    </h5>
    <button
      style="margin: -1rem !important"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      id="modalCloser"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #msform="ngForm" id="msform" (ngSubmit)="onSubmit(msform)">
      <input type="hidden" name="id" [ngModel]="ms.datum['id']" />

      <div class="row neue-reg">
        <ng-container *ngFor="let field of textInputs">
          <div
            *ngIf="
              ms.inputs[field['called'] || field['name']] &&
              !(
                msform.value['type'] == 'income' &&
                field['name'] == 'paid_value'
              )
            "
            class="col-6"
          >
            <div class="form-group">
              <label
                style="display: block; text-align: start"
                [innerText]="field['label'] | locale"
              ></label>
              <input
                [required]="field['required']"
                [name]="field['name']"
                [pattern]="field['pattern']"
                [ngModel]="ms.datum[field['bind_name'] || field['name']]"
                #{{field.name}}_input="ngModel"
                [type]="field['type'] || 'text'"
                class="form-control"
                [placeholder]="field['placeholder'] | locale"
              />

              <!-- <small
                *ngIf="
                  (name_input.errors ? name_input.errors['required'] : false) &&
                  name_input.touched
                "
              >
                the first name is required
              </small> -->
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let field of multiSelectInputs">
          <div *ngIf="ms.inputs[field.called]" class="col-6">
            <label style="display: block; text-align: start">{{
              field.label | locale
            }}</label>
            <div
              placement="top"
              [ngbTooltip]="
                !field.data ? (field.disabled_message | locale) : null
              "
            >
              <ng-multiselect-dropdown
                [ngStyle]="field.data ? {} : { 'pointer-events': 'none' }"
                [settings]="{
                  singleSelection: false,
                  idField: 'id',
                  textField: 'name',
                  selectAllText: 'Select All',
                  unSelectAllText: 'UnSelect All',
                  itemsShowLimit: 3,
                  allowSearchFilter: true
                }"
                [placeholder]="field.label | locale"
                [data]="field.data"
                [disabled]="!field.data"
                ngModel
                [name]="field.key"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let field of dropdownInputs">
          <div
            *ngIf="ms.inputs[field['called'] || field['name']]"
            class="col-6"
          >
            <div class="form-group">
              <label
                style="display: block; text-align: start"
                [innerText]="field['label'] | locale"
              ></label>
              <select
                [required]="field['required']"
                [name]="field['key']"
                [ngModel]="
                  ms.datum[field['name']]|| ms.datum[field['key']] || field['options'][0]['id']
                "
                #{{field.name}}_input="ngModel"
                class="custom-select"
              >
                <option
                  *ngFor="
                    let one of field['name'] == 'device_id' && ms.devices.length
                      ? ms.devices
                      : field['options']
                  "
                  [ngValue]="one.id"
                >
                  {{ one.name | locale }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngFor="let field of dateInputs">
          <div
            [ngStyle]="{ display: ms.inputs[field['key']] ? 'block' : 'none' }"
            class="col-6"
          >
            <!-- <input class="form-control" type="date" ngModel name="date" /> -->
            <div class="form-group">
              <label
                style="display: block; text-align: start"
                [innerText]="field['label'] | locale"
                for="picker"
              ></label>
              <input
                [disabled]="!ms.inputs[field['key']]"
                [required]="field['required']"
                [name]="field['key']"
                [ngModel]="ms.datum[field['name']]"
                #{{field.name}}_input="ngModel"
                type="text"
                class="form-control"
                [id]="'date_' + field['iteration']"
                class="flatpickr flatpickr-input active form-control"
                type="text"
                [placeholder]="'Select a date' | locale"
              />
            </div>
          </div>
        </ng-container>

        <ng-container *ngFor="let flex of flexableInputs">
          <div
            *ngIf="
              ms.inputs[flex['name']] &&
              !(msform.value['type'] == 'income' && flex['name'] == 'provider')
            "
            [class]="flex.col"
          >
            <ng-container *ngIf="!formExtra[flex['key']]">
              <app-item-select
                (setSelectedItem)="setSelectedItem($event, flex['key'])"
                [title]="flex['name']"
              ></app-item-select>
            </ng-container>
            <ng-container *ngIf="formExtra[flex['key']]">
              <label
                style="display: block; text-align: start"
                [innerText]="flex['label'] | locale"
              ></label>
              <button
                (click)="removeSelectedItem(flex['key'], msform)"
                class="btn btn-light border col-12"
              >
                {{ formExtra[flex["key"]].name | locale }}
              </button>
            </ng-container>
          </div>
        </ng-container>
        <div class="col-12"></div>
        <ng-container *ngIf="ms.inputs['is_employees']">
          <div class="col-12">
            <div class="row neue-reg mt-3">
              <div class="col-lg-12">
                <selector
                  [unique]="cashiers_index"
                  title="employee"
                  (setSelectedItem)="setSelectedCashiers($event)"
                ></selector>
              </div>
              <div *ngIf="selectedCashiers?.length != 0" class="col-12">
                <div class="table-responsive">
                  <table class="table mt-3">
                    <thead>
                      <tr class="neue-light roboto-400">
                        <th width="15%" scope="col">{{ "#" | locale }}</th>
                        <th scope="col">{{ "Name" | locale }}</th>
                        <th scope="col">{{ "Remove" | locale }}</th>
                      </tr>
                    </thead>
                    <br />

                    <tbody class="neue-light mt-2">
                      <ng-container
                        *ngFor="let cashier of selectedCashiers; let i = index"
                      >
                        <tr style="height: fit-content;" valign="middle" class="clickable_row roboto-400">
                          <td>{{ cashier.id }}</td>
                          <td>{{ cashier.name }}</td>
                          <td>
                            <button
                              (click)="removeCashier(cashier)"
                              type="button"
                              class="btn btn-outline-danger"
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </td>
                       </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-lg-12" *ngIf="enabledUnits">
                <selector
                  [unique]="units_index"
                  title="units"
                  (setSelectedItem)="setSelectedUnits($event)"
                ></selector>
              </div>
              <div *ngIf="(selectedUnits?.length != 0) && enabledUnits" class="col-12">
                <div class="table-responsive">
                  <table class="table mt-3">
                    <thead>
                      <tr class="neue-light roboto-400">
                        <th width="15%" scope="col">{{ "#" | locale }}</th>
                        <th scope="col">{{ "Name" | locale }}</th>
                        <th scope="col">{{ "Remove" | locale }}</th>
                      </tr>
                    </thead>
                    <br />

                    <tbody class="neue-light mt-2">
                      <ng-container
                        *ngFor="let unit of selectedUnits; let i = index"
                      >
                        <tr style="height: fit-content;" valign="middle" class="clickable_row roboto-400">
                          <td>{{ unit.id }}</td>
                          <td>{{ unit.name }}</td>
                          <td>
                            <button
                              (click)="removeUnit(unit)"
                              type="button"
                              class="btn btn-outline-danger"
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </td>
                       </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-lg-12" *ngIf="enabledUnits">
                <selector
                  [unique]="categoires_index"
                  title="categoires"
                  (setSelectedItem)="setSelectedCategories($event)"
                ></selector>
              </div>
              <div *ngIf="(selectedCategories?.length != 0) && enabledUnits" class="col-12">
                <div class="table-responsive">
                  <table class="table mt-3">
                    <thead>
                      <tr class="neue-light roboto-400">
                        <th width="15%" scope="col">{{ "#" | locale }}</th>
                        <th scope="col">{{ "Name" | locale }}</th>
                        <th scope="col">{{ "Remove" | locale }}</th>
                      </tr>
                    </thead>
                    <br />

                    <tbody class="neue-light mt-2">
                      <ng-container
                        *ngFor="let category of selectedCategories; let i = index"
                      >
                        <tr style="height: fit-content;" valign="middle" class="clickable_row roboto-400">
                          <td>{{ category.id }}</td>
                          <td>{{ category.name }}</td>
                          <td>
                            <button
                              (click)="removeCategory(category)"
                              type="button"
                              class="btn btn-outline-danger"
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </td>
                       </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div class="col-lg-12" *ngIf="enabledUnits">
                <selector
                  [unique]="devices_index"
                  title="device"
                  (setSelectedItem)="setSelectedMethods($event)"
                ></selector>
              </div>
              <div *ngIf="(selectedMethods?.length != 0) && enabledUnits" class="col-12">
                <div class="table-responsive">
                  <table class="table mt-3">
                    <thead>
                      <tr class="neue-light roboto-400">
                        <th width="15%" scope="col">{{ "#" | locale }}</th>
                        <th scope="col">{{ "Name" | locale }}</th>
                        <th scope="col">{{ "Remove" | locale }}</th>
                      </tr>
                    </thead>
                    <br />

                    <tbody class="neue-light mt-2">
                      <ng-container
                        *ngFor="let method of selectedMethods; let i = index"
                      >
                        <tr style="height: fit-content;" valign="middle" class="clickable_row roboto-400">
                          <td>{{ method.id }}</td>
                          <td>{{ method.name }}</td>
                          <td>
                            <button
                              (click)="removeMethod(method)"
                              type="button"
                              class="btn btn-outline-danger"
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </td>
                       </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let check_box of check_boxes">
          <div
            style="text-align: start"
            *ngIf="ms.inputs[check_box['key']]"
            class="col-12 col-md-3 mt-3"
          >
            <!-- <input type="checkbox" class="" id="exampleCheck1"> -->
            <div class="form-group form-check">
              <input
                [name]="check_box.key"
                [ngModel]="
                  ms.datum[check_box.key] == '✖'
                    ? false
                    : ms.datum[check_box.key] == '✔'
                    ? true
                    : ms.datum[check_box.key] || false
                "
                type="checkbox"
                class="form-check-input"
                [id]="'customCheck_' + check_box.key"
              />
              <label
                class="form-check-label"
                [ngClass]="{ 'mr-4': dataService.isRTL }"
                [innerText]="check_box.name | locale"
                for="exampleCheck1"
              ></label>
            </div>

            <!-- <div class="custom-control custom-checkbox">
              <label style="display: block;text-align: start;" [innerText]="check_box.name | locale" class="custom-control-label" [for]="'customCheck_' + check_box.key "
                ></label
              >
            </div> -->
          </div>
        </ng-container>
      </div>


      <ng-container *ngIf="ms.inputs['isPackage']">
        <div class="col-lg-12 m-0 p-0">
          <label for="exampleInputEmail1">{{'Tests' | locale}}</label>



          <selector
          title="tests"
          [no_label]="true"
          (setSelectedItem)="setPackageTests($event)"
          >


          </selector>
        </div>

        <div *ngIf="package_tests.length" class="col-12">
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <th width="15%" scope="col">{{'code' | locale}}</th>
                  <th scope="col">{{'Name' | locale}}</th>
                  <th scope="col">{{'Order' | locale}}</th>
                  <th scope="col">{{'Remove' | locale}}</th>
                </tr>
              </thead>
              <br />

              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let test of package_tests;let i =index">
                  <tr valign="middle" class="clickable_row roboto-400">
                    <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                    <td>{{ test.code }}</td>
                    <td>{{ test.name }}</td>
                    <td>
                      <input type="text" class="form-control" [name]="'order_' + i" style="width: 40px!important;" [(ngModel)]="test['order']">
                    </td>

                    <td>
                      <button
                        (click)="removePackageTest(test)"
                        type="button"
                        class="btn btn-outline-danger"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </td>

                    <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                    <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

      </ng-container>


      <ng-container *ngIf="ms.inputs['is_employees']">
        <div
        *ngIf="msform.value['isAdmin']"
        style="overflow-y: hidden !important"
        [@inOutAnimation]="{
          params: {
            height: admin_height + 'px',
            time: admin_height + admin_height + 200
          },
          value: true
        }"
        class="row mt-2"
      >
        <div class="col">
          <fieldset class="border p-2">
            <legend class="w-auto start">{{ "Admin" | locale }}</legend>
            <div class="row">
              <ng-container *ngFor="let userRole of user_roles_to_use">
                <div class="col-md-4" *ngIf="userRole.role_panel == 'admin'">
                  <div class="form-group form-check start">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [name]="'sub' + userRole.id"
                      [(ngModel)]="userRole.checked"
                      [ngModelOptions]="{ standalone: true }"

                      [id]="'customCheckBox' + userRole.id"
                      [value]="false"
                      />
                      <!-- (change)="subRolesArrPusher(userRole)" -->
                      <!-- [checked]="addedSubRoles[userRole.id]" -->
                    <label
                      class="form-check-label"
                      [ngClass]="{ 'mr-4': dataService.isRTL }"
                      [for]="'customCheckBox' + userRole.id"
                      >{{
                        name_fixer[userRole.name] || userRole.name
                          | titlecase
                          | locale
                      }}</label
                    >
                  </div>
                </div>
              </ng-container>
            </div>
          </fieldset>
        </div>
      </div>

      <div
        *ngIf="msform.value['isTechnician']"
        style="overflow-y: hidden !important"
        [@inOutAnimation]="{
          params: {
            height: tech_height + 'px',
            time: tech_height + tech_height + 200
          },
          value: true
        }"
        class="row mt-2"
      >
        <div class="col">
          <fieldset class="border p-2">
            <legend class="w-auto start">{{ "Technician" | locale }}</legend>
            <div class="row">
              <ng-container *ngFor="let userRole of user_roles_to_use">
                <div
                  class="col-md-4"
                  *ngIf="userRole.role_panel == 'technician'"
                >
                  <div class="form-group form-check start">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [name]="'sub' + userRole.id"
                      [(ngModel)]="userRole.checked"
                      [ngModelOptions]="{ standalone: true }"
                      [id]="'customCheckBox' + userRole.id"
                      [value]="false"
                      />
                      <!-- [checked]="addedSubRoles[userRole.id]" -->
                      <!-- (change)="subRolesArrPusher(userRole)" -->
                    <label
                      class="form-check-label"
                      [ngClass]="{ 'mr-4': dataService.isRTL }"
                      [for]="'customCheckBox' + userRole.id"
                      >{{
                        name_fixer[userRole.name] || userRole.name
                          | titlecase
                          | locale
                      }}</label
                    >
                  </div>
                </div>
              </ng-container>
            </div>
          </fieldset>
        </div>
      </div>

      <div
        *ngIf="msform.value['isReceptionist']"
        style="overflow-y: hidden !important"
        [@inOutAnimation]="{
          params: {
            height: rec_height + 'px',
            time: rec_height + rec_height + 200
          },
          value: true
        }"
        class="row mt-2"
      >
        <div class="col">
          <fieldset class="border p-2">
            <legend class="w-auto start">{{ "Receptionist" | locale }}</legend>
            <div class="row">
              <ng-container *ngFor="let userRole of user_roles_to_use">
                <div
                  class="col-md-4"
                  *ngIf="userRole.role_panel == 'receptionist'"
                >
                  <div class="form-group form-check start">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [name]="'sub' + userRole.id"
                      [(ngModel)]="userRole.checked"
                      [ngModelOptions]="{ standalone: true }"
                      [id]="'customCheckBox' + userRole.id"
                      [value]="false"
                      />
                      <!-- [checked]="addedSubRoles[userRole.id]" -->
                      <!-- (change)="subRolesArrPusher(userRole)" -->
                    <label
                      class="form-check-label"
                      [ngClass]="{ 'mr-4': dataService.isRTL }"
                      [for]="'customCheckBox' + userRole.id"
                      >{{
                        name_fixer[userRole.name] || userRole.name
                          | titlecase
                          | locale
                      }}</label
                    >
                  </div>
                </div>
              </ng-container>
            </div>
          </fieldset>
        </div>
      </div>
      </ng-container>


      <ng-container *ngIf="ms.inputs['is_pricing'] && dataService.pricings.length && !(dataService.pricings.length == 1 && ms.datum['id'])">
        <div class="row mt-2">
          <div class="col">
            <fieldset class="border p-2">
              <legend class="w-auto start">
                <div class="col-md-12">
                  <div class="form-group form-check start">
                    <input
                      type="checkbox"
                      class="form-check-input my-auto"
                      [ngModel]="!!ms.datum.isCheck"
                      name="isCheck"
                      style="height: 100%;"
                    />
                    <label
                      class="form-check-label"
                      [ngClass]="{ 'mr-4': dataService.isRTL }"
                      for="isCheck"
                      >{{ "Add from existed Pricing(+/-)" | locale }}</label
                    >
                  </div>
                </div>                  </legend>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pricing">{{ "Pricing" | locale }}</label>
                      <select
                        [ngModel]="ms.datum.inhert_price_id || dataService.pricings[dataService.pricings[0].id == ms.datum.id? 1:0].id"
                        name="inhert_price_id"
                        class="custom-select"
                        [disabled]="!msform.value['isCheck']"
                      >
                      <ng-container *ngFor="let price of dataService.pricings">
                        <option
                          *ngIf="price.id != ms.datum.id"
                          [value]="price.id"
                        >
                          {{ price.name }}
                        </option>
                      </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        {{ "Type" | locale }}</label
                      >
                      <select
                        name=""
                        class="form-control"
                        [disabled]="!msform.value['isCheck']"
                        [ngModel]="ms.datum.type || 'ratio'"
                        name="type"
                        #type_input="ngModel"
                      >
                        <option value="ratio">
                          {{ "Percentage" | locale: 2 }}
                        </option>
                        <option value="value">
                          {{ "Value" | locale: 2 }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1"> +/-</label>
                      <select
                        name=""
                        class="form-control"
                        [disabled]="!msform.value['isCheck']"
                        [ngModel]="ms.datum.direction || 'increase'"
                        name="direction"
                      >
                        <option value="increase">
                          {{ "Increase" | locale }}
                        </option>
                        <option value="decrease">
                          {{ "Decrease" | locale }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        {{ "Amount" | locale: 2 }}</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        [placeholder]="'Amount' | locale: 2"
                        [disabled]="!msform.value['isCheck']"
                        [ngModel]="ms.datum.amount"
                        name="amount"
                        min="0"
                        max="100"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group form-check start">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        [ngModel]="!!ms.datum.isAppro"
                        #isApprox_input="ngModel"
                        [disabled]="!msform.value['isCheck']"
                        name="isAppro"
                        value="1"
                      />
                      <label
                        class="form-check-label"
                        [ngClass]="{ 'mr-4': dataService.isRTL }"
                        for="isAppro"
                        >{{ "Rounding" | locale }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="ms.inputs['is_antibiotic']">
        <ng-container *ngIf="bacterias.length">
          <div class="row neue-reg">
            <div class="col-lg-12">
                <p
                  class="col-12 start text-dark h5 my-3 py-2 border-bottom"
                >
                  {{ "Bacteria" | locale }}
                </p>
            </div>
          </div>
          <div class="row">
            <ng-container *ngFor="let bacteria of bacterias; index as i">
              <div
                style="text-align: start"
                class="col-3 col-md-3 col-lg-3 mt-3"
              >
                <div class="form-group form-check">
                  <input
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="bacterias_ids[bacteria.id]"
                    type="checkbox"
                    [id]="'customCheck' + bacteria.id"
                    class="form-check-input"
                  />
                  <label
                    class="form-check-label"
                    [ngClass]="{ 'mr-4': dataService.isRTL }"
                    [innerText]="bacteria.name | titlecase"
                  ></label>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="ms.inputs['is_device'] && ms.datum as device">
        <div class="row neue-reg">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="cats">{{ "Categories" | locale }}</label>
              <select
                [ngModel]="device.category?.id || 'null'"
                [ngModelOptions]="{ standalone: true }"
                id="cats"
                class="custom-select"
                (change)="testsByCatagory($event.target.value)"
              >
                <option
                  *ngFor="let category of [{ name: 'None', id: null }].concat(dataService.categories)"
                  [value]="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>

          <ng-container *ngIf="tests.length">
            <p
              class="col-12 start text-dark h5 my-3 py-2 border-bottom"
            >
              {{ "Tests" | locale }}
            </p>

            <ng-container *ngFor="let test of tests; index as i">
              <div
                style="text-align: start"
                class="col-12 col-md-6 col-lg-4 mt-3"
              >
                <!-- <input type="checkbox" class="" id="exampleCheck1"> -->
                <!-- [name]="test.id" -->
                <div class="form-group form-check">
                  <input
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="devices_tests_ids[test.id]"
                    type="checkbox"
                    [id]="'customCheck' + test.id"
                    class="form-check-input"
                  />
                  <label
                    class="form-check-label"
                    [ngClass]="{ 'mr-4': dataService.isRTL }"
                    [innerText]="test.name | titlecase"
                  ></label>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="modal-footer">
    <div *ngIf="sureState" class="alert alert-danger mx-auto" role="alert">
      {{ "Are you sure?" | locale }}
      <button
        Loading
        [loadingState]="deleting"
        loadingColor="danger"
        (click)="remove()"
        class="mx-2 btn-danger btn-sm clickable"
      >
        {{ "Delete" | locale }}</button
      ><button
        (click)="sureState = false"
        class="ml-2 btn-sm btn-light clickable"
      >
        {{ "Cancel" | locale }}
      </button>
    </div>
    <ng-container *ngIf="!sureState">
      <button
        *ngIf="ms.action == 'Update'"
        type="button"
        (click)="sureState = true"
        class="btn btn-danger"
      >
        {{ "Delete" | locale }}
      </button>
      <button
      *ngIf="!destruction"
        [loadingState]="submitting"
        Loading
        form="msform"
        type="submit"
        class="btn btn-primary"
      >
        {{ ms.action | locale }}
      </button>
    </ng-container>

    <!-- <button
      id="modalCloser"
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"

    >
      {{'Cancel' | locale}}
    </button>
    <button form="msform" type="submit" class="btn btn-primary">
      {{ms.action| locale}}
    </button> -->
  </div>
</div>
