<div class="row">
  <div class="col">
    <h2 class="roboto-300 start">{{ "Users" | locale }}</h2>
  </div>
</div>

<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <div class="row mt-2">
      <div class="col-12 col-lg-2 mt-3 mt-lg-0">
        <div class="d-flex w-100 justify-content-end">
          <button
            type="button"
            id="add-button"
            class="btn btn-outline-primary w-100 roboto-400"
            (click)="
              ms.assign({
                title: 'Add a User',
                route: null,
                datum: {},
                inputs: {
                  username: true,
                  first_name: true,
                  last_name: true,
                  password: true,
                  gender: true,
                  doctor: true,
                  isAdmin: true,
                  isReceptionist: true,
                  isTechnician: true,
                  isAccountant: true,
                  is_employees: true
                },
                method: api.Admin.users.add,
                message: 'User was added successfully',
                onSuccess: getUsers.bind(this)
              })
            "
          >
            {{ "Add" | locale }} +
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                <th width="8%" scope="col">{{ "ID" | locale: 2 }}</th>
                <th width="12.5%" scope="col">{{ "First Name" | locale }}</th>
                <th width="12.5%" scope="col">{{ "Last Name" | locale }}</th>
                <th width="12.5%" scope="col">{{ "Username" | locale }}</th>
                <th width="12.5%" scope="col">{{ "Role" | locale }}</th>
                <!-- <th class="text-center" width="12.5%" scope="col">
                  {{ "Technical Supervisor" | locale }}
                </th> -->
                <!-- <th scope="col"> Histopathology Expert</th> -->

                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>
            </thead>
            <br />

            <tbody class="neue-light mt-2">
              <ng-container
                *ngFor="
                  let user of data.me['id'] == 1 ? users : (users | slice: 1);
                  let i = index
                "
              >
                <tr
                  style="height: auto"
                  (click)="
                    ms.assign({
                      title: 'Edit a User',
                      action: 'Update',
                      route: null,
                      datum: user,
                      inputs: {
                        username: true,
                        first_name: true,
                        last_name: true,
                        password: true,
                        gender: true,
                        doctor: true,
                        isAdmin: true,
                        isReceptionist: true,
                        isTechnician: true,
                        isAccountant: true,
                        is_employees: true
                      },
                      getDatum: api.Admin.users.getOne.bind(this, user.id),
                      method: api.Admin.users.update,
                      message: 'User was updated successfully',
                      onSuccess: getUsers.bind(this),
                      deleteMethod: api.Admin.users.remove,
                      deleteMessage: 'User was deleted successfully'
                    })
                  "
                  valign="middle"
                  class="clickable_row roboto-400"
                >
                  <td scope="row">{{ i + 1 }}</td>
                  <!-- <td width="8%" align="left">{{user.id }}</td> -->
                  <td width="12.5%">{{ user.first_name }}</td>
                  <td width="12.5%">{{ user.last_name }}</td>
                  <td width="12.5%">{{ user.username }}</td>
                  <td
                    style="display: flex; flex-direction: column"
                    width="12.5%"
                  >
                    <div *ngFor="let role of user.roles">
                      {{ role.name | locale }}
                    </div>
                  </td>
                  <!-- <td width="12.5%">
                    {{ user.is_technical_validator ? "✔" : "✖" }}
                  </td> -->
                  <!-- <td>{{user.is_histo_expert? 'Yes':'No' }}</td> -->

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <router-outlet></router-outlet> -->
