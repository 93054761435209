import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProgressService } from 'src/app/services/progress.service';
import { DataService } from 'src/app/services/data.service';
import { SnackService } from "src/app/services/snack.service";
declare var $:any

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  ticket:any;
  replies:any;
  message = ''
  ticket_id = null
  ticket_status = true

  constructor(public snack: SnackService, private activatedRoute: ActivatedRoute,private data:DataService, public router:Router,public api : ApiService,public progress : ProgressService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      setInterval(() => {
        this.getTicket(param.id);
      }, 3000);
      this.ticket_id = param.id
    })
  }


  getTicket(id){    
    
    // this.progress.on();
    this.api.Admin.supports.lab_ticket_replies(id).subscribe(res => {
      this.ticket = res
      this.replies = res['replies']
      if(res['status'] == 'closed')
      this.ticket_status = false
      // this.progress.off();
    });

  }

  onSubmit(form){
    if (form.valid) {
      let vals = form.value;
      if (!vals["message"])
        return this.snack.showerror("Please enter message");
        this.api.Admin.supports.add_ticket_reply(form.value, this.ticket_id).subscribe(res =>{
          if(!res['error']){
            this.message = ""
            this.getTicket(this.ticket_id)
          }
        })
    }
  }
}

