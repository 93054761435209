




  <div class="row justify-content-between">
    <div class="col">
      <h2 class="roboto-300 start">{{'Reports' | locale}}</h2>
    </div>

    <div>
      <div class="container-fluid">
        <div class="row">
          <button (click)='printInvoice()' class="btn btn-outline-primary mx-2" style="width:200px" >{{'Info Bill' | locale}}</button>
          <!-- <button (click)='print()' class="btn btn-outline-primary" style="width:200px" >{{'Print' | locale}}</button> -->
          
          <button
          type="button"
          class="btn btn-outline-primary"
          style="width:200px"
          data-target="#print-report"
          data-toggle="modal"
        >
          {{'Print' | locale}}
        </button>

        </div>

        <div class="start">
          <div class="form-group form-check mb-0 mt-2">
            <input
              type="checkbox"
              name="isSeparate"  [(ngModel)]="isSeparate"
              class="form-check-input"
              id="customCheck1"
            />
            <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="customCheck1">{{'Info Bill Separate Pages' | locale}}</label>
          </div>

          <!-- <div class="form-group form-check mb-0 mt-2">
            <input
              type="checkbox"
              name="isExcel"  [(ngModel)]="isExcel"
              class="form-check-input"
              id="customCheck2"
            />
            <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="customCheck2">{{'Excel' | locale}}</label>
          </div>

          <div class="form-group form-check mb-0 mt-2">
            <input
              type="checkbox"
              name="withDetails"  [(ngModel)]="withDetails"
              class="form-check-input"
              id="withDetails"
            />
            <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="withDetails">{{'With Details' | locale}}</label>
          </div> -->
        </div>

      </div>
    </div>


    <!-- <div class="custom-control mt-2  custom-checkbox ">
      <input name="urgent_flag"  [(ngModel)]="isSeparate"   type="checkbox"
             class="custom-control-input" id="customCheck2">
      <label class="custom-control-label" for="customCheck2">Info Bill Separate Pages</label>

    </div>

    <div class="custom-control  custom-checkbox ">
      <input name="urgent_flag"  [(ngModel)]="isExcel"   type="checkbox"
             class="custom-control-input" id="customCheck1">
      <label class="custom-control-label" for="customCheck1">EXCEL</label>

    </div> -->
  </div>



  <div class="card mt-4 mb-5">
    <div class="container-fluid py-3 px-4">


      <div   class="row mt-2">
        <div class="col-12 mx-auto">
          <div class="container">
            <div class="row">
              <div *ngFor="let key of globals_keys" class="col p-2">
                <div class="card">

                  <div class="card-body p-2 px-3">
                    <h5 class="mb-0 pb-0 text-dark start">
                      {{key | titlecase | locale}}
                    </h5>
                    <p class="mb-0 pb-0 start">{{payments[key]}}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <ng-container *ngFor="let filter of filters">
          <div *roles="filter.roles || {all:true}" [class]="filter.col + ' my-2'"  >
            <label>{{filter.label | locale}}</label>
          <div *ngIf="filter['type'] == 'text'" class="form-group m-0 roboto-400">
            <input type="text"
            autocomplete="off"
            (keyup)="getData(1)"
            [(ngModel)]="filter.value"
             class="form-control" id="param_autocomplete"
            [placeholder]="filter.placeholder | locale">
            <!-- <i [hidden]="!filter.isLoaderHidden" class="fas search-icon fa-search"></i>
            <div [hidden]="filter.isLoaderHidden" class="spinner-border text-primary load" role="status">
              <span class="sr-only ">Loading...</span>
            </div> -->
          </div>

          <div *ngIf="filter['type'] == 'select'" class="form-group m-0 roboto-400">

            <select
            [disabled]="!filter.options.length"
              class="custom-select" [(ngModel)]="filter.value"
              (change)="getData(1)"
            >
              <option
                *ngFor="let one of filter.options"
                [ngValue]="one.value"
              >
                {{ one.key | locale }}
              </option>
            </select>
            <!-- <div [hidden]="filter.isLoaderHidden" class="spinner-border text-primary load" role="status">
              <span class="sr-only ">Loading...</span>
            </div> -->
          </div>

<!--
            <div *ngIf="filter['type'] == 'flex'">
              <ng-container *ngIf="!filter.value">
                <admin-layout-item-select
                    [title]="filter.name"
                    [no_label]="true"
                    (setSelectedItem)="set_Item($event, filter)"
                  ></admin-layout-item-select>
              </ng-container>
                <ng-container *ngIf="this[filter.assigned_to]">
                  <button (click)="set_Item(null, filter)" class="btn btn-light border col-12">{{this[filter.assigned_to].name}}</button>
                </ng-container>
            </div> -->

          <div *ngIf="filter['type'] == 'date'" class="form-group m-0 roboto-400">
            <input
                type="text"
                class="form-control"
                [id]="filter.key"
                (change)="getData(1)"
                [(ngModel)]="filter.value"
                class="flatpickr flatpickr-input active form-control"
                placeholder="Select Date.."
              />
          </div>
          <div *ngIf="filter['type'] == 'time'" class="form-group m-0 roboto-400">
            <input
                type="time"
                class="form-control"
                [id]="filter.key"
                (change)="getData(1)"
                [(ngModel)]="filter.value"
                class="flatpickr flatpickr-input active form-control"
                placeholder="Select Time.."
              />
          </div>

      </div>
    </ng-container>

    <div class="col-lg-4 my-2">
      <selector  [no_margin]="true" title="tests" [datum]="selectedTest" (setSelectedItem)="getData(1)" ></selector>

    </div>


  </div>

      <div class="row ">

        <div class="col-12">
          <div class="table-responsive  ">
          <h6 class="start mt-2">{{'Requests' | locale}}: <span>{{number_of_requests}}</span></h6>
          <!-- <div class="custom-control  custom-checkbox ">
            <input  [(ngModel)]="selectAll"   type="checkbox"class="custom-control-input" id="customCheck">
            <label class="custom-control-label" for="customCheck">select all Requests</label>
          </div> -->
            <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th  style="width: 110px;"  scope="col">{{'ID' | locale:2}}</th>
                <th  style="width: 110px;"  scope="col">{{'Request #' | locale}}</th>
                <th  style="width: 100px;"  scope="col"> {{'Date' | locale}}</th>
                <th   scope="col"style="width: 200px;"> {{'Patient' | locale}}</th>
                <th   scope="col"style="width: 200px;"> {{'Doctor' | locale}}</th>
                <th   scope="col"style="width: 200px;"> {{'User' | locale}}</th>
                <!-- <th   scope="col"style="width: 200px;"> {{'Patient' | locale}}</th> -->
                <th class="start" style="width: 130px;" scope="col"> {{'Hospital ID' | locale}} </th>
                <th  style="width: 100px;" scope="col"> {{'Correspondent' | locale}}</th>
                <th  style="width: 100px;" scope="col"> {{'Main Correspondent' | locale}}</th>
                <th class="start" style="width: 160px;" scope="col"> {{'Correspondent ID' | locale}}</th>
                <!-- <th  style="width: 500px;" scope="col"> Status</th> -->

                <th  style="width: 30px;" scope="col"> {{'Total' | locale}}</th>
                <th  style="width: 30px;" scope="col"> {{'Discount' | locale}}</th>
                <th  style="width: 30px;" scope="col" *ngIf="isTax"> {{'Tax' | locale}}</th>
                <th  style="width: 30px;" scope="col"> {{'Charged' | locale}}</th>
                <th  style="width: 30px;" scope="col"> {{'Paid' | locale}}</th>
                <th style="width: 30px;" scope="col"> {{'Remaining' | locale}}</th>
                <th style="width: 30px;" scope="col"> {{'Tests' | locale}}</th>
                <th style="width: 30px;" scope="col"> {{'Deleted Tests' | locale}}</th>
                <th style="width: 30px;" scope="col"> {{'Delete' | locale}}</th>
                <th *roles="{admin:true}" style="width: 30px;" scope="col"> </th>
              </tr>



            </thead>
            <tbody class="neue-light ">
              <ng-container *ngFor="let request of requests | paginate: { id:'pagination' ,
              itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.totalItems }; let i = index">

                 <tr valign="middle" (click)="editRequest($event,request)" class="clickable_row roboto-400" [ngClass]="{'urgent':request.urgent_flag}" style="justify-content: center!important;">
                  <td style="width: 110px;">{{i + 1}}</td>
                  <td style="width: 110px;">{{request.reception_number}}</td>
                  <td style="width: 100px;">{{request.created_at }}</td>
                  <td style="width: 200px;">{{request.patient_name }}</td>
                  <td style="width: 200px;">{{request.doctor ? request.doctor.name : '✖' }}</td>
                  <td style="width: 200px;">{{request.user ? request.user.name : '✖' }}</td>
                  <td style="width: 130px;">{{request.request_hospital_id || '✖' }}</td>
                  <td style="width: 100px;">{{request.correspondent_name || 'OPD' }}</td>
                  <td style="width: 100px;">{{request.subcategory_name || '✖' }}</td>
                  <td style="width: 160px;">{{request.patient_insurance_id || '✖' }}</td>
                  <!-- <td style="width: 30px;" > {{request.status == 'deleted' ? 'Deleted' : 'Active' }}</td> -->
                  <td style="width: 30px;">{{request.payment?.total }}</td>
                  <td style="width: 30px;">{{request.payment?.discount }}</td>
                  <td style="width: 30px;" *ngIf="isTax">{{request.payment?.tax_avg }}%</td>
                  <td style="width: 30px;">{{request.payment?.total_summery }}</td>
                  <td style="width: 30px;">{{(request.payment?.total_summery || 0) - (request.payment?.remaining || 0) }}</td>
                  <td style="width: 30px;">{{request.payment?.remaining }}</td>
                  <td style="width: 30px;">
                      <button
                        type="button"
                        (click)="$event.stopPropagation();openActiveTest(request.request_id)"
                        class="btn btn-outline-primary p-0 px-1"
                      >
                        <i class="fas fa-pen"></i>
                      </button>
                    </td>
                  <td style="width: 30px;">
                      <button
                        type="button"
                        (click)="$event.stopPropagation();openDeletedTest(request.request_id)"
                        class="btn btn-outline-warning p-0 px-1"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </td>
                  <td *roles="{admin:true}"  style="width: 30px;">
                      <button
                        type="button"
                        (click)="$event.stopPropagation();remove(request.request_id, i)"
                        class="btn btn-outline-danger p-0 px-1"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </td>
                      <td style="width: 30px;" >
                      <!-- <div class="custom-control  custom-checkbox "> -->
                        <input   (click)="$event.stopPropagation();addIdRequestForPrintInvoice(request.request_id)" type="checkbox" [id]="request.id"  >
                        <!-- <label class="custom-control-label"  for="customCheck3">select all Requests</label> -->

                      <!-- </div> -->

                  </td>
                  <!-- <td style="width: 70px;">{{request.total }}{{data.info['currency']}}</td> -->
                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>



            </tbody>


            </table>

          </div>
        </div>


          <div class="col-12 mt-3">
              <div class="w-100 d-flex justify-content-center neue-med p-0">
                  <pagination-controls
                  responsive="true"
                  [previousLabel]="'Previous' | locale"
                  [nextLabel]="'Next' | locale"
                   (pageChange)="getData($event)"
                   id="pagination"></pagination-controls>
              </div>
          </div>



      </div>


    </div>
  </div>


<div
  class="modal fade"
  id="deletedTests"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{'Deleted Tests' | locale}}</h5>
      </div>

      <div class="modal-body">

        <div class="table-responsive  ">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th  scope="col" class="col-2"> {{'Code' | locale}}</th>
                <th  scope="col" class="col-8"> {{'Name' | locale}}</th>
                <th  scope="col" class="col-2"> {{'Reason' | locale}}</th>
              </tr>
            </thead>
            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let deletedTest of requestDeletedTests ">
                <tr valign="middle"  class="clickable_row roboto-400">
                  <td class="col-2" >{{deletedTest.code}}</td>
                  <td class="col-8" >{{deletedTest.name}}</td>
                  <td class="col-2" >{{deletedTest.reason}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
      </div>
    </div>
  </div>
  </div>
</div>

<div
  class="modal fade"
  id="activeTests"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{'Tests' | locale}}</h5>
      </div>

      <div class="modal-body">

        <div class="table-responsive  ">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th  scope="col" class="col-2"> {{'Code' | locale}}</th>
                <th  scope="col" class="col-8"> {{'Name' | locale}}</th>
                <th  scope="col" class="col-2"> {{'Price' | locale}}</th>
              </tr>
            </thead>
            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let activeTest of requestActiveTests ">
                <tr valign="middle"  class="clickable_row roboto-400">
                  <td class="col-2" >{{activeTest.code}}</td>
                  <td class="col-8" >{{activeTest.name}}</td>
                  <td class="col-2" >{{activeTest.test_current_price}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
      </div>
    </div>
  </div>
  </div>
</div>


<div
  class="modal fade"
  id="print-report"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{'Select tests to print' | locale}}</h5>
      </div>

      <div class="modal-body">
        <div class="row">

          <div class="d-flex h-100 align-items-center start">

            <div class="form-group form-check">
              <input
                  type="checkbox"
                  name="withCorrespondentId"  [(ngModel)]="withCorrespondentId"
                  class="form-check-input"
                  id="withCorrespondentId"
                />
                <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="withCorrespondentId">{{'With Correspondent Id' | locale}}</label>
            </div>

          </div>

          <div class="d-flex h-100 align-items-center start">

            <div class="form-group form-check">
              <input
                  type="checkbox"
                  name="withDetails"  [(ngModel)]="withDetails"
                  class="form-check-input"
                  id="withDetails"
                />
                <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="withDetails">{{'With Details' | locale}}</label>
            </div>

          </div>

          <div class="d-flex h-100 align-items-center start">

            <div class="form-group form-check">
              <input
                  type="checkbox"
                  name="withTests"  [(ngModel)]="withTests"
                  class="form-check-input"
                  id="withTests"
                />
                <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="withTests">{{'With Tests' | locale}}</label>
            </div>

          </div>
          
          <div class="d-flex h-100 align-items-center start">

            <div class="form-group form-check">
              <input
                  type="checkbox"
                  name="isExcel"  [(ngModel)]="isExcel"
                  class="form-check-input"
                  id="customCheck2"
                />
                <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="customCheck2">{{'Excel' | locale}}</label>
            </div>

          </div>
        </div>
      </div>

      <div class="modal-footer">

        <div class="row">
          <button (click)='print()' class="btn btn-primary">
            {{'Print' | locale}}
          </button>
        </div>

      </div>
    </div>
  </div>
</div>