import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $:any


@Component({
  selector: 'admin-layout-pricing-list',
  templateUrl: './pricing-list.component.html',
  styleUrls: ['./pricing-list.component.scss']
})
export class PricingListComponent implements OnInit {

  pricing: any [] = [];
  action: any = {};



  constructor(private data:DataService, public router:Router,public api : ApiService,public progress : ProgressService, public ms:ModalService) { }

  ngOnInit() {
    this.getPricing();

    this.data.remove.subscribe(({id,type}) => {
      if(type == "pricing"){
        this.pricing = this.pricing.filter(one => one.id != id)
        $("#pricing-modal").modal('hide');
      }
    })

  }


  getPricing(){

    // $('.dropdown-menu').dropdown('show');
    this.progress.on();

    this.api.Common.Get.pricings().subscribe(res => {
      this.progress.off();
      this.pricing = res
      this.data.pricings = res
    })


  }





}

