import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
declare let $;

@Component({
  selector: 'technician-layout-detials',
  templateUrl: './detials.component.html',
  styleUrls: ['./detials.component.scss']
})
export class DetialsComponent implements OnInit {

  request: any = {
    patient: null,
    gender: null,
    correspondent: null,
    hospitalization_date: null,
    clinical_information: null,
    laboratory_information: null,
    urgent_flag: null,
    is_repeat: null,
    patient_details: {
      age: null,
      birth_year: null,
      birth_month: null,
      birth_day: null,
      phone_number: null,
      insurance_id: null,
      hospital_id: null
    },
    pricing: {
      name: null
    }
  };
  categories: Array<any> = []
  single_sticker:boolean = false;
  total: any;
  remaining: any;
  @Input() patientId: any;
  @Input() request_id: any;

  constructor(public api : ApiService,private route : ActivatedRoute) { }
  ngOnChanges(changes){

    this.getRequest();

 }

 ngOnInit() {

 }


 categories_select_all(unselect){
   this.categories.forEach(category => {
     let element = document.getElementById('categories\|' + category.id)
     if(element['checked'] == unselect) element.click()
   })
 }

 getRequest(){
   this.api.Common.requests.get_one(this.request_id).subscribe(res =>{
     console.log(res);
     this.request =  res

   })
 }

}
