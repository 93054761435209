


  <div class="container-fluid py-3 px-4">



    <div class="row mt-2">

      <div class="col-12">
        <div class="form-group m-0">

          <div class="input-group">
            <!-- <div class="input-group-prepend">
          <span>
          </span>
        </div> -->
            <div class="input-group-prepend">
              <button
                class="btn btn-outline-primary form-control"
                [ngClass]="{
                  'rad-left': data.isRTL,
                  'rad-right': !data.isRTL
                }"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                [innerHTML]="
                  (type == 'hospital_id'
                    ? 'Hospital ID'
                    : (type | titlecase)
                  ) | locale
                "
              ></button>
              <div class="dropdown-menu">
                <a
                  *ngFor="
                    let key of [
                      'name',
                      'phone',
                      'hospital_id',
                      'insurance_id',
                      'barcode',
                      'NID',
                      'birthdate'
                    ]
                  "
                  class="dropdown-item clickable"
                  (click)="
                    type = key; patient_search = ''; getPatients()
                  "
                  style="text-align: start"
                  >{{
                    (key == "hospital_id"
                      ? "Hospital ID" :
                      key == 'NID'? key
                      : (key | titlecase)
                    ) | locale
                  }}</a
                >
              </div>
            </div>
            <input
              *ngIf="type != 'birthdate'"
              type="text"
              class="form-control"
              [ngClass]="{
                'rad-left': !data.isRTL,
                'rad-right': data.isRTL
              }"
              [(ngModel)]="patient_search"
              (blur)="hide()"
              (keydown)="getPatients()"
              [placeholder]="'Patient search' | locale"
            />

            <input
              [ngStyle]="type == 'birthdate' ? {} : { display: 'none' }"
              id="birthdatePicker"
              [(ngModel)]="patient_search"
              class="flatpickr flatpickr-input active form-control"
              [ngClass]="{
                'rad-left': !data.isRTL,
                'rad-right': data.isRTL
              }"
              (blur)="hide()"
              type="date"
              [placeholder]="'Birthdate' | locale"
              (change)="getPatients()"
              autocomplete="off"
            />
          </div>

          <!-- <input (keyup)="onChange($event.target.value)"  autocomplete="off" type="text" (blur)="hide()"  class="form-control" id="patient_autocomplete"
          [placeholder]="'Search by patient name, ID or phone number or national id' | locale"> -->

          <div id="patient_dropdown" style="max-height: 350px; overflow-y: scroll;"  class="dropdown-menu patient">

            <div class="table-responsive ">
              <table class="table ">
              <thead>
                <tr class="neue-light ">
                  <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                  <th width="15%" scope="col"> {{'id' | locale}}</th>
                  <th scope="col"> {{'Name' | locale}}</th>
                  <th scope="col"> {{'Birthdate' | locale}}</th>
                  <!-- <th scope="col">خيارات</th> -->
                  <!-- <th scope="col"></th> -->
                </tr>



              </thead>
              <br>

              <tbody class="neue-light">
                <ng-container *ngFor="let patient of patients | async">

                   <tr valign="middle" (mousedown)="$event.preventDefault()" (click)="pick(patient)"  class="clickable_row ">
                    <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                    <td>{{patient.custom_id}}</td>
                    <td>{{patient.name }}</td>
                    <td>{{patient.birth_year? patient.birth_year + '-' + patient.birth_month + "-" +  patient.birth_day : '-'}}</td>
                    <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                      <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                    </h5></a>
                    </td> -->
                    <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                  </tr>
                </ng-container>



              </tbody>
              </table>
            </div>
            <!-- <a *ngFor="let test of tests" class="dropdown-item" href="#">{{test.name}}</a>
            <a *ngFor="let test of tests" class="dropdown-item" href="#">{{test.name}}</a> -->
          </div>

        </div>
      </div>

    </div>


  </div>

