<div class="row">
  <div class="col">
    <h2 class="roboto-300 start">{{ "Devices | Methods" | locale }}</h2>
  </div>
</div>

<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <div class="row mt-2">
      <div class="col-12 col-lg-2 mt-3 mt-lg-0">
        <div class="d-flex w-100 justify-content-end">
          <button
            type="button"
            id="add-button"
            class="btn btn-outline-primary w-100 roboto-400"
            (click)="
              ms.assign({
                title: 'Add a device | method',
                route: null,
                datum: {},
                inputs: {
                  name: true,
                  printable_name: true,
                  code: true,
                  is_device: true
                },
                method: api.Admin.devices.add,
                message: 'Device was added successfully',
                onSuccess: getDevices.bind(this)
              })
            "
          >
            {{ "Add" | locale }} +
          </button>
        </div>
      </div>

      <!-- <ng-template *ngIf="user['id'] == 1"> -->
        <div *ngIf="user['id'] == 1"
          class="col-12 col-lg-2 mt-3 mt-lg-0"
          style="margin-left: auto; margin-right: 0"
        >
          <div class="d-flex w-100 justify-content-end">
            <button
              data-toggle="modal"
              data-target="#device-exp-modal"
              type="button"
              id="add-button"
              class="btn btn-outline-primary w-100 roboto-400"
              (click)="action = { type: 'New' }"
            >
              Edit Devices expiration
            </button>
          </div>
        </div>
      <!-- </ng-template> -->
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th scope="col">{{ "ID" | locale }}</th>
                <th width="25%" scope="col">{{ "code" | locale }}</th>
                <th width="25%" scope="col">{{ "Name" | locale }}</th>
                <th width="25%" scope="col">{{ "Statistics" | locale }}</th>
                <th width="25%" scope="col">{{ "Report" | locale }}</th>
                <th width="25%" scope="col">
                  {{ "expiration date" | locale }}
                </th>
                <!-- <th scope="col"> Printable name</th> -->

                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>
            </thead>
            <br />

            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let device of devices; let i = index">
                <tr
                  (click)="
                    ms.assign({
                      title: 'Edit a device | method',
                      action: 'Update',
                      route: null,
                      datum: device,
                      inputs: {
                        name: true,
                        printable_name: true,
                        code: true,
                        is_device: true
                      },
                      method: api.Admin.devices.update,
                      message: 'Device was updated successfully',
                      onSuccess: getDevices.bind(this),
                      getDatum: api.Admin.devices.getOne.bind(this, device.id),
                      deleteMethod: api.Admin.devices.remove,
                      deleteMessage: 'Device was deleted successfully'
                    })
                  "
                  valign="middle"
                  class="clickable_row roboto-400"
                >
                  <td scope="row">{{ i + 1 }}</td>
                  <td width="25%">{{ device.code }}</td>
                  <td width="25%">{{ device.name }}</td>
                  <td width="25%">
                    <button
                      (click)="
                        $event.stopPropagation();
                        data.stats_info = {
                          name: 'devices',
                          id: device.id,
                          label: device.name
                        };
                        data.statsModal.next();
                        data.fireModal('statsModal')
                      "
                      class="btn btn-light"
                    >
                      <i class="fas fa-chart-pie"></i>
                    </button>
                  </td>

                  <td width="25%">
                    <button
                      (click)="
                        $event.stopPropagation();
                        data.stats_info = {
                          name: 'devices',
                          id: device.id,
                          label: device.name,
                          pdf: true
                        };
                        data.statsModal.next(true);
                        data.fireModal('statsModal')
                      "
                      class="btn btn-light"
                    >
                      <i class="fas fa-file"></i>
                    </button>
                  </td>

                  <td width="25%">{{ device.expiration_date | date: "d/m/y" }}</td>
                  <!-- <td>{{device.printable_name }}</td> -->

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <router-outlet></router-outlet> -->

<div
  class="modal fade"
  id="device-exp-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <app-loading [type]="'dialog'"></app-loading>
      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
              <h5 class="roboto-500">Edit Devices Expiration</h5>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12 col-lg-6">
            <input
              id="toPicker"
              class="flatpickr flatpickr-input active form-control"
              type="date"
              placeholder="New Date"
              autocomplete="off"
              [(ngModel)]="date"
            />
          </div>

          <div class="col-12 col-lg-2 mt-3 mt-lg-0">
            <div class="d-flex w-100 justify-content-end">
              <button
                type="button"
                class="btn btn-outline-primary w-100 roboto-400"
                (click)="expiration()"
              >
                submit
              </button>
            </div>
          </div>
        </div>

        <ng-multiselect-dropdown
          [placeholder]="'select devices'"
          [settings]="dropdownSettings"
          [data]="devices"
          [(ngModel)]="selectedItems"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>
</div>
