import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkSelectedPatients'
})
export class CheckSelectedPatientsPipe implements PipeTransform {

  transform(selected = {}, patients = [],  ...args: any[]): any {
    return !patients.filter(one => !selected[one.id]).length;
  }

}
