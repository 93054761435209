import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'admin-layout-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.scss'],
})
export class EmployeesListComponent implements OnInit {
  user: any[] = [];
  users: any[] = [];
  action: any = {};

  constructor(
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public ms:ModalService
  ) {}

  ngOnInit() {
    this.getUsers();
    this.data.remove.subscribe(({ id, type }) => {
      if (type == 'employee') {
        this.users = this.users.filter((one) => one.id != id);
        $('#user-modal').modal('hide');
      }
    });
  }

  getUsers() {
    this.progress.on();
    this.api.Admin.users.get().subscribe((res) => {
      this.progress.off();
      this.users = res['data'];
    });
  }

  openModal(action) {
    this.action = action;
    $('#user-modal').modal('show');
  }

  userCreated() {
    console.log('here');

    this.getUsers();
    $('#user-modal').modal('hide');
  }
}
