import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { DataService, Gender, Civility } from 'src/app/services/data.service';
import { SnackService } from 'src/app/services/snack.service';
import { NgForm } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'admin-layout-civilities-list',
  templateUrl: './civilities-list.component.html',
  styleUrls: ['./civilities-list.component.scss'],
})
export class CivilitiesListComponent implements OnInit {
  civilities: Array<Civility> = [];
  civility: any = null;
  sureState: Boolean = false;
  action: any = '';

  constructor(
    public ms: ModalService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public snack: SnackService,
    public data:DataService
  ) {}

  ngOnInit() {
    this.getCivilities();
  }

  getCivilities() {
    this.progress.on();

    this.api.Common.Get.civilities().subscribe((res: Array<Civility>) => {
      this.progress.off();
      this.civilities = res;
    });
  }
}
