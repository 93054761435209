import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable()
export class ApiService extends HttpService {
  results = {
    set: (
      parameter_id,
      request_id,
      test_id,
      result,
      isFromDevice,
      note = null
    ) => {
      let obj = {};
      note ? (obj['note'] = note) : null;
      return super.post({
        route: `results`,
        body: {
          parameter_id,
          request_id,
          test_id,
          result,
          isFromDevice,
          ...obj,
        },
      });
    },

        set_notes_results: body => super.post({ route: `results/notes`, body }),


  };
}
