import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map, tap } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { ProgressService } from "src/app/services/progress.service";
import { SnackService } from 'src/app/services/snack.service';
declare let $;

@Component({
  selector: 'admin-layout-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss']
})
export class ClinicComponent implements OnInit {

  correspondent: any;
  param:any;
  clinic: any;
  isLoaderHidden = true;
  pagination: any = {};
  page:number = 1;
  currentPer: any;
  per: 'month' | 'day' = 'month';

  pers: any = [];

  toggleFlag = (page,per = null) => {

    this.per = per || this.per;

      this.api.Admin.clinics.get_stats(
        this.clinic.id,
        {per: per || this.per,page}
      ).subscribe((res) => {
        this.pers = res['data'];
        this.pagination = this.data.extract_pagination(res)

      });

  };

  getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }



  constructor(
    private activatedRoute: ActivatedRoute,
    private progress: ProgressService,
    private api: ApiService,
    public router: Router,
    public snack : SnackService,
    public data: DataService
  ) {}

  ngOnInit() {
    $("#payPicker")[0].flatpickr({
      dateFormat: "Y-m-d",
      defaultDate: this.getToday(),
    });
    this.activatedRoute.params.subscribe((param) => {
      this.progress.on();
      this.param = param
      this.api.Admin.clinics.get_one(param.id).subscribe((res) => {
        this.progress.off();
        this.clinic = res;
      });

      this.api.Admin.clinics.get_stats(
        param.id, {per:'month', page:1}).subscribe((res) => {
        this.pers = res['res'];
        this.pagination = this.data.extract_pagination(res)

      });

    });
  }





  onSubmit(form: NgForm) {

    if(this.currentPer && this.currentPer.date){
      var isDay = this.currentPer.date.match(/\//g).length == 2;
      var date = new Date(this.currentPer.date);
    }

    if($("#payPicker")[0].value != "")
          form.value.payment_date = $("#payPicker")[0].value;
          else {
            this.snack.showerror('Please enter the payment date');
            return null
          };
    this.api.Admin.clinics.pay(this.clinic.id, {
      ...form.value,
      year: this.currentPer? date.getFullYear(): undefined,
      month: this.currentPer? date.getMonth() + 1 : undefined,
      day: this.currentPer && isDay ? date.getDate() : undefined,
    }).subscribe((res) => {
      if (!res["error"]) {
        this.clinic = null
        this.progress.on();

      this.api.Admin.clinics.get_one(this.param.id).subscribe((res) => {
        this.progress.off();
        this.clinic = res;
      });
        $("#clinicPaymentModal").toggle("hide");
        $("#clinicPaymentModal").modal("hide");
      }
    });
  }
}




