import { Injectable } from '@angular/core';

import { ApiService as AdminApiService } from './admin-api.service';
import { ApiService as TechnicianApiService } from './technician-api.service';
import { ApiService as ReceptionistApiService } from './receptionist-api.service';
import { ApiService as CommonApiService } from './common-api.service';


// interface Something {
// [key: string]: any
// }

@Injectable()
export class ApiService  {

  constructor(
    public Common:CommonApiService,
    public Admin: AdminApiService,
    public Technician: TechnicianApiService,
    public Receptionist: ReceptionistApiService
  ) {}

}
