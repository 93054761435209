import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackService } from 'src/app/services/snack.service';
import { ProgressService } from 'src/app/services/progress.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { NgForm } from '@angular/forms';
declare var $:any

@Component({
  selector: 'admin-layout-bacteria',
  templateUrl: './bacteria.component.html',
  styleUrls: ['./bacteria.component.scss']
})
export class BacteriaComponent implements OnInit {

  @Output() setCreated = new EventEmitter();

  sureState:Boolean = false;
  bacteria: any [] = [];
  families:any[] = []
  selectedBacterium:any = {}
  action:any = {type : 'Add'}
  isLoaderHidden = true;

  antibiotics:any = []
  constructor(public router:Router,public api : ApiService,public progress : ProgressService, public snack:SnackService) { }

  onSubmit(form:NgForm){
//   admin/clinics post get delete put?
//  admin/clinics/:id/activate
// this.api.Correspondents.pay(this.correspondent.id, {

    if(form.valid){
      if(this.action.type == 'Edit'){
        form.value.antibiotics = this.antibiotics
        if (this.antibiotics.length != 0) {
          console.log(this.antibiotics)
          if(this.antibiotics.filter(one => !one.order || typeof Number(one.order) != 'number').length||
          this.antibiotics.length != Array.from(new Set(this.antibiotics.map(one => one.order))).length
          )  return this.snack.showerror("please add unique order for each parameter");
        }
        form.value['id'] = this.selectedBacterium.id
        this.api.Admin.bacteria.update(form.value).subscribe(res => {
          this.getBacteria()
          $("#bacterium-modal").modal('hide');
        })
      }else{
        form.value.antibiotics = this.antibiotics;
        if (this.antibiotics.length != 0) {
          console.log(this.antibiotics)
          if(this.antibiotics.filter(one => !one.order || typeof Number(one.order) != 'number').length||
          this.antibiotics.length != Array.from(new Set(this.antibiotics.map(one => one.order))).length
          )  return this.snack.showerror("please add unique order for each parameter");
        }
        this.api.Admin.bacteria.add(form.value).subscribe(res => {
          this.snack.show('Bacteria successfully added ');
          this.setCreated.emit(res['bacteria_id'])
          if(!res['error']) this.getBacteria()
          this.selectedBacterium['name'] = ''
          this.selectedBacterium['code'] = ''
          $("#bacterium-modal").modal('hide');
        })
      }
      console.log(form.value)
    }else{
      console.log('form is not valid')
    }
  }

  remove(){
    this.api.Admin.bacteria.remove(this.selectedBacterium.id).subscribe(res => {
       this.bacteria = this.bacteria.filter(one => one.id != this.selectedBacterium.id)
      $("#bacterium-modal").modal('hide');
    })
  }

  removeAnti(antibiotic) {
    this.antibiotics = this.antibiotics.filter(
      (anti) => anti.id != antibiotic.id
    );
    console.log(this.antibiotics)
  }



  ngOnInit() {

    this.progress.on();
    this.getFamilies();
    this.getBacteria();

  }

  ngAfterViewInit(){

    var input$ : Observable<any> = fromEvent($('#test_autocomplete'), 'input');
    input$.pipe(map(res => res['target'].value),debounceTime(500)).subscribe(res => {


      console.log('here');

      this.isLoaderHidden = false
      // this.getTests(this.key);

    })

  }

  setSelectedAntibiotics(e) {
    if(this.antibiotics.find(one => one.id == e.id)) return this.snack.showerror('This antibiotic is already added')

    let order = this.antibiotics.length + 1
    while(this.antibiotics.filter(antibaiotic => antibaiotic['order'] == order).length){
      order++
    }
    e['order'] = order

    this.antibiotics.push(e);
  }

  getAntibiotics(id){

    // $('.dropdown-menu').dropdown('show');
    this.antibiotics = []
    this.api.Admin.bacteria.antibiotics(id).subscribe(res => {

      this.progress.off();
      this.isLoaderHidden = true
      this.antibiotics = res

    });
  }

  getBacteria(){

    // $('.dropdown-menu').dropdown('show');

    this.api.Common.Get.bacteria().subscribe(res => {

      this.progress.off();
      this.isLoaderHidden = true

      this.bacteria = res

    });
  }
  getFamilies(){

    // $('.dropdown-menu').dropdown('show');

    this.api.Admin.germ_families.get().subscribe(res => {

      this.progress.off();
      this.isLoaderHidden = true

      this.families = res
    })
  }

  openModal(action){

    this.action = action
    $("#test-modal").modal('show');

  }

  // testCreated(){
  //   this.getTests(this.key);
  //   $("#test-modal").modal('hide');
  // }

}


