<div class="container-fluid py-2 px-4">
  <form id="test_form" #test_form="ngForm" (ngSubmit)="onSubmit(test_form)">
    <div class="row neue-reg mt-3">
      <div class="col-lg-6">
        <div class="form-group">
          <label for="exampleInputEmail1">{{ "Code" | locale }}</label>
          <input
            required
            maxlength="20"
            name="code"
            [(ngModel)]="test.code"
            #code_input="ngModel"
            type="text"
            class="form-control"
            [placeholder]="'Code' | locale"
          />
          <small
            style="display: block"
            class="start"
            *ngIf="
              (code_input.errors ? code_input.errors['required'] : false) &&
              code_input.touched
            "
          >
            {{ "This field is required" | locale }}
          </small>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group">
          <label for="exampleInputEmail1"> {{ "Name" | locale }}</label>
          <input
            required
            maxlength="60"
            [(ngModel)]="test.name"
            name="name"
            type="text"
            class="form-control"
            [placeholder]="'Name' | locale"
            #name_input="ngModel"
          />

          <small
            style="display: block"
            class="start"
            *ngIf="
              (name_input.errors ? name_input.errors['required'] : false) &&
              name_input.touched
            "
          >
            {{ "This field is required" | locale }}
          </small>
        </div>
      </div>
    </div>

    <div class="row neue-reg mt-3">

      <div class="col-lg-6">
        <selector
        title="samples"
        [datum]="selectedSample"
        >

      </selector>
      </div>

      <div class="col-lg-6">
        <div class="form-group">
            <label for="families">{{ "Families" | locale }}</label>
            <select [(ngModel)]="test.family_id" name="family_id" id="families" class="custom-select">
              <option [value]="null">{{ "Select a family" | locale }}</option>
              <option *ngFor="let family of families" [value]="family.id">
                {{ family.name }}
              </option>
            </select>
        </div>
    </div>


    </div>

    <div class="row neue-reg mt-3">

      <div class="col-lg-6">
        <div class="form-group">
          <label for="cats">{{ "Categories" | locale }}</label>
          <select
            [(ngModel)]="test.category_id"
            name="category_id"
            id="cats"
            class="custom-select"
          >
            <option [value]="-1">{{ "Select a category" | locale }}</option>
            <option *ngFor="let category of categories" [value]="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group">
          <label for="status">{{ "Status" | locale }}</label>
          <select
            [(ngModel)]="test.status"
            name="status"
            id="status"
            class="custom-select"
          >
            <option
              *ngFor="let status of ['active', 'inactive']"
              [value]="status"
            >
              {{ status | locale }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row neue-reg mt-3">
      <div class="col-lg-12">
        <div class="form-group">
          <label for="exampleInputEmail1">{{ "Inactive Note" | locale }}</label>
          <input
            maxlength="25"
            name="inactive_note"
            [(ngModel)]="test.inactive_note"
            type="text"
            class="form-control"
            [placeholder]="'Inactive Note' | locale"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label for="exampleInputEmail1">{{
            "Active Note" | locale
          }}</label>
          <textarea
            rows="5"
            name="active_note"
            [(ngModel)]="test.active_note"
            type="text"
            class="form-control"
            [placeholder]="'Active Note' | locale"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="row neue-reg mt-3">
      <div class="col-lg-12">
        <!-- <label for="exampleInputEmail1">{{ "Parameters" | locale }}</label> -->

        <selector
          title="parameters"
          (setSelectedItem)="setSelectedParam($event)"
        ></selector>
      </div>

      <div *ngIf="selectedParams?.length != 0" class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th width="15%" scope="col">{{ "code" | locale }}</th>
                <th scope="col">{{ "Name" | locale }}</th>
                <th scope="col">{{ "Order" | locale }}</th>
                <th scope="col">{{ "Can Print" | locale }}</th>
                <th scope="col">{{ "Bold" | locale }}</th>
                <th scope="col">{{ "Remove" | locale }}</th>
              </tr>
            </thead>
            <br />

            <tbody class="neue-light mt-2">
              <ng-container
                *ngFor="let parameter of selectedParams; let i = index"
              >
                <tr style="height: fit-content;" valign="middle" class="clickable_row roboto-400">
                  <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                  <td>{{ parameter.code }}</td>
                  <td>{{ parameter.name }}</td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      [name]="'order_' + i"
                      style="width: 40px !important"
                      [(ngModel)]="parameter['order']"
                    />
                  </td>
                  <td>
                    <input
                      class="form-check-input"
                      style="width: 40px; height: 40px"
                      [checked]="parameter['canPrint']"
                      name="canPrint"
                      type="checkbox"
                      (change)="changePrintStatue(i, $event)"
                    />
                  </td>
                  <td>
                    <input
                      class="form-check-input"
                      style="width: 40px; height: 40px"
                      [checked]="parameter['bold']"
                      name="bold"
                      type="checkbox"
                      (change)="boldStatue(i, $event)"
                    />
                  </td>
                  <td>
                    <button
                      (click)="removeParam(parameter)"
                      type="button"
                      class="btn btn-outline-danger"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                  <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                </h5></a>
                </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-lg-12 mt-4">
        <div class="form-group">
          <label for="notes">{{ "Extra notes" | locale }}</label>
          <!-- <div class="">
            <button
              hidden
              #add
              id="pushNote"
              type="button"
              (click)="
                notes.push(optionInput.value);
                optionInput.value = '';
                add.hidden = true
              "
              class="btn save-btn btn-success py-0 roboto-400 btn-result"
            >
              Add
            </button>
          </div> -->

          <div class="input-group">
            <input
              [ngModelOptions]="{ standalone: true }"
              (input)="(null)"
              type="text"
              [(ngModel)]="note"
              class="form-control optionFeild"
              [placeholder]="'Add a note' | locale"
              id="optionFeild"
              [ngClass]="{ 'rad-left': data.isRTL, 'rad-right': !data.isRTL }"
            />

            <div class="input-group-append">
              <button
                [disabled]="!note"
                (click)="
                  noteIndex || noteIndex == 0 ? updateNote() : pushNote()
                "
                class="btn btn-outline-primary form-control"
                [ngClass]="{ 'rad-left': !data.isRTL, 'rad-right': data.isRTL }"
                [innerText]="
                  (noteIndex || noteIndex == 0 ? 'Edit' : 'Add') | locale
                "
              ></button>
            </div>
          </div>
        </div>
      </div>

      <p class="col-12 text-primary h5 start" *ngIf="notes.length">
        {{ "Added notes" | locale }}
      </p>

      <div style="overflow-x: scroll; white-space: nowrap" class="my-2 start">
        <span
          class="m-2 p-2 py-3 btn-light btn"
          *ngFor="let note of notes; let i = index"
          >{{ note }}
          <span class="mx-1 btn-sm btn-primary pt-2">
            <i (click)="editNote(note, i)" class="fas fa-edit h5 m-0"></i>
          </span>
          <span class="mx-1 btn-sm btn-danger pt-2">
            <i (click)="removeNote(note)" class="far fa-trash-alt h5 m-0"></i>
          </span>
        </span>
      </div>

      <div *ngIf="notes.length" class="col-12 mt-3">
        <div class="d-flex h-100 align-items-center">
          <div class="">
            <div class="form-group form-check start">
              <input
                name="print_category"
                [(ngModel)]="test.print_category"
                type="checkbox"
                class="form-check-input"
                id="print_category"
              />
              <label
                class="form-check-label"
                [ngClass]="{ 'mr-4': data.isRTL }"
                for="print_category"
                >{{ "Print category" | locale }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-4">
        <label for="exampleInputEmail1">{{
          "Devices | Methods" | locale
        }}</label>

        <selector
        title="device"
        [no_label]="true"
        (setSelectedItem)="setSelectedDevice($event)"
        ></selector>
      </div>

      <div *ngIf="selectedDevices?.length != 0" class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th width="15%" scope="col">{{ "code" | locale }}</th>
                <th scope="col">{{ "Name" | locale }}</th>
                <th scope="col">{{ "Default" | locale }}</th>
                <th scope="col">{{ "Remove" | locale }}</th>
              </tr>
            </thead>
            <br />

            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let device of selectedDevices">
                <tr valign="middle" class="clickable_row roboto-400">
                  <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                  <td>{{ device.code }}</td>
                  <td>{{ device.name }}</td>
                  <td>
                    <input
                      (change)="
                        $event.preventDefault(); selectDefaultDevice(device)
                      "
                      [checked]="device.pivot?.is_default"
                      type="checkbox"
                      style="width: 35%; height: 100%"
                    />
                  </td>
                  <td>
                    <button
                      (click)="removeDevice(device)"
                      type="button"
                      class="btn btn-outline-danger"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                  <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                </h5></a>
                </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-4 mt-3">
        <div class="d-flex h-100 align-items-center">
          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="is_casa"
              [(ngModel)]="test.is_casa"
              id="is_casa"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="is_casa"
              >{{ "Report" | locale }}</label
            >
          </div>
        </div>
      </div>
      <div class="col-4 mt-3">
        <div class="d-flex h-100 align-items-center">
          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="printable_name"
              [(ngModel)]="test.printable_name"
              id="printable_name"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="printable_name"
              >{{ "Show name in results" | locale }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-4 mt-3">
        <div class="d-flex h-100 align-items-center">
          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="is_histo"
              [(ngModel)]="test.is_histo"
              id="is_histo"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="is_histo"
              >{{ "Has histogram" | locale }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-4 mt-3">
        <div class="d-flex h-100 align-items-center">
          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="hide_reference"
              [(ngModel)]="test.hide_reference"
              id="hide_reference"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="hide_reference"
              >{{ "Hide reference range from results" | locale }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-4 mt-3">
        <div class="d-flex h-100 align-items-center">
          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="hide_title_in_result"
              [(ngModel)]="test.hide_title_in_result"
              id="hide_title_in_result"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="hide_title_in_result"
              >{{ "Hide title from results" | locale }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-4 mt-3">
        <div class="d-flex h-100 align-items-center">
          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="hide_table_header_in_result"
              [(ngModel)]="test.hide_table_header_in_result"
              id="hide_table_header_in_result"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="hide_table_header_in_result"
              >{{ "Hide table header from results" | locale }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <h6 class="mt-3 roboto-400 start">{{ "Test Prices" | locale }}</h6>
      </div>
    </div>

    <div class="row neue-reg mt-3">
      <div class="col-lg-12">
        <div class="form-group">
          <label for="exampleInputEmail1">{{ "Cost Price" | locale }}</label>
          <input
            name="cost"
            [(ngModel)]="test.cost"
            type="text"
            class="form-control"
            [placeholder]="'Cost Price' | locale"
          />
        </div>
      </div>
    </div>


    <ng-container *ngIf="pricing">
      <div *ngFor="let pricing_item of pricing" class="row mt-3">
        <div class="col-12"
         *ngIf="!pricing_item.isCheck">
          <div class="d-flex align-items-center card w-100">
            <div class="container-fluid">
              <div class="row neue-reg mt-3">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{
                      "Pricing" | locale
                    }}</label>
                    <input
                      disabled
                      readonly
                      [ngModelOptions]="{ standalone: true }"
                      [ngModel]="pricing_item.name"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{
                      "Original" | locale
                    }}</label>
                    <input
                      required
                      maxlength="25"
                      form="test_form"
                      name="a_{{ pricing_item.id }}"
                      [(ngModel)]="pricing_item.price"
                      (input)="originalInputCheck(pricing, clinic_input)"
                      #price_input="ngModel"
                      type="text"
                      class="form-control"
                      [placeholder]="'Price' | locale"
                    />
                    <small
                      style="display: block"
                      class="start"
                      *ngIf="
                        (price_input.errors
                          ? price_input.errors['required']
                          : false) && price_input.touched
                      "
                    >
                      {{ "This field is required" | locale }}
                    </small>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{
                      "Clinic" | locale: 2
                    }}</label>
                    <input
                      #clinic_input
                      (input)="
                        clinicInputCheck($event, clinic_input, pricing_item)
                      "
                      maxlength="25"
                      form="test_form"
                      name="b_{{ pricing_item.id }}"
                      [(ngModel)]="pricing_item.clinic_price"
                      type="text"
                      class="form-control"
                      [placeholder]="'Price' | locale"
                    />
                  </div>
                </div>
                <!-- <div class="col-lg-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Lab</label>
                    <input
                      #lab_input
                      disabled
                      maxlength="25"
                      form="test_form"
                      name="c_{{ pricing_item.id }}"
                      [(ngModel)]="pricing_item.lab_price"
                      type="text"
                      class="form-control"
                      placeholder="Enter a Price"
                    />
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row neue-reg mt-3" *ngIf="isTax">
      <div class="col-lg-12">
        <div class="form-group">
          <label for="exampleInputEmail1">{{ "Tax" | locale }}</label>
          <input
            [ngModel]="test.tax"
            #tax_input="ngModel"
            name="tax"
            type="number"
            min="0"
            max="100"
            [placeholder]="'Tax' | locale"
            class="form-control"
          />

          <!-- <input
            required
            maxlength="60"
            [(ngModel)]="test.name"
            name="name"
            type="text"
            class="form-control"
            placeholder="Enter Name"
            #name_input="ngModel"
          /> -->
        </div>
      </div>
    </div>

    <div class="row neue-reg my-4">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <div
            *ngIf="sureState"
            class="alert alert-danger mx-auto"
            role="alert"
          >
            {{ "Are you sure?" | locale }}
            <span (click)="remove()" class="mx-2 btn-danger btn-sm clickable">{{
              "Delete" | locale
            }}</span
            ><span
              (click)="sureState = false"
              class="ml-2 btn-sm btn-light clickable"
              >{{ "Cancel" | locale }}</span
            >
          </div>

          <ng-container *ngIf="!sureState">
            <button
              *ngIf="action.type == 'Edit'"
              type="button"
              (click)="sureState = true"
              class="btn btn-outline-danger tajwal-regium px-4 mx-2"
            >
              {{ "Delete" | locale }}
            </button>
            <button
              form="test_form"
              type="submit"
              class="btn btn-outline-primary tajwal-regium px-5"
            >
              {{ (action.type == "Edit" ? "Save" : "Add") | locale }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
