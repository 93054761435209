<ng-container *ngIf="clinic">
  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{ clinic.name }}</h2>
    </div>
  </div>

  <div class="card mt-4 mb-5">
    <div class="container-fluid py-3 px-4">

        <div class="row ">
          <P class="col-12 h5 grey start my-2">{{ "Clinic info" | locale }}</P>

          <table class="table col-12">
            <thead class="bg-white">
              <tr>
                <th class="data">{{ "Address" | locale }}</th>
                <th class="data">{{ "Contact" | locale }}</th>
                <th class="data">{{ "Total" | locale }}</th>
                <th class="data">{{ "Paid" | locale }}</th>
                <th class="data">{{ "Remaining" | locale }}</th>
                <th >
                  <button
                  (click)="currentPer = undefined"
                  data-toggle="modal"
                  data-target="#clinicPaymentModal"
                    type="button"
                    id="add-button"
                    class="btn btn-primary w-100 roboto-400"
                  >
                    {{'Pay' | locale}}
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="data">{{ clinic.address }}</td>

                <td class="data">{{ clinic.contact  }}</td>

                <td class="data">{{  clinic.total }}</td>

                <td class="data">{{ clinic.paid }}</td>

                <td class="data">{{ clinic.remaining }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>


        </div>

        <hr class="my-5">

      <div class="row mt-2 justify-content-center">
        <div *ngFor="let p of ['day', 'month']" class="col-2 mx-4">
          <div class="d-flex w-100 justify-content-end">
            <p
              (click)="p == per? null :toggleFlag(1,p)"
              class="btn btn-outline-primary btn-sm w-100 roboto-400 m-0"
              [ngClass]="{
                'btn-primary text-white': p == per
              }"
            >
              {{ (p == 'day'? 'Daily' : 'Monthly') | locale }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table mt-3">
              <thead>
                <tr
                  class="neue-light roboto-400"
                >
                  <th  scope="col" > {{'ID' | locale:2}}</th>
                  <!-- <th width="15%" scope="col">Request #</th> -->
                  <th style="min-width: 100px" scope="col">
                    {{ per | titlecase | locale }}
                  </th>
                  <th style="min-width: 100px" scope="col">{{'Total' | locale}}</th>
                  <th style="min-width: 100px" scope="col">{{'Paid' | locale}}</th>
                  <th style="min-width: 100px" scope="col">{{'Remaining' | locale}}</th>
                  <th style="min-width: 100px" scope="col">{{'Payments' | locale}}</th>
                  <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                  <!-- <th scope="col">خيارات</th> -->
                  <!-- <th scope="col"></th> -->
                </tr>

                <!-- | paginate: { id:'pagination' ,
        itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }" -->
              </thead>
              <tbody class="neue-light">

                <ng-container>
                  <ng-container *ngFor="let per of pers | paginate: { id:'pagination' ,
                  itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">
                    <tr class="neue-light roboto-400">
                      <th  scope="col" >{{i + 1}}</th>
                      <!-- <th width="15%" scope="col">Request #</th> -->
                      <th style="min-width: 100px" scope="col">
                        {{ per.date }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        {{ per.total }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        {{ per.paid }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        {{ per.remaining }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        <button
                          (click)="currentPer = per"
                          data-toggle="modal"
                          data-target="#clinicPaymentModal"
                          type="button"
                          class="btn btn-outline-info p-0 px-3"
                        >
                          <i class="fas fa-dollar-sign"></i>
                        </button>
                      </th>
                      <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                      <!-- <th scope="col">خيارات</th> -->
                      <!-- <th scope="col"></th> -->
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="w-100 d-flex justify-content-center neue-med p-0">
            <pagination-controls
            (pageChange)="toggleFlag($event)"
              responsive="true"
              [previousLabel]="'Previous' | locale"
              [nextLabel]="'Next' | locale"
              id="pagination"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>



<!-- Modal -->

<div
  class="modal fade"
  id="clinicPaymentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4">
          <div class="row mt-4">
            <div class="col-12">
              <h5 class="roboto-500 start">{{'Make Payment' | locale}}</h5>
            </div>
          </div>

          <form #form="ngForm" (ngSubmit)="onSubmit(form)">
            <div class="row">
              <div class="col-lg-6 mt-3">
                <div class="form-group">
                  <label for="Price">{{'Price' | locale}}</label>
                  <input
                    maxlength="500"
                    name="price"
                    ngModel
                    type="text"
                    class="form-control"
                    [placeholder]="'Price' | locale"
                  />
                </div>
              </div>

              <div *ngIf="data.payment_methods[0]" class="col-lg-6 mt-3">
                <div class="form-group">
                  <label for="payment_method">{{'Payment Method' | locale}}</label>
                  <select
                    [ngModel]="data.payment_methods[0].id"
                    name="payment_method_id"
                    id="payment_method"
                    class="custom-select neue-reg"
                  >
                    <option
                      *ngFor="let paymentMethod of data.payment_methods"
                      [value]="paymentMethod.id"
                    >
                      {{ paymentMethod.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-lg-6 mt-3">
                <div class="form-group">
                  <label for="payPicker">{{'Payment Date' | locale}}</label>
                  <input
                    name="date"
                    id="payPicker"
                    class="flatpickr flatpickr-input active form-control"
                    type="text"
                    placeholder="Select Date.."
                  />
                </div>
              </div>
            </div>

            <div class="row mt-2 mb-3">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <button
                  *ngIf="clinic"
                    type="submit"
                    [disabled]="!clinic.remaining"
                    class="btn btn-outline-primary tajwal-regium px-5"
                  >
                    {{'Pay' | locale}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
