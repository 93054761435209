


  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{'Pricings' | locale}}</h2>
    </div>
  </div>

  <div class="card mb-5 mt-3">
    <div class="container-fluid py-3 px-4">



      <div class="row mt-2">



        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end ">
            <button
             type="button" id="add-button" class="btn btn-outline-primary w-100 roboto-400"   (click)="
             ms.assign({
               title: 'Add a Pricing',
               route: null,
               datum: {},
               inputs: { name: true, code: true, is_pricing:true },
               method: api.Admin.pricing.add,
               message: 'Pricing was added successfully',
               onSuccess: getPricing.bind(this)
             });
           ">{{'Add' | locale}} +</button>
          </div>
        </div>


      </div>

      <div class="row">

        <div class="col-12">
          <div class="table-responsive  ">
            <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <th width="50%" scope="col"> {{'ID' | locale:2}}</th>
                  <th width="50%" scope="col"> {{'code' | locale}}</th>
                  <th width="50%" scope="col"> {{'Name' | locale}}</th>
                </tr>
              </thead>
              <br>
              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let singlePricing of pricing; let i = index">
                  <tr  (click)="ms.assign({
                    title: 'Edit a Pricing',
                    action:'Update',
                    route: null,
                    datum: singlePricing,
                    inputs: { name: true, code: true, is_pricing:true },
                    method: api.Admin.pricing.update,
                    message: 'Pricing was updated successfully',
                    onSuccess: getPricing.bind(this),
                    deleteMethod:api.Admin.pricing.remove,
                    deleteMessage:'Pricing was deleted successfully'
                  });" valign="middle"  class="clickable_row roboto-400">
                    <td width="50%">{{i + 1}}</td>
                    <td width="50%">{{singlePricing.code}}</td>
                    <td width="50%">{{singlePricing.name }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

      </div>


    </div>
  </div>


<!-- <router-outlet></router-outlet> -->


