import { Component, OnInit, ElementRef, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { ProgressService } from "src/app/services/progress.service";
import { SnackService } from "src/app/services/snack.service";
declare var _: any;
declare var $: any;

@Component({
  selector: "technician-layout-edit-result",
  templateUrl: "./edit-result.component.html",
  styleUrls: ["./edit-result.component.scss"],
})
export class EditResultComponent implements OnInit {
  isFromDevice = true;
  request_id: any;
  patientId: any;
  devices:Boolean=false;
  areas:Boolean=false;
  families:Boolean=false;
  directPrint:Boolean=false;
  healthCare:Boolean=false;
  insurance:Boolean=false;
  lab_info_header:Boolean=false;
  outside_note:Boolean=false;
  send_sms:Boolean=false;

  sms_counter = 0
  category_id: any = -1;
  @Input() request: Object;
  tests: any = [];
  resultForm: any[] = [];
  loading = false;
  i: any = "";
  j: any = "";
  selectedTests: any[] = [];
  categories: any[] = [];
  is_technical_validator: any = false;
  upfile: File | null = null
  upload_file_data: any = {
    patient_request_id: null,
    test_id: null,
    reception_number: null,
    latest_file: {
      name: null,
      lastModified: null,
      webkitRelativePath: null,
      size: null,
      type: null
    }
  }
  colWidth: 12
  constructor(
    public route: ActivatedRoute,
    public api: ApiService,
    public progress: ProgressService,
    public snack: SnackService,
    private elementRef: ElementRef,
    public data:DataService
  ) {}
  // focsu_id=this.data.focus_id;


  sendSms(){
    this.api.Common.requests.send_sms(this.request_id).subscribe(res => {
      // if(!res['error']){
        this.snack.show('The message sent seccussfully')
          this.api.Common.Get.lab_info().subscribe(res =>{
            this.sms_counter = res['data']['sms_counter']
          })
        $("#send-sms").modal('hide');
      // }
    })
  }

fileChoosen(event){
  console.log('e', event)
    this.upfile = event.target.files ? event.target.files[0]: $('#sendFile')[0].files[0];
    console.log('file', this.upfile)
    // this.upfile = file //URL.createObjectURL(file)
    this.upload_file_data.latest_file = this.upfile
    // this.upload_file_data.latest_file.name = this.upfile.name,
    // this.upload_file_data.latest_file.lastModified = this.upfile.lastModified,
    // this.upload_file_data.latest_file.webkitRelativePath = this.upfile.webkitRelativePath,
    // this.upload_file_data.latest_file.size = this.upfile.size,
    // this.upload_file_data.latest_file.type = this.upfile.type
    console.log('this.upload_file_data.latest_file', this.upload_file_data.latest_file)
 }

  openUploadCasa(requestID, testId, receptionNumber) {
    this.upload_file_data.patient_request_id = requestID
    this.upload_file_data.test_id = testId
    this.upload_file_data.reception_number = receptionNumber
    $("#sendFile").val('')
    $("#openUploadCasa").modal("toggle");
  }

  uploadFile(){
    console.log('requestID', this.upload_file_data.patient_request_id)
    console.log('testId', this.upload_file_data.test_id)
    console.log('receptionNumber', this.upload_file_data.reception_number)
    console.log('file', this.upload_file_data.latest_file)
    console.log('latest_file', this.upfile, this.upfile.name)
    var request = new FormData();
    request.append('patient_request_id', this.upload_file_data.patient_request_id)
    request.append('test_id', this.upload_file_data.test_id)
    request.append('reception_number', this.upload_file_data.reception_number)
    request.append('location', URL.createObjectURL(this.upfile))
    request.append('latest_file', this.upfile, this.upfile.name);
    this.api.Common.casa.add(this.upload_file_data.patient_request_id,this.upload_file_data.test_id, request).subscribe((res) => {
      console.log('res', res)
      if (res['errors'] == false ){
        this.snack.show('File Uplaoed Successfully');
        this.getRequest();
      }
      else {
        // this.snack.showerror('something went wrong');
        this.snack.showerror(res['errors']['latest_file']);

      }
    });
    $("#openUploadCasa").modal("toggle");
  }

  printCasa(requestID, testID,receptionNumber) {
    // console.log(loc);

    window.open(this.api.Common.base_print_report+"requests/"+requestID+"/tests/"+testID+"/casa", "_blank");
    // this.api.Common.casa.get(requestID,testID).subscribe((res) => {
      // console.log('res', res)
      // var _url = window.URL.createObjectURL(res);
      // const fileURL =  res['data']['location'];
      // window.open(fileURL, "_blank");
      // window.open(_url, "_blank");
      // window.open(res, "_blank");
    // });
  }

  deleteCasa(requestID, testID){
    this.api.Common.casa.remove(requestID,testID).subscribe((res) => {
      console.log('res', res)
      if (res['errors'] == false ){
        this.snack.show('File Deleted Successfully');
        this.getRequest();
      }
    });
  }

  RepeatTest(event, test){
      console.log('event', event)
      console.log('test', test)

        this.api.Common.requests.tests.repeat_toggle(test.id, this.request_id).subscribe((res) => {
          // console.log(res);
          if (res["error"]) {
            event.target.checked = res['data'];
          }
          this.snack.show(res["message"]);
        });
  }

  print(form:NgForm){

    let entities = Object.entries(form.value)

    let query = entities.length? '?' : ''
    entities.filter(one => one[1]).forEach(one => query += `tests_ids[]=${one[0]}&`)

    if(this.directPrint){
      this.api.Common.printables.print_result(this.request_id, this.devices,this.areas, this.families,this.lab_info_header,this.outside_note, this.healthCare, query).subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      })
    }else{
      this.api.Common.printables.PrintResultForZebra(this.request_id, this.devices,this.areas, this.families,this.lab_info_header,this.outside_note, this.healthCare, query).subscribe(
        (response) => {
          //Next callback
          console.log('response received')
        },
        (error) => {
            //Error callback
          console.error('error caught in component',error)
          this.api.Common.printables.print_result(this.request_id, this.devices,this.areas, this.families,this.lab_info_header,this.outside_note, this.healthCare, query).subscribe(res => {

            console.warn('this.request', this.request)
            const fileURL = URL.createObjectURL(res);
            if(this.insurance && this.request['patient']['insurance_id']){
              const a = document.createElement('a');
              a.href = fileURL;
              const today = new Date();
              // a.download = this.request['patient']['insurance_id']+'-'+today.toISOString().split('T')[0];
              a.download = this.request['patient']['insurance_id'];
              a.click();
            }else{
              window.open(fileURL, '_blank');
            }

          })
        }
      )
    }
  }

  setColor(index) {
    console.log("setColor=========>", index);

    switch (index) {
      case 1:
        return ".bg-primary";
      case 2:
        return ".bg-secondary";
      default:
        return ".bg-dark";
    }
  }
  checkOptions(options, val) {
    return options.find((opt) => opt.content == val.result);
  }

  ngOnInit() {
    this.api.Common.Get.lab_info().subscribe(res =>{
      this.colWidth = res['data']["settings"]['technicalResult']
      this.insurance = res['data']['settings']['insurance']
      if(res['data']['settings']['pdf'] && res['data']['settings']['pdf']['results']){
        let results = res['data']['settings']['pdf']['results']
        console.log('results ', results)
        this.devices = results['withDevices'] || false
        this.areas = results['withAreas'] || false
        this.families = results['withFamily'] || false
        this.lab_info_header = results['lab-info-header'] || false
      }
      if((res['data']['settings']['sms']['abunwas']['status'] || res['data']['settings']['sms']['isend']['status']) && (res['data']['sms_counter'] > 0)){
        this.sms_counter = res['data']['sms_counter']
        this.send_sms = true
      }
    })

    // this.data.infoAssigned.subscribe(isAssigned =>{
    //   if(isAssigned){
    //     if(this.data.info && this.data.info['settings']){
    //     this.colWidth = this.data.info["settings"]['technicalResult']
    //     if(this.data.info['settings']['pdf'] && this.data.info['settings']['pdf']['results']){
    //       let results = this.data.info['settings']['pdf']['results']
    //       console.log('results ', results)
    //       this.devices = results['withDevices'] || false
    //       this.areas = results['withAreas'] || false
    //       this.lab_info_header = results['lab-info-header'] || false
    //     }

    //     }
    //   }
    // })
    //-----------------------------------------------------------------------------------------
        this.is_technical_validator = this.data.me['sub_roles']?.includes('supervisor')

    //-----------------------------------------------------------------------------------------

    this.route.params.subscribe((params) => {
      //-----------------------------------------------------------------------------------------

      this.patientId = params["id"];
      this.request_id = params["request_id"];

      //-----------------------------------------------------------------------------------------

      this.getRequest(true);
      this.getCategories();

      //-----------------------------------------------------------------------------------------
    });

    //-----------------------------------------------------------------------------------------
  }
  focus(event, i, j) {
    console.log("this.data.focus_id >>>>", this.elementRef);
    if (event.target.id == this.data.focus_id) {
      this.data.focus_id = "result" + i + "-" + j;
      return true;
    } else {
      this.data.focus_id = "result" + i + "-" + j;
      return false;
    }
  }
  onpageshow(event) {
    console.log(this.elementRef);

    // event.target.id ==this.data.focus_id?event.target.focsu:''
  }
  //-----------------------------------------------------------------------------------------

  preview_histogram({ media }) {
    window.open(`${this.api.Common.base}media/fetch/${media}`, "_blank");
  }
  //-----------------------------------------------------------------------------------------

  isArray(d) {
    return Array.isArray(d);
  }

  //-----------------------------------------------------------------------------------------

  getRequest(onInit = false) {
    if(!onInit){
      this.api.Common.requests.get_one(this.request_id).subscribe((res) => {
        this.request = res;
      });
    }
    this.request['tests'].forEach((one) => (one.device = one.device || {}));
    this.tests = this.request['tests']
    this.outside_note = this.request['is_outside_note']

    //-----------------------------------------------------------------------------------------
  }

  //-----------------------------------------------------------------------------------------


  //-----------------------------------------------------------------------------------------

  getCategories() {
    this.api.Common.requests.get_categories(this.request_id).subscribe((res) => {
      this.categories = res
    });
  }

  selectDevice(test, { value }) {
    // console.log(test);
    value == -1 ? (value = null) : null;
    this.api.Common.requests.tests.change_device(
      this.request_id,
      test.id,
      {device_id: value}
    ).subscribe((res) => {
      if (res["error"] == false) {
        this.snack.show("device has been selected");
        res["data"]["parameters_ranges"].forEach((a) => {
          test["parameters"].find((b) => b.id == a["parameter_id"])["range"] =
            a["range"];
        });
      } else {
        this.snack.showerror("device is not selected, something wrong happend");
      }
    });
  }

  current_note = {
    param: null,
    test: null,
    text: "",
  };

  //-----------------------------------------------------------------------------------------

  addNote() {
    let note = this.current_note.text;
    // console.log(this.current_note.param, this.current_note.test,note)
    let result = this.current_note.param.result
      ? this.current_note.param.result.result
      : null;
    this.api.Technician.results.set(
        this.current_note.param.id,
        this.request_id,
        this.current_note.test.id,
        result,
        null,
        note
      )
      .subscribe(
        (res) => {
          document.getElementById("closer").click();
          if(this.current_note.param.result) this.current_note.param.result.note = note
          else this.current_note.param.result = {note}
          this.current_note.text = "";
          this.snack.show("Note was added");
        },
        (err) => {
          this.snack.showerror("Please add result first");
        }
      );
  }
  setcurrent_note(param, test) {
    this.current_note.param = param;
    this.current_note.test = test;
    // if (param.results.length) {
    // console.log('jikhioghiugiuguigi===================>', param.results[0].hasOwnProperty('note'));
    // }
    this.current_note.text = param.result?.note ||  "";
    // text: param.results[0] != null ?  : ''
  }

  onSubmit(event, param, loader, save, tests, test_id) {
    // console.log(tests);

    // console.log(event);
    save.hidden = true;
    let result = event.target[1].value || null;
    let param_res = param["result"] ? param["result"]["result"] : "";
    if (param_res != event.target[1].value) {
      loader.hidden = false;
      save.hidden = true;

      this.api.Technician.results.set(
          param.id,
          this.request_id,
          test_id,
          result,
          this.isFromDevice
        )
        .subscribe(
          (res) => {
            if (!res["error"]) {
              if (param.result) param.result["result"] = result;
              else param.result = { result };

              res["extra_results"].forEach((one) => {
                let i;
                let j;
                tests
                  .forEach((test,index) =>{
                    if(test.id == one["test_id"])
                    i = index
                  })
                  tests[i]['parameters'].forEach(
                    (param,index) => {
                      if( param.id == one["parameter"]["id"])
                      j = index
                    }
                  )
                  if(tests[i]['parameters'][j].result) tests[i]['parameters'][j].result['result'] = one["result"];
                  else tests[i]['parameters'][j].result = {result : one["result"]};
                  let input = document.getElementById(`result${i}-${j}`)
                  if(input) input['value'] = one["result"]
              });
              // console.log(param.options)
              // if(param.options && param.options.length && !param.options.includes(param.results[0]))
              // param.options.push(param.results[0])

              // res['extra_results'].forEach(extra => {

              //   param.results[0] = extra['result']

              // });

              // this.getRequest()

              loader.hidden = true;
              save.hidden = true;
            }
          },
          (err) => {
            save.hidden = false;
          }
        );
    }

    // if(event.target[1].value){

    // }
  }

  ////////////////////////////////////////////////////////////////////////////////////
  disabledInput(status) {
    return status == "validated" ? true : false;
  }


  validate(event, test) {
    // switch (event.target.checked) {
        this.api.Common.requests.tests.validate_toggle( this.request_id,test.id).subscribe((res) => {
          // console.log(res);
          if (res["error"]) {
            event.target.checked = !event.target.checked;
          }else{
            test.status = event.target.checked? 'validated' : 'active'
          }
          this.snack.show(res["message"]);
        });
    }



  filterTests() {
    setTimeout(()=>{
      this.tests = this.request['tests'].filter(one => one.category_id == this.category_id )
    } , 0)
  }

  //-----------------------------------------------------------------------------------------\

  technicallyValidate() {
    this.api.Common.requests.validate_toggle(this.request_id).subscribe((res) => {
      if (!res["error"] && res['data']) {
        this.snack.show(res["message"]);
        this.request['status'] = res['data']
        this.selectedTests = [];
      } else {
        this.snack.showerror(res["message"]);
      }
    });
  }

  //-----------------------------------------------------------------------------------------
}
