


  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{'Parameters' | locale}}</h2>
    </div>

    <div style="display: flex; align-items: center" class="mx-3">
      <div class="form-group form-check start mb-0">
        <input
          type="checkbox"
          class="form-check-input"
          name="excel"
          [(ngModel)]="excel"
          [checked]="excel"
          id="excel"
        />
        <label
          class="form-check-label"
          [ngClass]="{ 'mr-4': data.isRTL }"
          for="excel"
          >{{ "Excel" | locale }}</label
        >
      </div>
    </div>

    <button
      type="button"
      id="add-button"
      style="height:fit-content"
      class="btn btn-primary roboto-400 px-5 my-auto"
      (click)="print_parameters()"
    >
      {{ "Print" | locale }}
    </button>


  </div>



  <div class="card mb-5 mt-5">
    <div class="container-fluid py-3 px-4">

      <div class="row mt-2">

        <div class="col-12 col-lg-10 ">

          <div class="form-group m-0 roboto-400">

            <input type="text"
             class="form-control px-5" id="param_autocomplete"
            [placeholder]="'Search by parameter code or name' | locale">

            <i [hidden]="!isLoaderHidden" class="fas search-icon fa-search"></i>

            <div [hidden]="isLoaderHidden"  #loader  class="spinner-border text-primary load" role="status">
              <span class="sr-only ">Loading...</span>
            </div>

          </div>

      </div>

        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end ">
            <button  data-toggle="modal" data-target="#param-modal"
             type="button" id="add-button" class="btn btn-outline-primary w-100 roboto-400" (click)="action = {'type' : 'New'}">{{'Add' | locale}} +</button>
          </div>
        </div>
      </div>

      <div *ngIf="pagination != null" class="row">
        <div class="col-12">
          <div class="table-responsive  ">
            <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th  scope="col" >{{'ID' | locale:2}}</th>
                <th width="40%" scope="col"> {{'code' | locale}}</th>
                <th width="40%" scope="col"> {{'Name' | locale}}</th>
                <th width="10%" scope="col"> {{'Statistics' | locale}}</th>
                <th width="10%" scope="col"> {{'Report' | locale}}</th>

                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>



            </thead>
            <br>


            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let parameter of Params | paginate: { id:'pagination' ,
              itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index; ">

                 <tr style="height: fit-content"  (click)="openModal({'type' : 'Edit' , 'id' : parameter.id})" valign="middle"  class="clickable_row roboto-400">
                  <td scope="row">{{i + 1}}</td>
                  <td width="40%">{{parameter.code}}</td>
                  <td width="40%">{{parameter.name }}</td>
                  <td width="10%">
                    <button
                      (click)="
                        $event.stopPropagation();
                        data.stats_info = {
                          name: 'parameters',
                          id: parameter.id,
                          label: parameter.name
                        };
                        data.statsModal.next();
                        data.fireModal('statsModal')
                      "
                      class="btn btn-light"
                    >
                      <i class="fas fa-chart-pie"></i>
                    </button>
                   </td>

                   <td width="10%">
                    <button
                      (click)="
                        $event.stopPropagation();
                        data.stats_info = {
                          name: 'parameters',
                          id: parameter.id,
                          label: parameter.name,
                          pdf: true
                        };
                        data.statsModal.next(true);
                        data.fireModal('statsModal')
                      "
                      class="btn btn-light"
                    >
                      <i class="fas fa-file"></i>
                    </button>
                   </td>

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>

            </tbody>
            </table>

            <ng-container *ngIf="pagination">
              <div class="col-12 mt-5">
                <div class="w-100 d-flex justify-content-center neue-med p-0">
                    <pagination-controls
                    responsive="true"
                    [previousLabel]="'Previous' | locale"
                    [nextLabel]="'Next' | locale"
                    (pageChange)="getparameters($event)"
                    id="pagination"></pagination-controls>
                </div>
              </div>
            </ng-container>

          </div>
        </div>

      </div>


    </div>
  </div>


<!-- <router-outlet></router-outlet> -->

<!-- Modal -->
<div class="modal fade" id="param-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
   <div class="modal-content">

      <app-loading [type]="'dialog'"></app-loading>
      <div class="modal-body">



        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
                <h5  class="roboto-500 start">{{ ((action.type == "Edit" ? "Edit " : "Add ") + 'a Parameter') | locale}}</h5>
            </div>
          </div>
        </div>

        <admin-layout-new-parameter [action]="action" (setCreated)="paramCreated()"></admin-layout-new-parameter>

      </div>
    </div>
  </div>
</div>


