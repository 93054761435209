import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { SnackService } from 'src/app/services/snack.service';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
declare let $;

@Component({
  selector: 'receptionist-layout-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {
  
  sms_counter = 0;
  phone_number = '';
  message = '';
  messageLength = 0;
  messgaeLimit = 70;
  pages = 1;

  constructor(public api : ApiService,private route : ActivatedRoute, public data:DataService, public snack: SnackService,) { }

  ngOnInit(): void {
    this.api.Common.Get.lab_info().subscribe(res =>{
      this.sms_counter = res['data']['sms_counter']
    });
  }

  updateAdditionalValue(){
    if(this.message.length > 70){
      if(this.message.length > (Math.floor(this.message.length / 70) * 70)){
        this.messgaeLimit = 70;
        this.messgaeLimit += Math.floor(this.message.length / 70) * 70
        this.pages = Math.floor((this.message.length / 70) + (this.message.length % 70 > 0 ? 1 : 0));
      }
    }else{
      this.messgaeLimit = 70;
      this.pages = 1
    }
  }

  getCharacterCount() {
    this.messageLength = this.message.length;
  }

  onSubmit(form){
    console.log('form' + form.value)
    console.warn('form' + form.value)
    this.api.Common.requests.send_message(form.value).subscribe(res => {
      if(!res['error']){
        this.snack.show('The message sent seccussfully')
          this.api.Common.Get.lab_info().subscribe(res =>{
            this.sms_counter = res['data']['sms_counter']
          })
        this.phone_number = '';
        this.message = '';
        $("#modalMessage").modal('hide');
      }else{
        this.snack.showerror('The message sent faild')
      }
    })
  }

}
