import { Component, OnInit, Output, EventEmitter, Input, OnChanges} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackService } from 'src/app/services/snack.service';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ProgressService } from 'src/app/services/progress.service';
import { DataService } from 'src/app/services/data.service';
import { Arabic } from "flatpickr/dist/l10n/ar";
declare var $:any;
declare let moment: any;

@Component({
  selector: 'app-new-support',
  templateUrl: './new-support.component.html',
  styleUrls: ['./new-support.component.scss']
})
export class NewSupportComponent implements OnInit {
  @Output() setCreated = new EventEmitter();
  ticket: any = {
    id: null,
    title:'',
    description :'',
    lab_id: null,
  };
  sureState:Boolean = false
  @Input() action :any;

  constructor(public data:DataService, public api : ApiService,public snack:SnackService,private router : Router,
      public progress : ProgressService) { }

  ngOnInit() {
    this.sureState = false
  }

  onSubmit(form){
    if(form.valid){
       // switch between creating and editing
      switch(this.action['type']){
        case 'New' : {
          form.value['lab_id'] = this.data.info['id']
          let vals = form.value;
          if (!vals["title"])
            return this.snack.showerror("Please enter title");
          if (!vals["description"])
            return this.snack.showerror("Please enter description");
          this.api.Admin.supports.add_ticket(form.value).subscribe(res => {
            console.log(res);
            if(res['error'] !=  true){
              this.snack.show('ticket successfully added');
              this.setCreated.emit(res)
            }
            else{
              this.snack.showerror('something went wrong')
            }
          })
          break;
        }
        case 'Edit' : {
          // this.api.Tickets.updateTicket(this.ticket.id , form.value).subscribe(res => {
          //   console.log(res);
          //   if(res['error'] !=  true){
          //     this.snack.show('ticket successfully edited');
          //     this.setCreated.emit(res)
          //   }
          //   else{
          //     this.snack.showerror('something went wrong')
          //   }
          // })
          break;
        }
      }
    } else {
      this.snack.showerror('Please enter all data correctly and try again');
    }
  }

  ngOnChanges(changes){
    this.sureState = false
    this.progress.onDP();
    console.log(changes);
    if (changes.action.currentValue.type != null) {
      let type = changes.action.currentValue.type
      console.log(type);
      this.setticketIfAvaliable(type)
    }
  }

  setticketIfAvaliable(type){
      switch(type){
        case 'New' : {
          this.ticket = {
            title:'',
            description :'',
          };
          this.progress.offDP();
          break;
        }
        case 'Edit' : {
          this.api.Admin.supports.get_ticket(this.action['id']).subscribe(res =>{
            this.ticket = res['data']
            this.progress.offDP();
          })
          break;
        }
      }
  }

  ngAfterViewInit() {
    // $("#fromPicker").flatpickr({
    //   dateFormat: "d-m-Y",
    //   defaultDate: moment().format("DD-MM-YYYY"),
    //   locale: this.data.language == "arabic" ? Arabic : null,
    //   position: "auto center",
    // });
  }

}
