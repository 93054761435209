<div class="row">
  <div class="col">
    <h2 class="roboto-300 start">{{ "Samples" | locale }}</h2>
  </div>
</div>

<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <div *roles="{ admin: true }" class="row mt-2">
      <div class="col-12 col-lg-2 mt-3 mt-lg-0">
        <div class="d-flex w-100 justify-content-end">
          <button
            type="button"
            id="add-button"
            class="btn btn-outline-primary w-100 roboto-400"
            (click)="
              ms.assign({
                title: 'Add a Sample',
                route: null,
                datum: {},
                inputs: { name: true, code: true },
                method: api.Admin.samples.add,
                message: 'Sample was added successfully',
                onSuccess: getSamples.bind(this)
              })
            "
          >
            {{ "Add" | locale }} +
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr class="neue-light roboto-400">
                <th scope="col">{{ "ID" | locale: 2 }}</th>
                <th width="50%" scope="col">{{ "code" | locale }}</th>
                <th width="50%" scope="col">{{ "Name" | locale }}</th>

                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>
            </thead>
            <br />

            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let sample of samples; let i = index">
                <tr
                  (click)="
                    ms.assign({
                      title: 'Edit a Sample',
                      action: 'Update',
                      route: null,
                      datum: sample,
                      inputs: { name: true, code: true },
                      method: api.Admin.samples.update,
                      message: 'Sample was updated successfully',
                      onSuccess: getSamples.bind(this),
                      deleteMethod: api.Admin.samples.remove,
                      deleteMessage: 'Sample was deleted successfully'
                    })
                  "
                  *roles="{ admin: true, type: 'click' }"
                  valign="middle"
                  class="clickable_row roboto-400"
                >
                  <td scope="row">{{ i + 1 }}</td>
                  <td width="50%">{{ sample.code }}</td>
                  <td width="50%">{{ sample.name }}</td>

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <router-outlet></router-outlet> -->
