

<h5 class="roboto-300 text-muted start"> {{'Expiration date is' | locale}}:  {{fixed_date}}</h5>

    <div class="container-fluid">
      <div class="row">
          <div class="form-group col">
            <label for="expiration_picker">{{ "New expiration date" | locale }}</label>
            <input
              [(ngModel)]="date"
              autocomplete="off"
              name="expiration_date"
              id="expiration_picker"
              class="flatpickr flatpickr-input active form-control"
              type="text"
              [placeholder]="'Select a Date' | locale"
            />
          </div>
        <div class="form-group">
          <label>&nbsp;</label>
          <button
          style="height: fit-content;"
          type="button"  (click)='setDate()' class=" form-control btn btn-outline-success tajwal-regium px-4">{{'Update' | locale}}
        </button>
        </div>
      </div>
    </div>

