


  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{'labs' | locale}}</h2>
    </div>

  </div>

  <div class="card mb-5 mt-5">
    <div class="container-fluid py-3 px-4">

      <div class="row">
        <div class="col">
          <div class="container">
            <div class="row">
              <div *ngFor="let object of globals" class="col p-2">
                <div class="card">

                  <div class="card-body p-2 px-3">
                    <p class="mb-0 pb-0 text-dark start">
                      {{object.label | locale}}
                    </p>
                    <p class="mb-0 pb-0 start">{{object.value}}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


          <div class="row mt-2 justify-content-center">
            <div *ngFor="let date of ['from', 'to']" class="col-5 mt-3">
              <div class="form-group">
                <label >{{'date ' + date | locale}}</label>
                <input
                  [name]="date"
                  [(ngModel)]="dates[date]"
                  [id]="date + 'Picker'"
                  (change)="getLabs()"
                  class="flatpickr flatpickr-input active form-control"
                  type="text"
                  placeholder="Select Date.."
                />
              </div>
            </div>

            <div class="col-12 col-lg-2 mt-3">
              <div class="form-group">
                <label >&nbsp;</label>
                <button
                 type="button" id="add-button" class="btn btn-outline-primary w-100 roboto-400" (click)="
                 ms.assign({
                   title: 'Add a Lab',
                   route: null,
                   datum: {},
                   inputs: {
                   name: true,
                   phone_number: true,
                   email: true,
                   lab_id:true },
                   method: api.Admin.labs.add,
                   message: 'Correpondent was added successfully',
                   onSuccess: getLabs.bind(this)
                 });">{{'Add' | locale}} +</button>
              </div>
            </div>

          </div>
      <!-- <div class="row mt-2">



        <div class="col-12 col-lg-2 mt-3 mt-lg-0">
          <div class="d-flex w-100 justify-content-end ">
            <button  data-toggle="modal" data-target="#cor-modal"
             type="button" id="add-button" class="btn btn-outline-primary w-100 roboto-400" (click)="action = {'type' : 'New'}">Add +</button>
          </div>
        </div>
        <div class="col-12 col-lg-10 ">

          <div class="form-group m-0 roboto-400">

            <input type="text"
            [(ngModel)]='searchByName'
            (change)="labsSearch()"
             class="form-control px-5" id="code"
            placeholder="Search with labs name">

            <i  class="fas search-icon fa-search"></i>

          </div>

      </div>


      </div> -->

      <div class="row">

        <div class="col-12">
          <div class="table-responsive  ">
            <table class="table mt-3">
            <thead>
              <tr style="text-align: center;" class="neue-light roboto-400">
                <th  scope="col" > {{'ID' | locale:2}} </th>
                <th style="min-width: 150px;" scope="col"> {{'Name' | locale}}</th>
                <th style="min-width: 150px;" scope="col"> {{'Email' | locale}}</th>
                <th style="min-width: 150px;" scope="col"> {{'Phone' | locale}}</th>
                <!-- <th style="width: 150px;" scope="col"> Default Pricing</th> -->
                <th style="min-width: 150px;" scope="col"> {{'Lab id' | locale}}</th>
                <th style="min-width: 150px;" scope="col"> {{'Number of downloaded' | locale}} </th>
                <th style="min-width: 150px;" scope="col"> {{'Downloaded total' | locale}} </th>
                <th style="min-width: 150px;" scope="col"> {{'Number of uploaded' | locale}}</th>
                <th style="min-width: 150px;" scope="col"> {{'Uploaded total' | locale}}</th>
                <th style="width:  50px;" scope="col"> {{'Edit' | locale}}</th>
                <!-- <th style="width: 150px;" scope="col"> Standard Price</th> -->
                <!-- <th style="width: 150px;" scope="col"> Original</th> -->
                <!-- <th style="width: 150px;" scope="col"> Discount</th> -->
                <!-- <th style="width: 150px;" scope="col"> Charged</th> -->
                <!-- <th style="width: 150px;" scope="col"> Paid</th> -->
                <!-- <th style="width: 150px;" scope="col"> Remaining</th> -->
                <!-- <th style="width:  50px;" scope="col"> Edit</th> -->
                <!-- <th style="width:  50px;" scope="col"> Default</th> -->
                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>



            </thead>
            <br>

            <tbody class="neue-light mt-2">
              <ng-container *ngFor="let lab of labs;let i = index">

                <tr
                (click)="router.navigateByUrl('admin/labs/' + lab.id)"
                valign="middle"  class="clickable_row roboto-400">
                <td scope="row">{{i + 1}}</td>
                <td style="min-width: 150px;">{{lab.name || '✖' }}</td>
                <td style="min-width: 150px;">{{lab.email || '✖'}}</td>
                <td style="min-width: 150px;">{{lab.phone_number || '✖'}}</td>
                <!-- <td style="min-width: 150px;">{{lab.pricing || '✖'}}</td> -->
                <td style="min-width: 150px;">{{lab.id || '✖'}}</td>
                <td style="min-width: 150px;">{{lab.num_of_requests?.downloaded}}</td>
                <td style="min-width: 150px;">{{lab.total_downloaded}}</td>
                <td style="min-width: 150px;">{{lab.num_of_requests?.uploaded }}</td>
                <td style="min-width: 150px;">{{lab.total_uploaded }}</td>

                <td style="width: 50px;">
                  <button (click)="$event.stopPropagation();
                  ms.assign({
                    title: 'Edit a Lab',
                    action:'Update',
                    route: null,
                    datum: lab,
                    inputs: {
                      name: true,
                      phone_number: true,
                      email: true},
                    method: api.Admin.labs.update,
                    message: 'Lab was updated successfully',
                    onSuccess: getLabs.bind(this),
                    deleteMethod:api.Admin.labs.remove,
                    deleteMessage:'Lab was deleted successfully'
                  });" type="button" class="btn btn-outline-info p-0 px-3"><i class="fas fa-edit"></i></button>
                </td>

                <!-- <td style="width: 150px;">{{correspondent.payment.total}}</td> -->
                <!-- <td style="width: 150px;">{{correspondent.payment?.total || 0}}</td> -->
                <!-- <td style="width: 150px;">{{correspondent.payment.total_discount || 0}}</td> -->
                <!-- <td style="width: 150px;">{{(correspondent.payment?.total_after_discount | number) || 0}}</td> -->
                <!-- <td style="width: 150px;">{{correspondent.payment?.paid | number}}{{data.info['currency']}}</td> -->
                <!-- <td style="width: 150px;">{{correspondent.payment?.remaining | number}}{{data.info['currency']}}</td> -->
                <!-- <td style="width: 50px;">
                  <button (click)="openModal({'type' : 'Edit' , 'id' : correspondent.id}); $event.stopPropagation()" type="button" class="btn btn-outline-info p-0 px-3"><i class="fas fa-edit"></i></button>
                </td>
                <td style="width: 50px;" (click)="$event.stopPropagation();">
                  <input (change)="$event.preventDefault();selectDefault(correspondent)"  [checked]="correspondent.is_default"  type="checkbox" style="width: 35%;height: 100%;">
                </td> -->
                <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                  <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                </h5></a>
                </td> -->
                <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
              </tr>
              </ng-container>



            </tbody>
            </table>
          </div>
        </div>

      </div>


    </div>
  </div>


<!-- <router-outlet></router-outlet> -->

