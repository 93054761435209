import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'receptionist-layout-main-component.scss',
  templateUrl: './main-component.html',
  styleUrls: ['./main-component.scss']
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
