<div class="row">

  <ng-container *ngIf="data.me['id'] == 1">
    <div *roles="{ admin: true }" class="col-12 mb-3">
      <div class="card">
        <div class="card-body">
          <admin-layout-expiration></admin-layout-expiration>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="days_left < 31">
    <div *roles="{ admin: true }" class="col-12">
      <div class="alert start" role="alert"
        [ngClass]="{'alert-warning' : days_left>10, 'alert-danger' : days_left < 11}"
      >
        {{
          ('The system subscription expires in'
            | locale) + ' '+ days_left + ' ' + ("days" | locale)
        }}
      </div>
    </div>
  </ng-container>


  <div class="col-12 container-fluid">
    <div style="width: 100%;" class="row m-0">
      <div class="col">
        <h2 class="roboto-300 start">{{ "Tests | Profiles" | locale }}</h2>
      </div>
      <!-- <div class="col">
          <admin-layout-expiration-date></admin-layout-expiration-date>
        </div> -->
      <!-- <div class="col-4"> -->
      <button
        *roles="{ admin: true }"
        class="btn btn-outline-primary px-5"
        data-target="#print_modal"
        data-toggle="modal"
      >
        {{ "Print" | locale }}
      </button>
    </div>
  </div>



  <!-- </div> -->
</div>
<ng-container *ngIf="data.me?.sub_roles?.indexOf('stats') != -1">
  <app-stat *roles="{ admin: true }"></app-stat>
</ng-container>

<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <div class="row justify-content-center mt-2">
      <div class="col-sm-6  col-md">
        <div class="form-group m-0 roboto-400">
          <input
            type="text"
            class="form-control px-5"
            id="test_autocomplete"
            [placeholder]="'Search by Test | Profile code or name' | locale"
          />

          <i [hidden]="!isLoaderHidden" class="fas search-icon fa-search"></i>

          <div
            [hidden]="isLoaderHidden"
            #loader
            class="spinner-border text-primary load"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group m-0 roboto-400">
          <select
            [(ngModel)]="category_id"
            name="category_id"
            id="cats"
            class="custom-select"
            (change)="changeFilter()"
          >
            <option [value]="-1">{{ "Select a category" | locale }}</option>
            <option *ngFor="let category of [{name:'select a category', id:-1}].concat(data.categories)" [value]="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-2 mb-2">
        <div class="form-group m-0 roboto-400">
          <select
            [(ngModel)]="tests_status"
            name="tests_status"
            class="custom-select"
            (change)="changeFilter()"
          >
            <option value="active">{{ "Active" | locale }}</option>
            <option value="inactive">{{ "Inactive" | locale }}</option>
          </select>
        </div>
      </div>
      <div class="col-2 mb-2">
        <div class="form-group m-0 roboto-400">
          <select
            [(ngModel)]="tests_alphabetical_order"
            name="tests_alphabetical_order"
            class="custom-select"
            (change)="changeFilter()"
          >
            <option value="id">{{ "order by id" | locale }}</option>
            <option value="code">{{ "order by code" | locale }}</option>
            <option value="name">{{ "order by name" | locale }}</option>
          </select>
        </div>
      </div>
      <button
      *roles="{receptionist:true}"
      class="btn btn-outline-primary mb-2"
      data-target="#calc_modal"
      data-toggle="modal"
    >
      {{'Total price calculation' | locale}}
    </button>

    <div *roles="{admin:true,technician:true}"
     class="col-12">

    </div>

      <ng-container *roles="{ admin: true }">
        <div class="mx-2 mb-2">
          <div class="d-flex w-100 justify-content-end">
            <!-- data-toggle="modal"
            data-target="#profile-modal" -->
            <!-- (click)="action = { type: 'New', isPackage: true }" -->
            <button
            (click)="ms.assign({
              title: 'Add a Package',
              route: null,
              datum: {},
              inputs: { name: true, code: true, isPackage:true },
              method: api.Admin.packages.add,
              message: 'Package was added successfully',
              onSuccess: getTests.bind(this,page, key, pricing_id)
            });"
              type="button"
              id="add-button"
              class="btn btn-outline-primary w-100 roboto-400"
            >
              {{ "Add Package" | locale }}
            </button>
          </div>
        </div>

        <!-- <div class="mx-2 mb-2">
          <div class="d-flex w-100 justify-content-end">
            <button
              data-toggle="modal"
              data-target="#profile-modal"
              type="button"
              id="add-button"
              class="btn btn-outline-primary w-100 roboto-400"
              (click)="
                action = { type: 'New', isPackage: false, isProfile: true }
              "
            >
              {{ "Add Profile" | locale }}
            </button>
          </div>
        </div> -->

        <div class="mx-2 mb-2">
          <div class="d-flex w-100 justify-content-end">
            <button
              data-toggle="modal"
              data-target="#test-modal"
              type="button"
              id="add-button"
              class="btn btn-outline-primary w-100 roboto-400"
              (click)="action = { type: 'New' }"
            >
              {{ "Add Test" | locale }}
            </button>
          </div>
        </div>
        <div class="mx-2 mb-2">
          <div class="d-flex w-100 justify-content-end">
            <button
              class="btn btn-outline-primary w-100 roboto-400"
              (click)="openTestsPriceModal()"
            >
              {{ "Update All Tests Price" | locale }}
            </button>
          </div>
        </div>
        <div class="mx-2 mb-2">
          <div class="d-flex w-100 justify-content-end">
            <button
              class="btn btn-outline-primary w-100 roboto-400"
              (click)="openPrintRenameTestsReportModal()"
            >
              {{ "Update Tests By Codes" | locale }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="neue-light roboto-400">
                <th scope="col">{{ "ID" | locale: 2 }}</th>
                <th width="15%" scope="col">
                  {{ "code" | locale }}
                </th>
                <th scope="col">{{ "Name" | locale }}</th>
                <th scope="col">{{ "Type" | locale }}</th>
                <th *roles="{ admin: true }" scope="col">
                  {{ "Statistics" | locale }}
                </th>
                <th *roles="{ admin: true }" scope="col">
                  {{ "Report" | locale }}
                </th>
                <!-- <th scope="col">pricing </th> -->
                <th style="min-width: 200px" scope="col">
                  <select
                    *ngIf="data.pricings.length"
                    name="type_id"
                    id="type_id"
                    [(ngModel)]="pricing_id"
                    class="custom-select"
                    (change)="onChange($event.target.value)"
                  >
                    <option
                      *ngFor="let price of data.pricings"
                      [value]="price.id"
                    >
                      <!-- <option selected > -->
                      {{ price.name }}
                    </option>
                  </select>
                </th>
              </tr>
            </thead>
            <br />

            <tbody class="neue-light mt-2 text-center">
              <ng-container
                *ngFor="
                  let test of tests
                    | paginate
                      : {
                          id: 'pagination',
                          itemsPerPage: pagination.per_page,
                          currentPage: pagination.current_page,
                          totalItems: pagination.total
                        };
                  let i = index
                "
              >
                <tr
                  *roles="{ admin: true, type: 'click' }"
                  style="height: auto"
                  (click)="
                    openModal({
                      type: 'Edit',
                      id: test.id,
                      isProfile: test.isProfile,
                      isPackage: test.isPackage
                    })
                  "
                  valign="middle"
                  class="clickable_row roboto-400"
                >
                  <td scope="row">{{ i + 1 }}</td>
                  <td>{{ test.code }}</td>
                  <td>{{ test.name }}</td>
                  <td>
                    {{
                      (test.isProfile
                        ? "Profile"
                        : test.isPackage
                        ? "Package"
                        : "Test"
                      ) | locale: 2
                    }}
                  </td>
                  <td *roles="{ admin: true }">
                    <button
                      (click)="
                        $event.stopPropagation();
                        data.stats_info = {
                          name: 'tests',
                          id: test.id,
                          label: test.name
                        };
                        data.statsModal.next();
                        data.fireModal('statsModal')
                      "
                      class="btn btn-light"
                    >
                      <i class="fas fa-chart-pie"></i>
                    </button>
                  </td>

                  <td *roles="{ admin: true }">
                    <button
                      (click)="
                        $event.stopPropagation();
                        data.stats_info = {
                          name: 'tests',
                          id: test.id,
                          label: test.name,
                          pdf: true
                        };
                        data.statsModal.next(true);
                        data.fireModal('statsModal')
                      "
                      class="btn btn-light"
                    >
                      <i class="fas fa-file"></i>
                    </button>
                  </td>

                  <td style="min-width: 200px">
                    {{ test.amount || 0 }}{{ data.info["currency"] }}
                  </td>
                  <!-- <td class="col-2"></td> -->

                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="pagination" class="col-12 mt-3">
        <div class="w-100 d-flex justify-content-center neue-med p-0">
          <pagination-controls

            responsive="true"
            [previousLabel]="'Previous' | locale"
            [nextLabel]="'Next' | locale"
            (pageChange)="getTests($event, key || '')"
            id="pagination"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <router-outlet></router-outlet> -->

<!-- Modal -->
<div
  class="modal fade"
  id="test-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <app-loading [type]="'dialog'"></app-loading>
      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
              <h5 class="roboto-500 start">
                {{
                  (action.type == "Edit" ? "Edit" : "Add") + " test" | locale
                }}
              </h5>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!action.isProfile && !action.isPackage">
          <admin-layout-new-test
            [tests]="tests"
            [action]="action"
            (setCreated)="testCreated()"
          ></admin-layout-new-test>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="profile-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <app-loading [type]="'dialog'"></app-loading>
      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
              <h5 class="roboto-500 start">
                {{
                  (action.type == "Edit" ? "Edit" : "Add") +
                    " " +
                    (action.isPackage ? "Package" : "Profile") | locale
                }}
              </h5>
            </div>
          </div>
        </div>

        <!-- <ng-container *ngIf="action.isProfile || action.isPackage">
          <admin-layout-new-profile
            [action]="action"
            (setCreated)="testCreated()"
          ></admin-layout-new-profile>
        </ng-container> -->
      </div>
    </div>
  </div>
</div>

<div class="modal" id="TestsPriceModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content modal-content-demo">
      <div class="modal-header">
        <h6 class="modal-title">{{ "Update All Tests Price" | locale }}</h6>
      </div>
      <admin-layout-update-all-price></admin-layout-update-all-price>
    </div>
  </div>
</div>

<div class="modal" id="PrintRenameTestsReport">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content modal-content-demo">
      <div class="modal-header">
        <h6 class="modal-title">{{ "Update Tests By Codes" | locale }}</h6>
      </div>
      <div class="modal-body">
        {{ "Are You Sure?" | locale }}
      </div>
      <div class="modal-footer">
        <button class="btn ripple btn-primary"
        (click)="PrintRenameTestsReport()">{{'Yes' | locale}}</button>
        <button class="btn ripple btn-secondary" data-dismiss="modal" type="button">{{'Cancel' | locale}}</button>
    </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="print_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{ "Select a pricing" | locale }}</h5>
      </div>

      <div class="modal-body pt-0">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="form-group form-check mb-0 mt-2 start mb-1">
              <input
                type="checkbox"
                class="form-check-input"
                #ex
                id="ex"
              />
              <label
                class="form-check-label"
                [ngClass]="{ 'mr-4': data.isRTL }"
                for="ex"
                >{{ "Excel" | locale }}</label
              >
            </div>

            <div class="form-group form-check mb-0 mt-2 start mb-1">
              <input
                type="checkbox"
                class="form-check-input"
                #filtered
                id="filtered"
              />
              <label
                class="form-check-label"
                [ngClass]="{ 'mr-4': data.isRTL }"
                for="filtered"
                >{{ "With price" | locale }}</label
              >

            </div>
            <div class="form-group form-check mb-0 mt-2 start mb-1">
              <input
                type="checkbox"
                class="form-check-input"
                #alphabetical
                id="alphabetical"
              />
              <label
                class="form-check-label"
                [ngClass]="{ 'mr-4': data.isRTL }"
                for="alphabetical"
                >{{ "A-Z" | locale }}</label
              >

            </div>

            <hr class="col-12 my-1" />

            <!-- <div class="form-check">
              <input class="form-check-input"
              type="checkbox">
              <label class="form-check-label" >
                Excel
              </label>
            </div> -->
          </div>
          <div class="row">
            <!-- (ngSubmit)="print(selectPricingForm)" -->

            <div
              *ngFor="let pricing of data.pricings"
              class="col-12 p-1 mb-2 clickable text-link"
              (click)="print(pricing.id, ex, filtered,alphabetical)"
            >
              <div class="d-flex h-100 align-items-center">
                <div class="">
                  <!-- [(ngModel)]="request.urgent_flag" -->
                  <p class="text-dark mb-0">{{ pricing.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="calc_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{'Total price calculation' | locale}}</h5>
      </div>

      <div class="modal-body">
        <div class="row justify-content-center">
          <div class="col-4 mx-2">
            <h4>{{'Total' | locale}}: {{total || 0}}</h4>
          </div>

          <select
          *ngIf="data.pricings.length && pricing_id != null"
          style="display: inline-block;"
          name="type_id"
          id="type_id"
          [(ngModel)]="pricing_id"
          class="custom-select col-4 mx-2"
          (change)="onChange($event.target.value)"
        >
          <option *ngFor="let price of data.pricings" [value]="price.id">
            <!-- <option selected > -->
            {{ price.name }}
          </option>
        </select>

        </div>

        <!-- <div class="btn-group btn-group-toggle mb-2" data-toggle="buttons">
          <label class="btn btn-outline-primary active">
            <input
              (click)="toggle('t')"
              type="radio"
              name="options"
              [value]="'t'"
              [(ngModel)]="searchType"
              id="option1"
            />
            Tests
          </label>
          <label class="btn btn-outline-primary">
            <input
              (click)="toggle('p')"
              type="radio"
              name="options"
              [value]="'p'"
              [(ngModel)]="searchType"
              id="option2"
            />
            Profiles
          </label>
        </div> -->

        <selector
          title="tests"
          (setSelectedItem)="setSelectedTest($event)"
          top="98px"
        ></selector>
        <!-- <app-profile-select
          [requestId]="request_id"
          (setSelectedProfile)="setSelectedProfile($event)"
          [hidden]="searchType == 't' ? true : false"
        ></app-profile-select> -->

        <div class="table-responsive mt-2">
          <table class="table">
            <thead>
              <tr class="neue-light">
                <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                <th width="15%" scope="col">{{'Code' | locale}}</th>
                <th scope="col">{{'Name' | locale}}</th>
                <th scope="col">{{'Price' | locale}}</th>
                <th scope="col">{{'Remove' | locale}}</th>

                <!-- <th scope="col"> Birthdate</th> -->
                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>
            </thead>

            <tbody class="neue-light">
              <ng-container *ngFor="let test of selectedTests;let i = index">
                <tr
                  valign="middle"
                  (mousedown)="$event.preventDefault()"
                  class="clickable_row"
                >
                  <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                  <td>{{ test.code }}</td>
                  <td>{{ test.name }}</td>

                  <td>{{ (test.amount || '0') + data.info['currency']}}</td>
                  <td>
                    <button
                      (click)="removetest(i)"
                      type="button"
                      class="btn btn-outline-danger"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>

                  <!-- <td>{{patient.birthdate | date}}</td> -->
                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                    <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                  </h5></a>
                  </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer">
        <!-- <button class="btn btn-primary col-2">Print</button> -->
      </div>
    </div>
  </div>
</div>
