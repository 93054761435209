<div id="stat-container" class="row mt-3">
  <div class="col-6 col-lg-3 h-100">
    <div class="card stat-card h-100 mt-2">
      <div [ngStyle]="data.isRTL? {'left' : '19px'}:{'right' : '19px'}" class="circle">
        <div class="w-100 h-100 d-flex">
          <img src="assets/icons/cal.svg" class="h-100" width="40px" alt="">
        </div>
      </div>
      <div class="row h-100">
        <div class="col-12 h-100 p-3 pl-4">
          <div class="h-100 d-flex justify-content-start
          align-items-center">
            <div class="container-fluid">

              <div class="row mt-1">
                <div class="col-12">

                  <h6 class="roboto-400">{{day | locale}}</h6>

                </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">

                  <h4 class="roboto-300">{{today}}</h4>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-6 col-lg-3 h-100">

    <div class="card stat-card h-100 mt-2">

      <div [ngStyle]="data.isRTL? {'left' : '19px'}:{'right' : '19px'}" class="circle " >
        <div class="w-100 h-100 d-flex">
          <img src="assets/icons/req.svg" class="h-100" width="40px" alt="">

        </div>
      </div>

      <div class="row h-100">
        <div class="col-12 h-100 p-3 pl-4">
          <div class="h-100 d-flex justify-content-start
          align-items-center">
            <div class="container-fluid">

              <div class="row mt-1">
                <div class="col-12">

                  <h6 class="roboto-400"> {{'Requests' | locale}}</h6>

                </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">

                  <h4 class="roboto-300">{{stats.requests_today}}</h4>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-6 col-lg-3 h-100">

    <div class="card stat-card h-100 mt-2">

      <div [ngStyle]="data.isRTL? {'left' : '19px'}:{'right' : '19px'}" class="circle">
        <div class="w-100 h-100 d-flex">
          <img src="assets/icons/pat.svg" class="h-100" width="40px" alt="">

        </div>
      </div>


      <div class="row h-100">
        <div class="col-12 h-100 p-3 pl-4">
          <div class="h-100 d-flex justify-content-start
          align-items-center">
            <div class="container-fluid">

              <div class="row mt-1">
                <div class="col-12">

                  <h6 class="roboto-400"> {{'Patients' | locale}}</h6>

                </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">

                  <h4 class="roboto-300">{{stats.patients_today}}</h4>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-6 col-lg-3 h-100">

    <div class="card stat-card h-100 mt-2">

      <div [ngStyle]="data.isRTL? {'left' : '19px'}:{'right' : '19px'}" class="circle">
        <div class="w-100 h-100 d-flex">
          <img src="assets/icons/chemical.svg" class="h-100" width="40px" alt="">

        </div>
      </div>

      <div class="row h-100">
        <div class="col-12 h-100 p-3 pl-4">
          <div class="h-100 d-flex justify-content-start
          align-items-center">
            <div class="container-fluid">

              <div class="row mt-1">
                <div class="col-12">

                  <h6 class="roboto-400"> {{'Tests | Profiles' | locale}}</h6>

                </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">

                  <h4 class="roboto-300">{{stats.tests_total}}</h4>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
