import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';


export interface TestParams {
    page:number;
    code?: string;
    pricing_id?:number;
    category?:number;
    status?: string;
}

export interface parametersParams {
  page:number;
  code?: string;
  pricing_id?:number;
  category?:number;
  status?: string;
}

export interface doctorsParams {
  page:number;
  name?: string;
}

@Injectable()
export class ApiService extends HttpService {

  base_print_report = environment.baseURL + '/';

  login(loginBody) {
    return super.post({
      route: 'auth/login',
      prefixed: false,
      body: loginBody,
    });
  }

  ltl = {
    download: (params:{number:string}) => super.get({ route: 'ltl/requests/download', prefixed: false, params }),
    upload: body => super.post({ route: 'ltl/requests/upload', body, prefixed: false }),
  };

  me = {
    get: () => super.get({ route: 'me', prefixed: false }),
    update: (body) => super.put({ route: 'me', body, prefixed: false }),
  };

  casa = {
    get : (requestID,testID)=> super.get({route: `requests/${requestID}/tests/${testID}/casa`, prefixed:false}),
    add : (requestID,testID, body)=> super.post({route: `requests/${requestID}/tests/${testID}/casa`, prefixed:false, body}),
    remove : (requestID,testID)=> super.delete({route: `requests/${requestID}/tests/${testID}/casa`, prefixed:false}),
  }

  requests = {
    get: (params) => {
      Object.keys(params).forEach(key => {
        if (!params[key] && params[key] !== 0) delete params[key];
      })
      return super.get({ route: 'requests', prefixed: false, params });
    },

    get_one: (id) => {
      return super.get({ route: 'requests/' + id, prefixed: false }).pipe(map(res => res['data']));
    },
    
    send_sms: (id) => {
      return super.get({ route: 'sms/send/' + id, prefixed: false }).pipe(map(res => res['data']));
    },

    send_message: (body) => super.post({ route: 'sms/send-message', body, prefixed:false }),

    add: (body) => super.post({ route: 'requests', body, prefixed:false }),
    update: (body) => super.put({ route: 'requests/' + body.id, body , prefixed:false}),
    remove: (id) => super.delete({ route: 'requests/' + id, prefixed:false }),

    get_total: (id) => {
      return super
        .get({ route: 'requests/' + id + '/total' , prefixed:false })
        .pipe(map(res => res['data']));
    },

    set_discount: (id,body) => super.post({ route: `requests/${id}/discount`, body, prefixed:false }),
    update_laboratory_information: (id,body) => super.put({ route: `requests/${id}/laboratory-information`, body, prefixed:false }),
    draw_sample: (id,body) => super.put({ route: `requests/${id}/draw`, body, prefixed:false }),
    toggle_delivry: id => super.put({ route: `requests/${id}/toggle-delivery`, prefixed:false }),
    get_categories: id => {
      return super
        .get({ route: 'requests/' + id + '/categories' , prefixed:false })
        .pipe(map(res => res['data']));
    },

    validate_toggle: requests_id => super.put({route:`requests/${requests_id}/toggle-validation`, prefixed:false}),

    deleted_tests: id => super.get({route: `requests/${id}/deleted-tests`, prefixed:false}).pipe(map(res => res['data'])),
    active_tests: id => super.get({route: `requests/${id}/active-tests`, prefixed:false}).pipe(map(res => res['data'])),
    samples: {
      get: id => super.get({route: `requests/${id}/samples`, prefixed:false}).pipe(map(res => res['data'])),
      bacteria:{
        add: (id, body) => super.post({route: `requests/${id}/samples/bacteria`, body, prefixed:false}).pipe(map(res => res['data'])),
        remove: (id, body) => super.post({route: `requests/${id}/samples/bacteria/detach`, body, prefixed:false}).pipe(map(res => res['data'])),
        CFU: (id, body) => super.post({route: `requests/${id}/samples/bacteria/cfu`, body, prefixed:false}),
        antibiotics:{
          add: (id, body) => super.post({route: `requests/${id}/bacteria/antibiotic`, body, prefixed:false}).pipe(map(res => res['data'])),
          remove: (id, body) => super.post({route: `requests/${id}/bacteria/antibiotic/detach`, body, prefixed:false}).pipe(map(res => res['data'])),
        }
      }

    },

    tests: {
      validate_toggle: (requests_id, test_id) => super.put({route:`requests/${requests_id}/tests/${test_id}/toggle-validation`, prefixed:false}),
      repeat_toggle: (requests_id, test_id) => super.put({route:`requests/${requests_id}/tests/${test_id}/toggle-repeat`, prefixed:false}),
      change_device: (requests_id, test_id, body) => super.put({route:`requests/${requests_id}/tests/${test_id}/change-device`, body, prefixed:false}),
    },
    payments: {
      get: id => {
        return super
          .get({ route: 'requests/' + id + '/payments' , prefixed:false })
          .pipe(map(res => res['data']));
      },

      add: (id,body) => super.post({ route: `requests/${id}/payments`, body, prefixed:false }),
      remove: (id, payment_id) => super.delete({ route: `requests/${id}/payments/${payment_id}`, prefixed:false }),
    },


  };

  patients = {
    get: (params: {
      patient_search_by?:string;
      patient_search?:string;
      reception_number?: number;
      name?: string;
      paginated?: any;
      page?: any;
      'with[]'?:Array<string>
    }) => super.get({ route: 'patients', params , prefixed:false}),
    get_one: id => super.get({ route: 'patients/' + id, prefixed:false}).pipe(map(res=> res['data'])),
    add: (body) => super.post({ route: 'patients', body , prefixed:false}),
    update: (body) => super.put({ route: 'patients/' + body.id, body , prefixed:false}),
    remove: (id) => super.delete({ route: 'patients/' + id , prefixed:false}),
  };

  Get = {
    genders: () =>super.get({ route: 'genders', prefixed: false }).pipe(map((res) => res['data'])),
    civilities: () =>super.get({ route: 'civilities', prefixed: false }).pipe(map((res) => res['data'])),
    correspondents: (params:{name:string, 'with[]'?:Array<string>}= {name:''}) =>super.get({ route: 'correspondents', prefixed: false, params }).pipe(map((res) => res['data'])),
    fitered_correspondents: (params:{subcategory_id:number}) =>super.get({ route: 'correspondents', prefixed: false, params }).pipe(map((res) => res['data'])),
    fitered_withSearch_correspondents: (params:{name:string, 'with[]'?:Array<string>, subcategory_id:number}) =>super.get({ route: 'correspondents', prefixed: false, params }).pipe(map((res) => res['data'])),
    categories: () =>super.get({ route: 'categories', prefixed: false }).pipe(map((res) => res['data'])),
    subcategories: () =>super.get({ route: 'sub-categories', prefixed: false }).pipe(map((res) => res['data'])),
    fitler_categories: () =>super.get({ route: 'fitler_categories', prefixed: false }).pipe(map((res) => res['data'])),
    units: (params?:{code:string}) =>super.get({ route: 'units', prefixed: false, params }).pipe(map((res) => res['data'])),
    user_units: (params?:{code:string}) =>super.get({ route: 'user_units', prefixed: false, params }).pipe(map((res) => res['data'])),
    identity_types: () =>super.get({ route: 'identity-types', prefixed: false }).pipe(map((res) => res['data'])),
    doctors: (params:{name:string} = {name: ''}) =>super.get({ route: 'doctors', prefixed: false }).pipe(map((res) => res['data'])),
    doctorsSearch: (params:{name:string} = {name: ''}) =>super.get({ route: 'doctors', prefixed: false, params }),
    pricings: () =>super.get({ route: 'pricing', prefixed: false }).pipe(map((res) => res['data'])),
    payment_methods: () =>super.get({ route: 'payment-methods', prefixed: false }).pipe(map((res) => res['data'])),
    priced_tests: (body) =>super.post({ route: 'tests/prices', prefixed: false, body }),
    samples: (params?: { name: string }) =>super.get({ route: 'samples', prefixed: false, params }).pipe(map(res => res['data'])),
    activity: (params: { code: string }) =>super.get({ route: 'activity_report', prefixed: false, params }).pipe(map((res) => res['data'])),
    lab_info: ()=> super.get({ route: 'lab', prefixed: false }),
    get_update_date: ()=> super.get({ route: 'get-update-date', prefixed: false }),
    treasuries: (params?:{is_bank:number})=> super.get({ route: 'treasuries', prefixed: false, params }).pipe(map(res => res['data'])),
    stat:() =>  super.get({ route: 'statistics' }),
    tests: (params:TestParams) =>super.get({ route: 'tests', prefixed: false, params }),
    test: id =>super.get({ route: 'admin/tests/' + id, prefixed: false}).pipe(map((res) => res['data'])),
    doctorsWithPaginate: (params:doctorsParams) =>super.get({ route: 'doctors', prefixed: false, params }),
    parameters: (params:parametersParams) =>super.get({ route: 'parameters', prefixed: false, params }),
    antibiotics: (params:{code:string} = {code:''}) =>super.get({ route: 'antibiotics', prefixed: false, params }).pipe(map((res) => res['data'])),
    bacteria: (params:{code?:string} = {}) =>super.get({ route: 'bacterias', prefixed: false, params }).pipe(map((res) => res['data'])),
    // antibiotic_bacteria: id =>super.get({ route: 'antibiotic/'+id+'/bacteria', prefixed: false }).pipe(map((res) => res['data'])),
    filters : {
      requests:(params: {page:number, query}) => super.get({route: 'filters/requests?page=' + params.page + params.query, prefixed:false}),
      results:(query, params:{page:number} = {page:1}) => super.get({route: 'filters/results?page=' + params.page + query, prefixed:false}),
      history:(query, params:{page:number} = {page:1}) => super.get({route: 'filters/history?page=' + params.page + query, prefixed:false}),

    },
  };


  printables = {

    receptionists: {
      transaction: (id, query= "") => super.get({route:`docs/receptionists/` + id + '/transactions'+ query, prefixed:false,pdf:true}),
      allTransaction: (query= "") => super.get({route:'docs/receptionists/allTransactions'+ query, prefixed:false,pdf:true}),
      requests: (id, query= "") => super.get({route: `docs/receptionists/` + id + '/requests'+ query, prefixed:false,pdf:true}),
    },


    // print_result : (id,params:{devices:number,areas:number, ['tests_ids[]']:Array<number>}) =>
    // super.get({route: `docs/requests/${id}/results`, params, prefixed:false, pdf:true}),

    print_result : (id, devices,areas,families,lab_info_header,outside_note, healthCare, tests)=>
    super.get({route: `docs/requests/${id}/results` + tests, prefixed:false,pdf:true, params: {
        devices : devices?"1":"0",
        areas : areas?"1":"0",
        families : families?"1":"0",
        lab_info_header: lab_info_header?"1":"0",
        outside_note: outside_note?"1":"0",
        healthCare: healthCare?"1":"0"
      }}),

    print_tests : (params:{pricing:number, excel:number,filtered:number,alphabetical:number})=> super.get({route: `docs/tests`, params, prefixed:false, pdf:true}),

    print_reciept : (id)=> super.get({route: `docs/correspondents/${id}/receipt`, prefixed:false, pdf:true}),

    print_reciept_manual : (correspondet,id)=> super.get({route: `docs/correspondents/${correspondet}/${id}/receipt-manual`, prefixed:false, pdf:true}),
    
    print_request_reciept_manual : (id)=> super.get({route: `docs/requests/${id}/receipt?payer=correspondent`, prefixed:false, pdf:true}),

    print_reciept_auto : (id,amount,note)=> super.get({route: `docs/correspondents/${id}/${amount}/receipt-auto?note=` + note, prefixed:false, pdf:true}),

    print_correspondent_account : (id, min_date, max_date)=> super.get({route: `docs/correspondents/${id}/account?min_date=`+min_date+`&max_date=`+max_date, prefixed:false, pdf:true}),

    results_search:  (query) => super.get({route: `docs/results-search?` + query,prefixed:false,pdf:true}),

    histo:  id => super.get({route: `requests/${id}/reports/histo`,pdf:true}),


    receptionists_revenues: (params:{date:string,from_time:string,to_time:string, excel:number}) => super.get({route:'docs/receptionists', params,prefixed:false,pdf:true}),


    correspondent_requests: (id,params:{min_date:string, max_date:string, asMail:number, withTests:number,excel:number}) => super.get({route: `docs/correspondents/${id}/requests` , params,prefixed:false,pdf:true}),

    getStats: (name, id, params:{min_date:string, max_date:string, per:string})=>{
      return super.get({route: `docs/statistics/${name}/${id}` , params, prefixed:false,pdf:true})
    },

    getParameterStatsPDF: (name, id, params:{min_date:string, max_date:string, per:string})=>{
      return super.get({route: `docs/statistics/${name}/${id}` , params, prefixed:false,pdf:true})
    },

    print_activity: (params:{min_date:string, max_date:string})=>{
      return super.get({route: 'docs/activity-log' , params, prefixed:false,pdf:true})
    },


    print_requests: (queries:string ='')=>{
      return super.get({route: 'docs/requests?' + queries , prefixed:false,pdf:true})
    },

    print_invoices: (queries:string ='')=>{
      return super.get({route: 'docs/invoices?' + queries , prefixed:false,pdf:true})
    },

    print_card : (id)=>{
      return super.get({route: `docs/patients/${id}/patient-card`, prefixed:false,pdf:true});

    },

    PrintPatientsRequests : (params:{min_date:string,max_date:string,excel:number,['patients[]']:Array<string>})=>{
      // window.open (this.base + `requests/${id}/printables/reciept`);

      return super.get({route: 'docs/patients',params, prefixed:false,pdf:true});

    },

    PrintTests : (params:{pricing:string | number})=>{
      return super.get({route: `docs/tests`, prefixed:false,pdf:true, params });

    },

    PrintReciept : (id)=>{
      return super.get({route: `docs/requests/${id}/receipt`, prefixed:false,pdf:true });

    },

    print_parameters : (params:{excel:number} = {excel:1})=>{
      return super.get({route: `docs/parameters`, prefixed:false,pdf:true, params });

    },
    PrintBarcodes : (id, one_per_row, query = "")=>{
      return super.get({route: `docs/requests/${id}/barcodes?one_per_row=${one_per_row}&${query}`,prefixed:false,  pdf:true});

    },

    PrintBarcodesForZebra : (id, one_per_row, query = "")=>{
      let url=this.base +`requests/${id}/barcodes?one_per_row=${one_per_row}&${query}`

      return super.post({route: this.printZebraURL+'/zebra', body: {
        url:url,
        username: sessionStorage.getItem('userName'),

      }, abs:true});

    },
    PrintdetailedInvoiceForZebra : (id)=>{
      let query = this.base +`docs/requests/${id}/detailedInvoice`

      return super.post({route: this.printZebraURL+'/invoice',body: {
        url:query
      }, abs:true});

    },

    PrintdetailedInvoice : (id)=>{
      return super.get({route: `docs/requests/${id}/detailed-invoice`,prefixed:false,  pdf:true});
    },

    PrintRecieptForZebra : (id)=>{
      let query=this.base +`docs/requests/${id}/receipt`
      return super.post({route: this.printZebraURL+'/pdf',body: {
        url:query,
        username: sessionStorage.getItem('userName'),
      }, abs:true});

    },
    PrintResultForZebra : (id, devices, areas,families,lab_info_header,outside_note, healthCare, tests)=>{
      // http = new HttpClient
      let query=this.base +`docs/requests/${id}/results` + tests
      return super.post({route:this.printZebraURL+'/pdf',body: {
        url: query+'&devices='+ (devices?"1":"0") + '&areas='+ (areas?"1":"0") + '&families='+ (families?"1":"0") + '&lab_info_header='+ (lab_info_header?"1":"0") + '&outside_note='+ (outside_note?"1":"0") + '&healthCare='+ (healthCare?"1":"0"),
        username: sessionStorage.getItem('userName'),
      }, abs:true});

    },

    PrintRenameTestsReport : ()=>{
      return super.get({route: `sys/tests/rename-by-code`,prefixed:false,  pdf:true});
    },
  }




}
