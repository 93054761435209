import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, fromEvent, Subscriber } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
declare var $:any

@Component({
  selector: 'admin-layout-antibiotics',
  templateUrl: './antibiotics.component.html',
  styleUrls: ['./antibiotics.component.scss']
})
export class AntibioticsComponent implements OnInit {

  antibiotics:any[] = []
  selectedAntibiotic:any = {}
  action:any = {type : 'Add'}
  isLoaderHidden = true;
  sureState:Boolean = false

  constructor(public router:Router,public api : ApiService,public progress : ProgressService, public ms:ModalService) { }


  ngOnInit() {

    this.progress.on();
    this.getAntibiotics();

  }



  getAntibiotics(){

    this.api.Common.Get.antibiotics().subscribe(res => {
      this.progress.off();
      this.isLoaderHidden = true
      this.antibiotics = res
    });
  }

}


