import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
import { SnackService } from 'src/app/services/snack.service';
import { trigger, style, animate, transition } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-modal',
  animations: [
    trigger('inOutAnimation', [
      transition(
        ':enter',
        [
          style({ height: 0 }),
          animate('{{time}}ms ease-out', style({ height: '{{height}}' })),
        ],
        { params: { height: '200px', time: 500 } }
      ),
      transition(
        ':leave',
        [
          style({ height: '{{height}}' }),
          animate('{{time}}ms ease-in', style({ height: 0 })),
        ],
        { params: { height: '200px', time: 500 } }
      ),
    ]),
  ],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  constructor(
    public ms: ModalService,
    private api: ApiService,
    public dataService: DataService,
    public snack: SnackService
  ) {}

  user_roles_to_use: Array<any> = [];
  package_tests:Array<any> = [];
  roles: Array<any> = [
    { name: 'admin', id: 2, key: 'isAdmin' },
    { name: 'technician', id: 4, key: 'isTechnician' },
    { name: 'receptionist', id: 5, key: 'isReceptionist' },
    { name: 'accountant', id: 6, key: 'isAccountant' },
  ];
  destruction: boolean = false;
  admin_height = 341;
  tech_height = 101;
  rec_height = 221;
  date: string = '01-01-1990';
  formExtra = {};
  tests: Array<any> = [];
  bacterias: Array<any> = [];
  devices_tests_ids = {};
  bacterias_ids = {};
  assign_sub;
  submitting: boolean = false;
  deleting: boolean = false;
  sureState: boolean = false;
  selectedCashiers: any = [];
  selectedUnits: any = [];
  selectedCategories: any = [];
  selectedMethods: any = [];

  name_fixer = {
    devices: 'Devices | Methods',
    payment: 'Payment methods',
    cashiers: 'Cashiers | Banks',
    activityLog: 'Activity Log',
    editRequest: 'Edit request',
    printWhenPaid: 'Print after payment',
    canPrintResults:"Can print results",
    saveWhenVaildated: 'Save when validated',
    changePrice: 'Change price',
    changeDoctor: 'Change doctor',
    deleteTestFromRequest: 'Delete test from request',
    deletePayment: 'Delete payment',
    addNew: 'Add New Request',
  };

  flexableInputs = [
    { label: 'Device/Method', name: 'device', key: 'device_id', col: 'col-12' },
    { label: 'Category', name: 'category', key: 'category_id', col: 'col-6' },
    { label: 'Provider', name: 'provider', key: 'provider_id', col: 'col-6' },
    { label: 'Supplier', name: 'supplier', key: 'supplier_id', col: 'col-12' },
    { label: 'Partner', name: 'partner', key: 'partner_id', col: 'col-12' },
    { label: 'Test', name: 'test', key: 'test_id', col: 'col-12' },
    { label: 'Item', name: 'item', key: 'item_id', col: 'col-12' },
    {
      label: 'Warehouse',
      name: 'warehouse',
      key: 'warehouse_id',
      col: 'col-6',
    },
    { label: 'Customer', name: 'customer', key: 'customer_id', col: 'col-12' },
    { label: 'Cashier', name: 'cashier', key: 'cashier_id', col: 'col-6' },
    {
      label: 'Cashier/Bank account',
      name: 'treasury',
      key: 'treasury_id',
      col: 'col-6',
    },
    { label: 'Client', name: 'client', key: 'client_id', col: 'col-6' },
    {
      label: 'Payment method',
      name: 'payment_method',
      key: 'payment_method_id',
      col: 'col-6',
    },
    { label: 'Currency', name: 'currency', key: 'currency_id', col: 'col-12' },
    { label: 'Type', name: 'fixed_assets_type', key: 'type_id', col: 'col-6' },
    { label: 'Type', name: 'equalizations_type', key: 'type', col: 'col-6' },
    { label: 'Expense', name: 'expense', key: 'expense_id', col: 'col-12' },
    { label: 'From', name: 'from', key: 'from_id', col: 'col-12' },
    { label: 'To', name: 'to', key: 'to_id', col: 'col-12' },
  ];

  textInputs = [
    {
      label: 'Name',
      name: 'name',
      required: true,
      placeholder: 'name',
      pattern: '',
    },
    {
      label: 'First name',
      name: 'first_name',
      required: true,
      placeholder: 'First name',
      pattern: '',
    },
    {
      label: 'Last name',
      name: 'last_name',
      required: true,
      placeholder: 'Last name',
      pattern: '',
    },
    {
      label: 'Username',
      name: 'username',
      required: true,
      placeholder: 'Username',
      pattern: '',
    },
    {
      label: 'Code',
      name: 'code',
      required: true,
      placeholder: 'code',
      pattern: '',
    },

    {
      label: 'Commercial name',
      name: 'commercial_name',
      required: true,
      placeholder: 'Commercial name',
      pattern: '',
    },

    {
      called: 'lab_id',
      label: 'Lab ID',
      name: 'id',
      required: true,
      placeholder: 'Lab ID',
      pattern: '',
    },

    {
      label: 'Note',
      name: 'note',
      required: true,
      placeholder: 'note',
      pattern: '',
    },
    {
      label: 'Address',
      name: 'address',
      required: true,
      placeholder: 'address',
      pattern: '',
    },
    {
      label: 'Contact',
      name: 'contact',
      required: true,
      placeholder: 'contact',
      pattern: '',
    },
    {
      label: 'Description',
      name: 'description',
      required: true,
      placeholder: 'description',
      pattern: '',
    },
    {
      label: 'Title',
      name: 'title',
      required: true,
      placeholder: 'title',
      pattern: '',
    },
    {
      label: 'Price',
      name: 'price',
      required: true,
      placeholder: 'price',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Paid',
      name: 'paid_price',
      required: true,
      placeholder: 'price',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Cost',
      name: 'cost',
      required: true,
      placeholder: 'cost',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Value',
      name: 'value',
      required: true,
      placeholder: 'value',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Paid',
      name: 'paid_value',
      required: true,
      placeholder: 'value',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Balance',
      name: 'balance',
      required: true,
      placeholder: 'Enter balance',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Initial balance',
      name: 'initial_balance',
      required: true,
      placeholder: 'initial balance',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Income percentage',
      name: 'income_percentage',
      required: true,
      placeholder: 'income percentage',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Payment percentage',
      name: 'percentage',
      required: true,
      placeholder: 'percentage',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Receiver name',
      name: 'receiver_name',
      required: true,
      placeholder: 'receiver name',
      pattern: '',
    },
    {
      label: 'Giver name',
      name: 'giver_name',
      required: true,
      placeholder: 'giver name',
      pattern: '',
    },
    {
      label: 'Phone number',
      name: 'phone_number',
      required: true,
      placeholder: 'phone number',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Phone number',
      name: 'phone',
      required: true,
      placeholder: 'phone number',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Email',
      name: 'email',
      required: true,
      placeholder: 'email',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Secondary phone number',
      name: 'secondary_phone_number',
      required: true,
      placeholder: 'phone number',
      pattern: '^([0-9]*)$',
    },
    {
      label: 'Specialty',
      name: 'specialty',
      required: true,
      placeholder: 'specialty',
      pattern: '',
    },
    {
      label: 'Password',
      name: 'password',
      required: true,
      placeholder: 'Password',
      pattern: '',
      type: 'password',
    },
  ];

  multiSelectInputs = [
    {
      key: 'tests',
      called: 'multi_tests',
      label: 'Tests/Profiles',
      data: null,
      disabled_message: 'Select a device first',
    },
  ];

  dropdownInputs = [
    {
      name: 'language',
      key: 'language',
      label: 'Language',
      required: true,
      placeholder: 'Pick a language',
      pattern: '',
      options: [
        { name: 'English', id: 'english' },
        { name: 'Arabic', id: 'arabic' },
      ],
    },
    {
      name: 'is_bank',
      key: 'is_bank',
      label: 'Type',
      required: true,
      pattern: '',
      options: [
        { name: 'Cashier', id: false },
        { name: 'Bank account', id: true },
      ],
    },

    {
      name: 'gender',
      key: 'gender_id',
      label: 'Gender',
      required: true,
      placeholder: 'Gender',
      pattern: '',
      options: [{ name: 'None', id: null }, ...this.dataService.genders],
    },
    {
      name: 'doctor',
      key: 'doctor_id',
      label: 'Doctor',
      required: false,
      placeholder: 'Doctor',
      pattern: '',
      options: [{ name: 'None', id: null }, ...this.dataService.doctors],
    },
    {
      name: 'pricing_id',
      key: 'pricing_id',
      label: 'Pricing',
      required: true,
      placeholder: 'Pricing',
      pattern: '',
      options: [{ name: 'None', id: null }, ...this.dataService.pricings],
    },
    {
      name: 'subcategory_id',
      key: 'subcategory_id',
      label: 'Main Correspondent',
      required: false,
      placeholder: 'Main Correspondent',
      pattern: '',
      options: [{ name: 'None', id: null }, ...this.dataService.subcategories],
    },
    {
      name: 'category',
      key: 'category_id',
      label: 'Category',
      required: true,
      placeholder: 'Category',
      pattern: '',
      options: [{ name: 'None', id: null }, ...this.dataService.categories],
    },
  ];

  // 'Type'
  // ,'Category','User', 'Provider

  dateInputs = [
    {
      key: 'creation_date',
      iteration: '1',
      label: 'date',
      name: 'date',
      required: true,
      placeholder: 'Pick a date',
    },
    {
      key: 'date',
      iteration: '2',
      label: 'date',
      name: 'date',
      required: true,
      placeholder: 'Pick a date',
    },
    {
      key: 'from_date',
      iteration: '3',
      label: 'Date from',
      name: 'from_date',
      required: true,
      placeholder: 'Pick a date',
    },
    {
      key: 'to_date',
      iteration: '4',
      label: 'Date to',
      name: 'to_date',
      required: true,
      placeholder: 'Pick a date',
    },
  ];

  check_boxes = [
    { key: 'is_micro', name: 'Micro' },
    { key: 'is_external', name: 'External' },
    { key: 'isLTA', name: 'Long Term Agreement' },
    { key: 'isAdmin', name: 'Admin' },
    { key: 'isTechnician', name: 'Technician' },
    { key: 'isReceptionist', name: 'Receptionist' },
    { key: 'isAccountant', name: 'Accountant' },
  ];

  enabledUnits = false
  cashiers_index = 'cashiers_index'
  units_index = 'units_index'
  categoires_index = 'categoires_index'
  devices_index = 'devices_index'


  removePackageTest(test) {
    this.package_tests = this.package_tests.filter(
      (one) => one.id != test.id
    );
  }

  setPackageTests(e) {
    if(this.package_tests.find(one => one.id == e.id)) return this.snack.showerror('This test is added already')
    let order = this.package_tests.length + 1
    while(this.package_tests.filter(test => test['order'] == order).length){
      order++
    }
    e['order'] = order
    this.package_tests.push(e);
  }

  onSubmit(form: NgForm) {
    this.submitting = true;
    this.dateInputs.forEach((one) => {
      form.value[one['key']] = form.value[one['key']]
        ? form.value[one['key']].split('-').reverse().join('-')
        : null;
    });

    let entries = Object.entries(this.formExtra).map((one) => ({
      [one[0]]: one[1]['id'],
    }));
    let formExtra = Object.assign({}, ...entries);
    let data = JSON.parse(
      JSON.stringify(Object.assign(form.value, formExtra, this.ms.fixed_keys))
    );
    // let method = 'post';
    if (this.ms.inputs['is_device']) {
      data['tests'] = Object.keys(this.devices_tests_ids).filter(
        (id) => this.devices_tests_ids[id]
      );
    }
    
    if (this.ms.inputs['is_antibiotic']) {
      data['bacterias'] = Object.keys(this.bacterias_ids).filter(
        (id) => this.bacterias_ids[id]
      );
    }

    if(this.ms.inputs['isPackage']){

      if (this.package_tests.length) {
        if(this.package_tests.filter(one => !one.order || typeof Number(one.order) != 'number').length ||
        this.package_tests.length != Array.from(new Set(this.package_tests.map(one => one.order))).length
        )  return this.snack.showerror("Please add a unique order for each test");

        data['tests'] = this.package_tests.map((e) => ({id: e.id, order:e.order}));
      } else {
        return this.snack.showerror("Please add at least one test");
      }

    }
    // needed

    // this.multiSelectInputs.forEach(one =>{
    //   form.value[one.key] = form.value[one.key]?form.value[one.key].map(obj => obj.id): []
    // })

    // needed

    if (this.ms.inputs['is_employees']) {
      data['roles'] = this.roles
        .filter((one) => {
          let bool = data[one.key];
          delete data[one.key];
          return bool;
        })
        .map((one) => {
          if (one.name == 'admin' || one.name == 'receptionist' || one.name == 'technician') {
            if (!data['subRoles']) data['subRoles'] = [];
            data['subRoles'].push(
              ...this.user_roles_to_use
                .filter((role) => role.checked && role.role_panel == one.name)
                .map((one) => one.id)
            );
          }

          return one.id;
        });

      data['cashiers'] = this.selectedCashiers.map((e) => (e.id));
      data['units'] = this.selectedUnits.map((e) => (e.id));
      data['categories'] = this.selectedCategories.map((e) => (e.id));
      data['methods'] = this.selectedMethods.map((e) => (e.id));
    }

    if (this.ms.method) {
      this.ms.method(data).subscribe(
        (res) => {
          this.submitting = false;
          this.destruction = true;
          setTimeout(() => (this.destruction = false), 0);
          if (res['error'])
            return this.snack.showerror('Sorry something went wrong');
            if(!res['error']){
              if (data.inhert_price_id) {
                this.setTestsPricing(data.inhert_price_id,res['price_id'], data)
              }
            }
          if (this.ms.onSuccess) this.ms.onSuccess();
          if (this.ms.message) this.snack.show(this.ms.message);
          this.ms.changesMade.next(true);
          this.formExtra = {};
          document.getElementById('modalCloser').click();
        },
        (err) => {
          this.submitting = false;
          this.destruction = true;
          setTimeout(() => (this.destruction = false), 0);
          this.snack.showerror('Something went wrong');
        }
      );
    } else {
      //   if (this.ms.action == 'Update')  method = 'put'
      //   this.api[method](route, data).subscribe((res) => {
      //     this.ms.changesMade.next(true);
      //     // document.getElementById('msform')['reset']()
      //     this.formExtra = {}
      //     document.getElementById('modalCloser').click();
      // });
    }
  }

  setTestsPricing(id, price_id, formValues){
    const tests_ids = {}
    this.api.Admin.pricing.getTestsPricing(id,price_id).subscribe(resp => {
              var lengths = Object.keys(resp).length;
              for (let i = 0; i < lengths; i++) {
                tests_ids[i] = {
                  'id': resp[i].test_id
                }
              }
              formValues.tests_ids = tests_ids
              this.updateTestsPricing(price_id, formValues)
            });
  }

  updateTestsPricing(price_id, formValues){
    this.api.Admin.pricing.updateTestsPricing(price_id,formValues).subscribe(resp => {
        console.log('see', resp)
    });
  }

  remove() {
    if (this.ms.deleteMethod) {
      this.deleting = true;
      this.ms.deleteMethod(this.ms.datum['id']).subscribe((res) => {
        this.deleting = false;
        if (res['error'])
          return this.snack.showerror('Sorry something went wrong');
        if (this.ms.onSuccess) this.ms.onSuccess();
        if (this.ms.deleteMessage) this.snack.show(this.ms.deleteMessage);
        this.ms.changesMade.next(true);
        this.formExtra = {};
        document.getElementById('modalCloser').click();
      });
    }
  }

  setSelectedItem(e, word) {
    this.formExtra[word] = e;
  }
  removeSelectedItem(key, form: NgForm = null) {
    if (key == 'device_id') {
      this.multiSelectInputs.find((one) => one.key == 'tests')['data'] = null;
      this.ms.inputs['multi_tests'] = false;
      setTimeout(() => (this.ms.inputs['multi_tests'] = true), 0);
    }
    delete this.formExtra[key];
  }

  check_rules(){
    this.user_roles_to_use.forEach(role => {
      role.checked = this.ms.datum['sub_roles']? !!this.ms.datum['sub_roles'].find(sub => sub.id == role.id) : true
    })
  }

  getPermissions() {
    this.dataService.permissions$.subscribe((res) =>{
      this.user_roles_to_use.length ? null : this.user_roles_to_use = res
      if( this.ms.inputs['is_employees']) this.check_rules()

    });
  }

  ngOnInit(): void {
    this.getPermissions();
    this.enabledUnits = this.dataService.info["settings"]['userUnits']
    if (this.ms.inputs['is_antibiotic']) {
      this.getBacterias();
    }
    if (this.ms.getDatum) {
      this.ms.getDatum().subscribe((datum) => {
        this.ms.datum = datum;
        if (this.ms.inputs['is_device'] && datum.category) {
          this.testsByCatagory(datum.category.id);
        }
        if(this.ms.inputs['is_employees']){

          this.ms.datum['roles'].forEach(role => {
            this.ms.datum['is' + role.name[0].toUpperCase() + role.name.slice(1)] = true
          })

          if(this.ms.datum['cashiers']) this.selectedCashiers = this.ms.datum['cashiers']
          if(this.ms.datum['units']) this.selectedUnits = this.ms.datum['units']
          if(this.ms.datum['categories']) this.selectedCategories = this.ms.datum['categories']
          if(this.ms.datum['methods']) this.selectedMethods = this.ms.datum['methods']

          if(this.user_roles_to_use.length) this.check_rules()

          delete this.ms.datum['gender']

        }

        if(this.ms.inputs['isPackage']){
          this.package_tests = datum['nested_tests'].map(one =>{
            one['test']['order'] = one['order']
            return one['test']
           })
        }

      });
    }

    // this.assign_sub = this.ms.assign_emitted.subscribe(bool => {
    this.formExtra = {};

    // Object.keys(this.ms.datum).length? null : document.getElementById('msform')['reset']()

    Object.keys(this.ms.datum).forEach((key) => {
      let used_key = '';

      let obj = this.ms.datum[key];
      if (obj && obj['id'] && Number(obj['id']))
        this.formExtra[(used_key || key) + '_id'] = obj;
      else if (obj && obj['id']) this.formExtra[used_key || key] = obj;
    });

    // })
  }


  testsByCatagory(cat_id) {
    if (!cat_id || cat_id == 'null')
      return (this.tests = []), (this.devices_tests_ids = {});
    this.api.Common.Get.tests({ category: cat_id, page: 1 }).subscribe(
      (res) => {
        this.tests = res['data'];
        this.devices_tests_ids = {};
        let tests =
          this.ms.datum &&
          this.ms.datum['category'] &&
          this.ms.datum['category']['id'] == cat_id
            ? this.ms.datum['tests']
            : this.tests;
        tests.forEach((one) => (this.devices_tests_ids[one.id] = true));
      }
    );
  }

  getBacterias() {
    this.api.Common.Get.bacteria().subscribe(
      (res) => {
        this.bacterias = res;
        this.bacterias_ids = {};
        if(this.ms.datum['id']){
          this.api.Admin.antibiotics.bacteria(this.ms.datum['id']).subscribe(
            (res) => {
              let bacterias = res;
              bacterias.forEach((one) => (this.bacterias_ids[one] = true));
            })
        }
      }
    );
  }

  ngAfterViewInit() {
    this.dateInputs.forEach((one) => {
      let date = $('#date_' + one['iteration']);
      if (date) {
        date.flatpickr({
          dateFormat: 'd-m-Y',
        });
      }
    });
  }

  setSelectedCashiers(e) {
    if(this.selectedCashiers.find(one => one.id == e.id)) return
    console.log('this.selectedParams', this.selectedCashiers)
    console.log('e', e)
    this.selectedCashiers.push(e);
  }

  setSelectedUnits(e) {
    if(this.selectedUnits.find(one => one.id == e.id)) return
    console.log('this.selectedParams', this.selectedUnits)
    console.log('e', e)
    this.selectedUnits.push(e);
  }
  
  setSelectedCategories(e) {
    if(this.selectedCategories.find(one => one.id == e.id)) return
    console.log('this.selectedParams', this.selectedCategories)
    console.log('e', e)
    this.selectedCategories.push(e);
  }
  
  setSelectedMethods(e) {
    if(this.selectedMethods.find(one => one.id == e.id)) return
    console.log('this.selectedParams', this.selectedMethods)
    console.log('e', e)
    this.selectedMethods.push(e);
  }

  removeCashier(cashier) {
    this.selectedCashiers = this.selectedCashiers.filter(
      (param) => param.id != cashier.id
    );
  }

  removeUnit(cashier) {
    this.selectedUnits = this.selectedUnits.filter(
      (param) => param.id != cashier.id
    );
  }
  
  removeCategory(cashier) {
    this.selectedCategories = this.selectedCategories.filter(
      (param) => param.id != cashier.id
    );
  }
 
  removeMethod(cashier) {
    this.selectedMethods = this.selectedMethods.filter(
      (param) => param.id != cashier.id
    );
  }
}
