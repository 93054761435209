import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
declare let $;
@Component({
  selector: 'receptionist-layout-request-summary',
  templateUrl: './request-summary.component.html',
  styleUrls: ['./request-summary.component.scss']
})
export class RequestSummaryComponent implements OnInit {

  request: any = {};
  single_sticker:boolean = false;
  receipt_preview:boolean = false;
  total: any;
  categories = [];
  remaining: any;
  @Input() patientId: any;
  @Input() request_id: any;

  constructor(public api : ApiService,private route : ActivatedRoute, public data:DataService) { }


  ngOnChanges(changes){

     this.getRequest();
     this.get_request_categories()
     this.getTotal();

  }

  ngOnInit() {

  }


  categories_select_all(unselect){
    this.categories.forEach(category => {
      let element = document.getElementById('categories\|' + category.id)
      if(element['checked'] == unselect) element.click()
    })
  }



  get_request_categories(){
    this.api.Common.requests.get_categories(this.request_id).subscribe(res =>{
      this.categories =  res
    })
  }

  getRequest(){
    this.api.Common.requests.get_one(this.request_id).subscribe(res =>{
      this.request =  res
    })
  }

  print(){

    if(!this.receipt_preview){
      this.api.Common.printables.PrintRecieptForZebra(this.request_id).subscribe(
        (response) => {
          //Next callback
          console.log('response received')
        },
        (error) => {
            //Error callback
          console.error('error PrintRecieptForZebra  in component')

          this.api.Common.printables.PrintReciept(this.request_id).subscribe(res => {

            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');

          })
        }
      )
    }else{
      this.api.Common.printables.PrintReciept(this.request_id).subscribe(res => {

        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');

      })
    }
  }

  printBarcode(form :NgForm){


    let categories = Object.keys(form.value).filter(key => form.value[key])
    let query = 'categories[]=' + categories.join("&categories[]=")

    this.api.Common.printables.PrintBarcodesForZebra(this.request_id, this.single_sticker, query).subscribe(

      (response) => {
        //Next callback
        $('#categories_modal').modal('hide')
      },
      (error) => {
          //Error callback
        this.api.Common.printables.PrintBarcodes(this.request_id, this.single_sticker, query).subscribe(res => {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL, '_blank');
          $('#categories_modal').modal('hide')
        })
      }

    )

  }
  detailedInvoice(){

    this.api.Common.printables.PrintdetailedInvoiceForZebra(this.request_id).subscribe(

      (response) => {
        //Next callback
        console.log('response received')
      },
      (error) => {
          //Error callback
        console.error('error PrintBarcodesForZebra in component')
        this.api.Common.printables.PrintdetailedInvoice(this.request_id).subscribe(res => {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL, '_blank');

        })
      }

    )

  }
  getTotal(){
    this.api.Common.requests.get_total(this.request_id).subscribe(res =>{
      console.log(res);
      this.total = res['total']
      this.remaining = res['remaining']
    })
  }

}
