import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor() {}

  languages_1 = {
    english: {},
    arabic: {
      saturday: 'السبت',
      sunday: 'الأحد',
      monday: 'الاثنين',
      tuesday: 'الثلاثاء',
      wednesday: 'الأربعاء',
      thursday: 'الخميس',
      friday: 'الجمعة',
      stats: 'الإحصائيات',
      'update tests by codes': 'تحديث التحاليل بالكود',
      'cost price': 'سعر التكلفة',
      'the doctor was successfully added': 'تمت إضافة الطبيب بنجاح',
      'the doctor was successfully updated': 'تم تعديل الطبيب بنجاح',
      'the correspondent was successfully added': 'تمت إضافة الجهة بنجاح',
      'the correspondent was successfully updated': 'تم تعديل الجهة بنجاح',
      'the patient was successfully added': 'تمت إضافة المريض بنجاح',
      'the patient was successfully updated': 'تم تعديل المريض بنجاح',
      'the request was successfully created': 'تمت إنشاء الطلب بنجاح',
      'the request was successfully updated': 'تم تعديل الطلب بنجاح',
      'the payment was successfully made': 'تم الدفع بنجاح',
      'the discount was successfully added': 'تم إضافة التخفيض بنجاح',
      logout: 'تسجيل الخروج',
      'requested correspondent': 'الجهة الطالبة',
      'limit results length': 'حد طول النتيجة',
      'select a category': 'إختر فئة',
      'current password': 'كلمة المرور القديمة',
      'background image': 'صورة الخلفية',
      'new password': 'كلمة المرور الجديدة',
      'confirm password': 'تأكيد كلمة المرور',
      results: 'النتائج',
      'print results': 'طباعة النتائج',
      'with categories?': 'مع الفئات؟',
      'with devices?': 'مع الأجهزة؟',
      'with families?': 'مع العائلات؟',
      'with headers?': 'مع العنوان؟',
      'outside sample?': 'عينة خارجية؟',
      'preview?': 'معاينة',
      'default date in requests list': 'التاريخ التلقائي لقائمة الطلبات',
      'monthly renumbering': 'اعادة الترقيم الشهري',
      'user units': 'وحدات المستخدمين',
      units: 'الوحدات',
      'Units (use ctrl)': 'الوحدات (إستخدم ctrl)',
      'add a unit': 'اضافة وحدة',
      'edit a unit': 'تعديل وحدة',
      'select tests to print': 'إختر تحاليل للطباعة',
      'select categories': 'إختر فئات',
      'patient or | and correspondent': 'مريض و | أو جهة',
      home: 'الصفحة الرئيسية',
      search: 'البحث',
      patients: 'المرضى',
      requests: 'الطلبات',
      request: 'الطلب',
      nationality: 'الجنسية',
      draw: 'سحب',
      'delete reason': 'سبب الحذف',
      reason: 'السبب',
      'request #': 'رقم الطلب',
      'patient name': 'اسم المريض',
      'patient id': 'الرمز الشخصي المريض',
      'doctor name': 'اسم الطبيب',
      'result state': 'حالة النتيجة',
      area: 'المجال',
      // 'result expression':'',
      "with price":"مع السعر",
      "enable cloud backup":"تفعيل النسخ الاحتياطي السحابي",
      'date from': 'التاريخ من',
      'date to': 'التاريخ إلى',
      'time from': 'الوقت من',
      'time to': 'الوقت إلى',
      paid: 'مدفوع',
      unpaid: 'غير مدفوع',
      add: 'إضافة',
      close: 'إغلاق',
      'draw sample': 'سحب عينة',
      'Draw Time': 'موعد السحب',
      download: 'تحميل',
      'with result': 'مع نتيجة',
      'without result': 'بدون نتيجة',
      'hospital id': 'رقم المريض في المستشفى',
      'search by request #': 'البحث برقم الطلب',
      'search by request code': 'البحث برمز الطلب',
      'search by patient name': 'البحث باسم المريض',
      'search by doctor name': 'البحث باسم الطبيب',
      'patients list': 'قائمة المرضى',
      'search by patient name or phone or birthdate or barcode':
        'البحث بالاسم أو رقم الهاتف أو تاريخ الميلاد أو الباركود',

      'search by patient name, id or phone number or national id':
        'البحث باسم أو الرمز الشخصي أو رقم الهاتف للمريض أو الرقم الوطني',

      'search by patient name or hospital id or phone or birthdate or barcode':
        'البحث بالاسم أو رقم المستشفى أو رقم الهاتف أو تاريخ الميلاد أو الباركود',

      'search by patient name or hospital id or phone or birthdate or barcode or national id':
        'البحث بالاسم أو رقم المستشفى أو رقم الهاتف أو تاريخ الميلاد أو الباركود أو الرقم الوطني',

      login: 'تسجيل الدخول',

      'please pick at least one test | profile':
        'الرجاء إختيار تحليل | ملف تحاليل واحد على الأقل',

      action: 'النشاط',
      activity: 'الفعالية',
      "select a family":"إختر عائلة",
      "wrong username or password":"اسم المستخدم أو كلمة المرور خاطئة",
      edit: 'تعديل',
      'activity log': 'سجل النشاطات',
      parameter: 'الباراميتر',
      'patient info': 'بيانات المريض',
      'request info': 'بيانات الطلب',
      'search by code': 'ابحث بالرمز',
      change: 'تغيير',
      arabic: 'العربية',
      english: 'الإنجليزية',
      'change your credentials': 'تعديل البيانات الشخصية',
      'change language': 'تغيير اللغة',
      'tests | profiles': 'التحاليل | ملفات التحاليل',
      "devices | methods": "الأجهزة | الطرق",
      "device | method": "الجهاز | الطريقة",
      'add tests | profiles': 'أضف التحاليل | ملفات التحاليل',
      'search by test | profile code': 'البحث برمز التحليل | ملف التحاليل',
      'search by parameters code': 'البحث برمز الباراميتر',
      'search by test | profile code or name':
        'البحث برمز أو اسم التحليل | ملف التحاليل',
      'test | profile': 'التحليل | ملف التحاليل',
      test: 'التحليل',
      tests: 'التحاليل',
      correspondents: 'الجهات',
      'correspondents list': 'قائمة الجهات',
      'all correspondents': 'جميع الجهات',
      all: 'الكل',
      print: 'طباعة',
      previous: 'السابق',
      next: 'التالي',
      category: 'الفئة',
      parameters: 'الباراميترات',
      doctors: 'الأطباء',
      doctor: 'الطبيب',
      pricing: 'التسعير',
      reports: 'التقارير',
      language: 'اللغة',
      civility: 'الوصف',
      excel: 'اكسل',
      pages: 'الصفحات',
      'info bill': 'فاتورة تفصيلية',
      'info bill separate pages': 'فاتورة تفصيلية صفحات منفصلة',
      'payment status': 'حالة الدفع',
      'total rows': 'عدد الصفوف',
      'in | out patient': 'إيواء | عيادة خارجية',
      'in patient': 'إيواء',
      'out patient': 'عيادة خارجية',
      // "charge to":"",
      'clinical information': 'المعلومات الطبية',
      'notes | clinical information': 'ملاحظات | المعلومات الطبية',
      'payment information': 'معلومات الدفع',
      'laboratory information': 'المعلومات المختبرية',
      'laboratory notes': 'الملاحظات المختبرية',
      'hospitalization date': 'تاريخ الإدخال',
      username: 'اسم المستخدم',
      password: 'كلمة المرور',
      new: 'جديد',
      normal: 'عادي',
      urgent: 'مستعجل',
      none: 'لا يوجد',
      patient: 'المريض',
      correspondent: 'الجهة',
      'correspondent id': 'رقم المريض في الجهة',
      balance: 'الرصيد',
      'patient card': 'بطاقة المريض',
      'print correspondent id by default': 'طباعة رقم الجهة بشكل افتراضي',
      'with correspondent id': 'مع رقم الجهة',
      'total price calculation': 'حساب إجمالي السعر',
      id: 'الرمز الشخصي',
      date: 'التاريخ',
      result: 'النتيجة',
      status: 'الحالة',
      payments: 'المدفوعات',
      'request payment': 'دفع الطلب',
      'request discount': 'تخفيض الطلب',
      'request summary': 'ملخص الطلب',
      pay: 'ادفع',
      'payment methods': 'طرق الدفع',
      'payment method': 'طريقة الدفع',
      'charged to clinic': 'على حساب العيادة',
      'reception number': 'رقم الطلب',
      clinic: 'العيادة',
      'this request is charged to clinic': 'هذا الطلب على حساب العيادة',
      'the request has been set to delivered':
        'تم تغيير حالة الطلب إلى تم التسليم',
      'please enter all data correctly and try again':
        'الرجاء إدخال جميع البيانات بطريقة صحيحة',
      'please enter either age or birthdate':
        'الرجاء إدخال العمر أو تاريخ الميلاد',
      'payment date': 'تاريخ الدفع',
      amount: 'القيمة',
      payer: 'الدافع',
      cashier: 'الخزينة',
      total: 'الإجمالي',
      remaining: 'المتبقي',
      'total charged': 'الإجمالي النهائي',
      summary: 'الملخص',
      urgency: 'الاستعجالية',
      repeat: 'إعادة',
      delivered: 'تم التسليم',
      ready: 'جاهز',
      active: 'نشط',
      'in progress': 'قيد التجهيز',
      save: 'حفظ',
      code: 'الرمز',
      profile: 'ملف تحاليل',
      original: 'السعر الأصلي',
      discount: 'التخفيض',
      'set discount': 'إضافة تخفيض',
      'discount percentage': 'نسبة التخفيض',
      percentage: 'النسبة المئوية',
      percent: 'النسبة المئوية',
      value: 'القيمة',
      'discount value': 'قيمة التخفيض',
      'national id': 'الرقم الوطني',
      transactions: 'المعاملات',
      charged: 'السعر النهائي',
      remove: 'إزالة',
      name: 'الاسم',
      receptionist: 'موظف إستقبال',
      technician: ' فني ',
      accountant: ' محاسب ',
      'charge to': 'على حساب',
      admin: ' مسؤول ',
      notes: 'الملاحظات',
      'the note was successfully updated': 'تم تعديل الملاحظة بنجاح',
      history: 'السجل',
      "sorry you can't update validated request":
        'عذرََا, لا يمكن تعديل طلب متحقق من صحته',
      phone: 'رقم الهاتف',
      'phone number': 'رقم الهاتف',
      specify: 'تحديد',
      barcode: 'الباركود',
      birthdate: 'تاريخ الميلاد',
      'patient search': 'البحث ببيانات المريض',
      'this field is required': 'هذا الحقل مطلوب',

      'please pick a firstname for the patient': 'الرجاء إضافة اسم أول للمريض',
      'please pick a lastname for the patient': 'الرجاء إضافة لقب للمريض',
      'please pick birthdate or age for the patient':
        'الرجاء إضافة تاريخ ميلاد أو عمر للمريض ',

      'change password': 'تغيير كلمة المرور',
      'first name': 'الاسم الأول',
      'middle name': 'اسم الأب',
      'last name': 'اللقب',
      years: 'سنوات',
      months: 'شهور',
      days: 'أيام',
      gender: 'الجنس',
      'select a date': 'إختر تاريخ',
      'select a patient': 'إختر مريض',
      age: 'العمر',
      'new patient': 'مريض جديد',
      'new doctor': 'طبيب جديد',
      'edit patient': 'تعديل بيانات مريض',
      'edit request': 'تعديل بيانات الطلب',
      'send sms': 'ارسال رسالة',
      'delete payment': 'حذف دفعة',
      send: 'ارسال',
      bold: 'تمييز',
      support:"دعم",
      ticket:"تذكرة",
      subcategories: "الجهات الرئيسية",
      title:"العنوان",
      "reread parameters in protocol": "اعادة قراءة الباراميترات في فالبروتوكول",
      "id card": "بطاقة شخصية",
      "passport": "جواز سفر",
      "identification": "تعريف",
      "national number": "رقم وطني",
      "birth certificate": "شهادة ميلاد",
      "health certificate": "شهادة صحية",
      "healthcare result colors": "الوان طباعة الشهادة الصحية",
      "identity type": "نوع الهوية",
      "identity number": "رقم الهوية",
      "new ticket":"اضافة تذكرة",
      charge:"شحن",
      insurance:"التأمين",
      "edit ticket":"تعديل تذكرة",
      'are you sure to send': 'هل انت متأكد من الارسال',
      'save when validated': 'حفظ بعد التأكيد',
      'new request': 'طلب جديد',
      'edit doctor': 'تعديل بيانات طبيب',
      'change price': 'تغير سعر',
      male: 'ذكر',
      priority: "الأولوية",
      low: "منخفض",
      high: "عالي",
      'delete test from request': 'حذف تحليل من طلب',
      'auto select gender': 'اختيار الجنس التلقائي',
      'print lab to lab requests receipt': 'طباعة واصل حالات اللاب تو لاب',
      female: 'أنثى',
      'doctor list': 'قائمة الأطباء',
      specialty: 'التخصص',
      delete: 'حذف',
      open: 'مفتوح',
      'are you sure you want to remove this doctor?':
        'هل أنت متأكد من حذف هذا الطبيب؟',
      yes: ' نعم ',
      no: ' لا ',
      type: 'النوع',

      'detailed invoice': 'فاتورة مفصلة',
      'print receipt': 'طباعة الإيصال',
      'print barcodes': 'طباعة الباركودات',
      'single sticker': 'ملصق واحد',

      calculate: 'احسب',
      cancel: 'رجوع',
      'draw time': 'وقت السحب',

      'select tests': 'إختر تحاليل',

      'search by test code': 'البحث برمز التحليل',

      credentials: 'البيانات الشخصية',
      upload: 'رفع',

      'all categories': 'كل الفئات',

      'edit result': 'تعديل النتيجة',

      samples: 'العينات',

      completed: 'جاهز',

      'partially validated': 'مثبت جزئيا',
      'technically validated': 'مثبت فنيا',
      validated: 'مثبت',
      'validate as a supervisor': 'تثبيت كمشرف',
      validate: 'تثبيت',
      'unvalidate as a supervisor': 'إلغاء التثبيت كمشرف',
      unvalidated: 'إلغاء التثبيت',

      duration: 'المدة',
      'drawing time': 'وقت السحب',

      'select a device': 'إختر جهاز',

      note: 'ملاحظة',
      'add note': 'إضافة ملاحظة',
      'note is updated successfully': 'تم تعديل الملاحظة بنجاح',

      year: 'سنة',
      month: 'شهر',
      day: 'يوم',

      details: 'التفاصيل',
      'request details': 'تفاصيل الطلب',

      'logout timer': 'مؤقت تسجيل الخروج',
      'total (patient)': 'إجمالي (المريض)',
      'send mail': 'أرسل بريد',
      'cash in': 'النقد الداخل',
      revenue: 'الإيرادات',
      'with tests': 'مع التحاليل',
      'tests status': 'حالة التحاليل',
      'add new request': 'اضافة طلب جديد',
      deleted: 'محذوف',
      pm: 'م',
      am: 'ص',
      time: 'الوقت',
      'lab info header': 'بيانات المعمل (أعلى الإيصال)',
      'default pricing': 'التسعيرة الافتراضية',
      'lab id': 'رقم المعمل',
      'can print': 'يمكن طباعته',
      'extra notes': 'ملاحظات إضافية',
      'make payment': 'قم بالدفع',
      'overall total downloaded': 'إجمالي التنزيل الكلي',
      'overall total uploaded': 'إجمالي الرفع الكلي',
      'total downloaded': 'إجمالي التنزيل',
      'total uploaded': 'إجمالي الرفع',
      "number of downloaded": "عدد التنزيل",
      "number of uploaded": "عدد الرفع",
      "downloaded total": "إجمالي التنزيل",
      "uploaded total": "إجمالي الرفع",
      uploaded: 'تم رفعه',
      downloaded: 'تم تنزيله',
      'lab info': 'بيانات المعمل',
      'clinic info': 'بيانات العيادة',
      'please add at least one patient': 'الرجاء إضافة مريض واحد على الأقل',
      address: 'العنوان',
      contact: 'طريقة الاتصال',
      activation: 'التفعيل',
      'default result': 'النتيجة الافتراضية',
      specification: 'التخصيص',
      'show old result': 'أظهر النتيجة السابقة',
      'insurance id': 'رقم التأمين',
      'search by parameter code': 'البحث برمز الباراميتر',
      'search by antibiotic code': 'البحث برمز المضاد الحيوي',
      'search by sample code': 'البحث برمز العينة',
      'search by device | method code': 'البحث برمز الجهاز | طريقة الاستخدام',
      'search by correspondent name': 'البحث باسم الجهة',

      'search by patient name, id or phone number':
        'البحث باسم أو الرمز الشخصي أو رقم الهاتف للمريض',

      default: 'الإفتراضي',
      'show name in results': 'إظهار الاسم في النتائج',
      'hide table header from results': 'إخفاء رأس الجدول من النتائج',
      'hide title from results': 'إخفاء العنوان من النتائج',
      'hide reference range from results': 'إخفاء النطاق المرجعي من النتائج',
      'reference ranges': 'النطاقات المرجعية',
      'reference range': 'النطاق المرجعي',
      range: 'النطاق',
      'min value': 'القيمة الأدنى',
      'max value': 'القيمة الأعلى',
      'min age': 'الحد الأدنى للعمر',
      'max age': 'الحد الأعلى للعمر',
      unit: 'الوحدة',
      enable: 'تفعيل',
      disable: 'تعطيل',
      integer: 'عدد صحيح',
      'has histogram': 'لديه هيستوجرام',
      'update all tests price': 'تعديل سعر جميع التحاليل',
      update: 'تحديث',
      users: 'المستخدمين',
      receptionists: 'موظفو الإستقبال',
      labs: 'المعامل',
      'restore default': 'استعادة الافتراضي',
      tax: 'الضريبة',
      'tax value': 'قيمة الضريبة',
      'technical result panel size': 'حجم لوحة النتائج الفنية',
      large: 'كبير',
      medium: 'متوسط',
      small: 'صغير',
      'confirm delete test': 'تأكيد حذف الاختبار',
      receipts: 'الإيصالات',
      receipt: 'إيصال',
      'print devices by default': 'طباعة الأجهزة بشكل افتراضي',
      'print categories by default': 'طباعة الفئات بشكل افتراضي',
      'print families by default': 'طباعة العائلات بشكل افتراضي',
      'bold note': 'ملاحظة بحجم كبير',
      'correspondent category': 'التصنيف',
      'two parts': 'جزئين',
      'hide details for correspondents patients': 'إخفاء التفاصيل لمرضى الجهات',
      '*if you are using gmail you have to activate the option "access the least secure apps".':
        'إذا كنت تستخدم الجيميل يجب عليك تفعيل الخيار "Access the least secure apps".',
      'font size': 'حجم الخط',
      'result alignment': 'محاذاة النتيجة',
      'unit alignment': 'محاذاة الوحدة',
      'reference range alignment': 'محاذاة النطاق المرجعي',
      'main correspondent': 'الجهة الرئيسية',
      'with details': 'مع التفاصيل',
      center: 'المنتصف',
      right: 'اليمين',
      left: 'اليسار',
      "you can't add letters nor signs": 'لا يمكنك إضافة حروف ولا إشارات',
      'you have to add if before do': 'يجب إضافة if قبل do',
      clinics: 'العيادات',
      civilities: 'الصفات',
      'cashiers | banks': 'الخزائن | الحسابات المصرفية',
      'cashiers | bank accounts': 'الخزائن | الحسابات المصرفية',
      external: 'خارجي',
      histopathologist: 'أخصائي أمراض الأنسجة',
      'enter number of days': 'أدخل عدد الأيام',
      'add days': 'أضف أيام',
      'print after payment': 'الطباعة بعد الدفع',
      'technical supervisor': 'مشرف فني',
      'can print results': 'إمكانية طباعة نتائج',
      daily: 'يومي',
      monthly: 'شهري',
      micro: 'علم الميكروبات',
      'commercial name': 'الاسم التجاري',
      'add a parameter': 'أضف باراميتر',
      'edit a parameter': 'تعديل باراميتر',
      'add a sample': 'أضف عينة',
      'edit a sample': 'تعديل عينة',
      'add a family': 'أضف عائلة',
      'edit a family': 'تعديل عائلة',
      'add an antibiotic': 'أضف مضاد حيوي',
      'edit an antibiotic': 'تعديل مضاد حيوي',
      'add a bacteria': 'أضف بكتيريا',
      'edit a bacteria': 'تعديل بكتيريا',
      'add a category': 'أضف فئة',
      'edit a category': 'تعديل فئة',
      'add a clinic': 'أضف عيادة',
      'edit a clinic': 'تعديل عيادة',
      'add a civility': 'أضف صفة',
      'edit a civility': 'تعديل صفة',
      'add a correspondent': 'أضف جهة',
      'edit a correspondent': 'تعديل جهة',
      "add a lab": "أضف معمل",
      "edit a lab": "تعديل معمل",
      'add a user': 'أضف مستخدم',
      'edit a user': 'تعديل مستخدم',
      'add a payment method': 'أضف طريقة دفع',
      'edit a payment method': 'تعديل طريقة دفع',
      'add a cashier | bank account': 'أضف خزينة | حساب مصرفي',
      'edit a cashier | bank account': 'تعديل خزينة | حساب مصرفي',
      'add a pricing': 'أضف تسعير',
      'edit a pricing': 'تعديل تسعير',
      'add from existed pricing(+/-)': 'إضافة من تسعيير موجود(+\\-)',
      rounding: 'تقريب',
      increase: 'زيادة',
      decrease: 'تخفيض',
      'add a patient': 'أضف مريض',
      'edit a patient': 'تعديل مريض',
      'add a doctor': 'أضف طبيب',
      'edit a doctor': 'تعديل طبيب',
      'add a device | method': 'أضف جهاز | طريقة',
      'edit a device | method': 'تعديل جهاز | طريقة',
      'add test': 'أضف تحليل',
      'add a note': 'أضف ملاحظة',
      'add a formula': 'أضف معادلة',
      'revenue day': 'يوم الإيرادات',
      formulas: 'المعادلات',
      'add an option': 'أضف خيار',
      'added notes': 'الملاحظات المضافة',
      options: 'الخيارات',
      'added options': 'الخيارات المضافة',
      'add profile': 'أضف ملف',
      'add a package': 'أضف حزمة',
      'add package': 'أضف حزمة',
      'edit test': 'تعديل تحليل',
      'edit profile': 'تعديل ملف',
      'edit package': 'تعديل حزمة',
      'edit a package': 'تعديل حزمة',
      'search by name': 'البحث بالاسم',
      'search by serial number': 'البحث بالرقم المتسلسل',
      'search by parameter code or name': 'البحث برمز أو اسم الباراميتر',
      'are you sure you want to delete this package?':
        'هل أنت متأكد من حذف هذه الحزمة؟',
      'are you sure you want to delete this profile?':
        'هل أنت متأكد من حذف هذا الملف؟',
      'are you sure?': 'هل أنت متأكد؟',
      order: 'الترتيب',
      sample: 'العينة',
      settings: 'الإعدادات',
      categories: 'الفئات',
      "sub categories": 'فئات فرعية',
      prices: 'أنواع الأسعار',
      price: 'السعر',
      pricings: 'التسعيرات',
      'serial number': 'الرقم المتسلسل',
      'test prices': 'أسعار التحليل',
      'initial balance': 'الرصيد الافتتاحي',
      'price calculation': 'حساب السعر',
      currency: 'العملة',
      'bank account': 'حساب مصرفي',
      treasury: 'خزينة | حساب مصرفي',
      email: 'البريد الإلكتروني',
      'long term agreement': 'اتفاق طويل الأمد',
      'correspondent | patient': 'الجهة | المريض',
      statistics: 'الإحصائيات',
      report: 'تقرير',
      inactive: 'غير نشط',
      'inactive note': 'ملاحظة ايقاف التحليل',
      'active note': 'ملاحظة التحليل',
      'last update date': 'تاريخ اخر تحديث',
      'payment percentage': 'نسبة الدفع',

      'expiration date is': 'تاريخ إنتهاء الصلاحية',
      'expiration date': 'تاريخ إنتهاء الصلاحية',

      'add another phone': 'أضف رقم هاتف آخر',
      phones: 'أرقام الهواتف',
      logo: 'الشعار',
      'phone no.': 'الهاتف رقم ',
      minutes: 'دقائق',
      'please add tests to the package': 'الرجاء إضافة تحاليل للحزمة',
      'please add tests to the profile': 'الرجاء إضافة تحاليل لملف التحاليل',
      'please add a unique order for each test':
        'الرجاء إضافة ترتيب مختلف لكل تحليل',
      'please add a unique order for each parameter':
        'الرجاء إضافة ترتيب مختلف لكل باراميتر',
      'profile was successfully added': 'تمت إضافة ملف التحاليل بنجاح',
      'package was successfully added': 'تمت إضافة الحزمة بنجاح',
      'profile was successfully edited': 'تم تعديل ملف التحاليل بنجاح',
      'package was successfully edited': 'تم تعديل الحزمة بنجاح',
      'civility was successfully added': 'تمت إضافة الصفة بنجاح',
      'civility was successfully updated': 'تم تعديل الصفة بنجاح',
      'civility was successfully removed': 'تم حذف الصفة بنجاح',
      'please enter all prices': 'الرجاء إضافة الأسعار بالكامل',
      'please select a sample': 'الرجاء اختيار عينة',
      'please select a category': 'الرجاء اختيار فئة',
      'please add parameters to the test': 'الرجاء إضافة باراميترات للتحليل',
      'please add a device | method to the test':
        'الرجاء إضافة جهاز | طريقة للتحليل',
      'this device | method is already added': 'هذا الجهاز | الطريقة مضاف',
      'select all': 'تحديد الكل',
      'unselect all': 'إلغاء تحديد الكل',
      'something went wrong': 'هناك خطأ ما',
      'alert message': 'رسالة تنبيه',
      message: 'رسالة',
      'select a pricing': 'إختر تسعيرة',
      'deleted tests': 'الإختبارات المحذوفة',
      'test name': 'إسم الإختبار',
      'technical validator': 'تثبيت فني',
      "supervisor":"مشرف فني",

      role: 'الوظيفة',
      roles: 'الوظاف',
      "change role behaviour":"سلوك تغيير الوظيفة",
      "new tab":"علامة تبويب جديدة",
      "same tab":"نفس علامة التبويت",
      'families': 'العائلات',
      'germ-families': 'عائلات جرثومية',
      'germ family': 'العائلة الجرثومية',
      antibiotics: 'مضادات حيوية',
      bacteria: 'بكتيريا',
      "new expiration date":"تاريخ إنتهاء الصلاحية الجديد",
      "this antibiotic is already added":"هذا المضاد الحيوي مضاف",
      "the system subscription expires in":"ينتهي اشتراك النظام في",
      "order by name":"ترتيب حسب الاسم",
      "order by code":"ترتيب حسب الكود",
      "order by id":"ترتيب حسب الادخال",
      'back':'رجوع'
    },
  };

  languages_2 = {
    english: {},
    arabic: {
      repeat: 'معاد',
      percentage: 'نسبة مئوية',
      percent: 'نسبة مئوية',
      value: 'قيمة',
      test: 'تحليل',
      profile: 'ملف تحاليل',
      package: 'حزمة',
      id: 'ر.م',
      receptionist: ' الإستقبال ',
      technician: ' المختبر ',
      accountant: ' المالية ',
      admin: ' الإدارة ',

      none: 'غير محدد',

      clinic: 'سعر العيادة',
      amount: 'المقدار',
    },
  };
}
