import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map, tap } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { ProgressService } from "src/app/services/progress.service";
import { SnackService } from 'src/app/services/snack.service';
declare let $;

@Component({
  selector: "admin-layout-correspondent",
  templateUrl: "./correspondent.component.html",
  styleUrls: ["./correspondent.component.scss"],
})
export class CorrespondentComponent implements OnInit {
  correspondent: any;
  isLoaderHidden = true;
  pagination: any = {};
  dates = {
    from:new Date().toISOString().slice(0,10).split('-').reverse().join('-'),
    to:new Date().toISOString().slice(0,10).split('-').reverse().join('-')
  }
  dates2 = {
    from:new Date().toISOString().slice(0,10).split('-').reverse().join('-'),
    to:new Date().toISOString().slice(0,10).split('-').reverse().join('-')
  }
  currentPer: any;
  flags: { month: Boolean; day: Boolean, requests:Boolean, transactions:Boolean } = {
    month: false,
    day: false,
    requests:false,
    transactions:false
  };
  per="";

  pers: any = [];
  payment_methods: any = [];
  cashiers: any = [];
  withTests = false
  excel = false
  payment_method_id = 1
  cashier_id = 1

  toggleFlags = (per,page=1) => {
    console.log(page);
    this.pagination=[];
    this.per="";
    if (per == "month") this.flags= {month:true, day:false,requests:false,transactions:false};this.per='month';
    if (per == "day") this.flags= {month:false, day:true,requests:false,transactions:false};this.per='day';
    if (per == "requests") this.flags= {month:false, day:false,requests:true,transactions:false};this.per='requests';
    if (per == "transactions") this.flags= {month:false, day:false,requests:false,transactions:true};this.per='transactions';
    // this.flags[per] = !this.flags[per];
    if (['month','day'].includes(per)) {
      this.api.Admin.correspondents.get_stats(
        this.correspondent.id,
       {per}
      ).subscribe((res) => {
        this.pers = res;
        // this.pagination = { per_page:res['per_page'], current_page: res['current_page'],total: res['total'] }
      });
    }else if(['transactions'].includes(per)){
      this.pers = []
      setTimeout(()=> {
        let min_date = this.dates.from.split('-').reverse().join('-')
        let max_date = this.dates.to.split('-').reverse().join('-')
        this.api.Admin.correspondents.get_transactions(
          this.correspondent.id,{min_date , max_date,page}
        ).subscribe((res) => {
          this.pers = res
        });
      }, 0)
    }else {
      this.pers = []
      setTimeout(()=> {
        let min_date = this.dates.from.split('-').reverse().join('-')
        let max_date = this.dates.to.split('-').reverse().join('-')
        this.api.Admin.correspondents.get_requests(
          this.correspondent.id,{min_date , max_date,page}
        ).subscribe((res) => {
          this.pers = res["data"];
          this.pagination = this.data.extract_pagination(res)

        });
      }, 0)

      // http://165.22.27.138/lims/v1/admin/correspondents/29/requests?min_date=2021-01-04&max_date=2021-03-10

    }
  };

  print(){

    this.api.Common.printables.print_reciept(this.correspondent.id).subscribe(res => {

      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');

    })
  }

  print_recipt(id){


    this.api.Common.printables.print_reciept_manual(this.correspondent.id,id).subscribe(res => {

      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');

    })

  }

  print_request_recipt(id){

    this.api.Common.printables.print_request_reciept_manual(id).subscribe(res => {

      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');

    })

  }


  printAccount(){
    let min_date = this.dates2.from.split('-').reverse().join('-')
    let max_date = this.dates2.to.split('-').reverse().join('-')
    this.api.Common.printables.print_correspondent_account(this.correspondent.id, min_date, max_date).subscribe(res => {

      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');

    })
  }

  getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }



  constructor(
    private activatedRoute: ActivatedRoute,
    private progress: ProgressService,
    private api: ApiService,
    public router: Router,
    public snack : SnackService,
    public data: DataService
  ) {}


  ngAfterViewInit(){
    ['from','to'].forEach(one => {

      $(`#${one}Picker`)[0].flatpickr({
        dateFormat: "Y-m-d",
      });
    });
    ['from2','to2'].forEach(one => {

      $(`#${one}Picker`)[0].flatpickr({
        dateFormat: "Y-m-d",
      });
    });
    ['from3','to3'].forEach(one => {

      $(`#${one}Picker`)[0].flatpickr({
        dateFormat: "Y-m-d",
      });
    });
  }

  ngOnInit() {

      $('#payPicker')[0].flatpickr({
        dateFormat: "Y-m-d",
        defaultDate: this.getToday(),
      });



    this.activatedRoute.params.subscribe((param) => {
      this.progress.on();
      this.api.Admin.correspondents.get_one(param.id).subscribe((res) => {
        this.progress.off();
        this.correspondent = res;
        this.toggleFlags('requests')
      });
      this.getCashiers()
      this.getPaymentMethods()
    });
  }

  getPaymentMethods(){
    this.api.Common.Get.payment_methods().subscribe((res) => {
      this.payment_methods = res;
    });
  }

  getCashiers(){
    // this.api.Common.Get.treasuries().subscribe((res) => {
    //   this.cashiers = res;
    // });
    this.cashiers = this.data.me.cashiers
  }

  print_requests(asMail = 0){

    let min_date = this.dates.from.split('-').reverse().join('-')
    let max_date = this.dates.to.split('-').reverse().join('-')
    let excel = this.excel && !asMail? 1 : 0
    let withTests = this.withTests? 1 : 0

    this.api.Common.printables.correspondent_requests(this.correspondent.id, {min_date,max_date,excel,withTests, asMail}).subscribe(res => {

      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');

    })
  }

  onSubmit(form: NgForm) {
    if(this.currentPer && this.currentPer.date){
      var isDay = this.currentPer.date.match(/\//g).length == 2;
      var date = new Date(this.currentPer.date);
    }
    if($("#payPicker")[0].value != "")
          form.value.payment_date = $("#payPicker")[0].value;
          else {
            this.snack.showerror('Please enter the payment date');
            return null
          };
    this.api.Admin.correspondents.pay(this.correspondent.id, {
      ...form.value,
      year: this.currentPer? date.getFullYear(): undefined,
      month: this.currentPer? date.getMonth() + 1 : undefined,
      day: this.currentPer && isDay ? date.getDate() : undefined,
    }).subscribe((res) => {
      if (!res["error"]) {
        if(this.currentPer){
          this.currentPer.remaining = Math.round((this.currentPer.remaining  -  form.value.value) *10)/10
          this.currentPer.paid = Math.round((Number(this.currentPer.paid) + Number(form.value.value)) *10 )/10

        }else{
          this.correspondent.remaining = Math.round((this.correspondent.remaining  -  form.value.value) *10)/10
          this.correspondent.paid = Math.round((Number(this.correspondent.paid) + Number(form.value.value)) *10 )/10
        }
        $("#paymentModal").toggle("hide");
        $("#paymentModal").modal("hide");
        setTimeout(()=> {
          let page = 1
          let min_date = this.dates.from.split('-').reverse().join('-')
          let max_date = this.dates.to.split('-').reverse().join('-')
          let param = {min_date , max_date,page}
          this.api.Admin.correspondents.get_transactions(
            this.correspondent.id,param
          ).subscribe((res) => {
            this.pers = res;
            this.pagination = { per_page:res['per_page'], current_page: res['current_page'],total: res['total'] }

          });
        }, 0)
        this.api.Admin.correspondents.get_one(this.correspondent.id).subscribe((res) => {
          this.correspondent = res;
        });
        this.api.Common.printables.print_reciept_auto(this.correspondent.id,form.value.value,form.value.note).subscribe(res => {

          const fileURL = URL.createObjectURL(res);
          window.open(fileURL, '_blank');

        })
      }
    });
  }

  remove(transactionID){
    this.api.Admin.correspondents.delete_transaction(this.correspondent.id,transactionID).subscribe((res)=>{
      // if (res["error"] == false) {
        setTimeout(()=> {
          let page = 1
          let min_date = this.dates.from.split('-').reverse().join('-')
          let max_date = this.dates.to.split('-').reverse().join('-')
          let param = {min_date , max_date,page}
          this.api.Admin.correspondents.get_transactions(
            this.correspondent.id,param
          ).subscribe((res) => {
            this.pers = res;
            this.pagination = { per_page:res['per_page'], current_page: res['current_page'],total: res['total'] }

          });
        }, 0)
        this.api.Admin.correspondents.get_one(this.correspondent.id).subscribe((res) => {
          this.correspondent = res;
        });
        this.snack.show("Transaction deleted");

      // }
    })
  }
  // getRequests(id= this.id, page = 1){
  //   setTimeout(()=>
  //   this.api.Correspondents.getRequests(id, `?page=${page}&min_date=${this.from.split("-").reverse().join('-')}&max_date=${this.to.split("-").reverse().join('-')}`).subscribe(res =>{
  //     this.requests = res['data']
  //     this.pagination = { per_page:res['per_page'], current_page: res['current_page'],total: res['total'] }

  //   })
  //   ,0)
  // }
}
