import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProgressService } from './progress.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare let $;
// let copier = import('copy-to-clipboard');

export interface Gender {
  name: string;
  id: number;
}

export interface Pricing {
  name: string;
  id: number;
}

export interface SubCategories {
  name: string;
  id: number;
}

export interface Civility {
  name: string;
  gender_id: number;
  id: number;
}

export interface Pagination {
  per_page: number;
  current_page: number;
  total: number;
}

type Role = 'technician' | 'receptionist' | 'admin';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private TitleSubject$ = new BehaviorSubject<any>('');

  //-----------------------------------------------------------------------------------------

  private SerachKeySubject = new BehaviorSubject<String>(null);
  private SerachKey$ = this.SerachKeySubject.asObservable();

  public sidebarStatus = true;

  payment_request_id;
  payment_patientId;
  isDiscount;
  discountMade: Subject<any> = new Subject();

  role: Role = location.pathname.split('/').filter(Boolean)[0] as Role;
  focus_id: any;

  constructor(public progress: ProgressService, private router: Router) {
    this.role_route_changed.subscribe((role: Role) => {
      this.role = role;
    });
  }

  //-----------------------------------------------------------------------------------------
  queriesForSearch = {};
  activity_log_request_id_stored: string = '';
  navigate_to = (url, options = { abs: false }) => {
    let parent = options.abs ? '' : location.pathname + '/';
    this.router.navigateByUrl(parent + url);
  };

  prevent_viewing: boolean = false;
  info = {};

  dataAssigned: BehaviorSubject<boolean> = new BehaviorSubject(false);
  infoAssigned: BehaviorSubject<boolean> = new BehaviorSubject(false);

  genders: Array<Gender> = [];
  doctors: Array<Gender> = [];
  pricings: Array<Pricing> = [];
  subcategories: Array<SubCategories> = [];
  categories: Array<any> = [];
  cashiers: Array<any> = [];
  payment_methods: Array<any> = [];
  civilities: Array<any> = [];
  permissions$: BehaviorSubject<any> = new BehaviorSubject([]);

  reload: Subject<any> = new Subject();
  bilingual: boolean = true;

  language: string = !this.bilingual
    ? 'english'
    : localStorage.getItem('language') || 'english';
  isRTL: boolean = this.bilingual && !!localStorage.getItem('isRTL');

  role_route_changed: Subject<string> = new Subject();

  queries = {};
  userName: any = '';
  user: any = [];
  me: any = {};
  logout_intreval;
  tab_open_intreval;

  setTitle(title: any) {
    this.TitleSubject$.next(title);
  }

  getTitle() {
    return this.TitleSubject$.asObservable();
  }

  getSearchKey$() {
    return this.SerachKey$;
  }

  setSearchKey(key) {
    this.SerachKeySubject.next(key);
  }

  extract_pagination: Function = (res) => ({
    per_page: res['per_page'],
    current_page: res['current_page'],
    total: res['total'],
  });

  check_logout_timer_difference = () => {
    let minutes = +localStorage.getItem('minutes')
    if(!minutes) return
    let last_action = new Date(+localStorage.last_action).getMinutes();
    let nowMins = new Date().getMinutes();
    if (nowMins < last_action) nowMins += 60;
    let diff = nowMins - last_action;

    if(diff >= minutes){
      localStorage.removeItem('token');
      clearInterval(this.logout_intreval)
      location.reload()
    }

  };


  check_tab_opened_time = () => {
    let last_action = new Date(+localStorage.tab_open).getMinutes();
    let nowMins = new Date().getMinutes();
    if (nowMins < last_action) nowMins += 60;
    let diff = nowMins - (last_action || nowMins);


    if(diff > 2){
      localStorage.removeItem('token');
      clearInterval(this.tab_open_intreval)
      location.reload()
    }

  };


  format_date: Function = (date) =>
    date?.split ? date.split('-').reverse().join('-') : null;

  patient = null;
  request = null;

  fireModal(id) {
    $(`#${id}`).modal('show');
  }

  stats_info: { name: string; id: number; label: string; pdf?: boolean };
  statsModal: Subject<any> = new Subject();

  logger = (value) => !environment.production && console.log(value);

  // copy = (str) =>{
  //   copier.then(copy_object => {
  //       // copy_object['default'](str)
  //       copy_object['default'](str)
  //   }).catch(err => console.log)
  // }

  remove: Subject<any> = new Subject();
  cashier: any = {};

  correspondent: {
    current: {};
    month: {};
    day: {};
  };
}
