<ng-container *ngIf="this.router.url === '/admin/support'">
    <div class="row">
        <div class="col">
            <h2 class="roboto-300 start">{{'Support' | locale}}</h2>
        </div>

        <button
          (click)="$event.preventDefault()"
          class="form-control"
          style="width: fit-content;height: fit-content; cursor: inherit"
        >
          <h3 class="m-0">#{{ tickets_counter }}</h3>
        </button>
        <button
          (click)="$event.preventDefault()"
          class="form-control"
          style="width: fit-content;height: fit-content; cursor: inherit; background-color: rgb(183, 50, 50);"
        >
          <h3 class="m-0" style="color: white;">#{{ high_tickets_counter }}</h3>
        </button>
        <button
          (click)="$event.preventDefault()"
          class="form-control"
          style="width: fit-content;height: fit-content; cursor: inherit; background-color: rgb(180, 136, 56);"
        >
          <h3 class="m-0" style="color: white;">#{{ medium_tickets_counter }}</h3>
        </button>
        <button
          (click)="$event.preventDefault()"
          class="form-control"
          style="width: fit-content;height: fit-content; cursor: inherit; background-color: rgb(61, 188, 61);"
        >
          <h3 class="m-0" style="color: white;">#{{ low_tickets_counter }}</h3>
        </button>
    </div>
    <div class="card mb-5 mt-5">
        <div class="container-fluid py-3 px-4">
            <div class="col-12 col-lg-2 mt-3">
                <div class="form-group" *ngIf="tickets_counter > 0">
                    <label>&nbsp;</label>
                    <button data-toggle="modal" data-target="#cor-modal" type="button" id="add-button" class="btn btn-outline-primary w-100 roboto-400" (click)="action = {'type' : 'New'}">{{'Add' | locale}} +</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive  ">
                        <table class="table mt-3">
                            <thead>
                                <tr style="text-align: center;" class="neue-light roboto-400">
                                    <th scope="col"> # </th>
                                    <th style="min-width: 150px;" scope="col"> {{'Subject' | locale}}</th>
                                    <th style="min-width: 150px;" scope="col"> {{'Status' | locale}}</th>
                                    <th style="min-width: 150px;" scope="col"> {{'Priority' | locale}}</th>
                                    <th style="min-width: 150px;" scope="col"> {{'Duo Date' | locale}}</th>
                                    <th style="min-width: 150px;" scope="col"> {{'Date' | locale}}</th>
                                </tr>
                            </thead>
                            <br>
                            <tbody class="neue-light mt-2">
                                <ng-container *ngFor="let ticket of tickets;let i = index">
                                    <tr *ngIf="ticket.status == 'new'" (click)="router.navigateByUrl('admin/support/' + ticket.id)" valign="middle" class="clickable_row roboto-400" style="background-color: gray; color: white">
                                        <td scope="row">{{i + 1}}</td>
                                        <td style="min-width: 150px;">{{ticket.title || '✖' }}</td>
                                        <td style="min-width: 150px;">
                                            <span *ngIf="ticket.status == 'new'">
                                                {{(ticket.status | locale)}}
                                            </span>
                                            <span *ngIf="ticket.status == 'open' " class="badge badge-warning">
                                                {{(ticket.status | locale)}}
                                            </span>
                                            <span *ngIf="ticket.status == 'closed' " class="badge badge-success">
                                                {{(ticket.status | locale)}}
                                            </span>
                                            <span *ngIf="ticket.status == 'pending' " class="badge badge-danger">
                                                {{(ticket.status | locale)}}
                                            </span>
                                        </td>
                                        <td style="min-width: 150px;">
                                            <span *ngIf="!ticket.priority">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                            <span *ngIf="ticket.priority == 'high' " class="badge badge-danger">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                            <span *ngIf="ticket.priority == 'medium' " class="badge badge-warning">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                            <span *ngIf="ticket.priority == 'low' " class="badge badge-success">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                        </td>
                                        <td style="min-width: 150px;">{{ticket.due_date ? (ticket.due_date | date: "short"): '✖'}}</td>
                                        <td style="min-width: 150px;">{{ticket.created_at ? (ticket.created_at | date: "short"): '✖'}}</td>
                                    </tr>
                                    <tr *ngIf="ticket.status != 'new'" (click)="router.navigateByUrl('admin/support/' + ticket.id)" valign="middle" class="clickable_row roboto-400">
                                        <td scope="row">{{i + 1}}</td>
                                        <td style="min-width: 150px;">{{ticket.title || '✖' }}</td>
                                        <td style="min-width: 150px;">
                                            <span *ngIf="ticket.status == 'new'">
                                                {{(ticket.status | locale)}}
                                            </span>
                                            <span *ngIf="ticket.status == 'open' " class="badge badge-warning">
                                                {{(ticket.status | locale)}}
                                            </span>
                                            <span *ngIf="ticket.status == 'closed' " class="badge badge-success">
                                                {{(ticket.status | locale)}}
                                            </span>
                                            <span *ngIf="ticket.status == 'pending' " class="badge badge-danger">
                                                {{(ticket.status | locale)}}
                                            </span>
                                        </td>
                                        <td style="min-width: 150px;">
                                            <span *ngIf="!ticket.priority">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                            <span *ngIf="ticket.priority == 'high' " class="badge badge-danger">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                            <span *ngIf="ticket.priority == 'medium' " class="badge badge-warning">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                            <span *ngIf="ticket.priority == 'low' " class="badge badge-success">
                                                {{ticket.priority ? (ticket.priority | locale) : '✖'}}
                                            </span>
                                        </td>
                                        <td style="min-width: 150px;">{{ticket.due_date ? (ticket.due_date | date: "short"): '✖'}}</td>
                                        <td style="min-width: 150px;">{{ticket.created_at ? (ticket.created_at | date: "short"): '✖'}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Modal -->
<div class="modal fade" id="cor-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <app-loading [type]="'dialog'"></app-loading>
            <div class="modal-body">
                <div class="container-fluid px-4 mt-3">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="roboto-500 start">{{((this.action?.type == 'Edit'? 'Edit' : 'Add') + ' ticket') | locale}}</h5>
                        </div>
                    </div>
                </div>
                <app-new-support [action]="action" (setCreated)="correspondentCreated()"></app-new-support>
            </div>
        </div>
    </div>
</div>