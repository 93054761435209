import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $:any

@Component({
  selector: 'admin-layout-family-list',
  templateUrl: './family-list.component.html',
  styleUrls: ['./family-list.component.scss']
})
export class FamilyListComponent implements OnInit {

  action :any = '';

  families :any = [];

  constructor(public data:DataService, public router:Router,public api : ApiService,public progress : ProgressService, public ms:ModalService) { }

  ngOnInit() {
    this.getFamilies();
    this.data.remove.subscribe(({id,type}) => {
      if(type == "family"){
        this.families = this.families.filter(one => one.id != id)
        $("#families-modal").modal('hide');
      }
    })
  }

  getFamilies (){
    this.progress.on();
    this.api.Admin.families.get().subscribe(res => {
      this.families = res
      this.progress.off();
    });
  }

  openModal(action){
    this.action = action
    $("#families-modal").modal('show');
  }

  catCreated(){
    console.log('here');
    this.getFamilies();
    $("#families-modal").modal('hide');
  }

}
