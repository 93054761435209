import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'app-sub-categories-list',
  templateUrl: './sub-categories-list.component.html',
  styleUrls: ['./sub-categories-list.component.scss']
})
export class SubCategoriesListComponent implements OnInit {
  action: any = '';
  cats: any[] = [];

  constructor(
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public ms: ModalService
  ) {}

  ngOnInit() {
    this.getSubCategories();
  }

  getSubCategories() {
    this.progress.on();

    this.api.Common.Get.subcategories().subscribe((res) => {
      this.progress.off();
      this.cats = res;
    });
  }
}
