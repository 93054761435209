<div class="row" style="margin-top: 50px">
  <div class="col">
    <h2 class="roboto-300 start">
      {{ "Request" | locale }} #{{ request?.reception_number }}
    </h2>
    <h5 class="text-muted start" *ngIf="request?.created_at">
      {{ request.created_at | date: "full" }}
    </h5>
    <h5 class="text-muted start" *ngIf="request?.draw_time">
      {{ "Drawing Time" | locale }} : {{ request.draw_time | date: "full" }}
    </h5>
  </div>

  <div *ngIf="hasActivityLog">
    <div>
      <button
        data-toggle="modal"
        data-target="#param-modal"
        type="button"
        id="add-button"
        class="btn btn-primary mx-2 roboto-400"
        (click)="
          data.activity_log_request_id_stored = request?.reception_number;
          data.navigate_to('technician/activity', { abs: true })
        "
      >
        {{ "Activity log" | locale }}
      </button>
    </div>
  </div>


  <!-- <ng-container *ngIf="patient.hospital_id != null" > -->
  <div>
    <button
      type="button"
      id="add-button"
      class="btn btn-primary mx-2 roboto-400"
      (click)="history()"
    >
      {{ "history" | locale }}
    </button>
  </div>
  <!-- </ng-container> -->

  <div>
    <div>
      <button
        type="button"
        id="add-button"
        class="btn btn-primary mx-2 roboto-400"
        (click)="backWithData()"
      >
        {{'Back' | locale}}
      </button>
    </div>
  </div>
</div>

<div class="card w-100 p-4 p-xl-5 my-5">
  <div id="patient-content" class="container-fluid py-2">
    <div class="row mt-2 mb-4">
      <div class="col-12">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 container-fluid">
              <div class="row justify-content-end">
                <div>
                  <div class="container-fluid">
                    <div class="row">
                      <div
                        *ngFor="let button of buttons"
                        style="text-align: end"
                        class="col px-2"
                      >
                        <button
                          *ngIf="lab_to_lab"
                          (click)="download_upload_modal_data = button.data"
                          data-target="#download_upload_modal"
                          data-toggle="modal"
                          class="btn btn-outline-primary"
                        >
                          {{ button.label | locale }}
                        </button>
                      </div>
                      <div *ngIf="request?.is_micro" class="col px-2">
                        <button
                          type="button"
                          data-target="#cult_modal"
                          data-toggle="modal"
                          class="btn"
                          [ngClass]="{
                            'btn-primary ': request.culture_results,
                            'btn-outline-primary': !request.culture_results
                          }"
                        >
                          Culture
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9">
        <h4 class="roboto-500 start">{{ "Patient Info" | locale }}</h4>
      </div>
      <div class="col-3">
        <div class="w-100 justify-content-end d-flex">
          <!-- <button
          *ngIf="this.action == 'Edit' "
           type="button" (click)="goTo()" class="btn btn-outline-success tajwal-regium px-4">Edit Result
          </button> -->

          <!-- <button
          *ngIf="this.action == 'Edit' && (request.status == 'active')"
           type="submit" disabled class="btn btn-outline-danger tajwal-regium px-4">Edit Result
          </button> -->
        </div>
      </div>
    </div>
    <ng-container *ngIf="patient">
      <form>
        <div class="row neue-reg mt-3">
          <div class="col-lg-4">
            <div class="form-group">
              <label for="exampleInputEmail1">{{
                "First Name" | locale
              }}</label>
              <input
                disabled
                required
                pattern="^([^0-9]*)$"
                maxlength="25"
                name="first_name"
                [(ngModel)]="patient['first_name']"
                #name_input="ngModel"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label for="exampleInputEmail1">{{
                "Middle Name" | locale
              }}</label>
              <input
                disabled
                name="middle_name"
                [(ngModel)]="patient['middle_name']"
                #middle_name_input="ngModel"
                type="text"
                class="form-control"
                placeholder="Enter Middle Name"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label for="exampleInputEmail1">{{ "Last Name" | locale }}</label>
              <input
                disabled
                required
                pattern="^([^0-9]*)$"
                maxlength="25"
                [(ngModel)]="patient['last_name']"
                name="last_name"
                type="text"
                class="form-control"
                #last_name_input="ngModel"
              />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label for="gender">{{ "Gender" | locale }}</label>

              <input
                class="form-control"
                name="gender"
                disabled
                [(ngModel)]="patient['gender']['name']"
              />

              <!-- <select [(ngModel)]="Gender_id"   name="gender_id" id="civility" class="custom-select">
                  <option *ngFor="let gender of genders | async"  [value]="gender.id">{{gender.name}}</option>
              </select> -->
            </div>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label for="picker">{{ "Birthdate" | locale }}</label>
              <input
                class="form-control"
                name="birthdate"
                disabled
                [ngModel]="patient['birthdate'] | date"
              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label for="exampleInputEmail1">{{ "Age" | locale }}</label>
              <ng-container *ngIf="data.patient['birthdate'] | ago as values">
                <input
                  readonly
                  pattern="^(0|[1-9][0-9]*)$"
                  [ngModel]="patient['age'] || ''"
                  maxlength="3"
                  name="age"
                  type="text"
                  class="form-control"
                  #age_input="ngModel"
                />
              </ng-container>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label for="exampleInputEmail1">{{
                "Phone number" | locale
              }}</label>
              <input
                disabled
                required
                [(ngModel)]="patient['phone_number']"
                name="phone_number"
                type="text"
                class="form-control"
                #last_name_input="ngModel"
              />
            </div>
          </div>
        </div>

        <div class="row neue-reg">
          <div class="col-lg-3">
            <div class="form-group">
              <label for="civility">{{ "Civility" | locale }}</label>

              <input
                class="form-control"
                name="civility"
                disabled
                [(ngModel)]="patient['civility']"
              />
              <!-- <select [(ngModel)]="civility_id"  name="civility_id" id="civility" class="custom-select">
                  <option *ngFor="let civility of civilities | async"  [value]="civility.id">{{civility.name}}</option>

              </select> -->
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="exampleInputEmail1">{{
                "Correspondent ID" | locale
              }}</label>
              <input
                disabled
                maxlength="25"
                [(ngModel)]="patient['insurance_id']"
                [placeholder]="'Correspondent ID' | locale"
                name="insurance_id"
                type="text"
                class="form-control"
                #insurance_id_input="ngModel"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>{{ "Hospital ID" | locale }}</label>
              <input
                disabled
                pattern="[0-9]{0,14}"
                title="Hospital ID"
                maxlength="25"
                name="hospital_id"
                [(ngModel)]="patient['hospital_id']"
                #phone_input="ngModel"
                type="text"
                class="form-control"
                [placeholder]="'Hospital ID' | locale"
              />
              <small
                *ngIf="
                  (phone_input.errors
                    ? phone_input.errors['pattern']
                    : false) && phone_input.touched
                "
              >
                the hospital id should not contain any letter
              </small>
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label>{{ "National ID" | locale }}</label>
              <input
                disabled
                pattern="[0-9]{0,14}"
                title="National ID"
                maxlength="12"
                name="NID"
                [(ngModel)]="patient['NID']"
                #NID_input="ngModel"
                type="text"
                class="form-control"
                [placeholder]="'National ID' | locale"
              />
              <small
                *ngIf="
                  (phone_input.errors
                    ? phone_input.errors['pattern']
                    : false) && phone_input.touched
                "
              >
                the hospital id should not contain any letter
              </small>
            </div>
          </div>
        </div>
      </form>
    </ng-container>

    <!-- <form #form="ngForm"  (ngSubmit)="onSubmit(form)" >


      <div class="row neue-reg mt-3">

        <div class="col-lg-6">

            <div class="form-group">
                <label for="exampleInputEmail1"> Name</label>
                <input disabled required maxlength="25" name="first_name" [(ngModel)]="request.patient" #name_input="ngModel" type="text" class="form-control"   placeholder="Enter First Name">

            </div>

        </div>

        <div class="col-lg-6">

          <div class="form-group">
              <label for="exampleInputEmail1"> Gender</label>
              <input disabled required maxlength="25" name="gender" [(ngModel)]="request.gender" #gender_input="ngModel" type="text" class="form-control"   placeholder="Enter First Name">
          </div>

      </div>

      </div>


    </form> -->
  </div>

  <form #form="ngForm">
    <div class="container-fluid">
      <div class="row mt-2 mb-4">
        <div class="col-12">
          <h4 class="roboto-500 start">{{ "Request Info" | locale }}</h4>
        </div>
      </div>

      <div class="row neue-reg">
        <div *ngIf="request" class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputEmail1">
              {{ "Correspondent" | locale }}</label
            >
            <input
              disabled
              required
              pattern="^([^0-9]*)$"
              maxlength="25"
              name="correspondent"
              [(ngModel)]="request['correspondent']['name']"
              #name_input="ngModel"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="picker">{{ "Hospitalization Date" | locale }}</label>
            <input
              disabled
              name="hospitalization_date"
              id="picker"
              class="flatpickr flatpickr-input active form-control"
              type="text"
              [placeholder]="'Select a Date' | locale"
            />
          </div>
        </div>
      </div>

      <div *ngIf="request" class="row neue-reg">
        <div class="col-lg-8">
          <div class="form-group">
            <label for="exampleInputEmail1"
              >{{ "Notes | Clinical Information" | locale }}
            </label>
            <input
              readonly
              maxlength="500"
              name="clinical_information"
              [(ngModel)]="request.clinical_information"
              type="text"
              class="form-control"
              #clincal_information_input="ngModel"
              [placeholder]="'Notes | Clinical Information' | locale"
            />
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label for="exampleInputEmail1"> {{ "Pricing" | locale }}</label>
            <input
              disabled
              required
              pattern="^([^0-9]*)$"
              maxlength="25"
              name="pricing"
              [(ngModel)]="request['pricing']['name']"
              #name_input="ngModel"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div *ngIf="request" class="row neue-reg">
        <div class="col-lg-12">
          <div class="form-group">
            <!-- <i (click)="removeTest()" class="fas fa-minus-circle btn btn-outline-dark edit-notes p-0 text-left"></i> -->
            <label class="" for="exampleInputEmail1">{{
              "Laboratory Notes" | locale
            }}</label>
            <textarea
              (input)="showWSaveLabNotes()"
              rows="4"
              cols="50"
              name="laboratory_information"
              [(ngModel)]="request.laboratory_information"
              type="text"
              class="form-control"
              #lab_input="ngModel"
              [placeholder]="'Laboratory Notes' | locale"
            ></textarea>

            <div class="">
              <button
                (click)="saveLabNotes(lab_input)"
                [hidden]="isLabNotesSaveHidden"
                #save
                type="submit"
                class="btn save-btn btn-success py-0 roboto-400 btn-lab-notes"
              >
                {{ "Save" | locale }}
              </button>
            </div>
            <!-- <i class="fas fa-pen btn btn-outline-dark edit-notes p-0 text-left mb-1"></i> -->
          </div>
        </div>
      </div>

      <div *ngIf="request" class="row">
        <div class="col-lg-2">
          <div class="d-flex h-100 align-items-center">
            <div class="">
              <div class="custom-control custom-checkbox">
                <input
                  name="urgent_flag"
                  [(ngModel)]="request.urgent_flag"
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                />
                <label class="custom-control-label" for="customCheck1">{{
                  "Urgent" | locale
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="d-flex h-100 align-items-center">
            <div class="">
              <div class="custom-control custom-checkbox">
                <input
                  name="is_repeat"
                  [(ngModel)]="request.is_repeat"
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck2"
                />
                <label class="custom-control-label" for="customCheck2">{{
                  "Repeat" | locale: 2
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="mt-4" />

      <!-- <div class="row mt-4 mb-4">
          <div class="col-12">
              <h4 class="roboto-500">Request Tests & Profiles</h4>
          </div>
        </div>


          <div  class="btn-group btn-group-toggle mb-2" data-toggle="buttons">
              <label class="btn btn-outline-primary active">
                <input (click)="toggle('p')" type="radio" name="options" [value]="'p'" [(ngModel)]="searchType" id="option1" > Profiles
              </label>
              <label class="btn btn-outline-primary">
                <input (click)="toggle('t')" type="radio" name="options" [value]="'t'" [(ngModel)]="searchType" id="option2"> Tests
              </label>
          </div> -->

      <!-- <app-test-select (setSelectedTest)="setSelectedTest($event)"
         [hidden]="searchType == 'p'? true : false"></app-test-select>
        <app-profile-select (setSelectedProfile)="setSelectedProfile($event)"
         [hidden]="searchType == 't'? true : false"></app-profile-select> -->

      <!-- <div [hidden]="searchType == 't'? true : false" class="table-responsive mt-2">
          <table class="table ">
            <thead>
              <tr class="neue-light ">
                <th width="15%" scope="col"> id</th>
                <th scope="col"> Name</th>

              </tr>



            </thead>

            <tbody class="neue-light">

              <ng-container *ngIf="request['profiles']">

                <ng-container *ngFor="let profile of request['profiles']['data'] ">

                  <tr valign="middle" (mousedown)="$event.preventDefault()" class="clickable_row ">
                    <td>{{profile.code}}</td>
                    <td>{{profile.name }}</td>



                </ng-container>

              </ng-container>


            </tbody>
          </table>
        </div>

        <div [hidden]="searchType == 'p'? true : false" class="table-responsive mt-2">
          <table class="table ">
            <thead>
              <tr class="neue-light ">
                <th width="15%" scope="col"> id</th>
                <th scope="col"> Name</th>

              </tr>



            </thead>

            <tbody class="neue-light">

              <ng-container *ngIf="request['tests']">

                <ng-container *ngFor="let test of request['tests']['data']">

                  <tr valign="middle" (mousedown)="$event.preventDefault()"  class="clickable_row ">

                    <td>{{test.code}}</td>
                    <td>{{test.name }}</td>

                  </tr>
                </ng-container>


              </ng-container>



            </tbody>
          </table>
        </div> -->

      <!-- <div  class="row neue-reg my-4">
          <div class="col-6">
            <h4>Total : <span class="roboto-700">{{total}}LYD</span> </h4>
          </div> -->
      <!-- <div class="col-6">
              <div class="d-flex justify-content-end">
                  <button  type="submit" type="submit" class="btn btn-outline-primary tajwal-regium px-5">Next </button>
              </div>
          </div> -->
      <!-- </div> -->
    </div>
  </form>
</div>

<technician-layout-edit-result
  *ngIf="request"
  [request]="request"
></technician-layout-edit-result>

<div *ngIf="notes.length" class="card w-100 p-4 p-xl-5 my-5">
  <div id="patient-content" class="container-fluid py-2">
    <div class="d-flex mt-2 mb-4">
      <h4 class="roboto-500">Notes</h4>
      <div class="col">
        <ng-container *ngIf="is_expert && request">
          <div class="d-flex align-items-center justify-content-end w-100">
            <button
              type="button"
              [disabled]="request['status'] == 'techncally validated'"
              (click)="printHisto()"
              class="btn btn-outline-success tajwal-regium px-4"
            >
              Print report
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row neue-reg">
      <div *ngFor="let note of notes" class="col-lg-12">
        <div class="form-group">
          <!-- <i (click)="removeTest()" class="fas fa-minus-circle btn btn-outline-dark edit-notes p-0 text-left"></i> -->
          <label class="" for="exampleInputEmail1">{{ note.title }}</label>
          <textarea
            (keydown)="note.showSaveText = true"
            rows="4"
            cols="50"
            [name]="note.id"
            [(ngModel)]="note.val"
            type="text"
            class="form-control"
            #lab_input{{note.id}}="ngModel"
            placeholder="Enter Notes"
          ></textarea>

          <div class="">
            <button
              (click)="addNotesContent(note)"
              [hidden]="!note.showSaveText"
              #save{{note.title}}
              type="submit"
              class="btn save-btn btn-success py-0 roboto-400 btn-lab-notes"
            >
              Save
            </button>
          </div>
          <!-- <i class="fas fa-pen btn btn-outline-dark edit-notes p-0 text-left mb-1"></i> -->
        </div>
      </div>
    </div>
    <hr class="mt-4" />
  </div>
</div>

<div
  class="modal fade"
  id="cult_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Culture</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <ng-container *ngFor="let sample of samples$ | async">
          <h5>{{ sample.name }}</h5>
          <div class="card container-fluid">
            <div class="card-body row">
              <div class="col-12">
                <selector
                  (setSelectedItem)="addBacteria(sample, $event)"
                  [label]="'Add bacteria' | titlecase"
                  [title]="'bacteria'"
                  [unique]="sample.id"
                >
                </selector>
              </div>

              <div
                class="card mb-5 col-12"
                *ngFor="
                  let bacteria of sample['bacterias'];
                  let bacteria_index = index
                "
              >
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 mt-3 form-group">
                      <ng-container>
                        <label> {{ "bacteria" | titlecase }} </label>
                        <div class="col-12 container-fluid">
                          <div class="row">
                            <div class="col-10">
                              <button
                                class="btn btn-light border col right-rad"
                              >
                                {{ bacteria.name }}
                              </button>
                            </div>
                            <div class="col-2">
                              <button
                                class="btn btn-danger form-control left-rad"
                                style="width: fit-content"
                                (click)="
                                  removeSampleBacteria(
                                    sample,
                                    bacteria,
                                    bacteria_index
                                  )
                                "
                              >
                                ✖
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <label>CFU: </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter CFU Notes"
                            id="cfu"
                            name="cfu"
                            value="{{ bacteria.sample?.cfu }}"
                            maxlength="100"
                            (blur)="
                              addCfu(sample, bacteria, $event.target.value)
                            "
                          />
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 mt-3 form-group">
                      <ng-container *ngIf="bacteria.name">
                        <selector
                          [unique]="bacteria_index"
                          (setSelectedItem)="addAntibiotic(bacteria, $event)"
                          [label]="'Add antibiotic' | titlecase"
                          [title]="'antibiotic'"
                        >
                        </selector>

                      </ng-container>
                    </div>

                    <div
                      *ngIf="bacteria.name"
                      style="width: 100% !important"
                      class="table-responsive mt-4 col-12"
                    >
                      <table style="width: 100% !important" class="table">
                        <thead>
                          <tr class="neue-light">
                            <th scope="col">Antibiotic</th>
                            <th scope="col">Sensitivity</th>
                            <th scope="col">Remove</th>
                          </tr>
                        </thead>
                        <br />

                        <tbody class="neue-light">
                          <ng-container
                            *ngFor="
                              let anti of bacteria['antibiotics'];
                              index as anti_index
                            "
                          >
                            <tr
                              class="hover-danger"
                              valign="middle"
                              class="clickable_row"
                            >
                              <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                              <td>
                                {{ anti.name }}
                              </td>
                              <td>
                                <select
                                  name="sensitivity"
                                  [(ngModel)]="anti['sensitivity']"
                                  (change)="
                                    addSensitivity(sample.id, bacteria.id, anti)
                                  "
                                  class="form-control"
                                >
                                  <option
                                    *ngFor="
                                      let sensitivity of [
                                        'Sensitive',
                                        'Sensitive+',
                                        'Sensitive++',
                                        'Sensitive+++',
                                        'Resistance',
                                        'Resistance+',
                                        'Resistance++',
                                        'Resistance+++',
                                        'Intermediate',
                                        'Intermediate+',
                                        'Intermediate++',
                                        'Intermediate+++'
                                      ]
                                    "
                                    [ngValue]="sensitivity"
                                  >
                                    {{ sensitivity }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <button
                                  class="btn btn-danger form-control"
                                  style="width: fit-content"
                                  (click)="
                                    removeSampleAnti(
                                      sample,
                                      bacteria,
                                      anti,
                                      anti_index
                                    )
                                  "
                                >
                                  ✖
                                </button>
                              </td>
                              <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                                <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                              </h5></a>
                              </td> -->
                              <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="download_upload_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">
          {{ download_upload_modal_data.title | locale }}
        </h5>
        <!-- <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <form #upload_form="ngForm" (ngSubmit)="upload(upload_form)">
          <input type="hidden" name="request_id" [(ngModel)]="request_id" />

          <div class="form-group">
            <label for="lab">{{ "Lab" | locale }}</label>
            <select
              *ngIf="labs$ | async as labs"
              [ngModel]="labs[0]?.id"
              name="to_lab_id"
              id="lab"
              class="custom-select"
            >
              <option *ngFor="let lab of labs" [value]="lab.id">
                {{ lab.name }}
              </option>
            </select>
          </div>

          <!-- selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All', -->
          <div class="form-group">
            <label for="tests">{{ "Tests" | locale }}</label>

            <ng-multiselect-dropdown
              [placeholder]="'select tests' | locale"
              [settings]="{
                singleSelection: false,
                idField: 'id',
                enableCheckAll: false,
                textField: 'name',
                itemsShowLimit: 3,
                allowSearchFilter: true
              }"
              [data]="tests"
              ngModel
              name="online_tests"
            >
              <!-- (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)" -->
            </ng-multiselect-dropdown>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Close" | locale }}
        </button>
        <button
          (click)="upload_form.ngSubmit.emit()"
          type="button"
          class="btn btn-primary"
        >
          {{ download_upload_modal_data.title | locale }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="history"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4">
          <ng-container>
            <h1 class="start">{{ "History" | locale }}</h1>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table mt-3">
                    <thead>
                      <tr class="neue-light roboto-400">
                        <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                        <th scope="col" width="15%">
                          {{ "Request" | locale }} #
                        </th>
                        <!-- <th scope="col"> Patient</th> -->
                        <!-- <th scope="col"> Correspondent</th> -->
                        <th scope="col" width="15%">{{ "Date" | locale }}</th>
                        <!-- <th scope="col"> Clinical Info</th> -->
                        <!-- <th scope="col" width="15%">Category</th> -->
                        <!-- <th scope="col"> Total</th> -->
                        <th scope="col" width="10%">{{ "Test" | locale }}</th>
                        <th scope="col" width="15%">
                          {{ "Parameter" | locale }}
                        </th>
                        <th scope="col" width="5%">{{ "Result" | locale }}</th>
                        <th scope="col" width="25%">{{ "Notes" | locale }}</th>
                        <!-- <th scope="col">خيارات</th> -->
                        <!-- <th scope="col"></th> -->
                      </tr>
                    </thead>
                    <tbody class="neue-light">
                      <ng-container
                        *ngFor="
                          let request of historyData
                            | paginate
                              : {
                                  id: 'pagination',
                                  itemsPerPage: pagination.per_page,
                                  currentPage: pagination.current_page,
                                  totalItems: pagination.totalItems
                                }
                        "
                      >
                        <tr
                          valign="middle"
                          class="clickable_row roboto-400"
                        >
                        <!-- <tr
                          valign="middle"
                          class="clickable_row roboto-400"
                          [ngClass]="{ urgent: request.urgent_flag }"
                        > -->
                          <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                          <td width="15%">{{ request.reception_number }}</td>
                          <!-- <td>{{request.patient_name }}</td> -->
                          <!-- <td>{{request.correspondent_name }}</td> -->
                          <td width="15%">{{ request.date }}</td>
                          <!-- <td width="15%">{{ request.category_name }}</td> -->
                          <td width="10%">{{ request.test_name }}</td>
                          <td width="15%">{{ request.parameter }}</td>
                          <td width="5%">{{ request.result }}</td>
                          <td width="25%">{{ request.note }}</td>

                          <!-- <td style="width: 70px;">{{request.total }} <span class="roboto-400 cur"> &nbsp; LYD</span></td> -->
                          <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                          <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                        </h5></a>
                        </td> -->
                          <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-12 mt-3">
                <div class="w-100 d-flex justify-content-center neue-med p-0">
                  <pagination-controls
                    pagination-template
                    responsive="true"
                    [previousLabel]="'Previous' | locale"
                    [nextLabel]="'Next' | locale"
                    (pageChange)="getData($event)"
                    id="pagination"
                  ></pagination-controls>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
