import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';


export interface Receptionist_requests_params {
  page:number
  min_date:string,
  max_date:string,
  from_time:string,
  to_time:string,
  payment_status?: 'paid' | 'unpaid',
  correspondent?: 'hasCorrespondent' | 'noCorrespondent',
  patient?:string
}

@Injectable()

export class ApiService extends HttpService {
  civilities = {
    add: body => super.post({ route: 'civilities', body }),
    update: body => super.put({ route: 'civilities/' + body.id, body }),
    remove: id => super.delete({ route: 'civilities/' + id }),
  };

  categories = {
    add: (body) => super.post({ route: 'categories', body }),
    update: (body) => super.put({ route: 'categories/' + body.id, body }),
    remove: (id) => super.delete({ route: 'categories/' + id }),
  };

  subcategories = {
    add: (body) => super.post({ route: 'sub-categories', body }),
    update: (body) => super.put({ route: 'sub-categories/' + body.id, body }),
    remove: (id) => super.delete({ route: 'sub-categories/' + id }),
  };

  patients = {
    upload: (body) => super.post({ route: 'patients/upload', body }),
  };

  units = {
    add: (body) => super.post({ route: 'units', body }),
    update: (body) => super.put({ route: 'units/' + body.id, body }),
    remove: (id) => super.delete({ route: 'units/' + id }),
  };

  samples = {
    add: (body) => super.post({ route: 'samples', body }),
    update: (body) => super.put({ route: 'samples/' + body.id, body }),
    remove: (id) => super.delete({ route: 'samples/' + id }),
  };

  pricing = {
    add: (body) => super.post({ route: 'pricing', body }),
    update: (body) => super.put({ route: 'pricing/' + body.id, body }),
    remove: (id) => super.delete({ route: 'pricing/' + id }),
    pricingsWithNoEnherit: () =>super.get({ route: 'pricing_with_auto'}),
    getTestsPricing: (id,price_id) =>super.get({ route: `get_tests_pricing/${id}/${price_id}`}),
    updateTestsPricing: (price_id,body) => super.post({ route: `update_tests_pricing/${price_id}`, body }),

  };

  payment_methods = {
    add: (body) => super.post({ route: 'payment-methods', body }),
    update: (body) => super.put({ route: 'payment-methods/' + body.id, body }),
    remove: (id) => super.delete({ route: 'payment-methods/' + id }),
  };

  clinics = {
    get: () => super.get({route:'clinics'}).pipe(map(res => res['data'])),
    get_one: id => super.get({route:`clinics/${id}`}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'clinics', body }),
    update: (body) => super.put({ route: 'clinics/' + body.id, body }),
    remove: (id) => super.delete({ route: 'clinics/' + id }),
    activate: id => super.put({ route: `clinics/${id}/activate`}),
    deactivate: id => super.put({ route: `clinics/${id}/deactivate`}),
    get_stats: (id, params:{per:string,page:number}) =>  super.get({route: `clinics/${id}/stats`, params}),
    pay: (id, body) => super.post({route: `clinics/${id}/pay`, body}),
  };

  correspondents = {
    get: () => super.get({route:'correspondents'}).pipe(map(res => res['data'])),
    get_one: id => super.get({route:`correspondents/${id}`}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'correspondents', body }),
    update: (body) => super.put({ route: 'correspondents/' + body.id, body }),
    remove: (id) => super.delete({ route: 'correspondents/' + id }),
    toggle_default: id => super.get({ route: `correspondents/${id}/toggle-default` }),
    get_stats: (id, params:{per:string}) =>  super.get({route: `statistics/correspondents/${id}`, params}).pipe(map((res) => res['data'])),
    get_requests: (id,params:{min_date?:string,max_date?:string,page:number} = {page:1}) =>  super.get({route: `correspondents/${id}/requests`, params}),
    get_transactions: (id, params:{min_date?:string,max_date?:string,page:number} = {page:1}) =>  super.get({route: `correspondents/${id}/transactions`, params}).pipe(map((res) => res['data'])),
    pay: (id, body) => super.post({route: `correspondents/${id}/pay`, body}),
    delete_transaction: (correspondent_id,id) =>  super.delete({route:`correspondents/${correspondent_id}/transactions/` + id}).pipe(map((res) => res['data'])),


  };

  packages = {
    get_one: id => super.get({route:'tests/packages/' + id}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'tests/packages', body }),
    update: (body) => super.put({ route: 'tests/packages/' + body.id, body }),
    remove: (id) => super.delete({ route: 'tests/packages/' + id }),


  };

  families = {
    get: () => super.get({route:'families'}).pipe(map(res => res['data'])),
    get_one: id => super.get({route:'families/' + id}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'families', body }),
    update: (body) => super.put({ route: 'families/' + body.id, body }),
    remove: (id) => super.delete({ route: 'families/' + id }),


  };

  receptionists = {
    get: (params:{date:string,from_time:string,to_time:string}) => super.get({route:'admin/receptionists', params, prefixed:false}).pipe(map(res => res['data'])),
    get_one: (id,params:{page:number,min_date:string,max_date:string,from_time:string,to_time:string}) => super.get({route:'admin/receptionists/' + id, params, prefixed:false}),
    transactions: (id,params:{min_date:string,max_date:string,from_time:string,to_time:string,payment_method_id:string,page:number}) => super.get({route:`admin/receptionists/${id}/transactions`, params, prefixed:false}),
    allTransactions: (params:{min_date:string,max_date:string,from_time:string,to_time:string,payment_method_id:string,cashier_id:string,payer:string,user_id:string,page:number}) => super.get({route:`admin/receptionists/allTransactions`, params, prefixed:false}),
    requests: (id,params:Receptionist_requests_params) => super.get({route: `admin/receptionists/${id}/requests`, params, prefixed:false}),
  };

  lab = {
    update: (body) => super.put({ route: 'lab', body }),
    update_logo: (body) => super.post({ route: 'lab/logo', body }),
    update_background_image: (body) => super.post({ route: 'lab/background_image', body }),
    recharge: (body) => super.post({ route: 'recharge', body }),
    // recharge: () => super.get({route:'admin/recharge', prefixed:false}),
    rechargeTickets: (body) => super.post({route:'rechargeTickets', body}),
  }

  labs = {
    get: (params:{min_date?:string, max_date?:string}) => super.get({route:'admin/labs',params, prefixed:false}).pipe(map(res => res['data'])),
    get_one: id => super.get({route:'labs/'+ id}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'labs', body }),
    update: (body) => super.put({ route: 'labs/' + body.id, body }),
    remove: (id) => super.delete({ route: 'labs/' + id }),
    get_requests: (id,params:{min_date?:string,max_date?:string,page:number} = {page:1}) =>  super.get({route: `labs/${id}/requests`, params}),
    
  };
  
  supports = {
    get_tickets: () => super.get({route:'support'}).pipe(map(res => res['data'])),
    get_ticket: id => super.get({route:'support/'+id}).pipe(map(res => res['data'])),
    add_ticket: (body) => super.post({route:'support', body}),
    lab_ticket_replies: id => super.get({route:'support/lab_ticket_replies/'+id}).pipe(map(res => res['data'])),
    add_ticket_reply: (body, id) => super.post({route:'support/add_ticket_reply/'+id, body}),
    get_tickets_numbers: () => super.get({route:'support/support_tickets_numbers'}).pipe(map(res => res['data'])),
    charge_lab_counters: (body) => super.post({route:'support/charge_lab_counters', body}),
  };

  treasuries = {
    get: () => super.get({route:'treasuries'}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'treasuries', body }),
    update: (body) => super.put({ route: 'treasuries/' + body.id, body }),
    remove: (id) => super.delete({ route: 'treasuries/' + id }),
  };

  germ_families = {
    get: () => super.get({route:'germ-families'}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'germ-families', body }),
    update: (body) => super.put({ route: 'germ-families/' + body.id, body }),
    remove: (id) => super.delete({ route: 'germ-families/' + id }),
  };

  antibiotics = {
    add: (body) => super.post({ route: 'antibiotics', body }),
    update: (body) => super.put({ route: 'antibiotics/' + body.id, body }),
    remove: (id) => super.delete({ route: 'antibiotics/' + id }),
    bacteria: id => super.get({ route: `admin/antibiotics/${id}/bacterias`, prefixed:false }).pipe(map(res => res['data'])),
  };

  bacteria = {
    add: (body) => super.post({ route: 'bacterias', body }),
    update: (body) => super.put({ route: 'bacterias/' + body.id, body }),
    remove: (id) => super.delete({ route: 'bacterias/' + id }),
    antibiotics: id => super.get({ route: `admin/bacterias/${id}/antibiotics`, prefixed:false }).pipe(map(res => res['data'])),
  };

  devices = {
    get: (params:{code?:string}={code:''}) => super.get({route:'devices', params}).pipe(map(res => res['data'])),
    getOne: id => super.get({route:'devices/' + id,}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'devices', body }),
    update: (body) => super.put({ route: 'devices/' + body.id, body }),
    remove: (id) => super.delete({ route: 'devices/' + id }),
  };

  parameters = {
    getOne: id => super.get({route:'parameters/' + id,}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'parameters', body }),
    update: (body) => super.put({ route: 'parameters/' + body.id, body }),
    remove: (id) => super.delete({ route: 'parameters/' + id }),
  };

  users = {
    get: () => super.get({route:'users'}),
    getOne: id => super.get({route:'users/' + id,}).pipe(map(res => res['data'])),
    add: (body) => {
      body['name'] = `${(body['first_name'] || '')} ${(body['last_name'] || '')}`
      return super.post({ route: 'users', body })
    },
    update: (body) => {
      body['name'] = `${(body['first_name'] || '')} ${(body['last_name'] || '')}`
      console.log(body)
      return super.put({ route: 'users/' + body.id, body })
    },
    remove: (id) => super.delete({ route: 'users/' + id }),
    getPermissions: () => super.get({route:'admin/users/permissions' , prefixed:false}).pipe(map(res => res['data'])),

  };

  tests = {
    get: (params) => super.get({route:'tests', params}).pipe(map(res => res['data'])),
    get_one: id => super.get({route:'tests/'+ id}).pipe(map(res => res['data'])),
    add: (body) => super.post({ route: 'tests', body }),
    update: (body) => super.put({ route: 'tests/' + body.id, body }),
    remove: (id) => super.delete({ route: 'tests/' + id }),
    editPrices: (body) => super.put({route: `tests/tests-prices`, body, }),
    testPriceFilter: (body,price_id,cat_id) => super.get({route: `tests/test-price-filter/` + body + `/` + price_id + `/` + cat_id, }),
    singleTestPriceFilter: (test_id,price_id) => super.get({route: `tests/single-test-price-filter/` + test_id + `/` + price_id , }),
  };

  doctors = {
    add: (body) => super.post({ route: 'doctors', body }),
    update: (body) => super.put({ route: 'doctors/' + body.id, body }),
    remove: (id) => super.delete({ route: 'doctors/' + id }),
  };


  update_expiration = (params:{date:string}) => super.get({route: 'sys/app/expiration',prefixed:false, params});
  getStats = (name:string,id:number, params:{per:string}) =>  super.get({route:`statistics/${name}/${id}`,params}).pipe(map(res => res['data']))
  getParameterStats = (name:string,id:number, params:{per:string}) =>  super.get({route:`statistics/${name}/${id}`,params}).pipe(map(res => res['data']))
  expiration = (body) => super.put({ route: 'devices/expiration', body })
  // expiration = (params:{devices , date}) => super.put({route: `admin/devices/expiration`,prefixed:false, params});
}
