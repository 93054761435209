import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { SnackService } from '../services/snack.service';
import { version } from '../../version';
declare var $:any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  email:any = "";
  password:any = "";
  productId: any = '';
  last_update_date: any = '';
  appVersion: string = version;


  constructor(public api : ApiService,private router : Router,private data : DataService,
    public snack : SnackService) { }


  // constructor(public api : ApiService,private router : Router,private data : DataService,
  // public snack : SnackService) { }


  ngOnInit() {
    this.api.Common.Get.get_update_date().subscribe(res => {
      this.last_update_date = res['data']
    })
  }

  //-----------------------------------------------------------------------------------------

  // ngAfterViewInit(){

  //   $('body').css('background-color', '#f2f2f2');

  // }

  //-----------------------------------------------------------------------------------------

  onSubmit(form){




    if(form.valid){

      this.api.Common.login(form.value).subscribe((res) => {

console.log('res', res)
        if (!res['error']){
          localStorage.setItem('token','Bearer ' + res['data']['token']);
          localStorage.setItem('id',res['data']['id']);
          localStorage.setItem('tab_open' , (new Date()).getTime() + "")
          this.router.navigateByUrl('/');
        }else this.snack.showerror('Please check you credentials and try again');

      },err => {
        if(err.status == 401){
          this.snack.showerror('Please check you credentials and try again');
        }
        if(err.status == 403){
          this.snack.showerror(err.error?.message || 'Please check you credentials and try again')
        }


      })


    }

    else {

      this.snack.showerror('Please check you credentials and try again');


    }




  }

}
