<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h2  class="roboto-300 start">{{ "Micro" | locale }}</h2>
    </div>
    <select
      class="custom-select mx-auto"
      style="width: fit-content; padding: 0 100px; margin-top: -45px;z-index:2"
      (change)="goto()"
      [(ngModel)]="route"
    >
      <option selected *ngFor="let r of routes" [value]="r">
        {{ r | titlecase | locale }}
      </option>
    </select>
  </div>
</div>

<div>
  <!-- <h2 style="padding-left: 15px;" class="roboto-300 my-0"> -->
  <!-- </h2> -->
</div>

<router-outlet></router-outlet>
