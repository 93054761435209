import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from "@angular/forms";
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { SnackService } from 'src/app/services/snack.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { DataService, Pagination } from 'src/app/services/data.service';
declare let $;

@Component({
  selector: 'admin-layout-update-all-price',
  templateUrl: './update-all-price.component.html',
  styleUrls: ['./update-all-price.component.scss']
})
export class UpdateAllPriceComponent implements OnInit {

  constructor(public router:Router,public api : ApiService,public progress : ProgressService,public data:DataService,public snack:SnackService) { }


  tests: any [] = [];
  pricings: any [] = [];
  key: any = '';
  page:number = 1;
  isLoaderHidden = true;
  price_type_id = 1
  testsArray: any[] = [];
  pagination:Pagination= {current_page:null,per_page:null,total:null};
  category_id = ''
  searchValueText: any
  testDis: any
  testOldAmount: any

  onChange(newValue) {
    // this.progress.on();
    this.tests=[];
    this.getTests(1,this.key,newValue);

  }

  ngOnInit() {
    this.api.Common.Get.pricings().subscribe((res) => {
      this.pricings = res;
    });
    // this.getTests(this.page, this.key);
  }

  getTests(page, key,pricing_id:number=1){
    this.page = page
    console.log(page)

    // $('.dropdown-menu').dropdown('show');

    this.api.Common.Get.tests({page,code:key,pricing_id}).subscribe(res => {

      let tests = res['data']
      this.pagination = this.data.extract_pagination(res)

      this.progress.off();
      this.isLoaderHidden = true

      this.tests = tests
      this.testsArray = []
      tests.forEach(obj => {
        obj['discount'] = obj['discount'] || 0
        obj['amount'] = obj['amount'] || 0
        obj['cost'] = obj['cost'] || 0
        obj['test_pricing']?.forEach(obj2 => {
          if(obj2['pricing_id'] == this.price_type_id){
            this.testDis = obj2['discount']
            this.testOldAmount = obj2['old_amount']
          }
        })
        this.testsArray.push(
          {
            id: obj['id'],
            code: obj['code'],
            name: obj['name'],
            amount: obj['amount'],
            cost: obj['cost'],
            discount: this.testDis,
            old_amount: this.testOldAmount
          }
        )
      })
      console.log('this.testsArray', this.testsArray)
      console.warn('this.testsArray', this.testsArray)
    })
  }


  applay_discount(event, index, price_id){
    var row = this.testsArray[index]
    var oldAmounts
    var oldAmount
    var amount
    var discountValue = event.target.value
    var ratio: any
    var discountAmount
    var newAmount
    this.api.Admin.tests.singleTestPriceFilter(row['id'], price_id).subscribe(res => {
      oldAmounts = res['data']['old_amount']
      amount = res['data']['amount']
      if(!oldAmounts){
        oldAmount = amount
      }else{
        oldAmount = oldAmounts
      }
      if (discountValue > 1 && discountValue < 10) {
         ratio = '0.0'+discountValue
      }else if(discountValue == 100){
         ratio = 1
      }else{
         ratio = '0.'+discountValue
      }
      discountAmount = oldAmount * ratio
      newAmount = oldAmount - discountAmount
      row['old_amount'] = oldAmount
      row['amount'] = Math.round(newAmount)
    })
  }

  changeArea(event, price_id, searchTextValue){
    var textsearch
    if(searchTextValue == '')
    {
      textsearch = ' '
    }else{
      textsearch = searchTextValue
    }
    this.searhMethods(textsearch, price_id, event.target.value)
  }

  searchTest(event, price_id, cat_id) {
    var textsearch
    if(event.target.value == '')
    {
      textsearch = ' '
    }else{
      textsearch = event.target.value
    }
    this.searhMethods(textsearch, price_id, cat_id)
  }

  searhMethods(textsearch, price_id, cat_id){
    this.api.Admin.tests.testPriceFilter(textsearch, price_id, cat_id).subscribe(res => {
      this.testsArray = []
      var keys = Object.keys(res['tests']);
      var len = keys.length;
      for (let index = 1; index <= len; index++) {
        this.testsArray.push(res['tests'][index])
      }
    });
  }


  onSubmit(form){
      var price_type_id = form.value.price_type_id
      var reque = {
        tests: this.testsArray,
        price_type_id: price_type_id
      }
      console.log('requ', reque)
      this.api.Admin.tests.editPrices(reque).subscribe(res => {
      console.log('res', res)
        if(!res['errors']){
          this.snack.show(res['message'])
          $('#TestsPriceModal').modal('hide')
        }else{
          this.snack.showerror('Please try again');
        }
      })
  }

}
