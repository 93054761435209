import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SnackService } from 'src/app/services/snack.service';
import { Router } from '@angular/router';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { LocalePipe } from 'src/app/pipes/locale.pipe';

@Component({
  selector: 'admin-layout-new-parameter',
  templateUrl: './new-parameter.component.html',
  styleUrls: ['./new-parameter.component.scss']
})
export class NewParameterComponent implements OnInit,OnChanges {


  @Output() setCreated = new EventEmitter();

  param: any = {

    name :'',
    code :'',
    unit :'',
    type_id :'',
    show_old_result: false,
    show_previous_deviation: false,
    deviation_value : 0,
  };


  @Input() action :any;
  cformula:any= ""
  formulas:any = []
  formulaN:any = "0"
  selectedParams: any = [];
  cFormulaArr = []
  formulaBtns = ['IF' , 'DO', 'AND' , 'OR' , '(', ')', '=', '>' , '<', '>=','<=', '+', '-', '*','/' ,'MALE','FEMALE', 'AGE' ,'Back', 'Done']
  types: any = [];
  deviation_types = ['increase', 'decrease'];
  RRs:any[]=[]
  options:Array<String> = []
  sureState:Boolean = false
  opti: string = '';
  optionIndex: number = null;



    constructor(public api : ApiService,public snack:SnackService,private router : Router, public data:DataService,
      public progress : ProgressService, private localePipe:LocalePipe) { }

    ngOnInit() {
      this.getTypes()
    }

    editOpt(opt, i){
      this.optionIndex = i
      this.opti = opt
      }
    updateOpt(){
      this.options[this.optionIndex] = this.opti
      this.opti = ''
      this.optionIndex = null
    }
    removeOpt = opt =>{
      let index = this.options.indexOf(opt)
      this.options = this.options.filter(one => one != opt)
      this.optionIndex = index < this.optionIndex? this.optionIndex - 1 : index == this.optionIndex? null : this.optionIndex
    }
    removeFormula = f => this.formulas = this.formulas.filter(one => one != f)

    formulaValidator(txt, n=false){
      if(n && (!+txt && +txt != 0))  return {error: true, msg:"You can't add letters nor signs"}

      if(txt == 'DO'){
        if(!this.cformula.includes('IF:')) return {error: true, msg:"You have to add if before do"}
        if(this.cformula.includes('DO:')) return {error: true, msg:"You can't use do twice"}
      }
      if(txt == 'IF'){
        if(this.cformula.includes('IF:')) return {error: true, msg:"You can't use if twice"}
      }
      if(txt == ')'){
        let openCount = this.cformula.match(/\(/g)? this.cformula.match(/\(/g).length : 0
        let closeCount = this.cformula.match(/\)/g)? this.cformula.match(/\)/g).length : 0
        if(openCount <= closeCount) return {error: true, msg:"There's no open parenthesis to close"}
      }
      if(txt == 'Done'){
        if((this.cformula.includes('IF:') && !this.cformula.includes('DO:'))) return {error: true, msg:"you have to add do after if"}
        let openCount = this.cformula.match(/\(/g)? this.cformula.match(/\(/g).length : 0
        let closeCount = this.cformula.match(/\)/g)? this.cformula.match(/\)/g).length : 0
        if(openCount != closeCount) return {error: true, msg:"You have to close all open parenthesis"}
        let lastELE = this.cFormulaArr[this.cFormulaArr.length -1].trim()
        let forbiddens = this.formulaBtns.filter(one => ![')', 'IF', 'DO'].includes(one)).concat(['IF:' , 'DO:'])
        if(forbiddens.includes(lastELE)) return {error: true, msg:"You can't end the statement with " + lastELE}
      }
      return {error: false, msg: ""}
    }

    formulise(txt, n=false){
      if(txt == "Done"){
        let {error , msg} = this.formulaValidator(txt)
        if(error) return this.snack.showerror(msg)
        this.formulas.push(this.cformula)
        this.cformula = ""
        this.cFormulaArr = []
      }else if(txt == 'Back'){
          this.cFormulaArr.pop()
          this.cformula = this.cFormulaArr.join("")
        } else {
        if(txt == 'IF' || txt == 'DO'){
            let {error , msg} = this.formulaValidator(txt)
            if(error) return this.snack.showerror(msg)
            txt = txt + ':'
        }
        if(txt == ')'){
          let {error , msg} = this.formulaValidator(txt)
          if(error) return this.snack.showerror(msg)
        }
        if(n){
          let {error , msg} = this.formulaValidator(txt, n)
          if(error) return this.snack.showerror(msg)
          txt = +txt
          this.formulaN = ""
        }
        if(txt.code){
          txt = `[${txt.code}]`
        }

        this.cformula += `${txt} `
        this.cFormulaArr.push(`${txt} `)

      }
    }


    addRrInput(){
      this.RRs.push({
        "id" : this.RRs.length + 1,
        "gender_id" : "1",
        "type_id" : "1",
        "device" :  {}
      })
    }


    getTypes(){
    this.types = [{"id" : 1 , name : "real number"},{"id" : 2, name : "string"},{"id" : 3, name : "title"},{"id" : 4, name : "text"}]
   }


    onSubmit(form){



      form.value['reference_ranges'] = this.RRs
      form.value['options'] = this.options
      form.value['extra_notes'] = form.value['extra_notes']? form.value['extra_notes']: undefined
      form.value['formulas'] = this.formulas

      if(form.valid){


         // switch between creating and editing
        switch(this.action['type']){
          case 'New' : {



            if(this.RRs.some(e => {
              return (((!e.max || !e.min) && (e.type_id == 1)) || (!e.range && e.type_id == 2)) && !e.note
            })){
              this.snack.showerror('please enter a range for all reference ranges');
            }



            // if(this.RRs.some(e => {
            //   return e.unit == null || e.unit == ''
            // })){
            //   this.snack.showerror('please enter a unit for all reference ranges');
            // }

            else {
              this.RRs.forEach(e => {
                e.device_id = e.device.id
              });
            }


            this.api.Admin.parameters.add(form.value).subscribe(res => {

              if(!res['error']){

                this.snack.show('Param successfully added ');

                this.RRs = []


                this.setCreated.emit(res['param_id'])


              }
              else{
                if(Object.values(res['errors'].length != 0))
                {this.snack.showerror(Object.values(res['errors'])[0])}
                else {this.snack.showerror('something went wrong')}
              }

            })


            break;
          }

          case 'Edit' : {



            // if(this.RRs.some(e => {
            //   return e.device == ''
            // })){
            //   this.snack.showerror('please enter device for all reference ranges');
            // }

            // else {
              this.RRs.forEach(e => {
                e.device_id = e.device?.id
              });
            // }

            if(this.RRs.some(e => {
              return (((!e.max || !e.min) && (e.type_id == 1)) || (!e.range && e.type_id == 2)) && !e.note
            })){
              return this.snack.showerror('please enter a range for all reference ranges');
            }

            // if(this.RRs.some(e => {
            //   return e.unit == null || e.unit == ''
            // })){
            //   this.snack.showerror('please enter a unit for all reference ranges');
            // }

            this.api.Admin.parameters.update(form.value).subscribe(res => {

              console.log(res);

              if(res['error'] !=  true){

                this.snack.show('Param successfully edited ');
                this.setCreated.emit(res)
              }
              else{
                if(Object.values(res['errors'].length != 0))
                {this.snack.showerror(Object.values(res['errors'])[0])}
                else {this.snack.showerror('something went wrong')}
              }

            })

            break;
          }
        }

      } else {

        this.snack.showerror('Please enter all data correctly and try again');

      }

    }

    removeRR(id){
      this.RRs = this.RRs.filter(rr => rr.id != id)
      console.log(this.RRs);
    }

    ngOnChanges(changes){

      this.progress.onDP();
      this.sureState = false




      if (changes.action.currentValue.type != null) {

        let type = changes.action.currentValue.type
        this.setParamIfAvaliable(type)

      }

    }

    remove(){
      this.api.Admin.parameters.remove(this.param.id).subscribe(res => {
        if(!res['error']) this.data.remove.next({id : this.param.id, type: "param"})
      })
    }

    pushOpt(){
      if(!this.options.includes(this.opti)){
        this.options.push(this.opti);
        this.opti = '';
      }else{
        this.snack.showerror('option exists')
      }
    }

    setParamIfAvaliable(type){


        switch(type){
          case 'New' : {
            this.param = {}
            this.RRs = []
            this.options = []
            this.formulas = []
            this.cformula = ''
            this.cFormulaArr = []
            this.progress.offDP();
            break;
          }
          case 'Edit' : {
            this.api.Admin.parameters.getOne(this.action['id']).subscribe(res =>{
              this.param = res
              this.formulas = res['formulas']
              if(res['options']){
                this.options = res['options'].map(one => one.content)

              }else this.options = []

              this.RRs = res['reference_ranges']
              this.RRs.forEach(e => {
                e.device = e.device || {}
              });
              console.log(this.RRs)
              this.progress.offDP();

            })
            break;
          }
        }





    }






    // createRequest(param_id){


    //   this.api.Requests.createIntialRequest(param_id).subscribe(res => {


    //     if (res['error'] == false ){

    //       this.router.navigateByUrl(`/params/${param_id}/requests/${res['request_id']}/add`);
    //       console.log('here');

    //       $("#modal").toggle("hide");
    //       $('body').removeClass('modal-open');
    //       $('.modal-backdrop').remove();
    //       $('.dropdown-menu').dropdown('hide');

    //     }
    //     else {

    //       this.snack.showerror('something went wrong');

    //     }


    //   })

    // }






    // }


  }
