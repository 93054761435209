import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { ProgressService } from "src/app/services/progress.service";
import { SnackService } from "src/app/services/snack.service";
import { Arabic } from "flatpickr/dist/l10n/ar";
declare let $;
declare let moment;

@Component({
  selector: "admin-layout-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  constructor(
    public router: Router,
    private api: ApiService,
    public snack: SnackService,
    private progress: ProgressService,
    public data:DataService
  ) {}

  activity$: Observable<any>;
  show_pass:boolean = false;
  from = null;
  deleted_image: boolean = false;
  deleted_background_image: boolean = false;
  to = null;
  is_updating: boolean = false;
  is_background_image_updating: boolean = false;
  info = {};
  tickets_to_charge = 0
  phones: Array<string> = [];
  pricings: any [] = [];
  priority = "low"
  settings: any = {
    "logout-timer": null,
    note: null,
    defaultPrice: 1,
    isTax: false,
    tax: null,
    mobile: null,
    cloud: null,
    folderId: null,
    qr: null,
    lab: null,
    tickets: 0,
    highTickets: 0,
    mediumTickets: 0,
    lowTickets: 0,
    smsNumber: 0,
    smsExpire: '',
    limiUsers: false,
    limiUsersNumber: 0,
    limiTechNumber: 0,
    resultRound: 0,
    limiReceNumber: 0,
    ReqListDefaultDate: "false",
    monthlyRenumbering: "false",
    userUnits: "false",
    selectGender: "false",
    onlyMessage: "false",
    rereadParameterInProtocol: "true",
    healthcareResultColors: "true",
    limitResultsLength: "false",
    technicalResult: 12,
    confirmDeleteTest: false,
    insurance: false,
    insuranceClinicId: null,
    insuranceClinicType: "sanatoria",
    financial: {
      roundPrecision: 2
    },
    pdf: {
      receipt: {
        "lab-info-header": null,
        "two-part": true,
        "hideDetails": false,
        FontSize: 'medium'
      },
      results: {
        "lab-info-header": false,
        withDevices: null,
        withAreas: null,
        withFamily: null,
        noteBold: null,
        fontSize: 'medium',
        resultAlignment: 'center',
        unitAlignment: 'center',
        referenceRangeAlignment: 'center'
      },
      report: {
        withCorrespondentId: null
      }
    },
    sms:  {
      isend: {
          status:  false,
          baseUrl:  "https://send.com.ly/",
          end_point:  "api/v3/sms/send",
          api_key:  null,
          callerID:  "17320",
      },
      abunwas: {
          status:  false,
          baseUrl:  "https://api.libyasms.com:3236/",
          end_point:  "sendtext",
          api_key:  null,
          secret_key:  null,
          callerID:  null,
      },
    },
  };
  email_settings: any = {
    encryption: "ssl",
    host: "smtp.gmail.com",
    password: null,
    port: 465,
    transport: "stmp",
    username: null
  };
  logo: { file: Blob; url: string } = { file: null, url: "" };
  background_image: { file: Blob; url: string } = { file: null, url: "" };

  update_logo() {
    let formData = new FormData();
    formData.append("file", this.logo.file);
    this.is_updating = true;
    this.api.Admin.lab.update_logo(formData).subscribe((res) => {
      this.is_updating = false;
      if (!res["error"]) {
        this.info["logo"] = this.logo.url;
        this.logo = { file: null, url: "" };
        this.deleted_image = false;
        this.snack.show("Logo is updated successfully");
      } else this.snack.showerror("Sorry, something went wrong");
    });
  }

  update_background_image() {
    let formData = new FormData();
    formData.append("file", this.background_image.file);
    this.is_background_image_updating = true;
    this.api.Admin.lab.update_background_image(formData).subscribe((res) => {
      this.is_background_image_updating = false;
      if (!res["error"]) {
        this.info["background_image"] = this.background_image.url;
        this.background_image = { file: null, url: "" };
        this.deleted_background_image = false;
        this.snack.show("Background image is updated successfully");
      } else this.snack.showerror("Sorry, something went wrong");
    });
  }

  update_settings(form: NgForm) {
    let data = {
      ...form.value,
      settings: JSON.parse(JSON.stringify(this.settings)),
      email_settings: JSON.parse(JSON.stringify(this.email_settings)),
    };
    Object.keys(data).forEach((key) => {
      if (key.includes("remove_")) delete data[key];
    });

    form.form.disable();
    this.api.Admin.lab.update(data).subscribe((res) => {
      if (!res["error"]) {
        this.snack.show("Setting updated successfully");
        form.form.enable();
      }
    });
    console.log('form.value',form.value)
  }

  toggleStatue(e){
      console.log(e.target.checked)
      if (e.target.checked == true) {
        this.settings['isTax'] = false
      }else{
        this.settings['isTax'] = true
      }

    }

  limitUsersToggleStatue(e){
      console.log(e.target.checked)
      if (e.target.checked == true) {
        this.settings['limitUsers'] = false
      }else{
        this.settings['limiUsers'] = true
      }

    }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  upload(event) {
    // if(this.is_saving) return;
    this.deleted_image = false;
    let file = event.target.files[0];
    this.logo = { file, url: URL.createObjectURL(file) };
  }

  uploadBackground(event) {
    // if(this.is_saving) return;
    this.deleted_background_image = false;
    let file = event.target.files[0];
    this.background_image = { file, url: URL.createObjectURL(file) };
  }


  //
  ngOnInit() {
    this.progress.on();

    this.data.infoAssigned.subscribe(isAssigned => {

      if(isAssigned){
            this.info = this.data.info;
            this.phones = JSON.parse(JSON.stringify(this.info["phones"])) || []
            this.settings = JSON.parse(JSON.stringify(this.info["settings"]));
            this.email_settings = JSON.parse(JSON.stringify(this.info["email_settings"]));
            this.settings.defaultPrice = this.settings['defaultPrice'] || 1
            this.settings.technicalResult = this.settings['technicalResult'] || 12
            this.settings.confirmDeleteTest = !!this.settings['confirmDeleteTest']
            this.settings.insurance = !!this.settings['insurance']
            this.settings.ReqListDefaultDate = !!this.settings['ReqListDefaultDate']
            this.settings.monthlyRenumbering = !!this.settings['monthlyRenumbering']
            this.settings.userUnits = !!this.settings['userUnits']
            this.settings.onlyMessage = !!this.settings['onlyMessage']
            this.settings.selectGender = !!this.settings['selectGender']
            this.settings.rereadParameterInProtocol = !!this.settings['rereadParameterInProtocol']
            this.settings.healthcareResultColors = !!this.settings['healthcareResultColors']
            this.settings.limitResultsLength = !!this.settings['limitResultsLength']
            this.settings.limiUsers = !!this.settings['limiUsers']
            this.settings.limiUsersNumber = this.settings['limiUsersNumber'] || 1
            this.settings.limiTechNumber = this.settings['limiTechNumber'] || 2
            this.settings.resultRound = this.settings['resultRound'] || 2
            this.settings.limiReceNumber = this.settings.limiReceNumber || 1
            this.settings.insuranceClinicId = this.settings.insuranceClinicId || null
            this.settings.insuranceClinicType = this.settings.insuranceClinicType || "sanatoria"
            this.settings.mobile = this.settings.mobile == null? true : this.settings.mobile
            this.settings.cloud = this.settings.cloud == null? true : this.settings.cloud
            this.settings.folderId = this.settings['folderId'] || null
            this.settings.qr = this.settings.qr == null? true : this.settings.qr
            this.settings.lab = this.settings.lab == null? true : this.settings.lab
            this.settings.tickets = this.settings.tickets || 0
            this.settings.highTickets = this.settings.highTickets || 0
            this.settings.mediumTickets = this.settings.mediumTickets || 0
            this.settings.lowTickets = this.settings.lowTickets || 0
            this.settings.smsNumber = this.settings.smsNumber || 0
            this.settings.smsExpire = this.settings.smsExpire || ''
            this.email_settings.encryption = this.email_settings.encryption  || 'ssl'
            this.email_settings.host = this.email_settings.host || "smtp.gmail.com"
            this.email_settings.port = this.email_settings.port || 465
            this.email_settings.transport = this.email_settings.transport || "smtp"

            if (this.settings["pdf"]) {
                this.settings.pdf.receipt = this.settings.pdf.receipt || {
                  "lab-info-header": null,
                  "two-part": true,
                  "hideDetails": false,
                  FontSize: 'medium'
                };
                this.settings.pdf.results = this.settings.pdf.results || {
                  "lab-info-header": null,
                  withDevices: null,
                  withAreas: null,
                  withFamily: null,
                  noteBold: null,
                  fontSize:'medium',
                  resultAlignment: 'center',
                  unitAlignment: 'center',
                  referenceRangeAlignment: 'center'
                };
                this.settings.pdf.report = this.settings.pdf.report || {
                  withCorrespondentId: null
                };
            }
              this.settings.financial = this.settings.financial || {
                roundPrecision: 2
              }
              if (this.settings["sms"]) {
                this.settings.sms.abunwas = this.settings.sms.abunwas || {
                  status:  false,
                  baseUrl:  "https://send.com.ly/",
                  end_point:  "api/v3/sms/send",
                  api_key:  null,
                  callerID:  "17320",
                };
                this.settings.sms.isend = this.settings.sms.isend || {
                  status:  false,
                  baseUrl:  "https://api.libyasms.com:3236/",
                  end_point:  "sendtext",
                  api_key:  null,
                  secret_key:  null,
                  callerID:  null,
                };
            }


          this.progress.off();


      }

    })
  }

  recharge(){
    let body = {
      'smsNumber': this.settings.smsNumber
    }
    this.api.Admin.lab.recharge(body).subscribe((res) => {
      if (!res["error"]) {
        this.snack.show(res["data"]["message"]);
      }
    })
  }
  
  rechargeTickets(){
    let form = {
      'tickets_to_charge': this.tickets_to_charge,
      'priority': this.priority
    }
    this.api.Admin.supports.charge_lab_counters(form).subscribe((res) => {
      if (!res["error"]) {
        this.snack.show(res["message"]);
      }
    })
    // this.api.Admin.lab.rechargeTickets(form).subscribe((res) => {
    //   if (!res["error"]) {
    //     this.snack.show(res["data"]["message"]);
    //   }
    // })
  }

  ngAfterViewInit() {
    $("#fromPicker").flatpickr({
      dateFormat: "d-m-Y",
      defaultDate: moment().format("DD-MM-YYYY"),
      locale: this.data.language == "arabic" ? Arabic : null,
      position: "auto center",
    });
  }

}
