<div class="card w-100 p-4 p-xl-5 mt-1 mb-5">
  <div class="container-fluid">
    <div class="row mt-4 mb-2">
      <div class="col">
        <h4 class="roboto-500 start">{{'Edit Result' | locale}}</h4>
      </div>

      <div class="col">
        <ng-container *ngIf="is_technical_validator && request">
          <div class="d-flex align-items-center justify-content-end w-100">
            <ng-container
              *ngIf="
                request['status'] != 'technically validated' && request['status'] != 'partially validated';
                else unvalidate
              "
            >
              <button
                type="button"
                (click)="technicallyValidate()"
                class="btn btn-outline-success tajwal-regium px-4"
              >
                {{'Validate as a Supervisor' | locale}}
              </button>
            </ng-container>

            <ng-template #unvalidate>
              <!-- [disabled]="request['status'] == 'techncally validated' || request['status'] == 'partially validated'" -->
              <button
                type="button"
                (click)="technicallyValidate()"
                class="btn btn-outline-secondary tajwal-regium px-4"
              >
                {{'Unvalidate as a Supervisor' | locale}}
              </button>
            </ng-template>
          </div>
        </ng-container>



      </div>

      <div *ngIf="request && data.user">
        <ng-container *ngIf="(request['status'] == 'technically validated' || request['status'] == 'partially validated') && data.me?.sub_roles?.indexOf('canPrintResults') != -1 && (request.payment_status == 'paid' || request.patient_isPaid || data.me?.sub_roles?.indexOf('printWhenPaid') != -1)">
          <div class="px-2">
            <button
              type="button"
              class="btn btn-outline-success px-4"
              data-target="#select-tests-modal"
              data-toggle="modal"
            >
              {{'Print Results' | locale}}
            </button>
          </div>

          <div class="px-2" *ngIf="send_sms">
            <button
              type="button"
              class="btn btn-primary px-4"
              data-target="#send-sms"
              data-toggle="modal"
            >
              {{'Send SMS' | locale}}
            </button>

            <button
              (click)="$event.preventDefault()"
              class="form-control"
              style="width: fit-content;height: fit-content; cursor: inherit"
            >
              <h3 class="m-0">#{{ sms_counter }}</h3>
            </button>
          </div>
<!--
          <div class="px-2">
            <button
              *ngIf="result['status'] != 'active'"
              type="button"
              (click)="setDelivered()"
              class="btn btn{{
                result['delivery_flag'] == 0 ? '-outline' : ''
              }}-success px-4"
              [disabled]="result['delivery_flag'] == 1"
            >
              <i class="fas fa-check"></i>
            </button>
          </div> -->
        </ng-container>
      </div>

    </div>

    <ng-container *ngIf="request">
      <div class="row mb-4">
        <div class="col-12">
          <div
            class="tag"
            [ngClass]="{
              'red-tag': request['status'] == 'active',
              'green-tag': !(request['status'] == 'active')
            }"
          >
            <p class="roboto-300">
              {{ request["status"] | titlecase | locale }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-lg-3">
        <ng-container *ngIf="categories[0]">
          <div class="form-group m-0">
            <select
              (change)="filterTests()"
              [(ngModel)]="category_id"
              name="category_id"
              id="category"
              class="custom-select"
            >
              <option selected [value]="-1">{{'All Categories' | locale}}</option>
              <option *ngFor="let cat of categories" [value]="cat.id">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </ng-container>

        <!-- <div class="form-group ">
          <label for="civility">Gender</label>
          <select [ngModel]="patient.gender_id || genders[0].id"   name="gender_id" id="civility" class="custom-select">
              <option selected *ngFor="let gender of genders"  [value]="gender.id">{{gender.name}}</option>
          </select>
        </div> -->
      </div>

    </div>

    <div class="row mt-4" style="justify-content: center;">
      <div class="col-{{colWidth}}">
        <ng-container *ngIf="tests">
          <ng-container *ngFor="let test of tests; index as i">
            <!-- data-toggle="collapse" [attr.data-target]="'#collapse-' + i" -->
            <div class="accordion" id="tests-accordion">
              <div class="card test-section">
                <div class="card-header p-3" id="headingOne">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-7">
                        <div class="d-flex align-items-center w-100">
                          <!--
                            THIS SECTION FOR CHECK MULTE TEST PLZZZZZZ DON'T DELETED
                            <div class="pretty p-icon p-smooth p-curve">
                            <input
                              id="test-{{ i }}-2"
                              (change)="select($event, test.id)"
                              (click)="$event.stopPropagation()"
                              type="checkbox"
                            />

                            <div class="state p-primary">
                              <i class="icon fa fa-check"></i>
                              <label></label>
                            </div>
                          </div> -->

                          <h2 class="mb-0 test-section-title roboto-400">
                            {{ test.name }}
                          </h2>
                          <ng-container *ngIf="test.is_casa">
                            <button
                            (click)="openUploadCasa(request['id'], test.id, request['reception_number'])"
                            type="button"
                            *ngIf="!test.has_file"
                            class="btn btn-outline-primary px-4 ml-4"
                          >
                            Upload Report
                          </button>
                          <button
                            (click)="printCasa(request['id'], test.id, request['reception_number'])"
                            type="button"
                            *ngIf="test.has_file"
                            class="btn btn-outline-primary px-4 ml-4"
                          >
                            Print Report
                          </button>
                          <button
                            (click)="deleteCasa(request['id'], test.id, request['reception_number'])"
                            type="button"
                            *ngIf="test.has_file"
                            class="btn btn-outline-danger px-4 ml-4"
                          >
                            Delete Report
                          </button>
                          </ng-container>
                          <button
                            (click)="preview_histogram(test.histogram)"
                            type="button"
                            *ngIf="test.histogram?.media && test.is_histo"
                            class="btn btn-outline-primary px-4 ml-4"
                          >
                            Preview histogram
                          </button>
                        </div>
                      </div>

                      <div class="col-3">
                        <div
                          *ngIf="test.devices.length != 0"
                          class="form-group m-0"
                        >
                          <select
                            *ngIf="test.device"
                            (change)="selectDevice(test, $event.target)"
                            [ngModel]="test.device?.id || -1"
                            name="test_device_id"
                            id="device"
                            class="custom-select"
                          >
                            <option selected [value]="-1">
                              {{'Select a Device' | locale}}
                            </option>
                            <option
                              *ngFor="let device of test.devices"
                              [value]="device.id"
                            >
                              {{ device.code }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-2">
                        <div
                          class="
                            d-flex

                            h-100
                            align-items-center
                            w-100
                          "
                          [ngClass]="{'justify-content-end': !data.isRTL, 'justify-content-start': data.isRTL}"
                          style="direction: ltr!important;"
                          >
                          <div class="pretty p-switch p-fill">
                            <input
                              [disabled]="
                              request['status'] == 'technically validated' || request['status'] == 'partially validated'
                              "
                              autocomplete="off"
                              id="test-{{ i }}"
                              [ngModel]="test.status == 'validated'"
                              (change)="validate($event, test)"
                              (click)="$event.stopPropagation()"
                              type="checkbox"
                            />
                            <div class="state p-success">
                              <label class="roboto-400">{{'Validate' | locale}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2" *ngIf="request['is_repeat']">
                        <div
                          class="
                            d-flex
                            justify-content-end
                            h-100
                            align-items-center
                            w-100
                          "
                          [ngClass]="{'justify-content-end': data.isRTL, 'justify-content-start': !data.isRTL}"
                          style="direction: ltr!important;"
                        >
                          <div class="pretty p-switch p-fill">


                            <input
                              [disabled]="
                              request['status'] == 'technically validated' || request['status'] == 'partially validated'
                              "
                              autocomplete="off"
                              id="test-repeat-{{ i }}"
                              [ngModel]="test.is_repeat"
                              (change)="RepeatTest($event, test)"
                              (click)="$event.stopPropagation()"
                              type="checkbox"
                            />
                            <div class="state p-success">
                              <label class="roboto-400">{{'Repeat' | locale:1}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- id="collapse-{{i}}" -->
                <div
                  class="show"
                  aria-labelledby="headingOne"
                  data-parent="#tests-accordion"
                >
                  <div class="container-fluid">
                    <ng-container
                      *ngIf="isArray(test.parameters) && !test.is_casa"
                    >
                      <div class="py-2"></div>
                      <div
                        (click)="$event.stopPropagation()"
                        *ngFor="let param of test.parameters; index as j"
                        class="row px-4 py-1 test-row"
                      >
                        <div class="col-lg-2 col-3 p-0">
                          <h5 class="roboto-400 mx-1">
                            {{ param.name }}

                            <!-- <div *ngIf="param.options && param.options.length">
                            <form
                                #resultForm[{{i}}_{{j}}]="ngForm"
                                (ngSubmit)="
                                  onSubmit(
                                    $event,
                                    param,
                                    loader,
                                    save,
                                    tests,
                                    test.id
                                  )">
                            <button
                              id="addop{{ i }}-{{ j }}"
                              #addop
                              type="button"
                              class="btn save-btn btn-primary py-0 roboto-400 btn-result"
                              (click)="new_option.hidden=!new_option.hidden;new_option.focus()"
                            >
                              +
                            </button>
                            <input
                            autocomplete="off"
                            autofocus
                            hidden
                            #new_option
                            (click)="$event.stopPropagation()"
                            name="result{{ i }}-{{ j }}"
                            required
                            id="result{{ i }}-{{ j }}"
                            type="text"
                            class="form-control right-rad"
                            placeholder="Enter a Result"
                          />


                          </form>
                          </div> -->
                          </h5>
                        </div>

                        <!-- <button (click)="param.add_result = true"  *ngIf="param.options && param.options.length" type="button" class="btn btn-secondary right-rad" data-toggle="tooltip" data-placement="bottom" [title]="' '">
                        <i class="fas fa-plus"></i>

                      </button> -->

                        <!-- [ngClass]="{'col-5 col-lg-6':param.options?.length, '':!param.options?.length}" -->
                        <div class="px-0 col-7 col-lg-8">
                          <div
                            hidden
                            #loader
                            id="load{{ i }}-{{ j }}"
                            class="spinner-border text-primary load-1"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>

                          <div class="d-flex justify-content-center w-100">
                            <div class="form-group m-0 w-100">
                              <form
                                #resultForm[{{i}}_{{j}}]="ngForm"
                                (ngSubmit)="
                                  onSubmit(
                                    $event,
                                    param,
                                    loader,
                                    save,
                                    tests,
                                    test.id
                                  )
                                "
                              >
                                <div class="">
                                  <button
                                    hidden
                                    #save
                                    id="save{{ i }}-{{ j }}"
                                    type="submit"
                                    class="
                                      btn
                                      save-btn
                                      btn-primary
                                      py-0
                                      roboto-400
                                      btn-result
                                    "
                                  >
                                    Save
                                  </button>
                                </div>
                                <ng-container
                                  *ngIf="
                                    !param.options || !param.options.length
                                  "
                                >
                                  <ng-container *ngIf="param.type_id != 4">
                                    <!-- (input)="save.hidden = false" -->
                                    <input
                                      (blur)="save.click()"
                                      autocomplete="off"
                                      #result_input
                                      [ngModel]="
                                        param.result
                                          ? param.result.result
                                          : ''
                                      "
                                      [disabled]="test.status == 'validated' || request['status'] == 'technically validated' || request['status'] == 'partially validated'"
                                      (click)="$event.stopPropagation()"
                                      name="result{{ i }}-{{ j }}"
                                      required
                                      id="result{{ i }}-{{ j }}"
                                      (focus)="focus($event, i, j)"
                                      (onload)="onpageshow($event)"
                                      tabindex="{{ i }}{{ j + 1 }}"
                                      type="text"
                                      class="form-control"
                                      [ngClass]="{
                                        'right-rad':!data.isRTL,
                                        'left-rad':data.isRTL
                                      }"
                                      [placeholder]="'Result'| locale"
                                    />
                                  </ng-container>
                                  <ng-container *ngIf="param.type_id == 4">
                                    <textarea
                                      #result_input
                                      (input)="save.hidden = false"
                                      [ngModel]="
                                        param.result
                                          ? param.result.result
                                          : ''
                                      "
                                      [disabled]="test.status == 'validated' || request['status'] == 'technically validated' || request['status'] == 'partially validated'"
                                      (click)="$event.stopPropagation()"
                                      name="result{{ i }}-{{ j }}"
                                      required
                                      id="result{{ i }}-{{ j }}"
                                      tabindex="100"
                                      type="text"
                                      class="form-control"
                                      [ngClass]="{
                                        'right-rad':!data.isRTL,
                                        'left-rad':data.isRTL
                                      }"
                                      [placeholder]="'Result' | locale"
                                    ></textarea>
                                  </ng-container>
                                </ng-container>

                                <ng-container *ngIf="param.options?.length">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text"
                                        id="basic-addon1"
                                        (click)="
                                          param.add_result = !param.add_result
                                        "
                                        *ngIf="
                                          param.options && param.options.length
                                        "
                                        type="button"
                                        class="btn right-rad"
                                        [ngClass]="{
                                          'btn-secondary': !param.add_result,
                                          'btn-success': param.add_result,
                                          'right-rad': !data.isRTL,
                                          'left-rad': data.isRTL
                                        }"
                                        [ngbTooltip]="
                                          param.add_result
                                            ? 'Stop typing'
                                            : 'Type result'
                                        "
                                      >
                                        <ng-container *ngIf="param.add_result">
                                          ✔
                                        </ng-container>

                                        <ng-container *ngIf="!param.add_result">
                                          +
                                        </ng-container>
                                      </span>
                                    </div>

                                    <ng-container *ngIf="param.add_result">
                                      <input
                                        (blur)="save.click()"
                                        autocomplete="off"
                                        #result_input
                                        [ngModel]="
                                          param.result
                                            ? param.result.result
                                            : ''
                                        "
                                        [disabled]="test.status == 'validated' || request['status'] == 'technically validated' || request['status'] == 'partially validated'"
                                        (click)="$event.stopPropagation()"
                                        name="result{{ i }}-{{ j }}"
                                        required
                                        id="result{{ i }}-{{ j }}"
                                        (focus)="focus($event, i, j)"
                                        (onload)="onpageshow($event)"
                                        tabindex="{{ i }}{{ j + 1 }}"
                                        type="text"
                                        class="form-control"
                                        [ngClass]="{
                                          'right-rad':!data.isRTL,
                                          'left-rad':data.isRTL
                                        }"

                                        [placeholder]="'Result' | locale"
                                      />
                                    </ng-container>

                                    <!-- (change)="save.hidden = false " -->
                                    <ng-container *ngIf="!param.add_result">
<!--
                                        <div   ngbDropdown container="body" class="d-inline-block col px-0">
                                          <button #width class="btn btn-light text-left form-control right-rad"  ngbDropdownToggle>Toggle dropdown</button>
                                          <div  ngbDropdownMenu class="is_me" >
                                              <ng-container *ngFor="let opt of param.options.concat([{content:'second'}, {content:'third'},
                                              {content:'fourth'}, {content:'fifth'}, {content:'sixth'}, {content:'seventh'}
                                              ])">
                                                <div [ngStyle]="{width: width.offsetWidth + 'px'}"   >
                                                  <div class="custom-control custom-checkbox m-1 mx-2 clickable">
                                                    <input

                                                      type="checkbox"
                                                      class="custom-control-input"
                                                      [id]="'customCheck_' + opt.content"
                                                    />
                                                    <label class="custom-control-label" [for]="'customCheck_' + opt.content"
                                                      >{{opt.content}}</label
                                                    >
                                                  </div>
                                                </div>
                                              </ng-container>

                                              <ng-container *ngIf="
                                              param.result &&
                                              !checkOptions(
                                                param.options,
                                                param.result
                                              )
                                            " >
                                                <div [ngStyle]="{width: width.offsetWidth + 'px'}"   >
                                                  <div class="custom-control custom-checkbox m-1 mx-2 clickable">
                                                    <input

                                                      type="checkbox"
                                                      class="custom-control-input"
                                                      [id]="'customCheck_' + param.result.result"
                                                    />
                                                    <label class="custom-control-label" [for]="'customCheck_' + param.result.result"
                                                      >
                                                      {{ param.result.result }}
                                                      </label
                                                    >
                                                  </div>
                                                </div>
                                              </ng-container>


                                          </div>
                                        </div> -->



                                      <!-- itemsShowLimit: 3, -->
                                      <!-- <ng-multiselect-dropdown
                                        [placeholder]="'select options'"
                                        [settings]="{
                                          singleSelection: false,
                                          idField: 'content',
                                          enableCheckAll: false,
                                          textField: 'content',
                                          allowSearchFilter: true
                                        }"
                                        [data]="param.options"
                                        [disabled]="test.status == 'validated'  || request['status'] == 'technically validated' || request['status'] == 'partially validated'"
                                        ngModel
                                        class="custom-select no-rad"
                                        name="online_tests"
                                      >

                                      </ng-multiselect-dropdown> -->

                                      <select
                                        (blur)="save.click()"
                                        [ngModel]="
                                          param.result
                                            ? param.result.result
                                            : ''
                                        "
                                        [disabled]="disabledInput(test.status)"
                                        name="result{{ i }}-{{ j }}"
                                        id="result{{ i }}-{{ j }}"
                                        (focus)="focus($event, i, j)"
                                        (onload)="onpageshow($event)"
                                        tabindex="{{ i }}{{ j + 1 }}"
                                        class="custom-select no-rad"
                                      >
                                        <option selected [value]="''">
                                          {{'None' | locale:2}}
                                        </option>
                                        <option
                                          *ngFor="let option of param.options"
                                          [value]="option.content"
                                        >
                                          {{ option.content }}
                                        </option>
                                        <option
                                          *ngIf="
                                            param.result &&
                                            !checkOptions(
                                              param.options,
                                              param.result
                                            )
                                          "
                                        >
                                          {{ param.result.result }}
                                        </option>
                                      </select>
                                    </ng-container>
                                  </div>
                                </ng-container>
                              </form>
                            </div>
                          </div>
                        </div>

                        <button
                          *ngIf="param.type_id != 4"
                          [disabled]="test.status == 'validated' || request['status'] == 'technically validated' || request['status'] == 'partially validated'"
                          type="button"
                          [ngClass]="{
                            'bg-success':
                              param.result?.note
                          }"
                          class="btn btn-light no-rad"
                          (click)="
                            setcurrent_note(param, test);
                            toggle_add_note_modal.click()
                          "
                        >
                          <!-- <i class="fas fa-info"></i> -->
                          <p
                            [ngClass]="{
                              'text-white':
                                param.result?.note
                            }"
                            class="m-0"
                          >
                            {{'Note' | locale}}
                          </p>
                        </button>

                        <!-- data-toggle="tooltip" data-placement="bottom" [title]="'Reference range\n' + param.range?.range + param.range.unit" -->
                        <button
                          *ngIf="param?.range?.range && param.type_id != 4"
                          type="button"
                          class="btn btn-secondary"
                          [ngbTooltip]="
                            'Reference range\n' +
                            param.range.range +
                            param.range.unit
                          "
                          [ngClass]="{
                            'right-rad':data.isRTL,
                            'left-rad':!data.isRTL,
                            'btn-danger':
                              param.result &&
                                param.result.result &&
                                (param.result.result
                              | range: param.range.range),
                            'btn-success':
                              param.result &&
                              param.result.result &&
                              !(
                                param.result.result
                                | range: param.range.range
                              )
                          }"
                        >
                          <!-- <i class="fas fa-info"></i> -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path
                              d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                            />
                            <circle cx="8" cy="4.5" r="1" />
                          </svg>
                        </button>

                        <!-- data-toggle="tooltip" data-placement="bottom" title="There is no reference range" -->
                        <button
                          *ngIf="
                            (!param.range || !param.range.range) &&
                            param.type_id != 4
                          "
                          type="button"
                          class="btn btn-dark"
                          [ngClass]="{
                            'right-rad':data.isRTL,
                            'left-rad':!data.isRTL
                          }"
                          ngbTooltip="There is no reference range"
                        >
                          <!-- <i class="fas fa-info"></i> -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path
                              d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                            />
                            <circle cx="8" cy="4.5" r="1" />
                          </svg>
                        </button>
                        <svg
                          _ngcontent-nbp-c8=""
                          class="bi bi-info-circle mx-1"
                          fill="currentColor"
                          height="16"
                          viewBox="0 0 16 16"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                          style="border-radius: 11px"
                          [ngStyle]="
                          param.result?.isFromDevice?
                          { 'background-color': 'green' }
                          : param.result?.result
                                ? { 'background-color': 'cyan' }
                                : { 'background-color': 'grey' }
                          "
                        >
                          <path
                            _ngcontent-nbp-c8=""
                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            fill-rule="evenodd"
                          ></path>
                          <path
                            _ngcontent-nbp-c8=""
                            d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                          ></path>
                          <circle
                            _ngcontent-nbp-c8=""
                            cx="8"
                            cy="4.5"
                            r="1"
                          ></circle>
                        </svg>

                        <div class="tooltip-container">
                          <svg
                            *ngIf="param.show_previous_deviation"
                            class="bi bi-info-circle mx-1"
                            height="16"
                            viewBox="0 0 16 16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                            style="border-radius: 11px"
                          >
                            <!-- Circle with dynamic fill -->
                            <circle
                              cx="8"
                              cy="8"
                              r="7"
                              stroke="black"
                              stroke-width="1"
                              [attr.fill]="param.is_deviation ? 'red' : 'grey'"
                            ></circle>
                            <!-- Letter "d" -->
                            <text
                              x="8"
                              y="11"
                              font-size="8"
                              text-anchor="middle"
                              fill="white"
                              font-family="Arial"
                              font-weight="bold"
                            >d</text>
                          </svg>
                          <!-- Tooltip Text -->
                          <span class="tooltip-text">{{ 'Previous result\n' + param.previous_result }}</span>
                        </div>
                        
                      
                        <ng-container *ngIf="param.range && param.type_id != 4">
                          <p class="roboto-400 m-0 p-0 col-lg-4 col-4">
                            {{ param.range.range && param.range.unit ? 'Ref : ' + param.range.range + '/' + param.range.unit : "" }}
                          </p>
                        </ng-container>
                        <span class="roboto-400 m-0 p-0 col-lg-4 col-4" *ngIf="request.draw_time">{{ 'Duration' | locale }}: {{ param.result ? param.result.duration : '' }}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- <div class="card">
                <div class="card-header" id="headingTwo">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Collapsible Group Item #2
                    </button>
                  </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#tests-accordion">
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Collapsible Group Item #3
                    </button>
                  </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#tests-accordion">
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div> -->
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<button
  #toggle_add_note_modal
  id="closer"
  style="display: none"
  data-toggle="modal"
  data-target="#add_note_modal"
></button>

<div
  class="modal fade"
  id="add_note_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="title">{{'Add Note' | locale}}</h5>
      </div>
      <div class="modal-body">
        <textarea
          class="form-control"
          name=""
          id="noter"
          cols="30"
          rows="5"
          [(ngModel)]="current_note.text"
        ></textarea>
        <button (click)="addNote()" class="form-control mt-2">{{'Add' | locale}}</button>
      </div>
    </div>
  </div>
</div>


<div
  class="modal fade"
  id="select-tests-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{'Select tests to print' | locale}}</h5>
      </div>

      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <form
              id="selectTestsForm"
              #selectTestsForm="ngForm"
              (ngSubmit)="print(selectTestsForm)"
            >
              <div *ngFor="let test of tests" class="col-12 p-4">
                <div class="d-flex h-100 align-items-center">
                  <div class="">
                    <div class="custom-control custom-checkbox">
                      <!-- [(ngModel)]="request.urgent_flag" -->
                      <input
                        [name]="test.id"
                        [ngModel]="test.status == 'validated'"
                        [disabled]="test.status != 'validated'"
                        type="checkbox"
                        class="custom-control-input"
                        [id]="'selectTests|' + test.id"
                      />
                      <label class="custom-control-label" [for]="'selectTests|' + test.id">{{
                        test.name
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="row">
          <div class="custom-control custom-checkbox py-2">
            <input
              name="withDevices"
              [(ngModel)]="devices"
              type="checkbox"
              class="custom-control-input"
              id="customCheckDevices"
            />
            <label class="custom-control-label" for="customCheckDevices"
              >{{'with devices?' | locale}}</label
            >

          </div>

          <div class="custom-control custom-checkbox py-2">
            <input
              name="withAreas"
              [(ngModel)]="areas"
              type="checkbox"
              class="custom-control-input"
              id="customCheckAreas"
            />
            <label class="custom-control-label" for="customCheckAreas"
              >{{'with categories?' | locale}}</label
            >

          </div>

          <div class="custom-control custom-checkbox py-2">

            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                name="withFamily"
                [(ngModel)]="families"
                id="customCheckFamily"
              />
              <label class="form-check-label" for="customCheckFamily" [ngClass]="{'mr-4' : data.isRTL}" >{{'with families?' | locale}}</label>
            </div>

          </div>

          <div class="custom-control custom-checkbox py-2">

              <input
                type="checkbox"
                class="form-check-input"
                name="withHeaders"
                [(ngModel)]="lab_info_header"
                id="customCheckHeaders"
              />
              <label class="form-check-label" for="customCheckHeaders" [ngClass]="{'mr-4' : data.isRTL}" >{{'with headers?' | locale}}</label>

          </div>

          <div class="custom-control custom-checkbox py-2">

            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                name="withOutsideNote"
                [(ngModel)]="outside_note"
                id="customOutsideNote"
              />
              <label class="form-check-label" for="customOutsideNote" [ngClass]="{'mr-4' : data.isRTL}" >{{'outside sample?' | locale}}</label>
            </div>

          </div>

          <div class="custom-control custom-checkbox py-2">

            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                name="withHealthCare"
                [(ngModel)]="healthCare"
                id="customHealthCare"
              />
              <label class="form-check-label" for="customHealthCare" [ngClass]="{'mr-4' : data.isRTL}" >{{'Health Certificate' | locale}}</label>
            </div>

          </div>

          <div class="custom-control custom-checkbox py-2">

            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                name="withDirectPrint"
                [(ngModel)]="directPrint"
                id="customDirectPrint"
              />
              <label class="form-check-label" for="customDirectPrint" [ngClass]="{'mr-4' : data.isRTL}" >{{'preview?' | locale}}</label>
            </div>

          </div>
        </div>


        <div class="row">
          <button form="selectTestsForm" type="submit" class="btn btn-primary">
            {{'Print' | locale}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<div
  class="modal fade"
  id="openUploadCasa"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4">
          <ng-container>
            <h1>Upload Report</h1>
            <br><br>
            <div class="row">
              <div class="col-12">
                <!-- <form method="post" enctype="multipart/form-data"> -->
                  Upload File
                  <input type="file" id="sendFile" name="sendFile" (change)="fileChoosen($event)">
                  <button (click)="uploadFile()" class="btn btn-outline-primary px-4 ml-4">
                    Upload
                  </button>
                <!-- </form> -->
              </div>
            </div>
            <br><br>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="send-sms"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5></h5>
      </div>

      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <h3>{{'Are you sure to send' | locale}}</h3>
          </div>
        </div>
       </div>
       <div class="modal-footer">
        <div class="row">
          <button (click)="sendSms()" class="btn btn-primary">
            {{'Send' | locale}}
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>