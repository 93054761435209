<app-loading [type]="'main'"></app-loading>

<div style="display: flex; flex-direction: column; height: 100vh !important;">
  <div *ngIf="data.patient && data.request" class="bg-white top-navbar">
    <div class="container">
        <div class="row justify-content-around p-2">
            <div class="col-lg-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span [ngClass]="{ 'rad-left': data.isRTL, 'rad-right': !data.isRTL }" class="input-group-text">{{ "Request #" | locale }}</span
          >
        </div>
        <input
          disabled
          required
          [ngClass]="{ 'rad-left': !data.isRTL, 'rad-right': data.isRTL }"
          pattern="^([^0-9]*)$"
          maxlength="25"
          value="{{ data.request['reception_number'] }}"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-lg-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span
            [ngClass]="{ 'rad-left': data.isRTL, 'rad-right': !data.isRTL }"
            class="input-group-text"
            >{{ "Name" | locale }}</span
          >
        </div>
        <input
          disabled
          required
          [ngClass]="{ 'rad-left': !data.isRTL, 'rad-right': data.isRTL }"
          pattern="^([^0-9]*)$"
          maxlength="25"
          value="{{
            data.patient['first_name'] +
              ' ' +
              (data.patient['middle_name'] ? data.patient['middle_name'] + ' ' : '') +
              data.patient['last_name']
          }}"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-lg-2" >
      <div class="input-group">
        <div class="input-group-prepend">
          <span
            [ngClass]="{ 'rad-left': data.isRTL, 'rad-right': !data.isRTL }"
            class="input-group-text"
            >{{ "Age" | locale }}</span
          >
        </div>

        <!-- *ngIf="(data.patient['age'] | ago) as values" -->
        <!-- [ngModel]="(data.patient['age'] | locale) || ''" -->
        <input
          disabled
          required
          [ngClass]="{ 'rad-left': !data.isRTL, 'rad-right': data.isRTL }"
          pattern="^([^0-9]*)$"
          maxlength="25"
          [ngModel]="data.patient['age'] || ''"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-lg-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span
            [ngClass]="{ 'rad-left': data.isRTL, 'rad-right': !data.isRTL }"
            class="input-group-text"
            >{{ "Gender" | locale }}</span
          >
        </div>
        <input
          disabled
          required
          [ngClass]="{ 'rad-left': !data.isRTL, 'rad-right': data.isRTL }"
          pattern="^([^0-9]*)$"
          maxlength="25"
          [ngModel]="(data.patient['gender']?.name || '') | locale"
          type="text"
          class="form-control"
        />
      </div>
    </div>
  </div>
</div>
</div>

  <div style="flex: 1; overflow: hidden scroll" class="px-lg-1 mt-5 mt-lg-0">
    <div class="container-fluid mt-5 p-2">
      <div class="container-fluid p-5">
        <app-title></app-title>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div *ngIf="data.me?.roles?.length" class="bg-white bottom-navbar">
    <div class="username px-3"
      [ngStyle]="{float: data.isRTL? 'left': 'right'}"
    >
      <p class="m-0 card py-1 px-2 rounded-0 text-dark btn-sm shadow font-weight-bold">v{{appVersion}}</p>
    </div>
    <div class="username px-3"
      [ngStyle]="{float: data.isRTL? 'left': 'right'}"
    >
      <p class="m-0 card py-1 px-2 rounded-0 text-dark btn-sm shadow font-weight-bold">{{data.me?.name}}</p>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <a
        [routerLink]="role.name == 'accountant' ? 'fms' : role.name"
        [routerLinkActive]="
        role.name == 'accountant' ? '' : 'role_link text-primary'
        "
          [target]="new_tab || role.name == 'accountant'? '_blank' : null"
          (click)="new_tab || role.name == 'accountant'? null : this.data.role_route_changed.next(role.name)"
          style="color: black; cursor: pointer"
          class="m-2 p-2 h6 text-center card"
          *ngFor="let role of data.me.roles"
        >
          {{ role.name | titlecase | locale }}
        </a>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="payment_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4">
          <ng-container
            *ngIf="this.data.payment_patientId && this.data.payment_request_id"
          >
            <receptionist-layout-payment
              [patientId]="this.data.payment_patientId"
              [request_id]="
                this.data.payment_request_id
                  ? this.data.payment_request_id
                  : null
              "
            ></receptionist-layout-payment>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
