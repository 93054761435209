<ng-container *ngIf="correspondent">
  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{ correspondent.name }}</h2>
    </div>
    <div class="col-2">
        <button (click)="print()" type="button" id="add-button" class="btn btn-secondary w-100 roboto-400">
        {{ "Receipt" | locale }}
      </button>
    </div>
    <div class="col-2">
      <button
        (click)="currentPer = undefined"
        data-toggle="modal"
        data-target="#paymentModal"
        type="button"
        id="add-button"
        class="btn btn-primary w-100 roboto-400"
      >
        {{ "Pay" | locale }}
      </button>
    </div>
  </div>
</ng-container>

<div class="card mb-5 mt-5">
    <div class="container-fluid py-3 px-4">
        <div class="row">
            <div *ngFor="let date of ['from', 'to']" class="col-4 mt-3">
                <div class="form-group">
                    <label>{{ "date " + date | locale }}</label>
                    <input [name]="date2" [(ngModel)]="dates2[date]" [id]="date + '3Picker'" class="flatpickr flatpickr-input active form-control" type="text" placeholder="Select Date.." />
                </div>
            </div>
            <div class="col-2">
                <br><br>
                <button (click)="printAccount()" type="button" id="add-button" class="btn btn-primary w-100 roboto-400">
      {{ "Account" | locale }}
    </button>
            </div>
        </div>

    </div>
</div>

<div class="card mb-5 mt-5">
  <div class="container-fluid py-3 px-4">
    <div *ngIf="correspondent" class="row">
      <!-- <div class="card" style="width: 18rem;">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
        </div> -->

      <P class="col-3 start h5 grey">{{ "Details" | locale }}</P>
      <P class="col-3 start h5 grey">{{
        "Correspondent | Patient" | locale
      }}</P>
      <P class="col-3 start h5 grey">{{ "Patient" | locale }}</P>
      <P class="col-3 start h5 grey">{{ "Correspondent" | locale }} </P>

      <P class="col-3 start h6 grey"
        >{{ "Code" | locale }}: {{ correspondent.code }}</P
      >
      <P class="col-3 start h6 grey"
        >{{ "Total" | locale }}:
        {{ correspondent.payment?.total + correspondent.payment?.patient_total }}</P
      >
      <P class="col-3 start h6 grey">
        {{ "Total" | locale }}: {{ correspondent.payment?.patient_total }}</P
      >
      <P class="col-3 start h6 grey">
        {{ "Total" | locale }}: {{ correspondent.payment?.total }}</P
      >

      <P class="col-3 start h6 grey"
        >{{ "Phone" | locale }}: {{ correspondent.phone_number }}</P
      >
      <P class="col-3 start h6 grey"
        >{{ "Paid" | locale }}:
        {{ correspondent.payment?.paid + correspondent.payment?.patient_paid }}</P
      >
      <P class="col-3 start h6 grey">
        {{ "Paid" | locale }}: {{ correspondent.payment?.patient_paid }}</P
      >
      <P class="col-3 start h6 grey">
        {{ "Paid" | locale }}: {{ correspondent.payment?.paid }}</P
      >

      <P class="col-3 start h6 grey"
        >{{ "Payment percentage" | locale }}: {{ correspondent.percentage }}%</P
      >
      <P class="col-3 start h6 grey"
        >{{ "Remaining" | locale }}:
        {{ correspondent.payment?.remaining + correspondent.payment?.patient_remaining }}</P
      >
      <P class="col-3 start h6 grey">
        {{ "Remaining" | locale }}: {{ correspondent.payment?.patient_remaining }}</P
      >
      <P class="col-3 start h6 grey">
        {{ "Remaining" | locale }}: {{ correspondent.payment?.remaining }}</P
      >
    </div>
    <!-- //  request list component start / -->

    <ng-container>
      <div class="card mt-4 mb-5">
        <div class="container-fluid py-3 px-4">
          <!-- <div class="row mt-2">
              <div class="col-12 col-lg-10">
                <div class="form-group m-0 roboto-400">
                  <input
                    type="text"
                    class="form-control px-5"
                    id="request_autocomplete"
                    placeholder="Search with patient name"
                  />

                  <i
                    [hidden]="!isLoaderHidden"
                    class="fas search-icon fa-search"
                  ></i>

                  <div
                    [hidden]="isLoaderHidden"
                    #loader
                    class="spinner-border text-primary load"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-2 mt-3 mt-lg-0">
                <div class="d-flex w-100 justify-content-end">
                  <button
                    data-toggle="modal"
                    data-target="#modal"
                    type="button"
                    id="add-button"
                    class="btn btn-primary w-100 roboto-400"
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div> -->

          <div class="row mt-2 justify-content-center">
            <div
              *ngFor="let per of ['requests', 'transactions', 'day', 'month']"
              class="col-2 mx-4"
            >
              <div class="d-flex w-100 justify-content-end">
                <p
                  (click)="toggleFlags(per)"
                  class="btn btn-outline-primary btn-sm w-100 roboto-400 m-0"
                  [ngClass]="{
                    'btn-primary text-white': flags[per]
                  }"
                >
                  {{ per | locale }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-2 justify-content-center">
            <div class="col">
              <div class="row">
                <div
                  [ngStyle]="!flags.requests ? { display: 'none' } : {}"
                  *ngFor="let date of ['from', 'to']"
                  class="col-6 mt-3"
                >
                  <div class="form-group">
                    <label>{{ "date " + date | locale }}</label>
                    <input
                      [name]="date"
                      [(ngModel)]="dates[date]"
                      [id]="date + 'Picker'"
                      (change)="toggleFlags('requests')"
                      class="flatpickr flatpickr-input active form-control"
                      type="text"
                      placeholder="Select Date.."
                    />
                  </div>
                </div>
                <div [ngStyle]="!flags.transactions ? { display: 'none' } : {}" *ngFor="let date of ['from', 'to']" class="col-6 mt-3">
                    <div class="form-group">
                        <label>{{ "date " + date | locale }}</label>
                        <input [name]="date" [(ngModel)]="dates[date]" [id]="date + '2Picker'" (change)="toggleFlags('transactions')" class="flatpickr flatpickr-input active form-control" type="text" placeholder="Select Date.." />
                    </div>
                </div>
              </div>
            </div>
            <div >
              <div *ngIf="flags.requests" class="form-group col-12 mt-3">
                <div class="row">
                  <div>
                    <label> &nbsp; </label>
                    <button
                      (click)="print_requests()"
                      class="btn form-control btn-primary"
                    >
                      {{ "Print" | locale }}
                    </button>
                  </div>
                  <div class="mx-3">
                    <label> &nbsp; </label>
                    <button
                      (click)="print_requests(1)"
                      class="btn form-control btn-outline-primary"
                    >
                      {{ "Send Mail" | locale }}
                    </button>
                  </div>
                  <div
                    style="
                      flex-direction: column;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    <label> &nbsp; </label>
                    <div style="display: flex; align-items: center">
                      <div class="form-group form-check start mb-0">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="withTests"
                          [(ngModel)]="withTests"
                          [checked]="withTests"
                          id="withTests"
                        />
                        <label
                          class="form-check-label"
                          [ngClass]="{ 'mr-4': data.isRTL }"
                          for="withTests"
                          >{{ "With Tests" | locale }}</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="mx-3"
                    style="
                      flex-direction: column;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    <label> &nbsp; </label>
                    <div style="display: flex; align-items: center">
                      <div class="form-group form-check start mb-0">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="excel"
                          [(ngModel)]="excel"
                          [checked]="excel"
                          id="excel"
                        />
                        <label
                          class="form-check-label"
                          [ngClass]="{ 'mr-4': data.isRTL }"
                          for="excel"
                          >{{ "Excel" | locale }}</label
                        >
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table mt-3">
                  <thead>
                    <tr
                      *ngIf="!(flags.month || flags.day || flags.transactions)"
                      class="neue-light roboto-400"
                    >
                      <th  scope="col" >{{'ID' | locale:2}}</th>
                      <!-- <th width="15%" scope="col">Request #</th> -->

                      <th scope="col">{{ "Request #" | locale }}</th>

                      <th scope="col">{{ "Patient" | locale }}</th>
                      <th scope="col" style="text-align: center">
                        {{ "Hospital ID" | locale }}
                      </th>
                      <th scope="col" style="text-align: center">
                        {{ "Correspondent ID" | locale }}
                      </th>
                      <th scope="col">{{ "Date" | locale }}</th>
                      <th scope="col">{{ "Total (Patient)" | locale }}</th>
                      <th scope="col">{{ "Total" | locale }}</th>
                      <th scope="col">{{ "Paid" | locale }}</th>
                      <th scope="col">{{ "Remaining" | locale }}</th>
                      <th scope="col">{{ "Status" | locale }}</th>
                      <th scope="col">#</th>

                      <!-- <th style="width: 50px" scope="col">Payments</th> -->
                      <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                      <!-- <th scope="col">خيارات</th> -->
                      <!-- <th scope="col"></th> -->
                    </tr>

                    <tr
                      *ngIf="flags.month || flags.day"
                      class="neue-light roboto-400"
                    >
                      <th  scope="col" > {{'ID' | locale:2}}</th>
                      <!-- <th width="15%" scope="col">Request #</th> -->
                      <th style="min-width: 100px" scope="col">
                        {{ (flags.month ? "Month" : "Day") | locale }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        {{ "Total" | locale }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        {{ "Paid" | locale }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        {{ "Remaining" | locale }}
                      </th>
                      <th style="min-width: 100px" scope="col">
                        {{ "Payments" | locale }}
                      </th>
                      <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                      <!-- <th scope="col">خيارات</th> -->
                      <!-- <th scope="col"></th> -->
                    </tr>

                    <!-- | paginate: { id:'pagination' ,
              itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }" -->
                    <tr *ngIf="(flags.transactions)"
                    class="neue-light roboto-400"
                  >
                    <td  scope="col" >{{'ID' | locale}}</td>
                    <td scope="col">{{ "Date" | locale }}</td>
                    <td scope="col">{{ "Reception number" | locale }}</td>
                    <td scope="col">{{ "Amount" | locale }}</td>
                    <td scope="col">{{ "Payment method" | locale }}</td>
                    <td scope="col">#</td>
                    <td scope="col">#</td>
                  </tr>
                  </thead>
                  <tbody class="neue-light">
                    <ng-container *ngIf="flags.requests">
                      <ng-container
                        *ngFor="
                          let request of pers
                            | paginate
                              : {
                                  id: 'pagination',
                                  itemsPerPage: pagination.per_page,
                                  currentPage: pagination.current_page,
                                  totalItems: pagination.total
                                };let i = index
                        "
                      >
                        <tr valign="middle" class="clickable_row roboto-400">
                          <td>{{ i + 1 }}</td>
                          <td>{{ request.reception_number }}</td>
                          <td>{{ request.patient_name }}</td>
                          <td>{{ request.patient_hospital_id || "❌" }}</td>
                          <td>{{ request.patient_insurance_id || "❌" }}</td>
                          <td>{{ request.created_at | date }}</td>
                          <td>{{ request.payment?.patient_total }}</td>
                          <td>{{ request.payment?.correspondent_total }}</td>
                          <td>{{ request.payment?.correspondent_paid }}</td>
                          <td>
                            {{ request.payment?.correspondent_remaining }}
                          </td>
                          <td>
                            {{
                              request.payment?.correspondent_isPaid
                                ? "Paid"
                                : "Unpaid"
                            }}
                            <span class="roboto-400 cur"> </span>
                          </td>
                          <td>
                            <button (click)="print_request_recipt(request.request_id)" class="btn form-control btn-primary">
                              {{ "Print" | locale }}
                            </button>
                        </td>
                        </tr>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="flags.month || flags.day">
                      <ng-container *ngFor="let per of pers; let i = index">
                        <tr class="neue-light roboto-400">
                          <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                          <!-- <th width="15%" scope="col">Request #</th> -->
                          <th style="min-width: 100px" scope="col">
                            {{ i + 1 }}
                          </th>
                          <th style="min-width: 100px" scope="col">
                            {{ per.date }}
                          </th>
                          <th style="min-width: 100px" scope="col">
                            {{ per.total }}
                          </th>
                          <th style="min-width: 100px" scope="col">
                            {{ per.paid }}
                          </th>
                          <th style="min-width: 100px" scope="col">
                            {{ per.remaining }}
                          </th>
                          <th style="min-width: 100px" scope="col">
                            <button
                              (click)="currentPer = per"
                              data-toggle="modal"
                              data-target="#paymentModal"
                              type="button"
                              class="btn btn-outline-info p-0 px-3"
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </button>
                          </th>
                          <!-- <th style="width: 30px;" scope="col"> Delivered</th> -->

                          <!-- <th scope="col">خيارات</th> -->
                          <!-- <th scope="col"></th> -->
                        </tr>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="flags.transactions">
                      <ng-container
                        *ngFor="let request of pers; let i = index"
                      >
                        <tr valign="middle" class="clickable_row roboto-400">
                          <td>{{ i + 1 }}</td>
                          <td>{{ request.payment_date | date }}</td>
                          <td>{{ request.reception_number }}</td>
                          <td>{{ request.amount }}</td>
                          <td>{{ request.payment_method.name }}</td>
                          <td>
                              <button (click)="print_recipt(request.id)" class="btn form-control btn-primary">
                                {{ "Print" | locale }}
                              </button>
                          </td>
                          <td  style="width: 30px;">
                            <button
                              type="button"
                              (click)="remove(request.id)"
                              class="btn btn-outline-danger p-0 px-1"
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12 mt-3" *ngIf="!(flags.month || flags.day || flags.transactions)">
              <div class="w-100 d-flex justify-content-center neue-med p-0">
                <pagination-controls
                  responsive="true"
                  [previousLabel]="'Previous' | locale"
                  [nextLabel]="'Next' | locale"
                  (pageChange)="toggleFlags('requests', $event)"
                  id="pagination"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- <router-outlet></router-outlet> -->

    <!-- Modal -->
    <!-- <div
        class="modal fade"
        id="modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-body">
              <div class="container-fluid px-4">
                <div class="row mt-4">
                  <div class="col-12">
                    <h5 class="roboto-500">Select a patient</h5>
                  </div>
                </div>
              </div>

              <admin-layout-patient-select></admin-layout-patient-select>

              <div class="container-fluid px-4 mt-3">
                <div class="row">
                  <div class="col-12">
                    <h5 class="roboto-500">or create new</h5>
                  </div>
                </div>
              </div>

              <admin-layout-new-patient
                (patientCreated)="onPatientCreated($event)"
                [action]="{ type: 'New' }"
              ></admin-layout-new-patient>
            </div>
          </div>
        </div>
      </div> -->

    <!-- request list component end -->
  </div>
</div>

<!-- <router-outlet></router-outlet> -->

<!-- Modal -->
<div
  class="modal fade"
  id="cor-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div class="row">
            <div class="col-12">
              <h5 class="roboto-500">Create a Correspondent</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="paymentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4">
          <div class="row mt-4">
            <div class="col-12">
              <h5 class="roboto-500 start">{{ "Make Payment" | locale }}</h5>
            </div>
          </div>

          <form #form="ngForm" (ngSubmit)="onSubmit(form)">
            <div class="row">
              <div class="col-lg-6 mt-3">
                <div class="form-group">
                  <label for="Amount">{{ "Amount" | locale }}</label>
                  <input
                    maxlength="500"
                    name="value"
                    ngModel
                    type="text"
                    class="form-control"
                    [placeholder]="'Amount' | locale"
                  />
                </div>
              </div>

              <div class="col-lg-6 mt-3">
                <div class="form-group">
                  <label for="payment_method">{{
                    "Payment Method" | locale
                  }}</label>
                  <select
                    [ngModel]="payment_method_id"
                    name="payment_method_id"
                    id="payment_method"
                    class="custom-select neue-reg"
                  >
                    <option
                      *ngFor="let paymentMethod of payment_methods"
                      [value]="paymentMethod.id"
                    >
                      {{ paymentMethod.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div *ngIf="cashiers.length" class="col-6">
                <div class="form-group">
                  <label for="cachier_id">{{ "Cashier" | locale }}</label>
                  <select
                    [ngModel]="cashier_id"
                    name="cashier_id"
                    id="cashier_id"
                    class="custom-select neue-reg"
                  >
                    <option
                      *ngFor="let cashier of cashiers"
                      [value]="cashier.id"
                    >
                      {{ cashier.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="payPicker">{{ "Payment Date" | locale }}</label>
                  <input
                    name="payment_date"
                    id="payPicker"
                    class="flatpickr flatpickr-input active form-control"
                    type="text"
                    placeholder="Select Date.."
                  />
                </div>
            </div>
            </div>
            <div class="row mt-2 mb-3">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="note">{{ 'Note' | locale}}</label>
                        <textarea name="note" ngModel id="note" class="form-control"></textarea>
                    </div>
                </div>
            </div>

            <div class="row mt-2 mb-3">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <button
                    type="submit"
                    type="submit"
                    class="btn btn-outline-primary tajwal-regium px-5"
                  >
                    {{ "Pay" | locale }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
