import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProgressService } from 'src/app/services/progress.service';
declare var $:any

@Component({
  selector: 'admin-layout-cashier-list',
  templateUrl: './cashier-list.component.html',
  styleUrls: ['./cashier-list.component.scss']
})
export class CashierListComponent implements OnInit {

  cashiers: any [] = [];
  action :any = {}
  selectedCashier:any = {}


  constructor(public router:Router,public api : ApiService,public progress : ProgressService,private data:DataService, public ms:ModalService) { }

  ngOnInit() {
    this.getCashiers();
    this.data.remove.subscribe(({id,type}) => {
      if(type == "cashier"){
        this.cashiers = this.cashiers.filter(one => one.id != id)
        $("#cashier-modal").modal('hide');
      }
    })
  }


  getCashiers(){

    // $('.dropdown-menu').dropdown('show');
    this.progress.on();

    this.api.Common.Get.treasuries().subscribe(res => {
      this.progress.off();
      this.cashiers = res
    });


  }



  openModal(action, cashier){

    this.data.cashier = cashier
    this.action = action
    $("#cashier-modal").modal('show');

  }



  cashierCreated(){
    console.log('here');

    this.getCashiers();
    $("#cashier-modal").modal('hide');
  }


}
