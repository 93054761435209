import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
declare var $:any


@Component({
  selector: 'admin-layout-parameters-list',
  templateUrl: './parameters-list.component.html',
  styleUrls: ['./parameters-list.component.scss']
})
export class ParametersListComponent implements OnInit,AfterViewInit  {
  Params: any [] = [];
  action :any = {}
  page : any = 1
  pagination: any ;
  isLoaderHidden = true;
  key: any;
  excel = false



  constructor(public router:Router,public api : ApiService,public progress : ProgressService, public data:DataService) { }

  ngOnInit() {

    this.progress.on();

    this.getparameters(this.page,this.key);

    this.data.remove.subscribe(({id,type}) => {
      if(type == "param"){
        this.Params = this.Params.filter(one => one.id != id)
        $("#param-modal").modal('hide');
      }
    })

  }


  getparameters(page,key){

    this.page = page

    // $('.dropdown-menu').dropdown('show');
    this.api.Common.Get.parameters({page,code:key || ''}).subscribe(res => {

      this.progress.off();
      this.isLoaderHidden = true
      this.Params = res['data']
      this.pagination = this.data.extract_pagination(res)

    });


  }

  print_parameters(){
    let excel = this.excel == true ? 1 : 0
      setTimeout(()=>
      this.api.Common.printables.print_parameters({excel}).subscribe((res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      })) ,0);
  }

  ngAfterViewInit(){

    var input$ : Observable<any> = fromEvent($('#param_autocomplete'), 'input');
    input$.pipe(map(res => res['target'].value),debounceTime(500)).subscribe(res => {


      console.log('here');

      this.key = res
      this.isLoaderHidden = false
      this.getparameters(1,this.key);



    })

  }



  openModal(action){

    this.action = action
    $("#param-modal").modal('show');

  }



  paramCreated(){

    this.getparameters(this.page,this.key);
    $("#param-modal").modal('hide');
  }



}
