import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProgressService } from 'src/app/services/progress.service';
import { DataService } from 'src/app/services/data.service';
declare var $:any

@Component({
  selector: 'app-support-list',
  templateUrl: './support-list.component.html',
  styleUrls: ['./support-list.component.scss']
})
export class SupportListComponent implements OnInit {
  tickets:any;
  tickets_counter:any;
  high_tickets_counter:any;
  medium_tickets_counter:any;
  low_tickets_counter:any;
  searchByName ="" ;
  action :any = {}
  lab_id = ""
  priority = ""
  status = ""

  constructor(private data:DataService, public router:Router,public api : ApiService,public progress : ProgressService) { }

  ngOnInit() {
    this.getTickets();
  }

  getTickets(){    
    this.progress.on();
    this.api.Admin.supports.get_tickets().subscribe(res => {
      this.tickets = res
      this.api.Admin.supports.get_tickets_numbers().subscribe(res => {
        this.tickets_counter = res['tickets_counter']
        this.high_tickets_counter = res['high_tickets_counter']
        this.medium_tickets_counter = res['medium_tickets_counter']
        this.low_tickets_counter = res['low_tickets_counter']
      })
      this.progress.off();
    });
  }

  openModal(action){
    this.action = action
    $("#cor-modal").modal('show');
  }

  correspondentCreated(){
    this.getTickets();
    $("#cor-modal").modal('hide');
  }

}
