<form
  id="draw_sample_form"
  #draw_sample_form="ngForm"
  (ngSubmit)="onSubmit(draw_sample_form)"
>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">

        <!-- <ng-container *ngIf="!doctor"> -->

          <selector
          title="doctors"
          [datum]="doctor"
          >

          </selector>

      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>{{ "Draw Time" | locale }}</label>

          <input [ngModel]="dateTime" class="flatpickr flatpickr-input active form-control" type="date"
          [placeholder]="'Draw Time' | locale"  autocomplete="off" name="draw_time"  id="draw_time" />

          <!-- <input
            type="datetime-local"
            class="form-control"
            [ngModel]="dateTime"
            name="draw_time"
            required
          /> -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn ripple btn-primary"
      form="draw_sample_form"
      type="submit"
    >
      {{ "Add" | locale }}
    </button>
    <button class="btn ripple btn-secondary" data-dismiss="modal" type="button">
      {{ "Cancel" | locale }}
    </button>
  </div>
</form>
