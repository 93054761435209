import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, fromEvent } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { map, tap, debounceTime } from "rxjs/operators";
import { ProgressService } from "src/app/services/progress.service";
import { DataService } from "src/app/services/data.service";
import { SnackService } from "src/app/services/snack.service";
import { NgForm } from "@angular/forms";
declare var $: any;

@Component({
  selector: "app-patients-list",
  templateUrl: "./patients-list.component.html",
  styleUrls: ["./patients-list.component.scss"],
})
export class PatientsListComponent implements OnInit, AfterViewInit {
  key: any = "";
  refresher:boolean = false;
  checker = false
  checked_patients = {};
  patients: any = [];
  pagination: any = {};
  action: any = "";
  isLoaderHidden = true;
  dates = {
    from: new Date().toISOString().slice(0, 10).split("-").reverse().join("-"),
    to: new Date().toISOString().slice(0, 10).split("-").reverse().join("-"),
  };
  excel = false;
  patientToPrint: any = [];
  patientCheckStatus: any = [];
  patientFile: any
  upfile: File | null = null
  
  upload_file_data: any = {
    latest_file: {
      name: null,
      lastModified: null,
      webkitRelativePath: null,
      size: null,
      type: null
    }
  }
  insurance = false

  constructor(
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public snack: SnackService
  ) {}

  ngOnInit() {
    this.progress.on();
    
    this.api.Common.Get.lab_info().subscribe(res => {
      this.insurance = res['data']['settings']['insurance']
    })

    this.getPatients(this.key, true, 1);
    this.data.remove.subscribe(({ id, type }) => {
      if (type == "patient") {
        this.patients = this.patients.filter((one) => one.id != id);
        $("#modal").modal("hide");
      }
    });
    this.patients.forEach(
      (patient) => (this.patientCheckStatus[patient.id] = false)
    );
  }

  getPatients(res, paginated, page) {
    // $('.dropdown-menu').dropdown('show');
    this.api.Common.patients.get({[Number(res)? 'reception_number' : 'name']:res, paginated, page, 'with[]': ['payment', 'requests']}).subscribe((res) => {
      this.patients = res["data"];
      this.pagination = this.data.extract_pagination(res);
      this.progress.off();
      this.isLoaderHidden = true;
    });
  }

  ngAfterViewInit() {
    // ["from", "to"].forEach((one) => {
    //   $(`#${one}Picker`)[0].flatpickr({
    //     dateFormat: "Y-m-d",
    //   });
    // });

    var input$: Observable<any> = fromEvent($("#pat_autocomplete"), "input");
    input$
      .pipe(
        map((res) => res["target"].value),
        debounceTime(500)
      )
      .subscribe((res) => {
        console.log("here");

        this.key = res;
        this.isLoaderHidden = false;
        this.getPatients(this.key, true, 1);
      });
  }

  patientForPrint(patient_id) {
    const index = this.patientToPrint.findIndex((e) => e == patient_id);

    if (index < 0) this.patientToPrint.push(patient_id);
    else this.patientToPrint.splice(index, 1);

    console.log("patientToPrint", this.patientToPrint);
  }

  patient_selection() {
    this.refresh()
    if(this.patients.filter(one => !this.checked_patients[one.id]).length){
      //selection


      this.patients.forEach((printPatient) => {
        this.checked_patients[printPatient.id] = true
      });
    }else{
      //unselection
      this.patients.forEach((printPatient) => {
        this.checked_patients[printPatient.id] = false
      });
    }


  }

  refresh(){
    this.refresher = true
    setTimeout(()=> this.refresher = false, 0)
  }


  print(id){

    this.api.Common.printables.print_card(id).subscribe(res => {

      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');

    })
  }

  fileChoosen(event){
    console.log('e', event)
      this.upfile = event.target.files ? event.target.files[0]: $('#sendFile')[0].files[0];
      console.log('file', this.upfile)
      this.upload_file_data.latest_file = this.upfile
   }

  uploadPatient(form: NgForm){

    if (!this.upfile){
      return this.snack.showerror('Please Upload File');
    }

    var request = new FormData();

    request.append('location', URL.createObjectURL(this.upfile))
    request.append('latest_file', this.upfile, this.upfile.name);

    this.api.Admin.patients.upload(request).subscribe(res => {
      console.log(res)
      
      if (res['error'] == false ){
        this.snack.show('File Uplaoed Successfully');
        this.getPatients(this.key, true, 1);
        $("#upload-patient-modal").modal("toggle");
      }else{
        this.snack.showerror('Try Again');
      }
    })
  }


  print_report() {
    if (Object.keys(this.checked_patients).filter(id => this.checked_patients[id]).length < 1) {
      this.snack.showerror("Please add at least one patient");
      return;
    }

    let min_date = this.dates.from.split("-").reverse().join("-");
    let max_date = this.dates.to.split("-").reverse().join("-");
    let patients = Object.keys(this.checked_patients).filter(id => this.checked_patients[id])
    let excel = this.excel? 1 : 0;
    this.api.Common.printables.PrintPatientsRequests(
      {min_date,
      max_date,
      excel,
      "patients[]": patients}
    ).subscribe((res) => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, "_blank");
    });
  }

  onPatientCreated(event) {
    this.getPatients(this.key, true, 1);

    $("#modal").modal("hide");

    // $("#modal").toggle("hide");
    // $('body').removeClass('modal-open');
    // $('.modal-backdrop').remove();
    // $('.dropdown-menu').dropdown('hide');
  }

  search() {
    this.getPatients(this.key, true, 1);
  }

  openModal(action) {
    this.action = action;
    $("#modal").modal("show");
  }
}
