import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DataService } from "src/app/services/data.service";
import { filter, map, tap } from "rxjs/operators";
import { SnackService } from "src/app/services/snack.service";
import { ProgressService } from "src/app/services/progress.service";
import { TheDatePipe } from "src/app/pipes/the-date.pipe";
import { AgoPipe } from "src/app/pipes/ago.pipe";
import { NgForm } from "@angular/forms";
import { Arabic } from "flatpickr/dist/l10n/ar";
import { PaymentComponent } from "src/app/home/content/receptionist-layout/payment/payment.component";

import { Observable, fromEvent, of } from "rxjs";

import { debounce } from "lodash";

declare var $: any;

@Component({
  selector: "receptionist-layout-new-request",
  templateUrl: "./new-request.component.html",
  styleUrls: ["./new-request.component.scss"],
})
export class NewRequestComponent implements OnInit {
  @ViewChild('paymentComponent', { static: false }) paymentComponent!: PaymentComponent;
  request_being_made: boolean = false;
  doctor = null;
  download_upload_modal_data = {
    title: "",
  };

  buttons: Array<{ label: string; data: any }> = [
    {
      label: "Download",
      data: { title: "Download" },
    },
  ];
  x(reception_number, test_name) {
    this.reception_number = reception_number;
    this.test_name = test_name;
  }
  reception_number: string = "";
  test_name: string = "";

  discount: any = {};
  clinic: {
    id: Number;
    name: String;
    address: String;
    contact: String;
    isActive: Boolean;
    created_at: string;
    updated_at: string;
  } = null;
  request: any = {
    correspondent_id: 0,
    identity_type_id: 0,
    // hospital_id: "",
    doctor: "",
    clinical_information: "",
    laboratory_information: "",
    pricing_id: 0,
    urgent_flag: false,
    is_repeat: false,
    is_whatsapp: false,
    is_outside_note: false,
  };
  subcategories_id= 0
  patient_insurance_id: "";
  profile_tests = [];
  profiles = [];
  profile = {};
  isProfileSelected = false;
  isTestSelected = false;
  patientId: any;
  request_id: any;
  requestNum: any;
  action: "New" | "Edit" = null;
  v;
  goToHome = false;
  // isDisable = false

  //-----------------------------------------------------------------------------------------
  correspondents: Observable<any> = of([]);
  subcategories: Observable<any> = of([]);
  // units: Observable<any> = of([]);
  // correspondents: any = [];
  // subcategories: any = [];
  units: any = [];
  identity_types: any = [];
  unit_id = 0
  labs$: Observable<any> = of([]);
  doctors: any = [];
  pricings: Observable<any> = of([]);
  total: any = 0;
  remaining: any = 0;
  selectedProfiles: any = [];
  selectedTests: any = [];
  originalTestsRemaining = 0;
  patient: any = {};
  ts_form = {};
  requests: any;
  historyData: any;
  pagination: any = {};
  page: number = 1;
  total_rows: number;
  isTax = false;
  userUnits = false;
  isLabNotesSaveHidden = true;
  defaultPrice: 1;
  reasons: any = [];
  confirmDeleteTest: any;
  testReasonTest: any;
  testReasonAmount: any;
  reason: any;
  hasActivityLog: Boolean = false;
  fromParams = null;
  toParams = null;
  receptionNumberParams = null;
  correspondentParams = null;
  patient_searchParams = null;
  typeParams = null;
  payment_statusParams = null;
  selectedItems: string[] = [];
  cantChangePrice: Boolean = true
  cantChangeDoctor: Boolean = true
  lab_to_lab: boolean = true
  is_lab_to_lab: boolean = true

showSelectedItems(){
console.log('sdd' + this.selectedItems)
}

  showWSaveLabNotes() {
    this.isLabNotesSaveHidden = false;
  }

  saveLabNotes(lab_input) {
    this.api.Common.requests.update_laboratory_information(this.request_id, {
      laboratory_information: lab_input.value,
    }).subscribe((res) => {
      this.isLabNotesSaveHidden = true;
      if (!res["error"]) {
        this.snack.show("The note was successfully updated"
        );
      }
    });
  }

  change_subcategory(correspondent, correspondents = []) {
    this.correspondents = this.api.Common.Get.fitered_correspondents({"subcategory_id": correspondent}).pipe(tap((res) => {}));
  }

  constructor(
    private theDate: TheDatePipe,
    private route: ActivatedRoute,
    public api: ApiService,
    public snack: SnackService,
    public router: Router,
    public progress: ProgressService,
    public data: DataService,
    private ago: AgoPipe,
  ) {
    this.onSubmit = debounce(this.onSubmit, 500);
  }


  checkChangePriceAbility(){
    if(this.data.me?.sub_roles?.indexOf('changePrice') != -1){
      this.cantChangePrice = false
    }
  }
  
  checkChangeDoctorAbility(){
    if(this.data.me?.sub_roles?.indexOf('changeDoctor') != -1){
      this.cantChangeDoctor = false
    }
  }
  
  setSelectedPatient(patient) {
    let to_be_assigned = JSON.parse(JSON.stringify(patient));
    [to_be_assigned.age, to_be_assigned.age_type, to_be_assigned.birthdate] =
      this.ago.transform(to_be_assigned["birthdate"], { slashes: true });
    this.patient = to_be_assigned;
  }

  download(form: NgForm) {
    let data = JSON.parse(JSON.stringify(form.value));
    let number = data["lab"]["id"] + data["reception_number"];
    this.api
      .Common.ltl.download( {number})
      .subscribe((res) => {
        if (!res["error"]) {
          let patient = res["data"]["patient"];
          patient["civility_id"] = patient["civility"]["id"];
          patient["civility"] = patient["civility"]["name"];
          patient["first_name"] = patient["name"].split(" ")[0];
          // patient['middle_name'] = ''
          patient["last_name"] = patient["name"]
            .split(" ")
            .filter((one, i) => i)
            .join(" ");

          delete patient["id"];

          this.setSelectedPatient(patient);
          delete res["data"]["patient"];
          this.selectedTests = res["data"]["tests"];
          delete res["data"]["tests"];
          // res['data']['correspondent_id'] = data['to_lab_id']
          this.request = res["data"];
          this.request["urgent_flag"] = false;
          this.request["type"] = "downloaded";
          this.request["correspondent_id"] = data["correspondent"]["id"];
          this.correspondents.toPromise().then((corres) => {
            this.change_pricing(data["correspondent"]);
            document.getElementById("download_closer").click();
          });
          $('#download_upload_modal').modal('hide');
        }
      });
  }

  refreshTotalAfterPayment(event){
    this.getTotal();
  }

  change_pricing(correspondent, correspondents = []) {
    if (correspondent == 0) {
      return (this.request.pricing_id = this.defaultPrice);
    }
    let cor = correspondent["pricing_id"]
      ? correspondent
      : correspondents.find((one) => one.id == correspondent);
    cor.pricing_id ? (this.request.pricing_id = cor.pricing_id) : null;
    this.changeTestsPricing();
  }

  get_labs(){
    this.labs$ = this.api.Admin.labs.get({}).pipe(map(res => res['labs']))
    this.labs$.subscribe(console.log)
  }

  ngOnInit() {
    this.api.Common.Get.lab_info().subscribe(res => {
      this.lab_to_lab = res['data']['settings']['lab']
      this.is_lab_to_lab = res['data']['settings']['isLabToLab']
    })

    this.checkChangePriceAbility()
    this.checkChangeDoctorAbility()
    this.getDoctors();
    this.get_labs()
    this.getUnits();
    this.getIdentities();

    this.data.discountMade.subscribe((discount) => {
      this.discount = discount || this.discount;
      this.changeTestsPricing();
      this.data.isDiscount ? $("#payment_modal").modal("toggle") : null;
    });

    this.data.infoAssigned.subscribe(isAssigned =>{
      if(isAssigned){
        this.confirmDeleteTest = this.data.info["settings"]["confirmDeleteTest"];
        this.isTax = this.data.info["settings"]['isTax']
        this.userUnits = this.data.info["settings"]["userUnits"];
        this.getDefaultPrice();
        if(this.data.me.sub_roles.indexOf('activityLog') != -1){
          this.hasActivityLog = true
        }
      }
    })
    this.route.queryParams.subscribe((params) => {
      console.log("params_check",params['from'])
      this.fromParams = params['from'];
      this.toParams = params['to'];
      this.receptionNumberParams = params['reception_num'];
      this.correspondentParams = params['correspondent'];
      this.patient_searchParams = params['patient_search'];
      this.typeParams = params['type'];
      this.payment_statusParams = params['payment_status'];
    })



    $("#picker").flatpickr({
      dateFormat: "d-m-Y",
      locale: this.data.language == "arabic" ? Arabic : null,
      position: "auto center",
    });

    this.route.params.subscribe((params) => {

      this.request_id = params["request_id"];
      // this.requestNum = params["requestNum"];

      switch (params["action"]) {
        case "add": {
          this.action = "New";
          this.getPricing();
          break;
        }

        case "edit": {
          this.progress.on();
          // this.getSelectedTests();
          this.getTotal();
          // this.getclinic(true);
          this.action = "Edit";
          this.getRequest();
          setTimeout(() => this.getPricing(), 800);
          break;
        }

        default: {
          this.router.navigateByUrl("/");
        }
      }

      this.getCorrespondents();
      this.getSubcategories();
    });
  }

  backWithData(){
    var queries = `?from=${this.fromParams}&to=${this.toParams}&correspondent=${this.correspondentParams}&payment_status=${this.payment_statusParams}&reception_num=${this.receptionNumberParams}&patient_search=${this.patient_searchParams}&type=${this.typeParams}`
   
    this.router.navigateByUrl(`receptionist${queries}`)
    
  }
  // getclinic(status) {
  //   this.api.Common.Get.getclinic(status).subscribe((res) => {
  //     this.clinic = res["data"][0];
  //     this.request.isClinic = this.clinic ? 1 : 0;
  //     // console.log("isClinic ----------------->", this.request.isClinic);
  //   });
  // }

  getCorrespondents() {
    this.correspondents = this.api.Common.Get.correspondents().pipe(
      tap((res) => {
        this.action === "New"
          ? (this.request.correspondent_id =
              (res.filter((one) => one["is_default"])[0] || {}).id || "0")
          : null;
      })
    );
  }
  getSubcategories() {
    // this.api.Common.Get.subcategories().subscribe((res) => {
    //   this.subcategories = res
    // });
    this.subcategories = this.api.Common.Get.subcategories().pipe(tap((res) => {}));
  }

  getUnits() {
    // this.api.Common.Get.subcategories().subscribe((res) => {
    //   this.subcategories = res
    // });
    // this.api.Common.Get.user_units().subscribe((res) => {
    this.api.Common.Get.units().subscribe((res) => {
      this.units = res
    });
    // this.units = this.data.me.units;
  }
  getIdentities() {
    this.api.Common.Get.identity_types().subscribe((res) => {
      this.identity_types = res
    });
  }
  getDoctors() {
    this.api.Common.Get.doctors().subscribe((res) => {
      this.doctors = res;
      if (this.request && Number(this.request["doctor"])) {
        this.doctor = this.doctors.find(
          (doc) => doc.id == this.request["doctor"]
        );
      }
    });
  }

  getPricing() {
    this.pricings = this.api.Common.Get.pricings().pipe(
      tap((res) => {
        if (this.action === "New") {
          this.request.pricing_id = this.defaultPrice;
        }
      })
    );
  }


  async history() {
    await this.getData(this.page);
    $("#history").modal("toggle");
  }
  async getData(page = this.page) {
    this.page = page;

    let queries = "&hospital_id=" + this.patient?.hospital_id;
    this.api.Common.Get.filters.results(queries).subscribe((res) => {
      this.historyData = res["data"];
      this.total_rows = res["total"];
      this.pagination = this.data.extract_pagination(res)
    });
  }

  openPayment(discount = false) {
    this.data.payment_request_id = this.request_id;
    this.data.payment_patientId = null;
    this.data.isDiscount = discount;
    this.data.payment_patientId = this.patientId;
    // this.isDisable = false;
    $("#payment_modal").modal("toggle");
    if(this.request_id) this.paymentComponent.getTotal();
  }


  check_if_exisit(id, test_id) {
    const checkbox_input = document.getElementById(id);
    const ids = [];
    for (let index = 0; index < this.selectedTests.length; index++) {
      ids[index] = this.selectedTests[index].id;
    }
    if (ids.includes(test_id)) {
      checkbox_input.addEventListener("click", (event) => {
        event.preventDefault();
      });
    }
  }


  ngAfterViewInit() {
    if(this.action == "New"){
      this.patient = {
          age_type: "years",
        }
        this.getDefaultPrice()

    }
  }

  openReasonModal(test, amount) {
    this.testReasonTest = test;
    this.testReasonAmount = amount;
    $("#reasonModal").modal("show");
  }
  addReasons(reason) {
      if(reason.value == ''){
        return this.snack.showerror('please add a reason');
      }
      
      this.reasons.push({
        test_id: this.testReasonTest["id"],
        reason: reason.value,
      });
      this.removeTest(this.testReasonTest, this.testReasonAmount);
      this.reason = "";
      this.testReasonTest = {};
      this.testReasonAmount = 0;
      $("#reasonModal").modal("hide");
  }

  onSubmit(form, { isPay = false, isDiscount = false }) {
    // this.isDisable = true;
    if (this.request_being_made) return;
    form.value.isClinic = form.value.isClinic == 1 ? true : false;
    form.value["type"] = this.request["type"];
    form.value["doctor"] = this.doctor ? this.doctor.id : null;
    form.value["number"] = this.request.number;
    form.value["reasons"] = this.reasons;
    form.value.in_patient = form.value.in_patient == 1 ? true : false;
    form.value["correspondent_id"] =
      Number(form.value["correspondent_id"]) || null;
    form.value["identity_type_id"] =
      Number(form.value["identity_type_id"]) || null;
    let error = "";

    if (!this.selectedTests.length) {
      error = "Please pick at least one test | profile";
      // this.isDisable = false;
    }

    if (this.userUnits) {
      if (!this.selectedItems.length) {
        error = "Please pick a unit";
      }else{
        form.value['unit_ids'] = this.selectedItems
      }
    }

    if (error) return this.snack.showerror(error);

    form.value.patient = {...this.patient};

    form.value.patient['birthdate'] = this.data.format_date(form.value.patient['birthdate'])

    if(!form.value.patient['birthdate']) delete form.value.patient['birthdate']


    form.value["tests"] = this.selectedTests
      .map((one) => one.id)
      .filter((one) => {
        let bool = true;
        this.profiles.forEach((profile) => {
          profile["tests"].includes("" + one) ? (bool = false) : null;
      });
        return bool;
      });

    form.value["profiles"] = this.profiles.map(({ id, tests }) => ({
      id,
      tests,
    }));


    console.log($("#picker")[0])

    if ($("#picker")[0].value != "")
      form.value.hospitalization_date = $("#picker")[0]
        .value.split("-")
        .reverse()
        .join("-");

    if (this.request_id) {
      // this.isDisable = true;
      this.request_being_made = true;

      form.value.request_id = this.request_id;
      form.value.id = this.request_id;
      // console.log('form.value', form.value)
      // return

      this.api.Common.requests.update(form.value).subscribe(
        (res) => {
          this.request_being_made = false;

          if (isPay || isDiscount) this.openPayment(isDiscount);
          else {
            this.snack.show(
              `The request was successfully ${
                this.action == "New" ? "created" : "updated"
              }`
            );
            if (this.action == "New") {
              this.print();
              this.printBarcode();
            }
            setTimeout(() => this.router.navigateByUrl("receptionist"), 800);
          }

        },
        (error) => {
          this.request_being_made = false;
          this.snack.showerror(error["error"]["message"]);
        }
      );
    } else {
      if (!this.patient.first_name)
        error = "Please pick a firstname for the patient";
      else if (!this.patient.last_name)
        error = "Please pick a lastname for the patient";
      else if (!this.patient.birthdate && !this.patient.age)
        error = "Please pick birthdate or age for the patient";

      if (error) {
        // this.isDisable = false;
        return this.snack.showerror(error);
      }
      this.request_being_made = true;

      this.api.Common.requests.add(form.value).subscribe(
        (done) => {
          // this.isDisable = true;
          this.request_being_made = false;
          // document.getElementById("onSubmitButton").disabled = true;

          this.request = done["data"];
          this.request_id = done["data"]["id"];
          this.requestNum = done["data"]["reception_number"];

          this.patientId = done["data"]["patient_id"];

          if (isPay || isDiscount) this.openPayment(isDiscount);
          else {
            // $('#onSubmitButton').prop('disabled', true);

            this.snack.show("the request was successfully created");
            this.print();
            this.printBarcode();
            setTimeout(() => this.router.navigateByUrl("receptionist"), 800);
          }
        },
        (error) => {
          this.request_being_made = false;
          this.snack.showerror(error["error"]["message"]);
        }
      );
    }

  }

  get_body() {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            request_id: this.request.id,
            correspondent_id: Number(this.request["correspondent_id"]) || null,
            identity_type_id: Number(this.request["identity_type_id"]) || null,
            pricing_id: this.request["pricing_id"],
            discount: this.discount["amount"],
            isPercentage: this.discount["type"] == "percentage",
            tests: this.selectedTests.map((one) => one.id),
            selectedItems: this.selectedItems.map((one) => one),
          }),
        0
      )
    );
  }


  getDefaultPrice() {
      if(Object.keys(this.data.info).length){
        if (this.data.info["settings"]["defaultPrice"] != null) {
          this.defaultPrice = this.data.info["settings"]["defaultPrice"];
        } else {
          this.pricings = this.api.Common.Get.pricings().pipe(
            tap((res) => {
              this.defaultPrice = res[0]["id"];
              this.request.pricing_id = this.defaultPrice;
            })
          );
        }
      }
  }

  async changeTestsPricing(test = { id: null }) {
    let id = test.id;
    let body = await this.get_body();
    if (test["isPackage"]) {
      test["nested_tests"].forEach((te) => {
        if (!body["tests"].includes(te.nested_test_id))
          body["tests"].push(te.nested_test_id);
      });
    } else {
      if (id) {
        if (body["tests"].includes(id))
          return this.snack.showerror("Test/Profile is already selected");
        else body["tests"].push(id);
      }
    }

    if (body["tests"].length) {
      this.api.Common.Get.priced_tests(body).subscribe((one) => {
        this.selectedTests = one["data"]["tests"].map((test) => {
          let te = this.selectedTests.find((t) => t.id == test.id);
          te = Object.assign(te || {}, test);
          return te;
        });
        this.total = one["data"]["total"];
        this.remaining = one["data"]["total"] - this.originalTestsRemaining
      });
    }
  }

  async changeTestsPricingOnEdit(test = { id: null }) {
    let id = test.id;
    let body = await this.get_body();
    if (test["isPackage"]) {
      test["nested_tests"].forEach((te) => {
        if (!body["tests"].includes(te.nested_test_id))
          body["tests"].push(te.nested_test_id);
      });
    } else {
      if (id) {
        if (body["tests"].includes(id))
          return this.snack.showerror("Test/Profile is already selected");
        else body["tests"].push(id);
      }
    }

    if (body["tests"].length) {
      this.api.Common.Get.priced_tests(body).subscribe((one) => {
        this.selectedTests = one["data"]["tests"].map((test) => {
          let te = this.selectedTests.find((t) => t.id == test.id);
          te = Object.assign(te || {}, test);
          return te;
        });
        // this.total = one["data"]["total"];
        // this.remaining = one["data"]["total"] - this.originalTestsRemaining
      });
    }
  }


  getSelectedTests() {
    if (this.request_id != null) {

          this.selectedTests = this.request.tests.map((test) => {
            this.profiles.forEach((profile) => {
              profile.tests.includes("" + test.id)
                ? (test["profile"] = profile)
                : null;
            });
            return test;
          });


          switch (this.request.tests.length) {
            case 0:
              this.isTestSelected = false;
              break;
            default:
              this.isTestSelected = true;
          }


    }
    this.progress.off()
  }

  getRequest() {
    this.api.Common.requests.get_one(this.request_id).subscribe((res) => {

      [res['patient'].age, res['patient'].age_type, res['patient'].birthdate] = this.ago.transform(
        res['patient']["birthdate"],
        { slashes: true }
      );
      this.patient = res['patient'];

      this.request = res;
      this.request.hospitalization_date
        ? (this.request.hospitalization_date = this.request.hospitalization_date
            .split("-")
            .reverse()
            .join("-"))
        : null;
      if (this.request["doctor"]) {
        if (this.doctors.length) {
          this.doctor = this.doctors.find(
            (doc) => doc["id"] == this.request["doctor"]
          );
        }
      }
      delete this.request["type"];
      this.request.correspondent_id = res["correspondent"]
        ? res["correspondent"]["id"]
        : "0";
      this.request.identity_type_id = res["identity_type_id"]
        ? res["identity_type_id"]
        : "0";
      this.selectedItems = res["unit_ids"]
        ? res["unit_ids"]
        : [];
      this.request.pricing_id = res["pricing"]["id"];
      this.request.urgent_flag = res["urgent_flag"];
      this.requestNum = res["reception_number"];
      this.discount = res["discount"] || {};
      let picker = $("#picker")[0]
      picker? picker.value = res["hospitalization_date"]
        ? this.theDate.transform(res["hospitalization_date"])
        : "01-01-1990" : null;
      // this.total = res['total']
      this.getSelectedTests();
      this.changeTestsPricingOnEdit();
      // this.changeTestsPricing();

    });
  }

  removeTest(test, amount) {
    this.selectedTests = this.selectedTests.filter((one) => one.id != test.id);
    if (test.profile) {
      let profile = this.profiles.find((pro) => pro.id == test.profile.id);
      profile["tests"] = profile["tests"].filter((one) => one != test.id);
    }
    this.total -= amount;
    this.remaining -= amount;

    // this.api.Tests.removeTestFromRequest(this.request_id, test.id).subscribe(
    //   (res) => {
    //     console.log(res);

    //     this.getSelectedTests();
    //     this.getTotal();
    //     this.get_payment();
    //   }
    // );
  }

  get_payment() {
    this.data.payment_patientId = null;
    this.data.payment_request_id = null;
  }

  getTotal() {
    this.api.Common.requests.get_total(this.request_id).subscribe((res) => {
      this.total = res["total_summery"];
      this.remaining = res["remaining"];
      this.originalTestsRemaining = res["total_summery"] - res["remaining"];
    });
  }

  print() {
    if(!this.is_lab_to_lab){
      console.log('before this.request.correspondent_id', this.request.correspondent_id)
      console.warn('before this.request.correspondent_id', this.request.correspondent_id)
        if(!this.request.correspondent_id){
          console.log('after this.request.correspondent_id', this.request.correspondent_id)
          console.warn('after this.request.correspondent_id', this.request.correspondent_id)
          this.api.Common.printables.PrintRecieptForZebra(this.request_id).subscribe(
            (response) => {
              //Next callback
            },
            (error) => {
              //Error callback
              console.error("error caught in component");
              this.api.Common.printables.PrintReciept(this.request_id).subscribe((res) => {
                const fileURL = URL.createObjectURL(res);
                window.open(fileURL, "_blank");
              });
            }
          );
        }
    }else{
      this.api.Common.printables.PrintRecieptForZebra(this.request_id).subscribe(
        (response) => {
          //Next callback
        },
        (error) => {
          //Error callback
          console.error("error caught in component");
          this.api.Common.printables.PrintReciept(this.request_id).subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, "_blank");
          });
        }
      );
    }
  }

  printBarcode() {
    this.api.Common.printables.PrintBarcodesForZebra(this.request_id, false).subscribe(
      (response) => {
        //Next callback
      },
      (error) => {
        //Error callback
        console.error("error caught in component");
        this.api.Common.printables.PrintBarcodes(this.request_id, true).subscribe(
          (res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, "_blank");
          }
        );
      }
    );
  }
}
