import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-receptionist-layout',
  templateUrl: './receptionist-layout.component.html',
  styleUrls: ['./receptionist-layout.component.scss']
})
export class ReceptionistLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
