import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {



  constructor(public data : DataService) { }

  ngOnInit() {

    if($(window).width() > 768){
      $('#sidebar').hover(  () => {

        this.hover()

      });
    }

    if($(window).width() < 768){

      this.data.sidebarStatus = false

    }

    $( window ).resize(()=>{

        if($(window).width() > 768){

          // $('#sidebar').addClass('active');
          $('#content').width('calc(100% - 120px)');
          this.data.sidebarStatus = true

          $('#sidebar').hover(  () => {

            this.hover()

           });

        }

        else

        if($(window).width() < 768){

          $('#content').width('100%');
          // $('#sidebar').addClass('active');
          this.data.sidebarStatus = false

          $('#sidebar').off('mouseenter mouseleave');

        }

      })

  }

  hover(){
    this.data.sidebarStatus = !this.data.sidebarStatus
  }

}
