import { Component, OnInit } from '@angular/core';
import { ProgressService } from 'src/app/services/progress.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'app-samples',
  templateUrl: './samples.component.html',
  styleUrls: ['./samples.component.scss']
})
export class SamplesComponent implements OnInit {
  samples: any[] = [];

  constructor(
    private data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public ms:ModalService
  ) {}

  ngOnInit() {
    this.getSamples();
  }

  getSamples() {
    this.progress.on();
    this.api.Common.Get.samples().subscribe((res) => {
      this.progress.off();
      this.samples = res;
    });
  }

}
