import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { map, tap, debounceTime } from 'rxjs/operators';
import { SnackService } from 'src/app/services/snack.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ProgressService } from 'src/app/services/progress.service';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $:any;

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent implements OnInit {
  key: any = "";
  doctors: any = []
  page : any = 1
  pagination: any ;
  isLoaderHidden = true;


  constructor(public data:DataService,public ms:ModalService, public router:Router,public api : ApiService,public progress : ProgressService) { }

  ngOnInit() {

  this.progress.on()
  this.getDoctors(this.page,this.key);

  }

  getDoctors(page,key){


    this.page = page
    // $('.dropdown-menu').dropdown('show');
    //  this.api.Common.Get.doctorsWithPaginate({name:res}).subscribe(res => {
     this.api.Common.Get.doctorsWithPaginate({page,name:key || ''}).subscribe(res => {
      this.doctors = res['data']
      this.pagination = this.data.extract_pagination(res)
      this.progress.off()
      this.isLoaderHidden = true
    });

  }


  ngAfterViewInit(){

    var input$ : Observable<any> = fromEvent($('#pat_autocomplete'), 'input');
    input$.pipe(map(res => res['target'].value),debounceTime(500)).subscribe(res => {

      this.key = res
      this.isLoaderHidden = false
      this.getDoctors(this.page,this.key);



    })

  }



}
