<ng-container *ngIf="ticket">
    <div class="row">
        <div class="col">
            <h2 class="roboto-300 start">{{'Ticket Details' | locale}}</h2>
        </div>
    </div>
    <div class="card">
        <!-- <div class="card-header">
            {{ticket.lab.name}}
        </div> -->
        <div class="card-body" style="background-color: rgb(60, 60, 233);">
          <blockquote class="blockquote mb-0">
            <p style="color: white; text-align: right;" dir="rtl">{{ticket.description}}</p>
            <footer class="blockquote-footer" style="color: white;">{{ticket.created_at | date: "full"}}</footer>
          </blockquote>
        </div>
    </div>
    <ng-container *ngFor="let reply of replies;let i = index">
        <div class="card mt-5">
            <ng-container *ngIf="reply.sender == 'user'">
                <!-- <div class="card-header">
                    {{reply.user.name}}
                </div> -->
                <div class="card-body">
                    <blockquote class="blockquote mb-0">
                      <p style="text-align: left;" dir="rtl">{{reply.message}}</p>
                      <footer class="blockquote-footer">{{reply.created_at | date: "full"}}</footer>
                    </blockquote>
                  </div>
            </ng-container>
            <ng-container *ngIf="reply.sender == 'lab'">
                <!-- <div class="card-header">
                    {{ticket.lab.name}}
                </div> -->
                <div class="card-body" style="background-color: rgb(60, 60, 233);">
                    <blockquote class="blockquote mb-0">
                      <p style="color: white; text-align: right;" dir="rtl">{{reply.message}}</p>
                      <footer class="blockquote-footer" style="color: white;">{{reply.created_at | date: "full"}}</footer>
                    </blockquote>
                  </div>
            </ng-container>
        </div>
    </ng-container>
    
    <ng-container *ngIf="ticket_status">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <form #form="ngForm" id="cor_form" (ngSubmit)="onSubmit(form)">
                    <div class="row neue-reg mt-3">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input required [(ngModel)]="message" name="message" type="text" class="form-control" [placeholder]="'Message' | locale" #message_input="ngModel" />
                            </div>
                            <div class="form-group">
                                    <button
                                    form="cor_form"
                                    type="submit"
                                    class="btn btn-outline-primary tajwal-regium px-5"
                                >
                                    {{'Send' | locale}}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-2"></div>
        </div>
    </ng-container>
</ng-container>