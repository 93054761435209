import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { TestParams } from 'src/app/services/common-api.service';
import { debounce } from 'lodash';
import { SnackService } from 'src/app/services/snack.service';
import { ModalService } from 'src/app/services/modal.service';
declare var $: any;

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss'],
})
export class TestsComponent implements OnInit, AfterViewInit {
  username: any = localStorage.getItem('userName');
  days_left = null;
  selectedTests = [];
  tests: any[] = [];
  action: any = {};
  total: number = 0;
  pagination: any = {};
  isLoaderHidden = true;
  key: any = '';
  page: number = 1;
  pricing_id: number = 0;
  category_id = -1;
  tests_status = 'active';
  tests_alphabetical_order ='id'

  print(pricing, excel, filtered, alphabetical) {
    excel = excel?.checked ? 1 : 0;
    filtered = filtered?.checked ? 1 : 0;
    alphabetical = alphabetical?.checked ? 1 : 0;
    this.api.Common.printables
      .print_tests({ pricing, excel, filtered , alphabetical})
      .subscribe((res) => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
  }

  PrintRenameTestsReport() {
    setTimeout(
      () =>
        this.api.Common.printables.PrintRenameTestsReport().subscribe((res) => {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL, '_blank');
        }),
      0
    );
  }

  removetest(index) {
    this.selectedTests.splice(index, 1);
    this.total = this.selectedTests.reduce(
      (acc, ele) => (acc += ele.amount || 0),
      0
    );
  }

  constructor(
    public router: Router,
    public api: ApiService,
    public progress: ProgressService,
    public data: DataService,
    private snack: SnackService,
    public ms: ModalService
  ) {
    this.setSelectedTest = debounce(this.setSelectedTest, 100);
  }

  setSelectedTest(test) {
    if (this.selectedTests.find((one) => one.code == test.id))
      return this.snack.showerror('test is already selected');
    this.selectedTests.unshift(test);
    // console.log(this.selectedTests.unshift(test));
    this.total = this.selectedTests.reduce(
      (acc, ele) => (acc += ele.amount || 0),
      0
    );
  }

  ngOnInit() {
    this.data.infoAssigned.subscribe((isAssigned) => {
      if (isAssigned) {
        let today = new Date().toISOString().slice(0, 10);

        const diffInMs =
          +new Date(this.data.info['expire_at']) - +new Date(today);
        this.days_left = diffInMs / (1000 * 60 * 60 * 24)
        if(this.days_left < 0) this.days_left = 0
      }
    });

    this.data.dataAssigned.subscribe((isAssigned) => {
      if (isAssigned && this.data.pricings.length)
        this.pricing_id = this.data.pricings[0].id;
    });

    this.progress.on();
    this.getTests(1, this.key);

    this.data.remove.subscribe(({ id, type }) => {
      if (type == 'test') {
        this.tests = this.tests.filter((one) => one.id != id);
        $('#test-modal').modal('hide');
        $('#profile-modal').modal('hide');
      }
    });
  }
  changeFilter() {
    this.tests = [];
    this.getTests(1, this.key);
  }

  onChange(newValue) {
    // this.progress.on();
    this.tests = [];
    this.getTests(1, this.key, newValue);
  }
  ngAfterViewInit() {
    var input$: Observable<any> = fromEvent($('#test_autocomplete'), 'input');
    input$
      .pipe(
        map((res) => res['target'].value),
        debounceTime(500)
      )
      .subscribe((res) => {
        this.key = res;
        this.isLoaderHidden = false;
        this.getTests(1, this.key);
      });
  }

  getTests(page, key, pricing_id: number = 1) {
    // $('.dropdown-menu').dropdown('show');
    this.page = page;
    let params = {
      page,
      code: key || '',
      pricing_id,
      category: this.category_id == -1 ? '' : this.category_id,
      status: this.tests_status,
      isPaginate: 1,
      isOrderBy: this.tests_alphabetical_order
    };
    this.api.Common.Get.tests(params as TestParams).subscribe((res) => {
      this.pagination = this.data.extract_pagination(res);
      console.log(this.pagination);
      this.progress.off();
      this.isLoaderHidden = true;

      this.tests = res['data'];
    });
  }

  openModal(action) {
    this.action = action;
    if (action.isPackage) {
      this.ms.assign({
        title: 'Edit a Package',
        action: 'Update',
        datum: {},
        inputs: { name: true, code: true, isPackage: true },
        method: this.api.Admin.packages.update,
        message: 'Package was updated successfully',
        onSuccess: this.getTests.bind(
          this,
          this.page,
          this.key,
          this.pricing_id
        ),
        deleteMethod: this.api.Admin.packages.remove,
        deleteMessage: 'Package was deleted successfully',
        getDatum: this.api.Admin.packages.get_one.bind(this, action.id),
      });
    } else $('#test-modal').modal('show');
  }

  openTestsPriceModal() {
    $(`#TestsPriceModal`).modal('show');
  }


  openPrintRenameTestsReportModal() {
    $(`#PrintRenameTestsReport`).modal('show');
  }

  testCreated() {
    this.getTests(this.page, this.key, this.pricing_id);
    $('#test-modal').modal('hide');
    $('#profile-modal').modal('hide');
  }
}
