
    <div class="row">
      <div class="col">
        <h2 class="roboto-300 start">{{'Doctors' | locale}}</h2>
      </div>
    </div>

    <div class="card mt-3 mb-5">
      <div class="container-fluid py-3 px-4">



        <div class="row mt-2">


          <div class="col">

              <div class="form-group m-0 roboto-400"><input type="text"
                name="key" id="pat_autocomplete" class="form-control"
                [placeholder]="'Search by doctor name' | locale">

              </div>

          </div>

          <div *roles="{admin:true}" class="col-12 col-lg-2 mt-3 mt-lg-0">
            <div class="d-flex w-100 justify-content-end ">
              <button
              (click)="
                ms.assign({
                  title: 'Add a Doctor',
                  route: null,
                  datum: {},
                  inputs: { name: true, specialty: true, phone:true },
                  method: api.Admin.doctors.add,
                  message: 'Doctor was added successfully',
                  onSuccess: getDoctors.bind(this)
                });
              "
              type="button"
              id="add-button"
              class="btn btn-outline-primary w-100 roboto-400"
            >
              {{ "Add" | locale }} +
            </button>
            </div>
          </div>


        </div>

        <div class="row">

          <div class="col-12">
            <div class="table-responsive  ">
              <table class="table mt-3">
              <thead>
                <tr class="neue-light roboto-400">
                  <th width="50%" scope="col"> {{'id' | locale:2}}</th>
                  <th width="50%" scope="col"> {{'Name' | locale}}</th>
                  <th width="50%" scope="col"> {{'Phone' | locale}}</th>
                  <th width="50%" scope="col"> {{'Specialty' | locale}}</th>


                </tr>



              </thead>

              <tbody class="neue-light mt-2">
                <ng-container *ngFor="let doctor of doctors | paginate: { id:'pagination' ,
                itemsPerPage: pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index; ">


                   <tr   (click)="
                   ms.assign({
                     title: 'Edit a Doctor',
                     action:'Update',
                     route: null,
                     datum: doctor,
                     inputs: { name: true, specialty: true, phone:true },
                     method: api.Admin.doctors.update,
                     message: 'Doctor was updated successfully',
                     onSuccess: getDoctors.bind(this),
                     deleteMethod:api.Admin.doctors.remove,
                     deleteMessage:'Doctor was deleted successfully'
                   });
                 "
                 *roles="{admin:true, type:'click'}"
                 valign="middle"  class="clickable_row roboto-400">
                    <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                    <td width="50%" >{{i + 1}}</td>
                    <td width="50%">{{doctor.name || '✖' }}</td>
                    <td width="50%">{{doctor.phone || '✖' }}</td>
                    <td width="50%">{{doctor.specialty || '✖' }}</td>

                  </tr>
                </ng-container>



              </tbody>
              </table>

              <ng-container *ngIf="pagination">
                <div class="col-12 mt-5">
                  <div class="w-100 d-flex justify-content-center neue-med p-0">
                      <pagination-controls
                      responsive="true"
                      [previousLabel]="'Previous' | locale"
                      [nextLabel]="'Next' | locale"
                      (pageChange)="getDoctors($event)"
                      id="pagination"></pagination-controls>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>



        </div>


      </div>
    </div>
